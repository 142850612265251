import { Space, Typography, message, Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	MediaDataFragment,
	TranscriptionDataFragment,
	Message,
	useRemoveIncidentMediaBlobMutation,
	useUpdateTranscriptionMutation,
} from '../../generated/graphql';
import { Button } from '../util/Button';
import { PlusOutlined, WarningFilled, WarningOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import './Transcription.less';

export interface TranscriptionProps {
	children: React.ReactNode;
	media: MediaDataFragment;
	incidentId: number;
	side: 'hinter' | 'user';
}

export const Transcription = ({
	children,
	media,
	incidentId,
	side,
}: TranscriptionProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'Transcription' });
	const qC = useQueryClient();
	const { mutateAsync, isLoading, isError } = useUpdateTranscriptionMutation();
	const removeMediaBlob = useRemoveIncidentMediaBlobMutation();
	const [transcription, setTranscription] = useState<
		TranscriptionDataFragment | null | undefined
	>(media.transcription);
	const [editing, setEditing] = useState(false);

	const onAddTranscription = async () => {
		setEditing(true);
	};

	const saveEditedTranscription = async (update: string) => {
		if (update === transcription?.text) {
			setEditing(false);
			return;
		}
		try {
			const res = await mutateAsync({
				id: media.id,
				input: { text: update ?? transcription?.text ?? '' },
			});
			if (res.updateTranscription == null) throw new Error('Update failed');
			setTranscription(res.updateTranscription);
			message.success(t('Message.Update.Success'));
			setEditing(false);
		} catch (err) {
			message.error(t('Message.Update.Error'));
		}
	};

	const finalizeTranscription = async () => {
		Modal.confirm({
			title: t('Finalize.Confirm'),
			okButtonProps: {
				loading: removeMediaBlob.isLoading,
			},
			onOk: async () => {
				try {
					const success = await removeMediaBlob.mutateAsync({
						incidentId,
						mediaId: media.id,
					});
					if (!success) {
						message.error('Finalize.Message.Error');
					}
					message.success('Finalize.Message.Success');
					qC.invalidateQueries({ queryKey: ['messagesOfIncident'] });
					qC.invalidateQueries({ queryKey: ['incident'] });
				} catch (e) {
					message.error('Finalize.Message.Error');
				}
			},
		});
	};

	return (
		<Space
			direction="vertical"
			size={[0, 2]}
			style={{
				width: '100%',
			}}
			className={media.deleted || transcription?.text ? 'Transcription' : ''}
		>
			{children}
			{side === 'user' &&
			!editing &&
			(transcription == null || transcription.text === '') ? (
				<div style={{ paddingLeft: '4px', marginTop: '4px' }}>
					{!media.storageConsent ? (
						<Typography.Text type="warning">{t('ProtocolNeeded')}</Typography.Text>
					) : null}
					<Button
						size="small"
						type="text"
						icon={<PlusOutlined />}
						onClick={onAddTranscription}
					>
						{t('Create')}
					</Button>
				</div>
			) : (
				(side === 'user' || transcription?.text) && (
					<div style={{ paddingLeft: '4px', marginTop: '4px' }}>
						<Typography.Text type="secondary">{t('Title')}</Typography.Text>
						<Typography.Paragraph
							style={{ paddingLeft: '8px' }}
							editable={
								side === 'hinter' || media.deleted
									? undefined
									: {
											onChange: saveEditedTranscription,
											editing,
											onStart: () => {
												setEditing(true);
											},
											onEnd: () => {
												setEditing(false);
											},
									  }
							}
						>
							{transcription?.text ?? ''}
						</Typography.Paragraph>
						{side === 'user' &&
							!media.deleted &&
							!media.storageConsent &&
							transcription?.text && (
								<Button
									type="primary"
									onClick={finalizeTranscription}
									size="small"
									loading={removeMediaBlob.isLoading}
								>
									{t('Finalize.Label')}
								</Button>
							)}
						{editing && !media.storageConsent && (
							<Typography.Paragraph type="secondary">
								<WarningFilled /> {t('ProtocolOnlyWarning')}
							</Typography.Paragraph>
						)}
					</div>
				)
			)}
		</Space>
	);
};
