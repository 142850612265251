/* eslint-disable react/prop-types */
import { Col, Form, message, Modal, Result, Row, Typography } from 'antd';
import { FormProvider } from 'antd/lib/form/context';
import { useForm } from 'antd/lib/form/Form';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import {
	CreateIncidentInput,
	IncidentCategory,
	IncidentType,
	useAssignPhoneCallToIncidentMutation,
	useCompanyChannelsQuery,
	useCreateIncidentManualMutation,
} from '../../../generated/graphql';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { formItemLayout, IncidentReportFormFields } from '../../Form/IncidentReportForm';
import moment, { Moment } from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Button } from '../../util/Button';
import { Select } from '../../util/Select';
import { DatePicker } from '../../util/DatePicker';
import { IncidentTypeSelect } from '../../util/EnumSelect';
import { useNavigate } from 'react-router';

export type FormDataType = {
	created: Moment;
	phoneCallId?: number;
} & CreateIncidentInput;

export interface CreateIncidentModalProps {
	channelId?: number;
}

export type CreateIncidentModalHandles = {
	open: (initialValues?: Partial<FormDataType>) => void;
	close: () => void;
};

export const CreateIncidentModal = forwardRef<
	CreateIncidentModalHandles,
	CreateIncidentModalProps
>(function CreateIncidentModal({ channelId }, ref) {
	const navigate = useNavigate();
	const [form] = useForm<FormDataType>();
	const { mutateAsync, isLoading } = useCreateIncidentManualMutation();
	const { mutateAsync: assignPhoneCall } = useAssignPhoneCallToIncidentMutation();
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'CreateIncidentModal',
	});
	const qC = useQueryClient();
	const selectedCompany = useSelectedCompanyStore((state) => state.company);
	const [result, setResult] = useState<JSX.Element>();
	const [visible, setVisible] = useState(false);
	const {
		data: channelsData,
		isLoading: channelsLoading,
		isSuccess,
		isError,
	} = useCompanyChannelsQuery(
		{ id: selectedCompany?.id ?? 0 },
		{ enabled: selectedCompany != null },
	);
	const channels = channelsData?.company.channels;

	const open = (
		initialValues: Partial<FormDataType> = {
			category: IncidentCategory.Uncategorized,
			channelId,
			hinter: { anonymous: false },
		},
	) => {
		form.setFieldsValue(initialValues);
		setVisible(true);
	};

	const close = () => {
		setVisible(false);
	};

	const submit = (values: FormDataType) => {
		const { created, ...input } = form.getFieldsValue();
		input.encryptionVersion = 0;
		input.recipients = [];
		mutateAsync({ created, input })
			.then((res) => {
				message.success(tt('Success.Title'));
				qC.invalidateQueries('self');
				qC.invalidateQueries({
					predicate: (q) => q.queryKey === 'incidents' || q.queryKey[0] === 'incidents',
				});
				if (values.phoneCallId) {
					assignPhoneCall({
						phoneCallId: values.phoneCallId,
						incidentId: res.createIncidentManual.incident.id,
					})
						.then((res2) => {
							message.success(
								t('PhoneCallCard.Assign.Message.Success', {
									incident: res.createIncidentManual.incident.fileNumber,
								}),
							);
							navigate('/incident/' + res.createIncidentManual.incident.id);
						})
						.catch((err) => {
							message.error(t('PhoneCallCard.Assign.Message.Error'));
						})
						.finally(() => {
							close();
						});
				} else {
					navigate('/admin/incident/' + res.createIncidentManual.incident.id);
					close();
				}
			})
			.catch((err) => {
				message.error(tt('Error.Title'));
			});
	};

	const onCancel = () => {
		form.resetFields();
		close();
		setResult(undefined);
	};

	useImperativeHandle(ref, () => ({ open, close }));

	return (
		<Modal
			title={tt('Title')}
			visible={visible}
			confirmLoading={isLoading}
			onCancel={onCancel}
			okText={tt('OkText')}
			cancelText={tt('CancelText')}
			destroyOnClose={true}
			maskClosable={false}
			footer={false}
			bodyStyle={{
				animation: '0.5s ease-in',
			}}
		>
			<Form form={form} onFinish={(values) => submit(values)}>
				<Form.Item {...formItemLayout} name="created" label={tt('Field.Received.Label')}>
					<DatePicker showTime defaultValue={moment()} />
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					name="channelId"
					label={tt('Field.Channel.Label')}
					rules={[{ required: true, message: tt('Field.Channel.Missing') }]}
				>
					<Select defaultValue={channelId}>
						{channels?.map((c) => (
							<Select.Option key={c.id} value={c.id}>
								{c.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					{...formItemLayout}
					name="type"
					label={tt('Field.Type.Label')}
					rules={[{ required: true, message: tt('Field.Type.Missing') }]}
				>
					<IncidentTypeSelect />
				</Form.Item>
				<IncidentReportFormFields form={form} />
				<Row justify="space-between">
					<Col>
						<Button type="text" onClick={onCancel}>
							{t('Cancel')}
						</Button>
					</Col>
					<Col>
						<Button onClick={() => form.submit()} type="primary">
							{t('Submit')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
});
