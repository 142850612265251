import './Select.less';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { OptionProps } from 'antd/lib/mentions';
import { DesignConfig } from '../HinterPortal/ChannelEditor';
import { CSSProperties, useEffect, useRef } from 'react';

type SelectType = {
	<VT extends SelectValue = SelectValue>(
		props: AntdSelectProps<VT> & { design?: DesignConfig },
	): JSX.Element;
	Option: typeof AntdSelect.Option;
	OptGroup: typeof AntdSelect.OptGroup;
};

const MySelect: any = <VT extends SelectValue = SelectValue>({
	design,
	...props
}: AntdSelectProps<VT> & { design?: DesignConfig }) => {
	let styleOverwrite: CSSProperties | null = null;
	if (design) {
		styleOverwrite = {};
		if (design.borderRadius != null)
			styleOverwrite.borderRadius =
				design.borderRadius < 1 ? 'none' : design.borderRadius + 'px';
		if (design.borderWidth != null) {
			styleOverwrite.outlineStyle = design.borderWidth < 1 ? 'none' : 'solid';
			styleOverwrite.outlineWidth = design.borderWidth;
		}
		if (design.borderColor != null) styleOverwrite.outlineColor = design.borderColor;
		// if (design.fontColor != null) styleOverwrite.color = design.fontColor;
		// if (design.fontFamily != null) styleOverwrite.fontFamily = design.fontFamily;
	}
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current != null && styleOverwrite) {
			let selector = ref.current.querySelector(
				'.ant-select-selector',
			) as HTMLElement | null;
			if (selector) {
				if (styleOverwrite.borderRadius != null)
					selector.style.borderRadius = styleOverwrite.borderRadius as string;
				if (styleOverwrite.outlineWidth != null)
					selector.style.borderWidth = styleOverwrite.outlineWidth + 'px';
				if (styleOverwrite.outlineColor != null)
					selector.style.borderColor = styleOverwrite.outlineColor;
				// if (styleOverwrite.color != null) selector.style.color = styleOverwrite.color;
				if (styleOverwrite.fontFamily != null)
					selector.style.fontFamily = styleOverwrite.fontFamily;
				console.log(
					'selector',
					selector.style.borderRadius,
					', ',
					styleOverwrite.borderRadius,
					'props ',
					props,
				);
			}
		}
	}, [design]);

	return (
		<div ref={ref}>
			<AntdSelect
				dropdownStyle={{ backgroundColor: '#F0F2F5', ...styleOverwrite }}
				className="Select"
				style={{ ...styleOverwrite }}
				{...props}
			/>
		</div>
	);
};
const MyOption = (props: OptionProps & { design?: DesignConfig }) => (
	<AntdSelect.Option {...props} />
);
const MyOptGroup = (props: any) => <AntdSelect.OptGroup {...props} />;
MySelect.Option = MyOption;
MySelect.OptGroup = MyOptGroup;

export const Select = MySelect as SelectType;
