import { FormInstance } from 'antd';
import { CryptographyKey } from 'sodium-plus';
import create from 'zustand';
import {
	CreateHinterInput,
	CreateIncidentInput,
	IncidentType,
} from '../../../generated/graphql';
import { RequiredPick, Incident } from './util';

interface State {
	step: number;
	incidentForm: RequiredPick<Partial<CreateIncidentInput>, 'hinter'>;
	switchedToVoice: boolean;
	encryptionKey?: CryptographyKey;
	incident?: Incident;
	password?: string;
	passwordConfirm?: string;
	masterKeySalt?: string | null;
	formInstance?: FormInstance<CreateIncidentInput>;
}
export interface Store extends State {
	setEncryptionKey: (key: CryptographyKey) => void;
	stepForward: (steps: number) => void;
	stepBackward: () => void;
	updateIncidentForm: (
		update: Partial<Omit<Omit<CreateIncidentInput, 'hinter'>, 'encryptionVersion'>>,
	) => void;
	updateHinterForm: (
		update: Partial<Omit<CreateIncidentInput['hinter'], 'encryptedPrivateKey'>>,
	) => void;
	setIncident: (incident: Incident) => void;
	setPassword: (password: string) => void;
	setPasswordConfirm: (passwordConfirm: string) => void;
	clear: () => void;
	setSwitchedToVoice: (switchedToVoice: boolean) => void;
	setFormInstance: (formInstance: FormInstance<CreateIncidentInput>) => void;
}

const initialStoreState = {
	step: 0,
	incidentForm: {
		hinter: {
			anonymous: false,
			encryptedPrivateKey: undefined,
			masterKeySalt: undefined,
			publicKey: undefined,
		} as CreateHinterInput,
	} as CreateIncidentInput,
	encryptionKey: undefined,
	incident: undefined,
	password: undefined,
	passwordConfirm: undefined,
	masterKeySalt: undefined,
	switchedToVoice: false,
	formInstance: undefined,
};

const useStore = create<Store>((set) => ({
	...initialStoreState,
	setEncryptionKey: (encryptionKey: CryptographyKey) =>
		set((state) => ({
			encryptionKey,
		})),
	stepForward: (steps: number) =>
		set((state: State) => ({
			step: state.step < steps - 1 ? state.step + 1 : state.step,
		})),
	stepBackward: () =>
		set((state: State) => ({ step: state.step > 0 ? state.step - 1 : state.step })),
	setPassword: (password: string) => set((state: State) => ({ password })),
	setPasswordConfirm: (passwordConfirm: string) =>
		set((state: State) => ({ passwordConfirm })),
	setIncident: (incident: Incident) => set({ incident }),
	setFormInstance: (formInstance: FormInstance<CreateIncidentInput>) =>
		set({ formInstance }),
	updateIncidentForm: (update) =>
		set((state: State) => ({
			incidentForm: { ...state.incidentForm, ...update },
			switchedToVoice:
				state.incidentForm.type !== IncidentType.Voice &&
				update.type === IncidentType.Voice,
		})),
	updateHinterForm: (update) =>
		set((state: State) => ({
			incidentForm: {
				...state.incidentForm,
				hinter: {
					...state.incidentForm.hinter,
					...update,
				},
			},
		})),
	clear: () => set((state: State) => initialStoreState),
	setSwitchedToVoice: (switchedToVoice: boolean) =>
		set((state: State) => ({ switchedToVoice })),
}));

export default useStore;
