import { Row, Col, Space, Typography, Button, Divider, Form, Checkbox } from 'antd';
import SetupUser from './SetupUser';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Roles, useAuthStore } from '../../hooks/useAuth';
import { Logo } from '../util/Logo';
import { FullScreenGlass } from '../util/Glass';
import { SetupCompany } from './SetupCompany';
import {
	CreateUserMutationVariables,
	Languages,
	useCreateUserMutation,
} from '../../generated/graphql';
import { useEffect, useState } from 'react';
import { useManageMasterKey } from '../../hooks/useCrypt';
import { getLang } from '../util';
import { Alert } from '../util/Alert';
import { Input } from '../util/Input';
import { Setup } from './Setup';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { PageAnimation } from '../util/Animation';
import { useRedirectContextStore } from '../Redirect/useRedirectStore';
import { Card } from '../util/Card';
import { Helmet } from 'react-helmet';

export const CredentialsForm = ({
	toLogin,
	onFinished,
}: {
	toLogin?: () => void;
	onFinished: () => void;
}) => {
	const { t, i18n } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'RegisterForm' });
	const [form] = Form.useForm();
	const setSession = useAuthStore((state) => state.setSession);
	const { mutateAsync } = useCreateUserMutation();
	const [alert, setAlert] = useState<string | undefined>(undefined);
	const manageMasterKey = useManageMasterKey();

	const redirectContext = useRedirectContextStore((state) => state.context);
	const initialValues: any = {};
	if (redirectContext?.user) {
		initialValues.email = redirectContext.user.email;
	}

	const onFormFinish = ({
		email,
		password,
		termsAndConditionsAccepted,
		privacyPolicyAccepted,
	}: {
		email: string;
		password: string;
		confirm: string;
		termsAndConditionsAccepted: boolean;
		privacyPolicyAccepted: boolean;
	}) => {
		const input: CreateUserMutationVariables['input'] = {
			email,
			password,
			termsAndConditionsAccepted,
			privacyPolicyAccepted,
			lang: (i18n.language?.toUpperCase() || 'EN') as Languages,
		};

		mutateAsync({ input })
			.then((res) => {
				res.createUser && setSession(res.createUser);
				manageMasterKey(input.password);
				onFinished();
			})
			.catch((error) => setAlert(error?.response?.errors[0]?.message))
			.finally();
	};

	return (
		<Space direction="vertical" size="large">
			<Helmet>
				<title>{t('Meta.Register.Title')}</title>
				<meta name="description" content={t('Meta.Register.Description')} />
			</Helmet>
			<Typography.Title level={2}>{tt('Title')}</Typography.Title>
			<Form
				form={form}
				name="basic"
				// labelCol={{ span: 8 }}
				// wrapperCol={{ offset: 8, span: 16 }}
				onFinish={onFormFinish}
				autoComplete="on"
				layout="vertical"
				size="large"
				style={{ width: '100%' }}
				initialValues={initialValues}
			>
				<Card hoverable={false}>
					{alert ? (
						<Alert style={{ width: '100%' }} message={alert} type="error" />
					) : null}
					<Form.Item
						label={t('Email')}
						name="email"
						hasFeedback
						rules={[
							{ required: true, message: tt('Email.Missing') },
							{ type: 'email', message: tt('Email.Invalid') },
						]}
					>
						<Input maxLength={255} />
					</Form.Item>
					<Form.Item
						label={t('Password')}
						name="password"
						hasFeedback
						rules={[
							{ required: true, message: tt('Password.Missing') },
							({ getFieldValue }) => ({
								validator(_, value) {
									// const regex = /^(?=.*[0-9])(?=.*[\W])[a-zA-Z0-9\W]{12,36}$/;
									// const match = value.match(regex);
									const match = value.length > 11;
									if (match) return Promise.resolve();
									else return Promise.reject(new Error(tt('Password.Invalid')));
								},
							}),
						]}
					>
						<Input.Password maxLength={255} />
					</Form.Item>
					<Form.Item
						label={t('Confirm')}
						name="confirm"
						dependencies={['password']}
						hasFeedback
						rules={[
							{ required: true, message: tt('Confirm.Missing') },
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error(tt('Confirm.Invalid')));
								},
							}),
						]}
					>
						<Input.Password maxLength={255} />
					</Form.Item>
					<Form.Item
						label={tt('TermsAndConditions.Label')}
						name="termsAndConditionsAccepted"
						valuePropName="checked"
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value !== true)
										return Promise.reject(new Error(tt('TermsAndConditions.Missing')));
									return Promise.resolve();
								},
							}),
						]}
					>
						<Checkbox>
							{tt('TermsAndConditions.Description')}{' '}
							<a href="https://konfidal.eu/terms" target="_blank" rel="noreferrer">
								{tt('TermsAndConditions.Link')}
							</a>
						</Checkbox>
					</Form.Item>
					<Form.Item
						label={tt('PrivacyPolicy.Label')}
						name="privacyPolicyAccepted"
						valuePropName="checked"
						rules={[
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value !== true)
										return Promise.reject(new Error(tt('PrivacyPolicy.Missing')));
									return Promise.resolve();
								},
							}),
						]}
					>
						<Checkbox>
							{tt('PrivacyPolicy.Description')}{' '}
							<a href="https://konfidal.eu/privacy" target="_blank" rel="noreferrer">
								{' '}
								{tt('PrivacyPolicy.Link')}
							</a>
						</Checkbox>
					</Form.Item>
				</Card>
				<Row style={{ marginTop: '24px' }} justify="space-between">
					<Col>
						{toLogin !== undefined ? (
							<Button type="default" size="large" onClick={toLogin}>
								{t('Login')}
							</Button>
						) : null}
					</Col>
					<Col>
						<Form.Item>
							<Button
								style={{
									width: '100%',
									float: 'right',
								}}
								type="primary"
								htmlType="submit"
							>
								{t('Continue')}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Space>
	);
};

const RegisterPage = ({
	parentPath,
	toLogin,
}: {
	parentPath: string;
	toLogin: () => void;
}) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const role = useAuthStore((state) => state.session?.role);
	const [credentialsSubmitted, setCredentialsSubmitted] = useState(false);
	const [once, setOnce] = useState(false);
	const location = useLocation();

	useEffect(() => {
		console.log('Once: ', once);
		if (once) return;
		if (
			location.pathname.endsWith(parentPath) &&
			(credentialsSubmitted || isAuthenticated) &&
			role === Roles.USER
		) {
			setOnce(true);
			console.log('USER SETUP REDIRECT');
			navigate('/setup/user');
		}
	}, [role, isAuthenticated, credentialsSubmitted, once]);

	const locationParts = location.pathname.split('/');
	const baseIndex = locationParts.findIndex((x) => x === parentPath);
	const key = locationParts.slice(baseIndex + 1, baseIndex + 2).join('/');

	return (
		<Space align="center" direction="vertical" size="large">
			<Row justify="center" align="top">
				<Col>
					<Logo />
				</Col>
			</Row>
			<Row className="Thin">
				<AnimatePresence>
					<PageAnimation type="slide">
						<CredentialsForm
							toLogin={toLogin}
							onFinished={() => setCredentialsSubmitted(true)}
						/>
					</PageAnimation>
				</AnimatePresence>
			</Row>
		</Space>
	);
};

export default RegisterPage;
