import { DeleteOutlined, RedoOutlined } from '@ant-design/icons';
import { Col, Progress, Row, Spin } from 'antd';
import { Button } from '../../components/util/Button';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { useCallback } from 'react';
import { MediaType } from '../../generated/graphql';
import { Media, useMediaUpload } from '../../hooks/useREST';
import { DesignConfig } from '../HinterPortal/ChannelEditor';

export interface AttachmentProps {
	file?: File;
	type?: MediaType;
	onRemove: () => void;
	reset: () => void;
	progress: number;
	isError: boolean;
	isSuccess: boolean;
	isLoading: boolean;
	design?: DesignConfig;
}

export const Attachment = ({
	file,
	type: mediaType,
	isLoading = false,
	onRemove,
	reset,
	progress,
	isError,
	isSuccess,
	design,
}: AttachmentProps) => {
	// const [status, setStatus] = useState<"normal" | "success" | "exception" | "active">("normal");

	const onClickRemove = () => {
		onRemove();
	};

	const onClickRedo = () => {
		if (isError) {
			reset();
		}
	};

	let content = (
		<>
			{file?.name}, {file?.size} bytes
		</>
	);
	if (file && mediaType === MediaType.Audio) {
		content = <audio src={URL.createObjectURL(file)} controls />;
	}

	return (
		<Row align="middle">
			<Col style={{ padding: '0.25em' }}>
				<Progress
					width={40}
					type="circle"
					percent={progress}
					status={isError ? 'exception' : isSuccess ? 'success' : 'normal'}
				/>
			</Col>
			<Col flex="auto" style={{ padding: '0.25em' }}>
				{content}
			</Col>
			{isError ? (
				<Col>
					<Button
						design={design}
						onClick={onClickRedo}
						icon={<RedoOutlined />}
						size="large"
					/>
				</Col>
			) : null}
			<Col style={{ padding: '0.25em' }}>
				{isLoading ? (
					<Spin />
				) : (
					<Button
						design={design}
						onClick={onClickRemove}
						icon={<DeleteOutlined />}
						size="large"
					/>
				)}
			</Col>
		</Row>
	);
};
