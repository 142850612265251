import {
	CompanyDesignFragment,
	ChannelConfig as FullChannelConfig,
	UpdateChannelConfigInput,
	UpdateCompanyDesignConfigInput,
	RichText,
	FeatureNames,
} from '../../generated/graphql';
import create from 'zustand';
import produce from 'immer';
import { AnimatePresence, motion } from 'framer-motion';
import {
	Col,
	Row,
	Space,
	Spin,
	Switch,
	Typography,
	Button as AntdButton,
	message,
	Slider,
} from 'antd';
import {
	ArrowLeftOutlined,
	ArrowRightOutlined,
	CloseOutlined,
	EditFilled,
	EditOutlined,
	LoadingOutlined,
	SaveFilled,
	SaveOutlined,
	UndoOutlined,
} from '@ant-design/icons';
import { Button } from '../util/Button';
import { Card } from '../util/Card';
import { Input } from '../util/Input';
import BraftEditor, { EditorState } from 'braft-editor';
import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react';
import './ChannelEditor.less';
import { ReactDOM } from 'react';
import { FeatureLock } from '../AdminPortal/FeatureLock';
import { useTranslation } from 'react-i18next';
import { validateHex, ensureHexColor, validateRGB, isColor } from '../../lib/util';
import { ColorInput } from '../AdminPortal/ColorInput';
import { BoxShadowInput } from '../AdminPortal/BoxShadowInput';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';

export type DesignConfig = Omit<
	CompanyDesignFragment,
	'_uuid' | 'id' | 'nanoId' | 'created' | 'updated' | 'version' | '__typename'
>;

export type ChannelConfig = Omit<
	FullChannelConfig,
	'_uuid' | 'id' | 'nanoId' | 'created' | 'updated' | 'version' | '__typename'
>;

export type Config = {
	channel: FullChannelConfig;
	company: DesignConfig;
};
export type ConfigUpdate = {
	channel?: UpdateChannelConfigInput;
	company?: UpdateCompanyDesignConfigInput;
};

export type EditorSaveFunction = (
	channelId: number,
	companyId: number,
	config: Config,
	callback: (success: boolean) => void,
) => void;

export type EditorEditFunction = (update: ConfigUpdate) => void;

export interface ChannelEditStore {
	editing: boolean;
	initialized: boolean;
	saving: boolean;
	history: Config[];
	channelId?: number;
	companyId?: number;
	config?: Config;
	editorWindowVisible: boolean;
	onSaveSuccess?: () => void;
	onSaveError?: () => void;
	saveFn?: EditorSaveFunction;
	init: (
		channelId: number,
		companyId: number,
		config: Config,
		saveFn: EditorSaveFunction,
		onSaveSuccess?: () => void,
		onSaveError?: () => void,
	) => void;
	edit: EditorEditFunction;
	setEditing: (editing: boolean) => void;
	reset: () => void;
	undo: () => void;
	startSave: () => void;
	finishSave: (success: boolean) => void;
	setEditorWindowVisible: (v: boolean) => void;
}
export const useChannelEditStore = create<ChannelEditStore>((set, get) => ({
	editing: false,
	initialized: false,
	saving: false,
	history: [],
	editorWindowVisible: false,
	setEditing: (editing) => set({ editing }),
	init: (channelId, companyId, config, saveFn, onSaveSuccess, onSaveError) => {
		console.log('Store init ', channelId, companyId, config);
		set({
			channelId,
			companyId,
			config,
			saveFn,
			onSaveSuccess,
			onSaveError,
			initialized: true,
			editing: false,
		});
	},
	edit: (update) => {
		const curr = get();
		if (!curr.config) {
			message.warn('the channel editor is not initialized yet!'); // untranslated
			return;
		}
		set(
			produce((state) => {
				state.history.push(curr.config);
				state.config.company = { ...curr.config?.company, ...update.company };
				state.config.channel = { ...curr.config?.channel, ...update.channel };
			}),
		);
	},
	reset: () => {
		console.log('Store reset');
		set((state) => ({
			config: state.history.length > 0 ? state.history[0] : state.config,
			history: [],
			editing: false,
			editorWindowVisible: false,
		}));
	},
	undo: () =>
		set(
			produce((state) => {
				if (state.history.length > 0) state.config = state.history.pop();
			}),
		),
	startSave: () => {
		const state = get();
		if (
			!state.initialized ||
			!state.channelId ||
			!state.companyId ||
			!state.editing ||
			!state.config ||
			!state.saveFn
		)
			return;
		state.saveFn(state.channelId, state.companyId, state.config, state.finishSave);
	},
	finishSave: (success) =>
		set((state) => {
			if (success) state.onSaveSuccess?.();
			else state.onSaveError?.();
			return {
				saving: false,
				editing: !success,
				editorWindowVisible: success ? false : state.editorWindowVisible,
				history: state.config ? [state.config] : [],
			};
		}),
	setEditorWindowVisible: (v: boolean) => set({ editorWindowVisible: v }),
}));

export type RichTextEditorProps = {
	edit: (content: RichText) => void;
	editable: boolean;
	content?: RichText;
};
export const RichTextEditor = ({ edit, content, editable }: RichTextEditorProps) => {
	const [greetingTextBraftState, setGreetingTextBraftState] =
		useState<EditorState | null>(null);
	const [editing, setEditing] = useState(false);
	const editorRef = useRef<BraftEditor>(null);

	useEffect(() => {
		if (content && !greetingTextBraftState) {
			const persistedState = content.raw === '' ? content.html : content?.raw;
			setGreetingTextBraftState(
				BraftEditor.createEditorState(persistedState) as EditorState,
			);
		}
	}, [content, greetingTextBraftState]);

	const triggerSave = () => {
		let ctrlS = new KeyboardEvent('keydown', {
			key: 's',
			code: 'KeyS',
			ctrlKey: true,
		});
		document.dispatchEvent(ctrlS);
	};

	if (editable && editing)
		return (
			<>
				<Row justify="end" align="middle" gutter={16}>
					<Col>
						<Typography.Text style={{ color: '#AAAAAA' }}>
							To save, press Ctrl + S or{' '}
						</Typography.Text>
					</Col>
					{/* <Col>
						<SaveOutlined className="EditIcon" onClick={triggerSave} />
					</Col> */}
				</Row>
				<Row justify="start">
					<Col span={24}>
						<BraftEditor
							ref={editorRef}
							handleKeyCommand={() => {}}
							language="en"
							value={greetingTextBraftState}
							onChange={(state) => setGreetingTextBraftState(state)}
							onSave={() => {
								edit({
									html: greetingTextBraftState.toHTML() as string,
									raw: greetingTextBraftState.toRAW() as string,
								});
								setEditing(false);
							}}
						/>
					</Col>
				</Row>
			</>
		);
	else if (editable)
		return (
			<>
				<Row justify="end">
					<Col>
						<EditOutlined className="EditIcon" onClick={() => setEditing(true)} />
					</Col>
				</Row>
				<Row justify="start">
					<Col>
						<div dangerouslySetInnerHTML={{ __html: content?.html || '' }}></div>
					</Col>
				</Row>
			</>
		);
	else return <div dangerouslySetInnerHTML={{ __html: content?.html || '' }}></div>;
};

export const createChannelEditable = (
	current: ChannelConfig,
	property: keyof ChannelConfig,
	edit: EditorEditFunction,
) => {
	return {
		onChange: async (x: any) => {
			if (x === current[property]) return;
			edit({ channel: { [property]: x } });
		},
		autoSize: true,
	};
};

export const EditMenu = () => {
	const store = useChannelEditStore();

	return (
		<Space>
			<AnimatePresence>
				{store.editing && store.history.length > 0 ? (
					<motion.div
						initial={{ opacity: 0, x: 45 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: 45 }}
						transition={{ delay: 0.45 }}
					>
						<Button onClick={() => store.undo()} icon={<UndoOutlined />} size="large" />
					</motion.div>
				) : null}
			</AnimatePresence>
			<AnimatePresence>
				{store.editing ? (
					<motion.div
						initial={{ opacity: 0, x: 45 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: 45 }}
						transition={{ delay: 0.3 }}
					>
						<Button
							onClick={() => store.setEditorWindowVisible(!store.editorWindowVisible)}
							icon={
								store.editorWindowVisible ? (
									<ArrowRightOutlined />
								) : (
									<ArrowLeftOutlined> </ArrowLeftOutlined>
								)
							}
							size="large"
						/>
					</motion.div>
				) : null}
			</AnimatePresence>
			<AnimatePresence>
				{store.editing ? (
					<motion.div
						initial={{ opacity: 0, x: 45 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, x: 45 }}
						transition={{ delay: 0.15 }}
					>
						<Button onClick={() => store.reset()} icon={<CloseOutlined />} size="large" />
					</motion.div>
				) : null}
			</AnimatePresence>
			<motion.div animate={{ scale: [1, 1.5, 1] }} transition={{ delay: 2 }}>
				<Button
					type={store.editing ? 'default' : 'primary'}
					icon={
						store.editing ? (
							store.saving ? (
								<LoadingOutlined />
							) : (
								<SaveFilled />
							)
						) : (
							<EditFilled />
						)
					}
					size="large"
					onClick={() => {
						if (store.editing) store.startSave();
						else {
							store.setEditing(true);
							store.setEditorWindowVisible(true);
						}
					}}
				/>
			</motion.div>
		</Space>
	);
};

export type EditorProps = {};

export const Editor = ({
	showParentCompanyCheckbox,
}: {
	showParentCompanyCheckbox?: boolean;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'ChannelEditor' });
	const store = useChannelEditStore();

	const motionProps = {
		initial: {
			x: 150,
			opacity: 0,
			scaleX: 0,
		},
		animate: {
			x: 0,
			opacity: 1,
			scaleX: 1,
		},
		exit: {
			x: 150,
			opacity: 0,
			scaleX: 0,
		},
	};

	if (store.config == null) return <Spin />;

	return (
		<Row
			style={{
				position: 'fixed',
				right: '32px',
				bottom: '32px',
				maxWidth: '360px',
				zIndex: 2,
			}}
			align="bottom"
			justify="end"
			gutter={[16, 16]}
		>
			<Col>
				<AnimatePresence>
					{store.editorWindowVisible ? (
						<motion.div
							{...motionProps}
							style={{
								height: 'calc(50vh -32px - 128px)',
								width: 'calc(100vw -64px)',
							}}
						>
							<Card
								style={{
									overflowY: 'auto',
									width: '100%',
									maxHeight: '75vh',
									height: '100%',
								}}
							>
								<FeatureLock featureName={FeatureNames.ChannelEditor}>
									<Row gutter={[16, 16]}>
										<Col span={24}>
											<Typography.Title level={4}>{t('CIConfig.Title')}</Typography.Title>
										</Col>
										{Object.entries(store.config?.company).map(([k, v]) => {
											let input: JSX.Element | null = null;

											switch (typeof v) {
												case 'boolean':
													input = (
														<Switch
															checked={v}
															onChange={(checked) =>
																store.edit({ company: { [k]: checked } })
															}
														/>
													);
													break;
												case 'number':
													input = (
														<Slider
															value={v}
															onChange={(v) => store.edit({ company: { [k]: v } })}
															max={32}
															min={0}
														/>
													);
													break;
												case 'string':
													const value = v as string;
													// check if v represents a color
													if (k.toLocaleLowerCase().includes('color')) {
														input = (
															<ColorInput
																color={ensureHexColor(v as string)}
																onChange={(v) => store.edit({ company: { [k]: v } })}
															/>
														);
													} else if (k.toLocaleLowerCase().includes('shadow')) {
														input = (
															<BoxShadowInput
																boxShadow={v as string}
																onChange={(v) => store.edit({ company: { [k]: v } })}
															/>
														);
													} else {
														input = (
															<Input
																maxLength={255}
																type="string"
																value={v as string}
																onChange={(e) =>
																	store.edit({ company: { [k]: e.target.value } })
																}
															/>
														);
													}
											}

											return (
												<Col span={24} key={k}>
													<Typography.Text strong>{k}</Typography.Text>
													{input}
												</Col>
											);
										})}
										<Col span={24}>
											<Typography.Title level={4}>
												{t('ChannelConfig.Title')}
											</Typography.Title>
										</Col>
										<Col span={24}>
											<Typography.Text style={{ display: 'block' }} strong>
												{t('ChannelConfig.ShowKonfidalLogo')}
											</Typography.Text>
											<Switch
												checked={store.config.channel.showKonfidalLogo}
												onChange={(checked) =>
													store.edit({ channel: { showKonfidalLogo: checked } })
												}
											/>
										</Col>
										{showParentCompanyCheckbox && (
											<Col span={24}>
												<Typography.Text style={{ display: 'block' }} strong>
													{t('ChannelConfig.ShowParentCompany')}
												</Typography.Text>
												<Switch
													checked={store.config.channel.showParentCompany}
													onChange={(checked) =>
														store.edit({ channel: { showParentCompany: checked } })
													}
												/>
											</Col>
										)}
									</Row>
								</FeatureLock>
							</Card>
						</motion.div>
					) : null}
				</AnimatePresence>
			</Col>
			<Col>
				<EditMenu />
			</Col>
		</Row>
	);
};
