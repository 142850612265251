import { useCallback, useEffect, useState } from 'react';
import { gql } from 'graphql-request';
import {
	FeatureNames,
	MediaType,
	MutationAddMessageMediaArgs,
	useRemoveMessageMediaMutation,
	MediaDataFragment,
} from '../../generated/graphql';
import { useGQLMutationWithFiles } from '../../hooks/useGQL';
import { Attachment } from '../util/Attachment';
import { useTranslation } from 'react-i18next';
import { CryptographyKey } from 'sodium-plus';
import { useEncrypt, useEncryptFile } from '../../hooks/useCrypt';
import { fileTypeFromBuffer } from 'file-type';
import { mimeToMediaType } from '../../lib/util';
import { serializeEncrypted } from '../../lib/crypt';
import { useFeature } from '../../hooks/useFeatures';
import { message } from 'antd';
import { useGet } from '../../hooks/useREST';

type Media = MediaDataFragment;

export interface MessageAttachmentProps {
	id: number;
	file?: File;
	type?: MediaType;
	uploadedMedia?: Media;
	messageId: number;
	storageConsent: boolean;
	encryptionKey?: CryptographyKey | null;
	onSuccess: (media: Media) => void;
	onRemove: (k: number) => void;
}

export const MessageAttachment = ({
	file: fileProp,
	type,
	uploadedMedia: uploadedMediaProp,
	messageId,
	encryptionKey,
	storageConsent,
	onSuccess,
	onRemove,
	id: key,
}: MessageAttachmentProps) => {
	const { t } = useTranslation('translations');
	const [uploadedMedia, setUploadedMedia] = useState<Media | null>(null);
	const [mediaType, setMediaType] = useState<MediaType | undefined>(undefined);
	const [file, setFile] = useState<File>();
	const { mutateAsync, progress, isError, isSuccess } = useGQLMutationWithFiles(gql`
		mutation ($id: Int!, $createMediaInput: CreateMediaInput!, $file: Upload!) {
			addMessageMedia(id: $id, createMediaInput: $createMediaInput, file: $file) {
				id
				uri
				type
				fileName
				extension
				storageConsent
				deleted
			}
		}
	`);
	const { mutateAsync: mutateAsyncRemove, isLoading: removeLoading } =
		useRemoveMessageMediaMutation();
	const {
		data,
		isError: downloadError,
		isSuccess: downloadSuccess,
		isLoading: downloadLoading,
	} = useGet<{
		type: MediaType;
		base64: string;
	}>(
		'/media/b64',
		{ id: uploadedMediaProp?.id },
		{ enabled: fileProp == null && uploadedMediaProp != null },
	);
	const encryptFile = useEncryptFile(encryptionKey);
	const encrypt = useEncrypt(encryptionKey);
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);

	const upload = useCallback(
		(file: File) => {
			(async () => {
				const inferredType = await fileTypeFromBuffer(await file.arrayBuffer());
				const extension = file.name.split('.').pop();
				if (!type) {
					type = mimeToMediaType(inferredType?.mime);
				}
				setMediaType(type);
				let encryptedFile: File | null = null;
				let nonce: Buffer | null = null;
				let fileName = file.name;
				if (e2eEnabled) {
					({ encryptedFile, nonce } = await encryptFile(file));
					fileName = serializeEncrypted(await encrypt(fileName));
				}
				const variables: MutationAddMessageMediaArgs = {
					id: messageId,
					file: null,
					createMediaInput: {
						type,
						extension,
						nonce: nonce ? nonce.toString('base64') : null,
						fileName,
						storageConsent,
					},
				};
				try {
					const res = await mutateAsync([
						variables,
						new Map([['variables.file', encryptedFile ? encryptedFile : file]]),
					]);
					setUploadedMedia(res.data.addMessageMedia);
					onSuccess(res.data.addMessageMedia);
				} catch (err) {
					console.log('Upload failed!');
				}
			})();
		},
		[onSuccess, mutateAsync, messageId],
	);

	const remove = () => {
		if (uploadedMedia != null) {
			mutateAsyncRemove({ messageId, mediaId: uploadedMedia.id })
				.then(() => {
					setUploadedMedia(null);
					console.log(`Removed attachment ${key} from server`);
					onRemove(key);
				})
				.catch(() =>
					message.error(
						'Failes to remove attachment. DO NOT SEND THE MESSAGE, if you do not want to share the attachment.',
					),
				);
		} else {
			onRemove(key);
		}
	};

	const reset = () => {
		if (isError && fileProp) {
			upload(fileProp);
		}
	};

	// download to file
	useEffect(() => {
		if (data != null && downloadSuccess) {
			const file = new File(
				[new Blob([data.base64], { type: 'base64' })],
				uploadedMedia?.fileName || '',
			);
			console.log('downloaded attachment file', file);
			setFile(file);
			return;
		} else if (downloadError) {
			message.error('Failed to download attachment');
			return;
		}
	}, [data, downloadError, downloadSuccess]);

	useEffect(() => {
		if (uploadedMediaProp) {
			setUploadedMedia(uploadedMediaProp);
		} else if (fileProp) {
			console.log('upload call');
			upload(fileProp);
		} else {
			throw new Error(t('MessageAttachment.Error'));
		}
	}, [fileProp, uploadedMediaProp]);

	return (
		<>
			{/* <p>messageId {messageId}</p>
			<p>uploadedMedia {JSON.stringify(uploadedMedia)}</p>
			<p>fileName: {file?.name}</p>
			<p>download: {'' + downloadSuccess}</p>
			<p>fileProp: {JSON.stringify(fileProp)}</p>
			<p>data: {(data != null) + ''}</p> */}
			{/* {<p>{storageConsent + ''}</p>} */}
			<Attachment
				file={fileProp ?? file}
				type={mediaType || uploadedMedia?.type}
				reset={reset}
				progress={progress}
				isError={isError || downloadError}
				isSuccess={isSuccess || downloadSuccess}
				onRemove={() => remove()}
				isLoading={removeLoading || downloadLoading}
			/>
		</>
	);
};
