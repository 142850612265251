import { useFeature, useFeatureStore, useSyncFeatures } from '../../hooks/useFeatures';
import { useEffect, useState } from 'react';
import { FeatureNames } from '../../generated/graphql';

export const FeaturesProvider = ({ children }: any) => {
	useSyncFeatures();
	const features = useFeatureStore((state) => state.features);

	return children;
};

export const FeatureToggle = ({
	features,
	condition,
	children,
	fallback,
}: {
	features: FeatureNames[];
	condition?: 'and' | 'or';
	children: any;
	fallback?: React.Component;
}) => {
	const active = useFeature(features, condition);

	if (active) return children;
	else if (fallback) return fallback;
	else return null;
};
