import i18n, { InitOptions, ReactOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

const reactOptions: ReactOptions = {
	bindI18n: 'loaded languageChanged',
	bindI18nStore: 'added',
	useSuspense: true,
};

const options: InitOptions = {
	// lng: 'en',
	backend: {
		/* translation file path */
		loadPath: `${process.env.PUBLIC_URL}/assets/i18n/{{ns}}/{{lng}}.json`,
	},
	nonExplicitSupportedLngs: true,
	supportedLngs: [
		'en',
		'de',
		'pt',
		'fr',
		'el',
		'it',
		'es',
		'da',
		'sv',
		'nl',
		'pl',
		'bg',
		'cs',
		'et',
		'fi',
		'hu',
		'lt',
		'lv',
		'ro',
		'sk',
		'sl',
		'tr',
		'uk',
	],
	fallbackLng: 'en',
	// fallbackLng: (code) => {
	// 	console.log('Translations for language not found:', code);
	// 	if (!code || code === 'en') return ['en'];
	// 	if (code.startsWith('de-')) {
	// 		console.log('falling back to de');
	// 		return ['de'];
	// 	}
	// 	if (code.startsWith('en-')) return ['en'];
	// 	if (code.startsWith('pt-')) return ['pt'];
	// 	console.log('falling back to en');
	// 	return ['en'];
	// },
	// fallbackLng: {
	// 	'de-DE': ['de'],
	// 	default: ['en'],
	// },
	debug: true,
	/* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
	ns: ['translations'],
	defaultNS: 'translations',
	keySeparator: '.',
	interpolation: {
		escapeValue: false,
		formatSeparator: ',',
	},
	react: reactOptions,
	detection: {
		order: ['localStorage', 'navigator', 'cookie', 'sessionStorage'],
	},
};

i18n
	// load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
	// learn more: https://github.com/i18next/i18next-http-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(I18nextBrowserLanguageDetector)
	// .use({
	// 	type: 'languageDetector',
	// 	async: false,
	// 	init: () => {},
	// 	detect: () => navigator.language.split('-')[0],
	// 	cacheUserLanguage: () => {},
	// })
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init(options);
export default i18n;
