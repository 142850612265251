import { Col, Row, Typography, Button, Space, Checkbox } from 'antd';
import {
	useSetCompanyOnboardingPublishLinkMutation,
	useSetCompanyOnboardingVisibleMutation,
} from '../../../generated/graphql';
import './Dashboard.less';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { Card } from '../../util/Card';
import { ArrowRightOutlined } from '@ant-design/icons';
import produce from 'immer';

export const CompanyOnboardingChecklistItem = ({
	checked,
	text,
	tutorialLink,
	onCheck,
}: {
	checked: boolean;
	onCheck?: (checked: boolean) => void;
	text: string;
	tutorialLink: string;
}) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'Dashboard.OnboardingChecklist',
	});

	return (
		<Row gutter={16} align="bottom" justify="space-between" wrap>
			<Col>
				<Space>
					<Checkbox
						checked={checked}
						onChange={(e) => onCheck && onCheck(e.target.checked)}
						disabled={onCheck === undefined}
					/>
					<Typography.Text>{text}</Typography.Text>
				</Space>
			</Col>
			<Col flex="auto"></Col>
			<Col>
				{tutorialLink && (
					<Typography.Link href={tutorialLink}>
						{t('TutorialLinkText')}
						&nbsp;
						<ArrowRightOutlined />
					</Typography.Link>
				)}
			</Col>
		</Row>
	);
};

export const CompanyOnboardingChecklist = () => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'Dashboard.OnboardingChecklist',
	});
	const selectedCompany = useSelectedCompanyStore((state) => state.company);
	const setSelectedCompany = useSelectedCompanyStore((state) => state.setCompany);
	const { mutateAsync: setPublishLink } = useSetCompanyOnboardingPublishLinkMutation();
	const { mutateAsync: setVisible } = useSetCompanyOnboardingVisibleMutation();

	const checklist = useMemo(
		() => [
			{
				checked: selectedCompany?.onboarding?.publishLink === true,
				onCheck: async (v: boolean) =>
					selectedCompany != null &&
					setPublishLink({ companyId: selectedCompany?.id, done: v || false }).then(
						(res) =>
							setSelectedCompany(
								produce(selectedCompany, (c) => {
									const publishLink = res.updateCompany.onboarding?.publishLink === true;
									if (c.onboarding == null) c.onboarding = { visible: true, publishLink };
									else c.onboarding.publishLink = publishLink;
								}),
							),
					),
				text: t('Task.PublishLink.Text'),
				tutorialLink: t('Task.PublishLink.TutorialLink'),
			},
			{
				checked: selectedCompany?.logo?.id != null,
				text: t('Task.SetLogo.Text'),
				tutorialLink: t('Task.SetLogo.TutorialLink'),
			},
		],
		[selectedCompany?.onboarding],
	);
	const count = checklist.reduce((p, c) => p + Number(!c.checked), 0);

	const onClick = () => {
		selectedCompany?.id != null &&
			setVisible({ companyId: selectedCompany.id, visible: false }).then((res) =>
				setSelectedCompany(
					produce(selectedCompany, (draft) => {
						if (draft.onboarding == null)
							draft.onboarding = {
								visible: true,
								publishLink: false,
							};
						draft.onboarding.visible = res.updateCompany.onboarding?.visible ?? true;
					}),
				),
			);
	};

	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			<Row align="middle" justify="space-between">
				<Col>
					<Typography.Title level={3}>{t('Title', { count })}</Typography.Title>
				</Col>
				<Col>
					{count === 0 && (
						<Button onClick={onClick} type="ghost">
							{t('HideButton')}
						</Button>
					)}
				</Col>
			</Row>
			<Card hoverable={false}>
				<Space direction="vertical" size="middle" style={{ width: '100%' }}>
					{checklist.map((props, i) => (
						<CompanyOnboardingChecklistItem key={i} {...props} />
					))}
				</Space>
			</Card>
		</Space>
	);
};
