import { captureException } from '@sentry/react';
import { Result } from 'antd';
import { Navigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useRedirectContextStore } from './useRedirectStore';

export const Redirect = () => {
	const setTarget = useRedirectContextStore.useSetTarget();
	const setContextFromJson = useRedirectContextStore.useSetContextFromJson();
	const [params, setParams] = useSearchParams();

	const target = params.get('target');

	if (!target) return <Result status={404} title={`Route ${target} does not exist`} />;

	setTarget(target);

	const contextEncoded = params.get('context');
	if (contextEncoded) {
		try {
			const context = JSON.parse(atob(contextEncoded));
			setContextFromJson(context);
		} catch (err) {
			captureException('Redirect: Failed to parse context', {
				extra: { contextEncoded, err },
			});
		}
	}
	return <Navigate to={target} />;
};
