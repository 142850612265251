import { Alert, Modal, Typography } from 'antd';
import { Input } from '../util/Input';
import { resolveSoa } from 'dns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useCheckHinterPasswordMutation,
	useCheckUserPasswordMutation,
} from '../../generated/graphql';
import { useAuthStore } from '../../hooks/useAuth';
import {
	useCryptSensitiveStore,
	useCryptStore,
	useManageMasterKey,
} from '../../hooks/useCrypt';

export const MasterKeyPasswordModal = () => {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const role = useAuthStore((state) => state.session?.role);
	const masterKey = useCryptSensitiveStore((state) => state.masterKey);
	const masterKeySalt = useCryptStore((state) => state.masterKeySalt);
	const [needPassword, setNeedPassword] = useState(false);
	const [passwordInput, setPasswordInput] = useState<string>();
	const [confirmLoading, setConfirmLoading] = useState(false);
	const deriveMasterKey = useManageMasterKey();
	const [message, setMessage] = useState('');
	const { t } = useTranslation('translations');
	const { mutateAsync: checkUserPassword } = useCheckUserPasswordMutation();
	const { mutateAsync: checkHinterPassword } = useCheckHinterPasswordMutation();

	// prompt for password if needed
	useEffect(() => {
		console.log('masterKeySaltNeed');
		if (isAuthenticated && masterKey == null) {
			setNeedPassword(true);
		} else {
			setNeedPassword(false);
		}
	}, [isAuthenticated, masterKey]);

	const onOk = async () => {
		setConfirmLoading(true);

		let password: string | undefined = undefined;
		if (role === 'HINTER') {
			if (!passwordInput) setMessage(t('MasterKeyPasswordModal.PasswordMissingHinter'));
			else password = passwordInput.slice(0, 16);
		} else {
			if (!passwordInput) setMessage(t('MasterKeyPasswordModal.PasswordMissingUser'));
			else password = passwordInput;
		}
		if (!password) {
			setConfirmLoading(false);
			return;
		}

		let passwordCorrect = false;
		if (role === 'HINTER') {
			passwordCorrect = (await checkHinterPassword({ password })).checkHinterPassword;
		} else if (role === 'USER') {
			passwordCorrect = (await checkUserPassword({ password })).checkUserPassword;
		}

		if (!passwordCorrect) {
			setConfirmLoading(false);
			setMessage(t('PasswordIncorrect'));
			return;
		}

		try {
			deriveMasterKey(password, masterKeySalt);
			setConfirmLoading(false);
			setNeedPassword(false);
		} catch (err) {
			setMessage(t('MasterKeyPasswordModal.KeyDerivationFailed'));
		}
		setPasswordInput(undefined);
	};

	return (
		<Modal
			centered
			closable={false}
			title={
				role === 'HINTER'
					? t('MasterKeyPasswordModal.TitleHinter')
					: t('MasterKeyPasswordModal.TitleUser')
			}
			visible={needPassword}
			cancelButtonProps={{ style: { display: 'none' } }}
			confirmLoading={confirmLoading}
			onOk={onOk}
			destroyOnClose={true}
		>
			{message !== '' ? <Alert type="error" description={message} /> : null}
			<Typography.Text>{t('Password')}</Typography.Text>
			<Input.Password
				value={passwordInput}
				onChange={(e) => setPasswordInput(e.target.value)}
			/>
		</Modal>
	);
};
