import { AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate, Link } from 'react-router-dom';
import {
	Currencies,
	useCheckoutCompanySubscriptionMutation,
} from '../../generated/graphql';
import { useRedirectContextStore } from '../Redirect/useRedirectStore';
import { PageAnimation } from '../util/Animation';
import { usePlansModalStore } from './Plans';
import { SetupCompany } from './SetupCompany';
import SetupUser from './SetupUser';

export const Setup = ({ parentPath }: { parentPath: string }) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const location = useLocation();
	const redirectContext = useRedirectContextStore((state) => state.context);
	const clearCheckoutContext = useRedirectContextStore.useClearCheckoutContext();
	const setPlansDefaults = usePlansModalStore.useSetDefaults();

	const locationParts = location.pathname.split('/');
	const baseIndex = locationParts.findIndex((x) => x === parentPath);
	const key = locationParts.slice(baseIndex, baseIndex + 2).join('/');

	return (
		<AnimatePresence exitBeforeEnter>
			<Helmet>
				<title>{t('Meta.Setup.Title')}</title>
				<meta name="description" content={t('Meta.Setup.Description')} />
			</Helmet>
			<Routes location={location} key={key}>
				<Route
					path="user/*"
					element={
						<PageAnimation type="slide">
							<SetupUser
								onComplete={() => {
									console.log('Redirect to setup/company');
									navigate('company');
								}}
							/>
						</PageAnimation>
					}
				/>
				<Route
					path="company/*"
					element={
						<PageAnimation type="slide">
							<SetupCompany
								parentPath="company"
								onComplete={() => {
									if (redirectContext?.checkout) {
										setPlansDefaults({
											currency:
												Currencies[
													redirectContext.checkout.currency as keyof typeof Currencies
												],
											tier: redirectContext.checkout.plan === 'business' ? 1 : 2,
										});
										navigate('/admin/plans');
										clearCheckoutContext();
									}
								}}
							/>
						</PageAnimation>
					}
				/>
			</Routes>
		</AnimatePresence>
	);
};
