import { Col, Row, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { PublicChannelQuery } from '../../../generated/graphql';
import { UserCard } from '../../util/UserCard';
import {
	createChannelEditable,
	RichTextEditor,
	useChannelEditStore,
} from '../ChannelEditor';
import ChannelConfigUpsell from '../ChannelConfigUpsell';

const StepInform = ({ channel }: { channel?: PublicChannelQuery['publicChannel'] }) => {
	const { t } = useTranslation('translations', { keyPrefix: 'ReportForm' });
	const editor = useChannelEditStore();
	if (!editor.config) return <Spin />;
	return (
		<Space direction="vertical" size="middle" style={{ width: '100%' }}>
			<Typography.Title
				editable={
					editor.editing
						? createChannelEditable(
								editor.config?.channel,
								'informationTitle',
								editor.edit,
						  )
						: false
				}
			>
				{editor.config?.channel.informationTitle}
			</Typography.Title>
			{channel?.config?.showRecipients && (
				<>
					<Typography.Title level={3}>{t('CaseworkersTitle')}</Typography.Title>
					<Space direction="horizontal" style={{ width: '100%' }} wrap size="small">
						{channel != null ? (
							channel.users.map((u, i) => (
								<UserCard design={channel.company.design} key={u.id} user={u} />
							))
						) : (
							<Spin />
						)}
					</Space>
				</>
			)}
			<RichTextEditor
				edit={(richText) =>
					editor.edit({
						channel: { informationText: richText },
					})
				}
				content={editor.config?.channel.informationText}
				editable={editor.editing}
			/>
			{channel?.company?.tier === 0 &&
				(!editor.config?.channel.informationText.html ||
					/^\s*((<[^>]*?>\s*)*(\s*<\/[^>]*?>\s*)*\s*)*$/.test(
						editor.config?.channel.informationText.html,
					)) && <ChannelConfigUpsell />}
		</Space>
	);
};

export default StepInform;
