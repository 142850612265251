import { UnlockFilled } from '@ant-design/icons';
import { Col, Popover, Row, Typography } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureDataFragment, FeatureNames } from '../../generated/graphql';
import { useFeatureStore } from '../../hooks/useFeatures';
import { Button } from '../util/Button';
import { usePlansModalStore } from './Plans';
import { motion } from 'framer-motion';

export const useFeatureLock = (featureName: FeatureNames) => {
	const [locked, setLocked] = useState(false);
	const features = useFeatureStore((state) => state.features);
	const [feature, setFeature] = useState<FeatureDataFragment | null>(null);

	useEffect(() => {
		const feature = features.find((f) => f.name === featureName);
		if (feature) {
			setLocked(feature.locked);
			setFeature(feature);
		} else setLocked(true);
	}, [features, featureName, setLocked]);

	return { locked, feature };
};

export type OverlayProps = {
	children: React.ReactNode;
	visible: boolean;
	left: number;
	top: number;
	width: number;
	height: number;
} & React.AreaHTMLAttributes<HTMLDivElement>;

export const Overlay = ({
	children,
	visible,
	left,
	top,
	width,
	height,
	...props
}: OverlayProps) => {
	const [visibleState, setVisibleState] = useState(false);

	useEffect(() => {
		if (!visibleState && visible) setVisibleState(visible);
		else if (visibleState && !visible) setTimeout(() => setVisibleState(false), 500);
	}, [visible]);

	if (width < 200) {
		const diff = 200 - width;
		left -= diff / 2;
		width = 200;
	}
	const style: React.CSSProperties = {
		display: visibleState ? 'block' : 'none',
		zIndex: 1,
		position: 'absolute',
		left,
		top,
		width,
		height,
	};
	return (
		<div {...props} style={style}>
			<motion.div
				style={{ height: '100%' }}
				animate={{ scale: visible ? 1 : 0 }}
				initial={{ scale: visible ? 0 : 1 }}
			>
				<Row style={{ height: '100%' }} gutter={8} justify="center" align="middle">
					<Col>{children}</Col>
				</Row>
			</motion.div>
		</div>
	);
};

export type FeatureLockProps = {
	description?: string;
	buttonText?: string;
	featureName: FeatureNames;
	children: React.ReactNode;
};

export const FeatureLock = ({
	description,
	buttonText,
	children,
	featureName,
}: FeatureLockProps) => {
	const { locked, feature } = useFeatureLock(featureName);
	const { t } = useTranslation('translations');
	const [visible, setVisible] = useState(false);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();
	const showForDuration = (timeout: number) => {
		if (timeoutId) clearTimeout(timeoutId);
		setVisible(true);
		setTimeoutId(setTimeout(() => setVisible(false), timeout));
	};
	const containerRef = useRef<HTMLDivElement | null>(null);
	const openPlansModal = usePlansModalStore((state) => state.open);

	buttonText = buttonText || t('FeatureLock.UnlockWith');
	const unlockPlan =
		feature?.tier === 1 ? 'business' : feature?.tier === 2 ? 'enterprise' : feature?.tier;

	const onUnlock = () => {
		openPlansModal({ tier: feature?.tier });
	};

	let contentStyle = {};
	if (visible) contentStyle = { ...contentStyle, filter: 'blur(5px)' };
	if (locked) contentStyle = { ...contentStyle, opacity: 0.5 };

	return (
		<div ref={containerRef} className="FeatureLock">
			{locked && containerRef.current ? (
				<Overlay
					onMouseLeave={() => locked && setVisible(false)}
					visible={visible}
					left={containerRef.current.offsetLeft}
					top={containerRef.current.offsetTop}
					height={containerRef.current.offsetHeight}
					width={containerRef.current.offsetWidth}
				>
					<Button
						style={{
							WebkitBackdropFilter: 'blur(10px)',
							backdropFilter: 'blur(10px)',
							backgroundColor: 'rgba(255,255,255,0.3)',
						}}
						type="ghost"
						href="https://my.meetergo.com/martinmeng/upgrade"
						target="_blank"
					>
						<UnlockFilled />
						<span>{buttonText}</span>&nbsp;
						<span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{unlockPlan}</span>
					</Button>
				</Overlay>
			) : null}
			<div
				className="FeatureLockContent"
				style={contentStyle}
				onMouseEnter={() => locked && setVisible(true)}
				onTouchEnd={(e) => {
					if (locked) {
						e.preventDefault();
						e.stopPropagation();
						showForDuration(5000);
					}
				}}
			>
				{children}
			</div>
		</div>
	);
};
