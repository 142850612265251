import { useMemo } from 'react';
import { useMessageTemplatesQuery } from '../../../generated/graphql';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';

// export function useMessageTemplates(_: {}):
export function useMessageTemplates({
	channelId,
	companyId,
}: {
	channelId?: number;
	companyId?: number;
}) {
	const selectedCompany = useSelectedCompanyStore((state) => state.company);

	// const userTemplatesQuery = useMessageTemplatesOfUserQuery();
	// const companyTemplatesQuery = useMessageTemplatesOfCompanyQuery(
	// 	{ companyId: companyId ?? 0 },
	// 	{ enabled: companyId != null },
	// );
	// const channelTemplatesQuery = useMessageTemplatesOfChannelQuery(
	// 	{ channelId: channelId ?? 0 },
	// 	{ enabled: channelId != null },
	// );

	const query = useMessageTemplatesQuery(
		{
			companyId: selectedCompany?.id ?? 0,
		},
		{ enabled: selectedCompany != null },
	);

	const privateTemplates = useMemo(
		() =>
			query.data?.messageTemplates.filter((t) => t.channel == null && t.company == null),
		[query.data],
	);
	const companyTemplates = useMemo(
		() =>
			query.data?.messageTemplates.filter((t) => t.channel == null && t.company != null),
		[query.data],
	);
	const channelTemplates = useMemo(
		() =>
			query.data?.messageTemplates.filter(
				(t) =>
					((channelId && t.channel?.id === channelId) ||
						(!channelId && t.channel != null)) &&
					(t.company == null || t.company === selectedCompany),
			),
		[query.data],
	);

	return {
		...query,
		privateTemplates,
		channelTemplates,
		companyTemplates,
	};
}
