import React, { useEffect, useState } from 'react';
import { Avatar, Col, message, Row, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	PGPRecipient,
	useCryptSensitiveStore,
	usePGPDecrypt,
} from '../../hooks/useCrypt';
import { useAuthStore, UserSession } from '../../hooks/useAuth';
import {
	Incident,
	IncidentCategory,
	IncidentsQuery,
	IncidentStatus,
} from '../../generated/graphql';
import { Card } from '../util/Card';
import Text from 'antd/lib/typography/Text';
import { TagDeadline, Tag, TagIncidentStatus, TagIncidentCategory } from '../util/Tag';
import Title from 'antd/lib/typography/Title';
import { PaperClipOutlined } from '@ant-design/icons';
import { UserAvatar } from '../util/Avatar';
import { Descriptions } from '../util/Descriptions';
import { motion } from 'framer-motion';
import Paragraph from 'antd/lib/typography/Paragraph';
import { formatTime } from '../../lib/util';
import { Time } from '../util/Time';
import { Button } from '../util/Button';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';

type IncidentDto = IncidentsQuery['incidents']['data'][0];

export interface IncidentCardProps {
	incident: IncidentDto;
}

export const IncidentCard = ({ incident }: IncidentCardProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const key = useCryptSensitiveStore((state) => state.masterKey);
	const pKey = useCryptSensitiveStore((state) => state.privateKey);
	const [decryptionError, setDecryptionError] = useState(false);
	const [decryptedSubject, setDecryptedSubject] = useState<string>();
	const [decryptedDescription, setDecryptedDescription] = useState<string>();
	const userId = useAuthStore((state) => (state.session as UserSession)?.user?.id);
	const recipients =
		incident.__typename === 'Incident' &&
		incident.encryptionVersion > 0 &&
		incident.recipients.map((r) => r.encryptionVersion > 0).reduce((p, n) => p && n)
			? incident.recipients
			: [];
	const {
		ready: decryptReady,
		decrypt,
		userNotInRecipients,
	} = usePGPDecrypt(
		recipients as PGPRecipient[],
		incident.__typename === 'Incident' ? incident.hinter.publicKey : null,
		userId,
	);

	useEffect(() => {
		if (incident.__typename === 'PublicIncident') return;
		if (incident.encryptionVersion === 0) {
			setDecryptedSubject(incident.subject);
			setDecryptedDescription(incident.description);
			return;
		}
		if (!decryptReady || !decrypt || !incident.subject) return;
		(async () => {
			try {
				console.log(`dectypying incdientcard ${incident.id}`);
				setDecryptedSubject(await decrypt('utf-8', incident.subject));
				setDecryptedDescription(await decrypt('utf-8', incident.description));
			} catch (err) {
				console.log(`Error decrypting incident ${incident.id}`);
				setDecryptionError(true);
			}
		})();
	}, [decrypt, decryptReady, incident]);

	const clickable =
		incident.__typename === 'Incident' ||
		incident.recipients.find((r) => r.user?.id === userId) != null;

	return (
		<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
			<Card
				id={'' + incident.id}
				hoverable={clickable}
				onClick={(e) => {
					clickable
						? navigate('/admin/incident/' + incident.id)
						: message.info(t('IncidentCard.Message.CannotOpen'));
				}}
			>
				<Space direction="vertical" size="middle" style={{ width: '100%' }}>
					<Row gutter={[8, 8]}>
						<Col flex="auto">
							{incident.__typename === 'Incident' && (
								<Title level={5} ellipsis={true}>
									{decryptionError
										? t('DecryptionError')
										: decryptedSubject || t('Decrypting') + '...'}
								</Title>
							)}
							{incident.__typename === 'PublicIncident' && (
								<Space align="center">
									<Text code>{incident.fileNumber ?? incident.nanoId}</Text>
									<TagIncidentCategory category={incident.category} />
								</Space>
							)}
						</Col>
						{incident.category !== IncidentCategory.Suggestion && (
							<Col>
								<TagDeadline days={incident.deadlineDays} />
							</Col>
						)}
						<Col>
							<TagIncidentStatus status={incident.status} />
						</Col>
					</Row>
					{incident.__typename === 'Incident' && (
						<Row gutter={[16, 16]} justify="space-between">
							<Col
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
								}}
								md={14}
								lg={16}
								sm={16}
								xs={24}
							>
								<Paragraph
									style={{ marginBottom: 0 }}
									ellipsis={{ rows: 5, expandable: false }}
								>
									{decryptedDescription || t('hidden')}
								</Paragraph>
							</Col>
							<Col xs={24} lg={8} md={10} sm={8}>
								<Descriptions
									size="small"
									column={{ sm: 1, xs: 2, md: 1, lg: 1, xl: 1, xxl: 1 }}
									colon={false}
									layout="vertical"
									contentStyle={{ width: '100%', textAlign: 'right', fontSize: '12px' }}
								>
									<Descriptions.Item label={t('IncidentCard.LastUpdated')}>
										<Time mode="relative" date={incident.updated} />
									</Descriptions.Item>
									<Descriptions.Item
										label={t('ReceivedOn')}
										style={{ textAlign: 'right', fontSize: '12px' }}
									>
										<Time date={incident.created} />
									</Descriptions.Item>
								</Descriptions>
							</Col>
						</Row>
					)}
					<Row gutter={[8, 8]} align="middle" justify="end" wrap={false}>
						{incident.__typename === 'Incident' ? (
							<>
								<Col>
									<Space align="center">
										<Text code>{incident.fileNumber ?? incident.nanoId}</Text>
										<TagIncidentCategory category={incident.category} />
									</Space>
								</Col>
								<Col>
									{(incident.medias?.length || 0) > 0 ? (
										<>
											{incident.medias?.length} <PaperClipOutlined />
										</>
									) : null}
								</Col>
							</>
						) : (
							<Col>
								<Descriptions
									size="small"
									column={2}
									colon={false}
									layout="vertical"
									contentStyle={{ textAlign: 'right', fontSize: '12px' }}
								>
									<Descriptions.Item label={t('IncidentCard.LastUpdated')}>
										<Time mode="relative" date={incident.updated} />
									</Descriptions.Item>
									<Descriptions.Item
										label={t('ReceivedOn')}
										style={{ textAlign: 'right', fontSize: '12px' }}
									>
										<Time date={incident.created} />
									</Descriptions.Item>
								</Descriptions>
							</Col>
						)}
						<Col flex="auto"></Col>
						<Col>
							<Avatar.Group style={{ display: 'block' }}>
								{incident.recipients
									.filter((r) => r.user != null)
									.map((r, i) =>
										r.user != null ? <UserAvatar key={i} userId={r.user?.id} /> : null,
									)}
							</Avatar.Group>
						</Col>
						{/* <Col>
							<Button type="text" onClick={onClick}>
								{t('Open')}
							</Button>
						</Col> */}
						{/* <Col><Tag color="cyan">New message</Tag></Col> */}
					</Row>
				</Space>
			</Card>
		</motion.div>
	);
};
