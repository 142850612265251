import { Col, Form, Result, Row, Space, Typography } from 'antd';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	CreateWaitlistItemInput,
	useCreateWaitlistItemMutation,
} from '../../generated/graphql';
import { Alert } from '../util/Alert';
import { Button } from '../util/Button';
import { Card } from '../util/Card';
import { Input } from '../util/Input';
import { Logo } from '../util/Logo';

const withLogo = ({ Wrapped }: { Wrapped: React.ComponentType }) =>
	function wrappedWithLogo() {
		return (
			<Space align="start" direction="vertical" size="large">
				<Logo size={32} />
				<Wrapped />
			</Space>
		);
	};

const Prerelease = () => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'Prerelease' });
	const [submitted, setSubmitted] = useState(false);
	const [alert, setAlert] = useState<JSX.Element | null>(null);
	const { mutateAsync } = useCreateWaitlistItemMutation();

	const onFinish = async (waitlistItem: CreateWaitlistItemInput) => {
		try {
			const res = await mutateAsync({ waitlistItem });
			setSubmitted(true);
		} catch (e) {
			setAlert(<Alert type="error" description={tt('ErrorMessage')} />);
		}
	};

	if (submitted) {
		return <Result status="success" title={tt('SuccessMessage')} />;
	}

	return (
		<Space direction="vertical" style={{ maxWidth: '400px', width: '100%' }} size="large">
			<div>
				<Typography.Title level={2}>{tt('Title')}</Typography.Title>
				<Typography.Text>{tt('Description')}</Typography.Text>
			</div>
			<Form
				name="basic"
				labelCol={{ span: 8 }}
				initialValues={{ remember: true }}
				onFinish={onFinish}
				autoComplete="off"
				layout="vertical"
				size="large"
				style={{ width: '100%' }}
			>
				<Space direction="vertical" size="small" style={{ width: '100%' }}>
					<Card hoverable={false} style={{ width: '100%' }}>
						{alert}
						<Form.Item
							label={tt('Email')}
							name="email"
							rules={[{ required: true, message: tt('EmailMissing') }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label={tt('Firstname')}
							name="firstname"
							rules={[{ required: true, message: tt('FirstnameMissing') }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label={tt('Lastname')}
							name="lastname"
							rules={[{ required: true, message: tt('LastnameMissing') }]}
						>
							<Input />
						</Form.Item>
						<Form.Item
							label={tt('Company')}
							name="company"
							rules={[{ required: true, message: tt('CompanyMissing') }]}
						>
							<Input />
						</Form.Item>
					</Card>

					<Form.Item style={{ marginTop: '16px' }}>
						<Button type="primary" htmlType="submit" style={{ width: '100%' }}>
							{tt('SubmitButtonText')}
						</Button>
					</Form.Item>
				</Space>
			</Form>
		</Space>
	);
};

export default withLogo({ Wrapped: Prerelease });
