import './Tag.less';
import { Popover, Space, Tag as AntdTag, TagProps } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	AccessLevel,
	IncidentCategory,
	IncidentStatus,
	useSetIncidentCategoryMutation,
	useSetMemberLevelMutation,
} from '../../generated/graphql';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { getDateFnsLocale } from '../../lib/util';
import { useMemo, useState } from 'react';
import { CalendarOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { Button } from './Button';
import { AccessLevelSelect, IncidentCategorySelect } from './EnumSelect';

export type TagDeadlineProps = {
	days: number;
};

export const TagDeadline = ({ days, ...props }: TagProps & TagDeadlineProps) => {
	const { t } = useTranslation('translations');
	let content = `${days} ${
		days > 1 ? t('Tag.Deadline.DaysLeft') : t('Tag.Deadline.DayLeft')
	}`;
	let color: string | undefined = undefined;
	switch (days) {
		case 0:
		case 1:
		case 2:
			color = 'red';
			break;
		case 3:
		case 4:
			color = 'volcano';
			break;
		case 5:
		case 6:
		case 7:
			color = 'orange';
			break;
	}
	return (
		<Tag className={days < 21 ? 'TagBold' : ''} color={color}>
			{content}
		</Tag>
	);
};

export const Tag = ({ className, children, ...props }: TagProps) => {
	return (
		<AntdTag {...props} className={className + ' Tag'}>
			{children}
		</AntdTag>
	);
};

export const TagIncidentStatus = ({ status }: { status: IncidentStatus }) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentStatus' });
	let text = t(status);
	let color = 'blue';
	switch (status) {
		case IncidentStatus.Created:
			color = 'magenta';
			break;
		case IncidentStatus.Confirmed:
			color = 'blue';
			break;
		case IncidentStatus.Progress:
			color = 'blue';
			break;
		case IncidentStatus.Draft:
			color = 'grey';
			break;
		case IncidentStatus.Closed:
			color = 'green';
			break;
		case IncidentStatus.Rejected:
			color = 'grey';
			break;
	}
	return <Tag color={color}>{text}</Tag>;
};

const snakeToCamel = (str: string, capitalized: boolean = false) => {
	const s = str
		.toLowerCase()
		.replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
	return s[0].toUpperCase() + s.slice(1);
};

export const TagIncidentCategory = ({
	category,
	editable,
}: {
	category: IncidentCategory;
	editable?: { incidentId: number; minWidth?: number };
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentCategories' });
	const { mutateAsync: setIncidentCategory } = useSetIncidentCategoryMutation();
	const [editing, setEditing] = useState(false);
	const [text, setText] = useState(t(category));
	let color = 'blue';
	const [selectState, setSelectState] = useState<string>(category);

	const onEdit = () => {
		if (!editable) return;
		if (!editing) setEditing(true);
		else {
			const variables = {
				id: editable.incidentId,
				category:
					IncidentCategory[snakeToCamel(selectState) as keyof typeof IncidentCategory],
			};
			console.log(variables);
			setIncidentCategory(variables)
				.then((res) => {
					setText(t(res.updateIncident.category));
				})
				.finally(() => setEditing(false));
		}
	};

	return (
		<Space>
			{editing ? (
				<IncidentCategorySelect
					value={selectState}
					onChange={(v) => setSelectState(v)}
					style={editable?.minWidth ? { minWidth: editable.minWidth + 'px' } : {}}
				/>
			) : (
				<Tag color={color}>{text}</Tag>
			)}
			{editable && (
				<Button
					type="text"
					size="small"
					icon={editing ? <SaveOutlined /> : <EditOutlined />}
					onClick={onEdit}
				/>
			)}
		</Space>
	);
};

export const TagAccessLevel = ({
	level,
	editable,
}: {
	level: AccessLevel;
	editable?: { companyId: number; userId: number };
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'AccessLevel' });
	const { mutateAsync: setMemberLevel } = useSetMemberLevelMutation();
	const [editing, setEditing] = useState(false);
	const [text, setText] = useState(t(level));
	const [selectState, setSelectState] = useState<string>(level);

	const onEdit = () => {
		if (!editable) return;
		if (!editing) setEditing(true);
		else {
			const variables = {
				...editable,
				level: selectState as AccessLevel,
			};
			console.log(variables);
			setMemberLevel(variables)
				.then((res) => {
					setSelectState(res.setMemberLevel.accessLevel);
					setText(t(res.setMemberLevel.accessLevel));
				})
				.finally(() => setEditing(false));
		}
	};

	return (
		<Space>
			{editing ? (
				<AccessLevelSelect value={selectState} onChange={(v) => setSelectState(v)} />
			) : (
				<Tag>{text}</Tag>
			)}
			{editable && (
				<Button
					type="text"
					size="small"
					icon={editing ? <SaveOutlined /> : <EditOutlined />}
					onClick={onEdit}
				/>
			)}
		</Space>
	);
};

export const TagTime = ({
	time,
	relative = false,
	...props
}: TagProps & { relative?: boolean; time: Date | string | number }) => {
	const { i18n } = useTranslation();
	const absolute = useMemo(
		() =>
			format(new Date(time), 'E, d MMM yyyy, HH:mm', {
				locale: getDateFnsLocale(i18n.language),
			}),
		[time],
	);
	const distance = useMemo(
		() =>
			formatDistanceToNow(new Date(time), { locale: getDateFnsLocale(i18n.language) }),
		[time],
	);
	return (
		<Popover content={relative ? absolute : distance}>
			<Tag {...props}>
				<Space>
					<CalendarOutlined />
					{relative ? distance : absolute}
				</Space>
			</Tag>
		</Popover>
	);
};
