import { Col, Form, Result, Row, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { JoinCompanyMutation, useJoinCompanyMutation } from '../../generated/graphql';
import { Alert } from '../util/Alert';
import { Button } from '../util/Button';
import { Input } from '../util/Input';

export interface JoinCompanyFormProps {
	onSuccess: (company: JoinCompanyMutation['joinCompany']) => void;
	onError?: () => void;
	onCancel?: () => void;
}

export const JoinCompanyForm = ({
	onCancel,
	onSuccess,
	onError,
}: JoinCompanyFormProps) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'JoinCompanyForm' });
	const queryClient = useQueryClient();
	const { mutateAsync, isLoading } = useJoinCompanyMutation();
	const [token, setToken] = useState('');
	const [result, setResult] = useState<JSX.Element | null>(null);
	const [err, setErr] = useState<boolean>(false);

	const onOk = () => {
		if (result !== null) {
			setResult(null);
		}
		mutateAsync({ token })
			.then((res) => {
				onSuccess(res.joinCompany);
				queryClient.invalidateQueries('self');
				setResult(
					<Result
						status="success"
						title={tt('Result.Success.Title')}
						subTitle={tt('Result.Success.SubTitle', res.joinCompany.name)}
					/>,
				);
			})
			.catch((err) => {
				setErr(true);
				onError && onError();
			});
	};
	if (result) return result;

	return (
		<Space style={{ width: '100%' }} direction="vertical" size="large">
			{err && <Alert type="error" message={tt('Alert.Message')} />}
			<Form layout="horizontal">
				<Form.Item>
					<Input
						maxLength={64}
						placeholder={tt('Input.Placeholder')}
						value={token}
						onChange={(e) => setToken(e.target.value)}
					/>
				</Form.Item>
				<Row justify="space-between">
					<Col>
						{onCancel != null ? (
							<Button type="text" onClick={onCancel}>
								{t('Back')}
							</Button>
						) : null}
					</Col>
					<Col>
						<Button type="primary" onClick={onOk}>
							{tt('OkText')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Space>
	);
};
