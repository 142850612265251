import { SelectProps } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useTranslation } from 'react-i18next';
import {
	IncidentParticipantLevel,
	IncidentStatus,
	useAccessLevelsQuery,
	useCategoriesQuery,
	useCountriesQuery,
	useIncidentParticipantLevelsQuery,
	useIncidentStatusQuery,
	useIncidentTypeQuery,
	useLanguagesQuery,
	useNoteTypesQuery,
} from '../../generated/graphql';
import {
	useCountryTranslation,
	useIncidentCategoryTranslation,
	useLanguageTranslation,
} from '../../hooks/useEnumTranslation';
import { DesignConfig } from '../HinterPortal/ChannelEditor';
import { Select } from './Select';

export type EnumSelectProps = SelectProps<string> & {
	design?: DesignConfig;
	exclude?: string[];
};

export const EnumSelect = <VT extends SelectValue>({
	tt,
	enumValues,
	exclude,
	...selectProps
}: {
	tt: (key: string) => string;
	enumValues:
		| {
				__typename?: '__EnumValue' | undefined;
				name: string;
		  }[]
		| undefined
		| null;
} & EnumSelectProps) => {
	return (
		<Select {...selectProps}>
			{enumValues
				?.filter(({ name }) => !exclude?.includes(name))
				.map(({ name }, i) => (
					<Select.Option key={i} value={name}>
						{tt(name)}
					</Select.Option>
				))}
		</Select>
	);
};

export const LanguageSelect = (
	props: EnumSelectProps & { enumValues?: { name: string }[] },
) => {
	const { t } = useTranslation('translations', { keyPrefix: 'LanguageNames' });
	const tt = useLanguageTranslation();
	const { data } = useLanguagesQuery({}, { enabled: props.enumValues === undefined });
	const { enumValues: propsEnumValues, ...propsRest } = props;
	const enumValues = propsEnumValues ?? data?.__type?.enumValues;
	return <EnumSelect tt={t} enumValues={enumValues} {...propsRest} />;
};

export const CountrySelect = (props: EnumSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'Countries' });
	const tt = useCountryTranslation();
	const { data } = useCountriesQuery();
	return <EnumSelect tt={tt} enumValues={data?.__type?.enumValues} {...props} />;
};

export const IncidentCategorySelect = (props: EnumSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentCategories' });
	const { data } = useCategoriesQuery();
	return <EnumSelect tt={t} enumValues={data?.__type?.enumValues} {...props} />;
};

export const IncidentTypeSelect = (props: EnumSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentTypes' });
	const { data } = useIncidentTypeQuery();
	return <EnumSelect tt={t} enumValues={data?.__type?.enumValues} {...props} />;
};

export const IncidentStatusSelect = (props: EnumSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentStatus' });
	const { data } = useIncidentStatusQuery();
	return <EnumSelect tt={t} enumValues={data?.__type?.enumValues} {...props} />;
};

export const NoteTypeSelect = (props: EnumSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'NoteType' });
	const { data } = useNoteTypesQuery();
	return <EnumSelect tt={t} enumValues={data?.__type?.enumValues} {...props} />;
};

export const AccessLevelSelect = (props: EnumSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'AccessLevel' });
	const { data } = useAccessLevelsQuery();
	return (
		<>
			<EnumSelect tt={t} enumValues={data?.__type?.enumValues} {...props} />
		</>
	);
};

export const IncidentParticipantLevelSelect = (props: EnumSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentParticipantLevel' });
	const { data } = useIncidentParticipantLevelsQuery();
	return (
		<>
			<EnumSelect
				tt={t}
				enumValues={data?.__type?.enumValues?.filter(
					(v) => v.name !== IncidentParticipantLevel.Hinter,
				)}
				{...props}
			/>
		</>
	);
};
