import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../App.less';

export const Logo = ({
	size = 'small',
	type,
	style: styleOverwrite,
	onClick,
}: {
	style?: React.CSSProperties;
	size?: 'small' | 'medium' | 'large' | 'xlarge' | 'w100' | number;
	type?: 'logo' | 'text' | 'logotext';
	onClick?: () => void;
}) => {
	const { t, i18n } = useTranslation('translations');
	let imgPath = `${process.env.PUBLIC_URL}/assets/img`;
	let style: React.CSSProperties = { height: '64px' };

	switch (type) {
		case 'logo':
			imgPath += '/logo.svg';
			break;
		case 'text':
			imgPath += '/logo_text.svg';
			break;
		default:
			imgPath += '/logo_and_text.svg';
			break;
	}
	if (typeof size === 'number') {
		style = { height: size + 'px' };
	} else {
		switch (size) {
			case 'small':
				style = { height: '32px' };
				break;
			case 'medium':
				style = { height: '48px' };
				break;
			case 'large':
				style = { height: '64px' };
				break;
			case 'xlarge':
				style = { height: '128px' };
				break;
			case 'w100':
				style = { width: '100%' };
				break;
		}
	}

	const [lang, setLang] = useState<string | undefined>();
	useEffect(() => {
		if (i18n.language === undefined) return;
		let language = i18n.language;
		if (language.startsWith('en')) {
			language = '';
		}
		setLang(language);
	}, [i18n.language]);

	return (
		<img
			onClick={
				onClick ?? (() => lang && window.open('https://konfidal.eu/' + lang, '_blank'))
			}
			src={imgPath}
			className="Clickable"
			style={{ ...style, ...styleOverwrite }}
			alt={t('Meta.Default.Title')}
		/>
	);
};
