import { Col, Row, Typography } from 'antd';
import { useMemo } from 'react';
import './Chat.less';

export interface SeperatorProps {
	text: string;
	bubble?: boolean;
}

export const Seperator = ({ text, bubble = false }: SeperatorProps) => {
	return (
		<Row
			className={bubble ? 'BubbleSeperator' : 'Seperator'}
			justify="center"
			align="middle"
		>
			<Col>
				<Typography.Text strong type="secondary" style={{ fontSize: '12px' }}>
					{text}
				</Typography.Text>
			</Col>
		</Row>
	);
};
