import { Row, Col, Space, Typography } from 'antd';
import LoginForm from './LoginForm';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../hooks/useAuth';
import { Logo } from '../util/Logo';
import '../../index.less';
import { PageAnimation } from '../util/Animation';
import './../../App.less';
import { Routes, Route, useLocation } from 'react-router';
import { AnimatePresence } from 'framer-motion';
import { TwoFactorAuthForm } from './TwoFactorAuthForm';
import { LogoutReasonPrompt } from './LogoutReasonPrompt';
import { Helmet } from 'react-helmet';

const LoginPage = ({ toRegister }: { toRegister: () => void }) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const location = useLocation();
	const locationParts = location.pathname.split('/');
	const baseIndex = locationParts.findIndex((x) => x === '');
	const key = locationParts.slice(baseIndex + 1, baseIndex + 2).join('/');

	return (
		<Space align="center" direction="vertical" size="large">
			<Helmet>
				<title>{t('Meta.Login.Title')}</title>
				<meta name="description" content={t('Meta.Login.Description')} />
			</Helmet>
			{/* <LogoutReasonPrompt /> */}
			<Logo />
			<Row className="Thin">
				<PageAnimation type="slide">
					<Space direction="vertical" size="large">
						<Typography.Title level={2}>{t('Login')}</Typography.Title>
						<AnimatePresence exitBeforeEnter>
							<Routes>
								{/*location={location} key={key}>*/}
								<Route
									path="/"
									element={
										<LoginForm
											toRegister={toRegister}
											to2fa={() => navigate('2fa')}
											toHinterLogin={() => navigate('/portal/login')}
										/>
									}
								/>
								<Route
									path="2fa"
									element={
										<TwoFactorAuthForm
											onSuccess={() => navigate('/admin/dashboard')}
											toLogin={() => navigate('/login')}
										/>
									}
								/>
							</Routes>
						</AnimatePresence>
					</Space>
				</PageAnimation>
			</Row>
		</Space>
	);
};

export default LoginPage;
