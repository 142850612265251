import { Col, Collapse, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureNames } from '../../generated/graphql';
import { CountrySelect } from '../util/EnumSelect';
import { FeatureToggle } from '../util/FeatureFlags';

export const SpecificInfoDE = () => {
	const { t } = useTranslation('translations'); // the t-function simply retrieves values from the local file by a string key
	const { t: tt } = useTranslation('translations', { keyPrefix: 'SpecificInfo.DE' });
	// if we want to create SpecficiInfo for another language we need to adjust this prepended path.
	return (
		<Space direction="vertical" size="middle">
			<Typography.Title type="secondary" level={5}>
				{tt('ExterneMeldestellen')}
			</Typography.Title>
			<Typography.Link href="https://www.bundesjustizamt.de/DE/MeldestelledesBundes/MeldestelledesBundes_node.html">
				{tt('MeldestelleDesBundes')}
			</Typography.Link>
			<Collapse style={{ fontSize: 'inherit' }}>
				<Collapse.Panel
					style={{ fontSize: 'inherit' }}
					header={tt('MeldestellenDerLaender')}
					key={1}
				>
					<Space style={{ fontSize: 'inherit' }} direction="horizontal" size="small" wrap>
						{/* <Typography.Link href="">{tt('Hessen')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Rheinland-Pfalz')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Berlin')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Sachsen')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Sachsen-Anhalt')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Thüringen')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Brandenburg')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Mecklenburg')}-Vorpommern</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Niedersachsen')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Bremen')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Nordrhein')}-Westfalen</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Hamburg')}</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Schleswig')}-Holstein</Typography.Link> */}
						{/* <Typography.Link href="">{tt('Saarland')}</Typography.Link> */}
						<Typography.Link href="https://rechnungshof.baden-wuerttemberg.de/kontakt/meldestelle-hinweisgeberschutzgesetz-hinschg/">
							{tt('Baden')}
						</Typography.Link>
					</Space>
				</Collapse.Panel>
			</Collapse>
			<Typography.Link href="https://www.bkms-system.net/bkwebanon/report/channels?id=bkarta&language=ger">
				{tt('MeldestelleDesBundeskartellamt')}
			</Typography.Link>
			{/* <Typography.Link href="">{tt('MeldestelleDesBundeskartellamt')}</Typography.Link> */}
		</Space>
	);
};

export const SpecificInfo = ({
	countryCode,
	showCountrySelector,
}: {
	countryCode: string;
	showCountrySelector?: boolean;
}) => {
	const [country, setCountry] = useState(countryCode);

	useEffect(() => {
		console.log('countryCode', countryCode);
		setCountry(countryCode);
	}, [countryCode]);

	let infoElement: React.ReactNode;

	switch (country) {
		case 'DE':
			infoElement = <SpecificInfoDE />;
			break;
		default:
			infoElement = null;
	}

	return (
		<FeatureToggle features={[FeatureNames.ChannelCountrySpecificInfo]}>
			<div style={{ marginTop: '200px' }}>
				{showCountrySelector && (
					<Row justify="end">
						<Col>
							<CountrySelect
								defaultValue={country}
								onChange={(value, _) => setCountry(value)}
							/>
						</Col>
					</Row>
				)}
				<Row>
					<Col style={{ fontSize: '12px' }}>{infoElement}</Col>
				</Row>
			</div>
		</FeatureToggle>
	);
};
