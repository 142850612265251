import { Button as AntdButton, ButtonProps } from 'antd';
import { useState } from 'react';
import { DesignConfig } from '../HinterPortal/ChannelEditor';

export const Button = ({
	children,
	design,
	wrap,
	style: styleCustom,
	...props
}: ButtonProps & {
	design?: DesignConfig;
	style?: React.CSSProperties;
	wrap?: 'left' | 'center' | 'right';
}) => {
	const [focused, setFocused] = useState(false);
	const style: React.CSSProperties = {};

	let styleOverwrite: React.CSSProperties = {};
	if (design?.borderRadius) styleOverwrite.borderRadius = design?.borderRadius;

	let className;
	switch (props.type) {
		case 'primary':
			className = 'Button ButtonPrimary';
			if (design?.primaryColor) styleOverwrite.backgroundColor = design?.primaryColor;
			if (design?.boxShadow) styleOverwrite.boxShadow = design?.boxShadow;
			break;
		case 'text':
			className = 'Button ButtonText';
			if (design?.primaryColor) styleOverwrite.color = design?.primaryColor;
			break;
		case undefined:
		case 'default':
			if (design?.borderWidth) {
				styleOverwrite.outlineWidth = design?.borderWidth;
				styleOverwrite.outlineStyle = 'solid';
			}
			if (design?.borderColor) styleOverwrite.outlineColor = design?.borderColor;
			if (design?.boxShadow) styleOverwrite.boxShadow = design?.boxShadow;
			if (design?.primaryColor && focused) styleOverwrite.color = design?.primaryColor;
			break;
		case 'link':
			if (design?.primaryColor) styleOverwrite.color = design?.primaryColor;
			break;
		case 'ghost':
			if (design?.boxShadow) styleOverwrite.boxShadow = design?.boxShadow;
			break;
		default:
			if (design?.primaryColor) styleOverwrite.color = design?.primaryColor;
			className = 'Button';
	}
	if (wrap) {
		style.whiteSpace = 'pre-wrap';
		style.height = 'auto';
		style.textAlign = wrap;
	}

	return (
		<AntdButton
			style={{ ...style, ...styleCustom, ...styleOverwrite }}
			{...props}
			onMouseEnter={(e) => {
				setFocused(true);
				props.onMouseEnter && props.onMouseEnter(e);
			}}
			onMouseLeave={(e) => {
				setFocused(false);
				props.onMouseLeave && props.onMouseLeave(e);
			}}
		>
			{children}
		</AntdButton>
	);
};
