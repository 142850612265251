export var resizeObserverChecker = function (element, check) {
    var timer = null;
    var onWindowResize = function () {
        if (timer) {
            window.cancelAnimationFrame(timer);
        }
        timer = window.requestAnimationFrame(function () {
            check();
        });
    };
    return {
        observe: function () {
            window.addEventListener('resize', onWindowResize);
        },
        destroy: function () {
            window.removeEventListener('resize', onWindowResize);
        }
    };
};
