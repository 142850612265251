import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { Col, Image, message, Row, Space, Spin, Typography, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { MediaType } from '../../generated/graphql';
import { useGet } from '../../hooks/useREST';
import { Button } from './Button';
import { Card } from './Card';
import { saveAs } from 'file-saver';
import b64toBlob from 'b64-to-blob';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export type MediaThumbnailProps = {
	id: number;
	type: MediaType;
	extension?: string | null;
	width: number;
	height: number;
};

export const MediaThumbnail = ({
	id,
	width,
	height,
	type,
	extension: ext,
}: MediaThumbnailProps) => {
	const size = Math.max(width, height);
	const {
		data: previewData,
		isLoading: previewIsLoading,
		isError: previewIsError,
	} = useGet<Buffer>(
		'/media/image/b64',
		{ id, size, fit: 'cover' },
		{ enabled: type === MediaType.Image },
	);

	const [showPreview, setShowPreview] = useState(false);

	const { data, isLoading, isError } = useGet<Buffer>(
		'/media/image/b64',
		{ id },
		{ enabled: type === MediaType.Image && showPreview },
	);

	return (
		<>
			<Image
				preview={{ visible: false }}
				src={'data:image/png;base64, ' + previewData}
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();
					setShowPreview(true);
				}}
				width={width}
				height={height}
				style={{ borderRadius: '12px' }}
			/>
			<div style={{ display: 'none' }}>
				<Image
					src={'data:image/png;base64, ' + data}
					preview={{
						visible: showPreview,
						onVisibleChange: (vis) => setShowPreview(vis),
					}}
				/>
			</div>
		</>
	);
};

const useMediaDeletedText = (type: MediaType) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'Chat.Message.Attachment.Deleted',
	});

	switch (type) {
		case MediaType.Audio:
			return t('Audio');
		case MediaType.Image:
			return t('Image');
		case MediaType.Document:
			return t('Document');
		case MediaType.Video:
			return t('Video');
	}
	return null;
};

export type MediaListItemProps = {
	id: number;
	type: MediaType;
	extension?: string | null;
	nanoId: string;
	fileName?: string | null;
	size?: number | null;
	deleted?: boolean;
	onRemove?: () => void;
};

export const MediaListItem = ({
	id,
	type,
	extension: ext,
	nanoId,
	fileName,
	size,
	deleted,
	onRemove,
}: MediaListItemProps) => {
	const [wantDownload, setWantDownload] = useState(false);
	const { data, isLoading, isError, isIdle, isSuccess } = useGet<{
		type: MediaType;
		base64: string;
	}>('/media/b64', { id }, { enabled: wantDownload || type === MediaType.Audio });

	const download = () => {
		if (data && isSuccess) {
			const blob = new Blob([Buffer.from(data.base64, 'base64')]);
			saveAs(blob, `${nanoId}-${fileName}`);
			setWantDownload(false);
			return;
		}
		if (!wantDownload) setWantDownload(true);
		if (isError) {
			message.error('Failed to download');
			return;
		}
	};

	useEffect(() => {
		if (wantDownload && isSuccess) {
			download();
		}
	}, [wantDownload, isSuccess]);

	const deletedText = useMediaDeletedText(type);

	const hasPreview = type === MediaType.Image;
	size = size || 32;
	if (deleted) {
		return (
			<Card hoverable={false} style={{ width: '100%' }}>
				<Row align="middle" justify="space-between" gutter={8}>
					<Col>
						<Typography.Text>{deletedText}</Typography.Text>
					</Col>
					<Col>
						<Typography.Text code type="secondary">
							{nanoId}
						</Typography.Text>
					</Col>
					{ext ? (
						<Col>
							<Typography.Text italic>{ext.toUpperCase()}</Typography.Text>
						</Col>
					) : null}
				</Row>
			</Card>
		);
	}
	return (
		<Card hoverable={false} style={{ width: '100%' }}>
			<Space style={{ width: '100%' }} direction="vertical">
				<Row gutter={16} justify="start">
					{hasPreview ? (
						<Col span={size <= 64 ? 7 : 24}>
							<MediaThumbnail
								id={id}
								extension={ext}
								type={type}
								width={size}
								height={size}
							/>
						</Col>
					) : null}
					<Col span={hasPreview && size <= 64 ? 17 : 24}>
						<Row justify="start">
							<Col>
								<Typography.Text strong ellipsis>
									{fileName}
								</Typography.Text>
							</Col>
						</Row>
						<Row align="middle" justify="space-between" gutter={8}>
							<Col>
								<Typography.Text code type="secondary">
									{nanoId}
								</Typography.Text>
							</Col>
							{ext ? (
								<Col>
									<Typography.Text italic>{ext.toUpperCase()}</Typography.Text>
								</Col>
							) : null}
							<Col>
								<Space>
									{onRemove && (
										<Button
											size="small"
											icon={<DeleteOutlined />}
											type="text"
											onClick={() =>
												Modal.confirm({
													content: t('ConfirmDeleteModal.Title'),
													okText: t('Ok'),
													cancelText: t('Cancel'),
													onOk: onRemove,
												})
											}
										/>
									)}
									<Button
										size="small"
										onClick={download}
										icon={isLoading ? <Spin /> : <DownloadOutlined />}
										type="ghost"
									/>
								</Space>
							</Col>
						</Row>
					</Col>
				</Row>
				{data && type === MediaType.Audio && (
					<audio
						style={{ width: '100%' }}
						src={URL.createObjectURL(b64toBlob(data.base64))}
						controls
					/>
				)}
			</Space>
		</Card>
	);
};
