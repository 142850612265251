import { LoadingOutlined } from '@ant-design/icons';
import {
	Button,
	Col,
	message,
	Modal,
	Popover,
	Result,
	Row,
	Skeleton,
	Space,
	Spin,
	Switch,
	Tabs,
	Typography,
} from 'antd';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { t } from 'i18next';
import { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import createStore from 'zustand';
import {
	AccessLevel,
	BillingFrequencies,
	Currencies,
	Languages,
	useCheckoutCompanySubscriptionMutation,
	usePricesQuery,
	useSelfQuery,
} from '../../generated/graphql';
import { useAuthStore } from '../../hooks/useAuth';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { Card } from '../util/Card';
import { Select } from '../util/Select';
import { Tag } from '../util/Tag';
import { AnimatePresence, motion } from 'framer-motion';
import './Plans.less';
import { Alert } from '../util/Alert';
import { accessLevelAbove } from '../../lib/util';

export const currency_symbols = {
	USD: '$', // US Dollar
	EUR: '€', // Euro
	CRC: '₡', // Costa Rican Colón
	GBP: '£', // British Pound Sterling
	ILS: '₪', // Israeli New Sheqel
	INR: '₹', // Indian Rupee
	JPY: '¥', // Japanese Yen
	KRW: '₩', // South Korean Won
	NGN: '₦', // Nigerian Naira
	PHP: '₱', // Philippine Peso
	PLN: 'zł', // Polish Zloty
	PYG: '₲', // Paraguayan Guarani
	THB: '฿', // Thai Baht
	UAH: '₴', // Ukrainian Hryvnia
	VND: '₫', // Vietnamese Dong
};

export type PlansProps = {
	companyId: number;
	companyTier: number;
	currency?: Currencies;
	onCheckout?: () => void;
};

export const Plans = ({ onCheckout, companyId, currency, companyTier }: PlansProps) => {
	const invoiceOnly = process.env.REACT_APP_CHECKOUT_INVOICE_ONLY === 'TRUE';
	const { t } = useTranslation('translations');
	const [state, setState] = useImmer({
		currency: currency || Currencies.Eur,
		billingFrequency: BillingFrequencies.Monthly,
	});

	const { data, isLoading, isError } = usePricesQuery();
	const { mutateAsync: getCheckOutLink, isLoading: checkoutIsLoading } =
		useCheckoutCompanySubscriptionMutation();

	const checkout = (tier: number) => {
		getCheckOutLink({
			companyId,
			tier,
			frequency: state.billingFrequency,
			currency: state.currency,
		})
			.then((res) => {
				onCheckout && onCheckout();
				window.open(res.checkoutCompanySubscription, '_blank');
			})
			.catch((err) => message.error(t('Plans.ForwardFailure')));
	};

	if (isError)
		return (
			<Result
				status="404"
				title={t('Plans.Error.Title')}
				subTitle={t('Plans.Error.SubTitle')}
			/>
		);

	if (isLoading)
		return (
			<Space direction="vertical" align="center" style={{ width: '100%' }}>
				<Spin />
				{t('Plans.LoadingPrices')}
			</Space>
		);

	let prices = data?.prices
		.filter(
			(price) =>
				price.currency === state.currency &&
				price.billingFrequency === state.billingFrequency &&
				/tier[0-9].*/.test(price.planId),
		) // filter prices by currency and billing period
		.map((price) => ({
			...price,
			tier: Number.parseInt(price.planId.slice(4, 5)),
		})) // parse tier int from planId
		.filter((price) => price.tier > companyTier) // only show tiers that are not yet unlocked
		.sort((a, b) => a.tier - b.tier);

	const PriceConfig = () => (
		<Row gutter={16} justify="space-between" wrap={false} align="top">
			<Col>
				<Space align="start">
					{t('Plans.Currency')}
					<Select
						size="small"
						value={state.currency}
						onChange={(v) =>
							setState((draft) => {
								draft.currency = v;
							})
						}
					>
						{(Object.keys(Currencies) as Array<keyof typeof Currencies>).map((key) => (
							<Select.Option key={key} value={Currencies[key]}>
								{key.toUpperCase()}
							</Select.Option>
						))}
					</Select>
				</Space>
			</Col>
			<Col>
				<Space direction="vertical" align="end">
					<Space>
						<Typography.Text>{t('Plans.BillingPeriod')}</Typography.Text>
						<Switch
							checked={state.billingFrequency === BillingFrequencies.Yearly}
							onChange={(v) =>
								setState((draft) => {
									draft.billingFrequency = v
										? BillingFrequencies.Yearly
										: BillingFrequencies.Monthly;
								})
							}
							checkedChildren={t('Plans.Yearly')}
							unCheckedChildren={t('Plans.Monthly')}
						/>
					</Space>
					{state.billingFrequency === BillingFrequencies.Yearly ? (
						<Typography.Text italic type="success">
							{t('Plans.YearlySave', { percent: 16 })}
						</Typography.Text>
					) : (
						<Typography.Text italic type="warning">
							{t('Plans.MonthlySave', { percent: 16 })}
						</Typography.Text>
					)}
				</Space>
			</Col>
		</Row>
	);

	if (prices?.length === 0)
		return (
			<Space size="middle" direction="vertical" style={{ width: '100%' }}>
				<Typography.Text italic>
					Sorry, we do not offer a subscription of the configured currency and billing
					period.
				</Typography.Text>
				<PriceConfig />
			</Space>
		);

	return (
		<>
			<Tabs>
				{prices?.map((price) => (
					<Tabs.TabPane tab={price?.planName} key={price?.id}>
						<Space size="large" direction="vertical" style={{ width: '100%' }}>
							<PriceConfig />
							{invoiceOnly && (
								<Row>
									<Col>
										<Alert
											type="info"
											message={t('Plans.InvoiceOnly.Message')}
											closable={false}
										/>
									</Col>
								</Row>
							)}
							<Row gutter={16} justify="start" align="middle">
								<Col>
									<Typography.Link
										href="https://konfidal.eu/pricing"
										target="_blank"
										rel="noreferrer"
									>
										{t('Plans.ComparePlans')}
									</Typography.Link>
								</Col>
								<Col flex="auto"></Col>
								<Col>
									<Typography.Text strong style={{ fontSize: '1.25em' }}>
										{price?.currency} {(price.amount / 100).toFixed(2)}
									</Typography.Text>
								</Col>
								<Col>
									<Button
										type="primary"
										size="large"
										onClick={() => checkout(price.tier)}
										disabled={invoiceOnly}
									>
										{checkoutIsLoading ? (
											<Space>
												<LoadingOutlined spin /> {t('Plans.LoadingCheckout')}
											</Space>
										) : (
											t('Plans.Buy')
										)}
									</Button>
								</Col>
							</Row>
						</Space>
					</Tabs.TabPane>
				))}
			</Tabs>
		</>
	);
};

export interface PlansModalState {
	visible: boolean;
	defaults: {
		currency?: Currencies;
		billingFrequency?: BillingFrequencies;
		tier?: number;
	};
	setDefaults: (defaults: {
		currency?: Currencies;
		billingFrequency?: BillingFrequencies;
		tier?: number;
	}) => void;
	open: (planDefaults: {
		currency?: Currencies;
		billingFrequency?: BillingFrequencies;
		tier?: number;
	}) => void;
	close: () => void;
}

export const usePlansModalStore = createSelectorHooks(
	createStore<PlansModalState>((set) => ({
		visible: false,
		defaults: {},
		setDefaults: (defaults) => set({ defaults }),
		open: (planDefaults) => set({ visible: true, defaults: planDefaults }),
		close: () => set({ visible: false }),
	})),
);

export const PlansModal = () => {
	const company = useSelectedCompanyStore((state) => state.company);
	const { visible, defaults, close } = usePlansModalStore();

	if (!company) {
		return null;
	}
	return (
		<Modal
			visible={visible}
			closable
			onCancel={close}
			onOk={close}
			footer={null}
			// maskStyle={{ backdropFilter: 'blur(10px)' }}
			centered
			bodyStyle={{ minHeight: '200px' }}
		>
			<Plans
				onCheckout={close}
				{...defaults}
				companyId={company.id}
				companyTier={company.tier}
			/>
		</Modal>
	);
};

export const PlansPage = () => {
	const company = useSelectedCompanyStore((state) => state.company);
	const defaults = usePlansModalStore.useDefaults();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');

	if (!company) {
		return (
			<Result
				status="error"
				title="We sincerely apologize!"
				subTitle="This is a mistake on our side and has already been reported to us. For further assistance with t"
			/>
		);
	}

	const close = () => {
		setTimeout(() => navigate('/admin/dashboard'), 1000);
	};

	return (
		<Row
			style={{ width: '100%', minHeight: '300px', height: '75%' }}
			justify="center"
			align="middle"
		>
			<Col style={{ width: '100%', maxWidth: '500px' }}>
				<Typography.Title level={2}>{t('Plans.PlansTitle')}!</Typography.Title>
				<Space direction="vertical" size="middle" style={{ width: '100%' }}>
					<Card hoverable={false}>
						<Plans
							onCheckout={close}
							{...defaults}
							companyId={company.id}
							companyTier={company.tier}
						/>
					</Card>
					<Row justify="end">
						<Col>
							<Button type="text" onClick={() => navigate('/admin/dashboard')}>
								{t('Plans.ContinueFree')}
							</Button>
						</Col>
					</Row>
				</Space>
			</Col>
		</Row>
	);
};

export const tierToPlanName = (tier?: number) => {
	switch (tier) {
		case 0:
			return 'free';
		case 1:
			return 'business';
		case 2:
			return 'enterprise';
		case 3:
			return 'consult';
		default:
			return 'free';
	}
};
export const tierToColor = (tier?: number) => {
	switch (tier) {
		case 0:
			return 'blue';
		case 1:
			return 'cyan';
		case 2:
			return 'purple';
		case 3:
			return 'magenta';
		default:
			return 'blue';
	}
};

export const tierToColorBackground = (tier?: number) => {
	switch (tier) {
		case 0:
			return '#E6F7FF';
		case 1:
			return '#fffbe6';
		case 2:
			return '#f9f0ff';
		default:
			return '#E6F7FF';
	}
};

const currencyFromLanguage = (language?: Languages | null) => {
	switch (language) {
		default:
			return Currencies.Eur;
	}
};

export const CurrentPlanTag = ({ style }: { style?: CSSProperties }) => {
	const company = useSelectedCompanyStore((state) => state.company);
	const openModal = usePlansModalStore((state) => state.open);
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const { data } = useSelfQuery({}, { enabled: isAuthenticated });

	const onClick: React.MouseEventHandler = (e) => {
		e.preventDefault();
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();
		openModal({
			currency: currencyFromLanguage(
				Languages[data?.self.lang as keyof typeof Languages],
			),
		});
	};

	const tag = (
		<Tag
			style={style}
			onClick={
				(company?.tier ?? 0) < 2 &&
				accessLevelAbove(AccessLevel.Admin, company?.selfAccessLevel)
					? onClick
					: undefined
			}
			color={tierToColor(company?.tier)}
			className={onClick != null ? 'CurrentPlanTag Clickable' : 'CurrentPlanTag'}
		>
			{tierToPlanName(company?.tier)}
		</Tag>
	);

	if (company == null)
		return <Skeleton.Button active={true} size="small" shape="round" block={false} />;

	if (company?.tier === 0)
		return (
			<Popover
				content={
					<>
						<Typography.Title level={5}>
							{t('PlanTag.Free.Popover.Title')}
						</Typography.Title>
						<Typography.Paragraph>
							{t('PlanTag.Free.Popover.Description')}
						</Typography.Paragraph>
					</>
				}
			>
				{tag}
			</Popover>
		);
	else return tag;
};
