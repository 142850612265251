import { LoadingOutlined } from '@ant-design/icons';
import { Col, Form, message, Row, Spin, Switch, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
	CreateCompanyInput,
	CreateChildCompanyInput,
	JoinCompanyMutation,
	useCreateCompanyMutation,
	useCreateChildCompanyMutation,
	useSelfQuery,
} from '../../generated/graphql';
import { useAuthStore } from '../../hooks/useAuth';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { AddressFormItem } from '../Form/AddressFormItem';
import { Alert } from '../util/Alert';
import { Button } from '../util/Button';
import { Input } from '../util/Input';
import { slugify } from '../../lib/util';

export type FormType = Omit<CreateChildCompanyInput, 'password'>;

export interface CreateCompanyFormProps {
	onSuccess: (company: JoinCompanyMutation['joinCompany']) => void;
	onError?: () => void;
	onCancel?: () => void;
	child?: boolean;
	onLoading?: (x: boolean) => void;
}

export const CreateCompanyForm = ({
	onCancel,
	onSuccess,
	onError,
	onLoading,
	child = false,
}: CreateCompanyFormProps) => {
	const { t } = useTranslation('translations');
	const [companyName, setCompanyName] = useState('');
	const queryClient = useQueryClient();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const { data, isError, isSuccess } = useSelfQuery({}, { enabled: isAuthenticated });
	const { mutateAsync, isLoading } = useCreateCompanyMutation();
	const { mutateAsync: createChild, isLoading: isLoadingCreateChild } =
		useCreateChildCompanyMutation();
	const [form] = Form.useForm<FormType>();
	const [alert, setAlert] = useState<string | undefined>(undefined);
	const parent = useSelectedCompanyStore((state) => state.company);

	useEffect(() => {
		onLoading && onLoading(isLoading || isLoadingCreateChild);
	}, [onLoading, isLoading, isLoadingCreateChild]);

	useEffect(() => {
		const lang = data?.self.lang;
		if (lang && ['DE', 'PT'].includes(lang)) {
			console.log('setFields');
			form.setFields([{ name: ['address', 'country'], value: lang }]);
		}
	}, [data]);

	const onFormFinish = (formData: FormType) => {
		if (child && parent)
			createChild({ parentId: parent?.id, input: formData })
				.then((res) => {
					message.success(t('CreateCompanyModal.Message.Success'));
					queryClient.invalidateQueries('self');
					onSuccess(res.createChildCompany);
					form.resetFields();
				})
				.catch((error) => {
					if ((error?.response?.errors?.length ?? 0) > 0)
						setAlert(error?.response?.errors[0].message);
					onError && onError();
				});
		else
			mutateAsync({ input: formData })
				.then((res) => {
					message.success(t('CreateCompanyModal.Message.Success'));
					queryClient.invalidateQueries('self');
					onSuccess(res.createCompany.company);
					form.resetFields();
				})
				.catch((error) => {
					setAlert(error.response.errors[0].message);
					onError && onError();
				});
	};

	return (
		<>
			{alert ? <Alert style={{ width: '100%' }} message={alert} /> : null}
			<Form
				form={form}
				name="basic"
				// labelCol={{ span: 8 }}
				// wrapperCol={{ offset: 8, span: 24 }}
				onFinish={onFormFinish}
				autoComplete="off"
				layout="vertical"
				style={{ width: '100%' }}
			>
				<Form.Item
					name="name"
					label={t('CreateCompanyModal.Form.Name')}
					rules={[
						{
							required: true,
							message: t('FieldIsMissing', {
								fieldName: t('CreateCompanyModal.Form.Name'),
							}),
						},
					]}
				>
					<Input
						onChange={(e) => form.setFieldsValue({ slug: slugify(e.target.value) })}
						maxLength={255}
						placeholder={t('CreateCompanyModal.Form.NamePlaceholder')}
					/>
				</Form.Item>
				<Form.Item
					name="slug"
					label={t('CreateCompanyModal.Form.Slug')}
					normalize={(value) => slugify(value, false)}
					tooltip={t('CreateCompanyModal.Form.SlugPlaceholder')}
					rules={[
						{
							required: true,
							message: t('FieldIsMissing', {
								fieldName: t('CreateCompanyModal.Form.Slug'),
							}),
						},
					]}
				>
					<Input maxLength={255} placeholder={t('CreateCompanyModal.Form.SlugTooltip')} />
				</Form.Item>
				<AddressFormItem name="address" />
				{child && parent?.tier === 3 && (
					<Form.Item
						name="demo"
						help={t('CreateCompanyModal.Form.DemoTooltip')}
						label={t('CreateCompanyModal.Form.Demo')}
						valuePropName="checked"
					>
						<Switch />
					</Form.Item>
				)}
				<Row justify="space-between">
					{onCancel != null ? (
						<Col>
							<Button type="text" onClick={onCancel}>
								{t('Back')}
							</Button>
						</Col>
					) : null}
					<Col>
						<Button type="primary" onClick={() => form.submit()}>
							{t('Create')} {(isLoading || isLoadingCreateChild) && <LoadingOutlined />}
						</Button>
					</Col>
				</Row>
			</Form>
		</>
	);
};
