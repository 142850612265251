import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	FeatureNames,
	IncidentInviteExternalMutation,
	IncidentInviteInternalMutation,
	IncidentParticipantLevel,
	IncidentQuery,
	JoinIncidentRequestState,
	useCompanyQuery,
	useIncidentInviteExternalMutation,
	useIncidentInviteInternalMutation,
} from '../../generated/graphql';
import { Breadcrumb, BreadcrumbProps } from './Breadcrumb';
import { Space, Row, Col, Typography } from 'antd';
import { InviteModal } from './Invite';
import { FeatureLock, useFeatureLock } from './FeatureLock';
import { Button } from '../util/Button';
import { UserAvatar } from '../util/Avatar';
import { PendingInviteUserRequests } from '../util/PendingIncidentInviteUser';
import { Helmet } from 'react-helmet';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { IncidentParticipantLevelSelect } from '../util/EnumSelect';
import { useQueryClient } from 'react-query';

type Incident = IncidentQuery['incident'];

const InviteUserButton = ({
	incident,
	onJoinRequest,
}: {
	incident: IncidentQuery['incident'];
	onJoinRequest: (
		request:
			| IncidentInviteExternalMutation['inviteExternalToIncident']
			| IncidentInviteInternalMutation['inviteInternalToIncident'],
	) => void;
}) => {
	const { t } = useTranslation(undefined, {
		keyPrefix: 'IncidentParticipantsOverview.Actions.Invite',
	});
	const qc = useQueryClient();
	const { mutateAsync: inviteExternal } = useIncidentInviteExternalMutation();
	const { mutateAsync: inviteInternal } = useIncidentInviteInternalMutation();
	const [visible, setVisible] = useState(false);
	const [error, setError] = useState<string>();
	const { data } = useCompanyQuery({ id: incident.channel.company.id });
	const users = useMemo(
		() =>
			data?.company.users
				.filter(
					(u) =>
						incident.recipients &&
						incident.recipients.find((r) => r.user?.id === u.id) === undefined,
				)
				.filter(
					(u) =>
						incident.joinRequests &&
						incident.joinRequests.find(
							(r) =>
								r.state !== JoinIncidentRequestState.Rejected &&
								(r.invitedUser?.id === u.id || r.email === u.email),
						) === undefined,
				),
		[data, incident],
	);

	const onInviteByEmail = async (
		email: string,
		level?: IncidentParticipantLevel,
		firstname?: string,
		lastname?: string,
		message?: string,
	) => {
		if (!level) return false;
		try {
			const res = await inviteExternal({
				incidentId: incident.id,
				input: {
					email,
					level,
					message,
					firstname: firstname as string,
					lastname: lastname as string,
				},
			});
			onJoinRequest(res.inviteExternalToIncident);
			await qc.invalidateQueries(['incident', { id: incident.id }]);
			return true;
		} catch (err: any) {
			setError(err?.response?.errors[0]?.message || err?.message);
			return false;
		}
	};

	const onInvite = async (
		userId: number,
		level?: IncidentParticipantLevel,
		message?: string,
	) => {
		if (!level) return false;
		try {
			const res = await inviteInternal({
				incidentId: incident.id,
				userId,
				level,
				message,
			});
			await qc.invalidateQueries(['incident', { id: incident.id }]);
			return true;
		} catch (err: any) {
			setError(err?.response?.errors[0]?.message || err?.message);
			return false;
		}
	};

	return (
		<>
			{users != null && (
				<InviteModal
					entity="incident"
					onInvite={onInvite}
					onInviteByEmail={onInviteByEmail}
					loading={false}
					error={error}
					users={users.map((u) => {
						u.lastname = u.lastname || '';
						return { ...u, surname: u.surname || '', lastname: u.lastname || '' };
					})}
					visible={visible}
					title={t('Modal.Title')}
					onClose={() => setVisible(false)}
					showFirstname
					showLastname
				/>
			)}
			<Button disabled={users == null} onClick={() => setVisible(true)}>
				{t('Button.Text')}
			</Button>
		</>
	);
};

type ActionHandlers = {
	onJoinRequest: (
		request:
			| IncidentInviteExternalMutation['inviteExternalToIncident']
			| IncidentInviteInternalMutation['inviteInternalToIncident'],
	) => void;
};

const Actions = ({
	incident,
	onJoinRequest,
}: { incident: Incident } & ActionHandlers) => {
	// const { t } = useTranslation('translations', {
	// 	keyPrefix: 'IncidentParticipantsOverview.Actions',
	// });
	const [inviteModalVisible, setInviteModalVisible] = useState(false);

	return (
		<Space>
			<FeatureLock featureName={FeatureNames.MultipleUsers}>
				<InviteUserButton {...{ incident, onJoinRequest }} />
			</FeatureLock>
		</Space>
	);
};

const ParticipantsRow = ({
	participant,
	onEdit,
}: {
	participant: Incident['recipients'][0];
	onEdit: (edit: boolean) => void;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentParticipantLevel' });
	const [edit, setEdit] = useState(false);
	const [editedLevel, setEditedLevel] = useState<string>(participant.level);
	const { locked: editingEnabled } = useFeatureLock(
		FeatureNames.IncidentParticipantEditing,
	);

	// TODO: also display a row for the hinter
	if (participant.user === null) {
		return null;
	}
	return (
		<Row gutter={[16, 16]} align="middle" wrap={false}>
			<Col>
				<UserAvatar
					size="large"
					user={{
						id: participant.user?.avatar?.id,
						surname: participant.user?.surname,
						lastname: participant.user?.lastname,
					}}
				/>
			</Col>
			<Col flex="auto">
				<Row gutter={16} justify="space-between" align="middle">
					<Col>
						<Typography.Text>{`${participant.user?.firstname} ${participant.user?.lastname}`}</Typography.Text>
						<br />
						<Typography.Text type="secondary">{participant.user?.email}</Typography.Text>
					</Col>
					<Col>
						{edit ? (
							<IncidentParticipantLevelSelect
								value={editedLevel}
								onSelect={(value) => setEditedLevel(value)}
							/>
						) : (
							<div style={{ paddingLeft: '11px', paddingRight: '11px' }}>
								<Typography.Text strong>{t(participant.level)}</Typography.Text>
							</div>
						)}
					</Col>
				</Row>
			</Col>
			{editingEnabled && (
				<Col sm={4} md={2} lg={1}>
					{edit ? (
						<Space>
							<Button icon={<SaveOutlined />} />
							<Button
								icon={<CloseOutlined />}
								onClick={() => {
									setEdit(false);
									setEditedLevel(participant.level);
									onEdit(false);
								}}
							/>
						</Space>
					) : (
						<Button
							icon={<EditOutlined />}
							type="text"
							onClick={() => {
								onEdit(true);
								setEdit(true);
							}}
						/>
					)}
				</Col>
			)}
		</Row>
	);
};

export const IncidentParticipantsOverview = ({
	incident,
	breadcrumbsRoot,
}: {
	incident: IncidentQuery['incident'];
	breadcrumbsRoot: BreadcrumbProps['items'];
}) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'IncidentParticipantsOverview',
	});
	const { t: tt } = useTranslation('translations');

	const breadcrumbs: BreadcrumbProps['items'] = [
		...breadcrumbsRoot,
		{
			type: 'user',
			display: t('Breadcrumb'),
			routeParam: `participants`,
		},
	];

	return (
		<>
			<Helmet>
				<title>
					{tt('Meta.IncidentParticipantsOverview.Title', {
						id: incident.fileNumber ?? incident.nanoId,
					})}
				</title>
				<meta
					name="description"
					content={tt('Meta.IncidentParticipantsOverview.Description', {
						id: incident.fileNumber ?? incident.nanoId,
					})}
				/>
			</Helmet>
			<Space direction="vertical" size="large" style={{ width: '100%' }}>
				<Breadcrumb items={breadcrumbs} />
				<Row justify="space-between" align="middle" style={{ width: '100%' }}>
					<Col>
						<Typography.Title level={1}>{t('Title')}</Typography.Title>
					</Col>
					<Col style={{ justifyContent: 'end', display: 'flex' }}>
						<Actions incident={incident} onJoinRequest={(r) => {}} />
					</Col>
				</Row>
				<Space direction="vertical" size="middle" style={{ width: '100%' }}>
					{incident.recipients.map((p) => (
						<ParticipantsRow onEdit={() => {}} key={p.id} participant={p} />
					))}
				</Space>
				<PendingInviteUserRequests requests={incident.joinRequests} />
			</Space>
		</>
	);
};
