import { Select } from '../../util/Select';
import type { OptionType } from 'antd/lib/select';
import { useState, useMemo, useEffect } from 'react';
import { SelfCompaniesQuery, useSelfCompaniesQuery } from '../../../generated/graphql';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { captureException } from '@sentry/react';

export type TemplateShare = {
	channelId?: number;
	companyId?: number;
};

type Company = SelfCompaniesQuery['self']['companies'][0];

export const MessageTemplateShareSelect = ({
	value,
	onSelect,
	style,
	allCompanies = false,
	isOwner = false,
}: {
	value?: TemplateShare;
	onSelect: (newValue: TemplateShare) => void;
	style?: React.CSSProperties;
	allCompanies?: boolean;
	isOwner?: boolean;
}) => {
	const { data, isLoading, isSuccess } = useSelfCompaniesQuery(
		{},
		{ enabled: allCompanies },
	);
	const company = useSelectedCompanyStore((s) => s.company);
	const companies = useMemo(() => {
		if (allCompanies && isSuccess) return data?.self.companies;
		if (!allCompanies && company != null) return [company];
		return undefined;
	}, [allCompanies, company, data, isSuccess]);
	const channels = useMemo(
		() => companies?.map((c) => c.channels).reduce((curr, acc) => acc.concat(curr)),
		[companies],
	);
	const [selectValue, setSelectValue] = useState<string>('private');

	const templateShareToSelectValue = (share: TemplateShare): string => {
		if (share.channelId) {
			return 'channel-' + channels?.find((c) => c.id === share.channelId)?.id || '';
		} else if (share.companyId) {
			return 'company-' + companies?.find((c) => c.id === share.companyId)?.id || '';
		} else {
			return 'private';
		}
	};

	useEffect(() => {
		if (value && companies && channels) {
			if (
				(value.companyId && !companies.find((c) => c.id === value?.companyId)) ||
				(value.channelId && !channels.find((c) => c.id === value?.channelId))
			) {
				captureException(
					`MessageTemplateShareSelect was given a value ${value} that contains ids that could not be found in companies/channls`,
				);
				return;
			} else {
				value && setSelectValue(templateShareToSelectValue(value));
			}
		}
	}, [companies, value, channels]);

	const options = useMemo(
		() => [
			...(isOwner
				? [
						{
							label: 'Individual',
							options: [
								{
									label: 'Private',
									value: 'private',
								},
							],
						},
				  ]
				: []),
			...(companies
				? companies.map((c) => ({
						label: c.name,
						options: [
							{
								label: 'All of ' + c.name,
								value: 'company-' + c.id,
								company: c,
							},
							...c.channels?.map((ch) => ({
								label: [ch.name, ch.config.language, ch.config.country].join(' | '),
								value: 'channel-' + ch.id,
								channel: ch,
							})),
						],
				  }))
				: []),
		],
		[data],
	);

	return (
		<Select
			style={{ minWidth: '200px', ...style }}
			value={selectValue}
			loading={isLoading}
			options={options}
			onSelect={(value, option) => {
				if (value === 'private') {
					onSelect({});
				} else if (option.channel) {
					onSelect({ channelId: option.channel.id });
				} else if (option.company) {
					onSelect({ companyId: option.company.id });
				}
			}}
		/>
	);
};
