import { Divider, SelectProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortDirection, useCompanyChannelsQuery } from '../../generated/graphql';
import useDebounce from '../../hooks/useDebounce';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { Select } from './Select';

export type ChannelSelectProps = SelectProps<number>;

export const ChannelSelect = (props: ChannelSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'ChannelSelect' });
	const companyId = useSelectedCompanyStore((s) => s.companyId);
	const { data, isLoading, isSuccess, isError, refetch } = useCompanyChannelsQuery(
		{
			id: companyId ?? 0,
		},
		{ enabled: companyId != null },
	);
	const options = isSuccess
		? data?.company.channels?.map((c) => ({
				label: `${c.name} ${c.config.country} ${c.config.language}`,
				value: c.id,
		  }))
		: [];

	if ((options?.length ?? 0) < 2) {
		const label = options?.find((o) => o.value === props.value)?.label ?? props.value;
		if (label) return <span>{label}</span>;
	}

	return (
		<Select
			loading={isLoading}
			placeholder={t('Placeholder')}
			filterOption={false}
			options={options}
			{...props}
		></Select>
	);
};
