import { Button, Col, Dropdown, Layout, Menu, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Roles, useAuthStore, useLogout } from '../../hooks/useAuth';
import LoginPage from './LoginPage';
import RegisterPage from './Registration';
import './AdminPortal.less';
import { ConfirmEmail } from './ConfirmEmail';
import { RequestResetPassword, SetNewPassword } from './ResetPassword';
import {
	Languages,
	useSelfQuery,
	useUpdateLangMutation,
	useSelfCompaniesQuery,
} from '../../generated/graphql';
import i18n from '../../i18n';
import { changeLanguage } from 'i18next';
import { AdminApp } from './AdminApp';
import { SetupCompany } from './SetupCompany';
import { Setup } from './Setup';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { AnimatePresence } from 'framer-motion';
import { PageAnimation } from '../util/Animation';
import { HelpCenter } from './HelpCenter';
import Prerelease from './Prerelease';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AdminPortal = () => {
	const { t } = useTranslation('translations');
	const logout = useLogout();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const session = useAuthStore((state) => state.session);
	const userHasBeenAuthenticatedBefore = useAuthStore(
		(state) => state.userHasBeenAuthenticatedBefore,
	);
	const navigate = useNavigate();
	const location = useLocation();
	const { data, isSuccess: selfQuerySuccess } = useSelfQuery(
		{},
		{ enabled: isAuthenticated },
	);
	const {
		data: dataCompanies,
		isSuccess: selfCompaniesQuerySuccess,
		refetch: refetchCompanies,
	} = useSelfCompaniesQuery({}, { enabled: isAuthenticated });
	const [resetSelectedCompany, setSelectedCompany] = useSelectedCompanyStore((state) => [
		state.reset,
		state.setCompany,
	]);
	const { mutateAsync: updateLang } = useUpdateLangMutation();

	React.useEffect(() => {
		if (
			selfQuerySuccess &&
			selfCompaniesQuerySuccess &&
			dataCompanies?.self.companies != null &&
			dataCompanies?.self.companies?.length > 0
		) {
			setSelectedCompany(dataCompanies.self.companies[0]);
		} else if (selfQuerySuccess && dataCompanies?.self.companies?.length === 0) {
			resetSelectedCompany();
			refetchCompanies();
		}
	}, [
		selfQuerySuccess,
		selfCompaniesQuerySuccess,
		data,
		setSelectedCompany,
		dataCompanies,
	]);

	React.useEffect(() => {
		const change = async () => {
			if (data?.self?.lang) {
				await changeLanguage(data.self.lang.toLowerCase());
			} else if (data?.self) {
				updateLang({ lang: i18n.language.toUpperCase() as Languages });
			}
		};
		change();
	}, [data]);

	// logged out redirect
	React.useEffect(() => {
		// need to exclude public routes
		if (
			!isAuthenticated &&
			!['login', 'register', 'confirm', 'resetPassword'].includes(
				location.pathname.split('/')[1],
			) &&
			!['login', 'register', 'confirm', 'resetPassword'].includes(
				location.pathname.split('/')[0],
			)
		) {
			if (userHasBeenAuthenticatedBefore) navigate('/login');
			// if there is no old, outdated login session in the local storage, we redirect to register
			else navigate('/register');
		}
	}, [
		isAuthenticated,
		navigate,
		location.pathname,
		session,
		userHasBeenAuthenticatedBefore,
	]);

	// if lagged in as a hinter and trying to access the admin panel
	// then we logout and redirect ot the login
	useEffect(() => {
		if (isAuthenticated && session?.role === Roles[Roles.HINTER]) {
			logout(t('LogoutReason.Role'));
			navigate('/login');
		}
	}, [isAuthenticated, session]);

	// if first and lastname are not set, redirect the user back to the registration form to complete it
	React.useEffect(() => {
		if (isAuthenticated) {
			if (
				selfQuerySuccess &&
				data &&
				!data.self.surname &&
				!data.self.lastname &&
				location.pathname !== '/register' &&
				!location.pathname.startsWith('/setup/user')
			) {
				navigate('/setup/user');
			} else if (
				location.pathname.startsWith('/register') ||
				location.pathname.startsWith('/login')
			) {
				navigate('/admin/dashboard');
			}
		}
	}, [isAuthenticated, selfQuerySuccess, location, data]);

	const locationParts = location.pathname.split('/');
	let baseIndex = locationParts.findIndex((x) => x === process.env.REACT_APP_BASENAME);
	baseIndex = baseIndex < 0 ? 0 : baseIndex;
	const key = locationParts.slice(baseIndex + 1, baseIndex + 2).join('/');

	return (
		<Row className="Page" justify="center">
			<Col>
				<HelpCenter />
				{process.env.REACT_APP_PRERELEASE === 'TRUE' ? (
					<Prerelease />
				) : (
					<AnimatePresence exitBeforeEnter>
						<Routes key={key}>
							<Route path="confirm/:token" element={<ConfirmEmail />} />
							<Route path="resetPassword/:token" element={<SetNewPassword />} />
							<Route path="resetPassword/" element={<RequestResetPassword />} />
							<Route
								path="login/*"
								element={<LoginPage toRegister={() => navigate('register')} />}
							/>
							<Route
								path="register/*"
								element={
									<RegisterPage parentPath="register" toLogin={() => navigate('login')} />
								}
							/>
							<Route
								path="setup/*"
								element={
									<PageAnimation type="slide">
										<Setup parentPath="setup" />
									</PageAnimation>
								}
							/>
							<Route path="admin/*" element={<AdminApp parentPath="admin" />} />
							<Route path="/*" element={<Navigate to="/admin" />} />
						</Routes>
					</AnimatePresence>
				)}
			</Col>
		</Row>
	);
};

export default AdminPortal;
