import { Divider, SelectProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	SortDirection,
	SortIncidentField,
	SeachIncidentField,
	useIncidentsQuery,
} from '../../generated/graphql';
import useDebounce from '../../hooks/useDebounce';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { Select } from './Select';

/**
 * Example from antd documentation
import React, { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends { key?: string; label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

async function fetchUserList(username: string): Promise<UserValue[]> {
  console.log('fetching user', username);

  return fetch('https://randomuser.me/api/?results=5')
    .then((response) => response.json())
    .then((body) =>
      body.results.map(
        (user: { name: { first: string; last: string }; login: { username: string } }) => ({
          label: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        }),
      ),
    );
}

const App: React.FC = () => {
  const [value, setValue] = useState<UserValue[]>([]);

  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder="Select users"
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        setValue(newValue as UserValue[]);
      }}
      style={{ width: '100%' }}
    />
  );
};

export default App;
 */

export type IncidentSelectProps = SelectProps<number>;

export const IncidentSelect = (props: IncidentSelectProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentSelect' });
	const companyId = useSelectedCompanyStore((s) => s.companyId);
	const [search, setSearch] = useState<string>();
	const [debouncedSearch, clearDebouncedSearch] = useDebounce<string>(500, search);
	const [page, setPage] = useState(0);
	const { data, isLoading, isSuccess, isError, refetch } = useIncidentsQuery(
		{
			companyId: companyId ?? 0,
			page: {
				page,
				limit: 10,
			},
			search: debouncedSearch
				? {
						fields: [SeachIncidentField.Subject, SeachIncidentField.FileNumber],
						text: debouncedSearch,
				  }
				: null,
			sort: {
				field: SortIncidentField.Updated,
				direction: SortDirection.Desc,
			},
			filter: {
				filters: [],
			},
		},
		{ enabled: companyId != null },
	);
	const options = isSuccess
		? data?.incidents?.data?.map((i) => ({
				label: `${i.fileNumber}`,
				value: i.id,
		  }))
		: [];

	return (
		<Select
			loading={isLoading}
			placeholder={t('Placeholder')}
			filterOption={false}
			onSearch={setSearch}
			options={options}
			showSearch
			searchValue={search}
			{...props}
		></Select>
	);
};
