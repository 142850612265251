import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Empty, Result, Row, Space, Spin, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageTemplateDataFragment, useSelfQuery } from '../../../generated/graphql';
import { MessageTemplateCard } from './MessageTemplateCard';
import { MessageTemplateFormValue, TemplateModal } from './MessageTemplateModal';
import { useMessageTemplates } from './useMessageTemplates';

export const MessageTemplates = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'MessageTemplates' });
	const selfQuery = useSelfQuery();
	const selfId = useMemo(() => selfQuery.data?.self.id, [selfQuery.data?.self.id]);
	const { data, isLoading, isError, refetch } = useMessageTemplates({});

	const [modalVisible, setModalVisible] = useState(false);
	const [modalInitValue, setModalInitValue] = useState<MessageTemplateFormValue>();
	const [editTemplateId, setEditTemplateId] = useState<number>();
	const [editTemplateOwner, setEditTemplateOwner] = useState<boolean>(false);

	const templateModalClose = () => {
		setModalInitValue(undefined);
		setEditTemplateId(undefined);
		setEditTemplateOwner(false);
		setModalVisible(false);
	};

	const onCreate = () => {
		setEditTemplateId(undefined);
		setModalInitValue(undefined);
		setModalVisible(true);
		setEditTemplateOwner(true);
	};

	const onEdit = (template: MessageTemplateDataFragment) => {
		setEditTemplateId(template.id);
		setModalInitValue({
			name: template.name,
			text: template.text,
			companyId: template.company?.id,
			channelId: template.channel?.id,
		});
		setModalVisible(true);
		setEditTemplateOwner(template.createdBy.id === selfId);
	};

	return (
		<>
			<TemplateModal
				visible={modalVisible}
				close={templateModalClose}
				initFormValue={modalInitValue}
				templateId={editTemplateId}
				onSaveTemplate={(t) => {
					onEdit(t); // we will assume the user wants to keep editing the template after creation
					if (!data) return;
					const idx = data.messageTemplates.findIndex((tt) => tt.id === t.id);
					if (idx === -1) data.messageTemplates.push({ ...t, medias: [] });
					else data.messageTemplates[idx] = { ...t, medias: [] };
					refetch();
					templateModalClose();
				}}
				onRemoveTemplate={(id) => {
					if (!data) return;
					data.messageTemplates = data.messageTemplates.filter((t) => t.id !== id);
					refetch();
					templateModalClose();
				}}
				isOwner={editTemplateOwner}
			/>
			<Row>
				<Col flex="auto">
					<Typography.Title level={2}>{t('Title')}</Typography.Title>
				</Col>
				<Col>
					<Space wrap>
						<Button onClick={onCreate} icon={<PlusOutlined />}>
							{t('Create')}
						</Button>
					</Space>
				</Col>
			</Row>
			<Row
				gutter={[
					{ lg: 24, md: 16, sm: 8, xs: 4 },
					{ lg: 24, md: 16, sm: 8, xs: 4 },
				]}
			>
				<Col span={24} style={{ textAlign: 'center' }}>
					{isError && <Result status="error" title={t('Error.Title')} />}
					{isLoading && <Spin />}
					{data?.messageTemplates.length === 0 && <Empty />}
				</Col>
				{data?.messageTemplates.map((t) => (
					<Col lg={8} md={12} sm={12} xs={24} key={t.nanoId}>
						<MessageTemplateCard template={t} onClick={onEdit} />
					</Col>
				))}
			</Row>
		</>
	);
};
