import { CloseOutlined } from '@ant-design/icons';
import { Col, Row, Space, Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
	AuditEventDataFragment,
	AuditEventNames,
	IncidentAuditLogQuery,
	Languages,
} from '../../generated/graphql';
import { Button } from '../util/Button';
import { Card } from '../util/Card';
import { Time } from '../util/Time';

interface AuditEvent {
	id: number;
	nanoId: string;
	_uuid: string;
	name: AuditEventNames;
	created: string;
	value?: string;
	oldValue?: string;
	property?: string;
	incident?: null | {
		id: number;
		nanoId: string;
		_uuid: string;
		hinter: {
			firstname?: null | string;
			lastname?: null | string;
			anonymous: boolean;
		};
	};
	user?: null | {
		id: number;
		nanoId: string;
		_uuid: string;
		firstname?: null | string;
		lastname?: null | string;
	};
	channel?: null | {
		id: number;
		nanoId: string;
		_uuid: string;
		name: string;
		config?: {
			language?: Languages;
		};
	};
	message?: null | {
		id: number;
		nanoId: string;
		_uuid: string;
		fromHint: string;
		sender: {
			firstname: string;
			lastname: string;
			nanoId: string;
		};
	};
}
const useAuditLogEventNameTranslation = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'AuditLog.Event' });
	const tt = (key: string) => {
		switch (key) {
			case 'LOGIN_HINTER':
				return t('LOGIN_HINTER');
			case 'LOGIN_USER':
				return t('LOGIN_USER');
			case 'PRINTED_INCIDENT':
				return t('PRINTED_INCIDENT');
			case 'SET_RESPONSIBLE_TO_INCIDENT':
				return t('SET_RESPONSIBLE_TO_INCIDENT');
			case 'PASSWORD_CHANGED':
				return t('PASSWORD_CHANGED');
			case 'REQUESTED_PASSWORD_CHANGED':
				return t('REQUESTED_PASSWORD_CHANGED');
			case 'INCIDENT_DELETED':
				return t('INCIDENT_DELETED');
			case 'INCIDENT_CREATED':
				return t('INCIDENT_CREATED');
			case 'INCIDENT_CHANGED':
				return t('INCIDENT_CHANGED');
			case 'INCIDENT_DELETE_REQUESTED':
				return t('INCIDENT_DELETE_REQUESTED');
			case 'INCIDENT_DELETE_REQUEST_REVOKED':
				return t('INCIDENT_DELETE_REQUEST_REVOKED');
			case 'INCIDENTS_MERGED':
				return t('INCIDENTS_MERGED');
			case 'INCIDENT_COMMENT_CREATED':
				return t('INCIDENT_COMMENT_CREATED');
			case 'INCIDENT_INTERLINKED':
				return t('INCIDENT_INTERLINKED');
			case 'AUDIT_LOG_VIEW':
				return t('AUDIT_LOG_VIEW');
			case 'AUDIT_LOG_EXPORT':
				return t('AUDIT_LOG_EXPORT');
			case 'SET_NEW_MASTER_KEY':
				return t('SET_NEW_MASTER_KEY');
			case 'INCIDENT_TRANSLATION_REQUESTED':
				return t('INCIDENT_TRANSLATION_REQUESTED');
			case 'INCIDENT_TRANSLATION_CANCELLED':
				return t('INCIDENT_TRANSLATION_CANCELLED');
			case 'INCIDENT_TRANSLATION_FINALIZED':
				return t('INCIDENT_TRANSLATION_FINALIZED');
			case 'HINTER_FILE_TRANSLATION_REQUESTED':
				return t('HINTER_FILE_TRANSLATION_REQUESTED');
			case 'INTERLINK_REMOVED':
				return t('INTERLINK_REMOVED');
			case 'INCIDENT_EXPORT_REQUESTED':
				return t('INCIDENT_EXPORT_REQUESTED');
			case 'INCIDENT_EXPORT_DOWNLOADED':
				return t('INCIDENT_EXPORT_DOWNLOADED');
			case 'HINTER_COMMENT_CREATED':
				return t('HINTER_COMMENT_CREATED');
			case 'USER_ACCESS_CHANGED':
				return t('USER_ACCESS_CHANGED');
			case 'HINTER_INCIDENT_OVERVIEW_VIEWED':
				return t('HINTER_INCIDENT_OVERVIEW_VIEWED');
			case 'INCIDENT_OVERVIEW_VIEWED':
				return t('INCIDENT_OVERVIEW_VIEWED');
			case 'INCIDENT_MESSAGE_READ':
				return t('INCIDENT_MESSAGE_READ');
			case 'INCIDENT_COMMENT_EDITED':
				return t('INCIDENT_COMMENT_EDITED');
			case 'INCIDENT_COMMENT_DELETED':
				return t('INCIDENT_COMMENT_DELETED');
			case 'INCIDENT_ANONYMIZED':
				return t('INCIDENT_ANONYMIZED');
			case 'INCIDENT_USER_ADD_REQUESTED':
				return t('INCIDENT_USER_ADD_REQUESTED');
			case 'INCIDENT_USER_ADD_REJECTED':
				return t('INCIDENT_USER_ADD_REJECTED');
			case 'INCIDENT_USER_ADD_ACCEPTED':
				return t('INCIDENT_USER_ADD_ACCEPTED');
			case 'INCIDENT_MESSAGE_SUBMITTED':
				return t('INCIDENT_MESSAGE_SUBMITTED');
			case 'CHANNEL_CREATED':
				return t('CHANNEL_CREATED');
			case 'CHANNEL_USER_ADD':
				return t('CHANNEL_USER_ADD');
			case 'CHANNEL_USER_REMOVE':
				return t('CHANNEL_USER_REMOVE');
			case 'CHANNEL_CHANGED':
				return t('CHANNEL_CHANGED');
			case 'CHANNEL_CONFIG_CHANGED':
				return t('CHANNEL_CONFIG_CHANGED');
			default:
				return key;
		}
	};
	return tt;
};

export const AuditLogEntry = ({ entry }: { entry: AuditEventDataFragment }) => {
	const { t } = useTranslation('translations');
	const tt = useAuditLogEventNameTranslation();

	const Skaffold = (props: {
		id: string;
		time: string;
		user?: null | { firstname?: null | string; lastname?: null | string };
		children: React.ReactNode;
	}) => {
		return (
			<Space size="small" direction="vertical" style={{ width: '100%' }}>
				<Row justify="space-between" style={{ width: '100%' }}>
					<Col>
						{props.user?.firstname && props.user?.lastname && (
							<Typography.Text
								style={{ fontWeight: 'bold' }}
							>{`${props.user.firstname} ${props.user.lastname}`}</Typography.Text>
						)}
					</Col>
					<Col>
						<Typography.Text style={{ color: '#777777', fontWeight: 'bold' }}>
							<Time mode="absolute" date={props.time} />
						</Typography.Text>
					</Col>
				</Row>
				<Row>
					<Col style={{ paddingLeft: '16px', fontSize: '16px' }}>{props.children}</Col>
				</Row>
			</Space>
		);
	};
	let content: React.ReactNode;
	switch (entry.name) {
		default:
			content = (
				<Row justify="end">
					<Col style={{ fontSize: '16px' }} span={24}>
						{tt(entry.name)}
					</Col>
					{/* {entry.property && <Col>Property: {entry.property}</Col>}
					{entry.value && <Col>value: {entry.value}</Col>}
					{entry.oldValue && <Col>old value: {entry.oldValue}</Col>} */}
				</Row>
			);
			break;
	}

	return (
		<Skaffold
			id={entry.nanoId}
			time={entry.created}
			user={
				entry.user ||
				(entry.incident
					? entry.incident.hinter.anonymous
						? { firstname: t('Hinter'), lastname: ' ' }
						: {
								firstname: entry.incident.hinter.firstname,
								lastname: entry.incident.hinter.lastname,
						  }
					: undefined)
			}
		>
			{content}
		</Skaffold>
	);
};

export interface AuditLogProps {
	style?: React.CSSProperties;
	log: AuditEventDataFragment[];
}

export const AuditLog = (props: AuditLogProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'AuditLog' });

	return (
		<Space direction="vertical" size="large" style={{ width: '100%' }}>
			<Typography.Title level={2}>{t('Title')}</Typography.Title>
			{props.log.map((entry, index) => (
				<AuditLogEntry key={index} entry={entry} />
			))}
		</Space>
	);
};

export interface AuditLogPopupProps extends AuditLogProps {
	visible: boolean;
	isLoading?: boolean;
	isError?: boolean;
	onClose: () => void;
}

export const AuditLogPopup = ({ visible, onClose, ...props }: AuditLogPopupProps) => {
	return (
		<AnimatePresence>
			{visible && (
				<motion.div
					key={3}
					style={{
						width: '100vw',
						height: '100vh',
						zIndex: 1,
						position: 'fixed',
						top: 0,
						left: 0,
						overflowX: 'hidden',
						overflowY: 'auto',
					}}
					initial={{
						// WebkitBackdropFilter: 'blur(0px)',
						// backdropFilter: 'blur(0px)',
						backgroundColor: 'rgba(0, 0, 0, 0)',
					}}
					animate={{
						// WebkitBackdropFilter: 'blur(10px)',
						// backdropFilter: 'blur(10px)',
						backgroundColor: 'rgba(0, 0, 0, 0.25)',
					}}
					exit={{
						// WebkitBackdropFilter: 'blur(0px)',
						// backdropFilter: 'blur(0px)',
						backgroundColor: 'rgba(0, 0, 0, 0)',
					}}
				>
					<motion.div
						key={2}
						initial={{ opacity: 0, y: 200 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 400 }}
						style={{
							padding: '32px',
							marginTop: '128px',
						}}
					>
						<Card
							style={{
								margin: 'auto',
								maxWidth: '500px',
								padding: '32px',
							}}
							hoverable={false}
						>
							<AuditLog {...props} />
						</Card>
					</motion.div>
					<motion.div
						key={1}
						style={{
							zIndex: 2,
							position: 'fixed',
							top: 32,
							right: 32,
						}}
						initial={{ y: -100 }}
						animate={{ y: 0 }}
						exit={{ y: -100 }}
					>
						<Button onClick={onClose} icon={<CloseOutlined />} size="large" />
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};
