import { Col, Row, Space, Typography, Modal, message, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {
	useCompanyApiKeysQuery,
	useCreateCompanyApiKeyMutation,
	useRemoveCompanyApiKeyMutation,
} from '../generated/graphql';
import { Card } from './util/Card';
import { Button } from './util/Button';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelectedCompanyStore } from '../hooks/useSelectedCompanyStore';
import { Input } from './util/Input';
import { useState } from 'react';
import { Alert } from './util/Alert';
import { PropertySafetyFilled } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import format from 'date-fns/format';
import { getDateFnsLocale } from '../lib/util';
import Item from 'antd/lib/list/Item';

const ModalCreateApiKey = ({
	companyId,
	visible,
	close: onClose,
}: {
	companyId: number;
	visible: boolean;
	close: (success: boolean) => void;
}) => {
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'CompanyApiKeys.Modal.Create',
	});

	const { isLoading, mutateAsync: createApiKey } = useCreateCompanyApiKeyMutation();
	const [name, setName] = useState<string>();
	const [alert, setAlert] = useState<string>();

	const onCreated = (key: string, secret: string) => {
		Modal.warn({
			title: tt('SaveKeyModal.Title'),
			content: (
				<>
					<Typography.Paragraph style={{ maxWidth: '100%' }}>
						{tt('SaveKeyModal.Description')}
					</Typography.Paragraph>
					<Typography.Paragraph strong copyable style={{ lineBreak: 'anywhere' }}>
						{key}
						{secret}
					</Typography.Paragraph>
				</>
			),
		});
	};

	const close = (success: boolean) => {
		setName(undefined);
		setAlert(undefined);
		onClose(success);
	};

	const onOk = async () => {
		if (!name || name?.trim().length < 5) {
			setAlert(tt('Message.NameTooShort'));
			return;
		}
		try {
			const res = await createApiKey({ id: companyId, name });
			onCreated(res.createCompanyApiKey.key, res.createCompanyApiKey.secret);
			close(true);
		} catch (e) {
			Sentry.captureException(e);
			message.error(tt('Message.Error'));
			close(false);
		}
	};

	return (
		<Modal
			visible={visible}
			title={tt('Title')}
			okText={tt('Ok')}
			onOk={onOk}
			confirmLoading={isLoading}
			onCancel={() => close(false)}
			destroyOnClose
		>
			{alert && <Alert message={alert} />}
			<Input
				title={tt('Input.Title')}
				value={name}
				onChange={(e) => setName(e.target.value)}
			/>
		</Modal>
	);
};

export const CompanyApiKeys = () => {
	const {
		t: tt,
		i18n: { language },
	} = useTranslation('translations', { keyPrefix: 'CompanyApiKeys' });
	const companyId = useSelectedCompanyStore((s) => s.companyId);
	const { data, isLoading, isError, refetch, isSuccess } = useCompanyApiKeysQuery(
		{ id: companyId || 0 },
		{ enabled: companyId != null },
	);
	const { mutateAsync: removeApiKey } = useRemoveCompanyApiKeyMutation();
	const [showCreateModal, setShowCreateModal] = useState(false);

	const onCreate = () => {
		setShowCreateModal(true);
	};

	const onRemove = (id: number, name: string) => {
		Modal.confirm({
			title: tt('Modal.Remove.Title', { name }),
			okText: tt('Modal.Remove.Ok'),
			onOk: () =>
				removeApiKey({ id })
					.then((res) => {
						message.success(tt('Modal.Remove.Message.Success'));
						refetch();
					})
					.catch((err) => {
						Sentry.captureException(err);
						message.error(tt('Modal.Remove.Message.Error'));
					}),
		});
	};

	const onCreateModalClose = (success: boolean) => {
		setShowCreateModal(false);
		if (success) refetch();
	};

	if (!companyId) return null;

	return (
		<Row gutter={[16, 16]}>
			<ModalCreateApiKey
				companyId={companyId}
				visible={showCreateModal}
				close={onCreateModalClose}
			/>
			<Col flex="auto">
				<Typography.Title level={4}>{tt('Title')}</Typography.Title>
			</Col>
			<Col>
				<Tooltip title={tt('Create.Tooltip')}>
					<Button onClick={onCreate} type="primary" icon={<PlusOutlined />} />
				</Tooltip>
			</Col>
			<Col span={24}>
				<Typography.Paragraph type="secondary">{tt('Description')}</Typography.Paragraph>
			</Col>
			{isLoading && (
				<Col>
					<Spin />
				</Col>
			)}
			{isError && (
				<Col>
					<Alert message={tt('Message.Error')} />
				</Col>
			)}
			{isSuccess &&
				data?.companyApiKeys.map((apiKey) => (
					<Col key={apiKey.id} span={24}>
						<Space direction="vertical" style={{ width: '100%' }}>
							<Row gutter={[16, 16]} align="middle">
								<Col>
									<Typography.Text strong>{apiKey.name}</Typography.Text>
								</Col>
								<Col>
									<Tooltip title={tt('CreatedAt.Tooltip')}>
										<Typography.Text type="secondary">
											{format(new Date(apiKey.created), 'Pp', {
												locale: getDateFnsLocale(language),
											})}
										</Typography.Text>
									</Tooltip>
								</Col>
								<Col flex="auto" />
								<Col>
									<Tooltip title={tt('Remove.Tooltip')}>
										<Button
											icon={<DeleteOutlined />}
											onClick={() => onRemove(apiKey.id, apiKey.name)}
										/>
									</Tooltip>
								</Col>
							</Row>
							<Typography.Text code ellipsis style={{ maxWidth: '100%' }}>
								{apiKey.key.slice(0, 32) + '*'.repeat(32)}
							</Typography.Text>
						</Space>
					</Col>
				))}
		</Row>
	);
};
