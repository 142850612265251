import {
	ArrowLeftOutlined,
	CloseOutlined,
	QuestionCircleFilled,
} from '@ant-design/icons';
import { Row, Col, Typography, Space, Form, Radio, message } from 'antd';
import { useState } from 'react';
import { Button } from '../util/Button';
import { AnimatePresence, motion } from 'framer-motion';
import './HelpCenter.less';
import { useAuthStore, UserSession } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { Card } from '../util/Card';
import { Input } from '../util/Input';
import {
	BugSeverity,
	FeatureRequestUrgency,
	FeedbackSentiment,
	useBugReportMutation,
	useContactMessageMutation,
	useFeatureRequestMutation,
	useFeedbackMessageMutation,
} from '../../generated/graphql';
import { Alert } from '../util/Alert';

interface HelpCenterCardProps {}

const BugReportCard = (props: { onFinish: () => void }) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'HelpCenter.BugReport.Form',
	});
	const [alert, setAlert] = useState<JSX.Element | null>(null);
	const { mutateAsync } = useBugReportMutation();

	const onFinish = (values: any) => {
		mutateAsync({
			input: {
				title: values.title,
				description: values.description,
				severity: values.severity,
				meta: {
					route: window.location.pathname,
				},
			},
		})
			.then((res) => {
				message.success(tt('Success'));
			})
			.catch(({ error }) => {
				message.error(tt('Error'));
			})
			.finally(() => {
				props.onFinish();
			});
	};

	return (
		<Card>
			<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
				{tt('Title')}
			</Typography.Text>
			<Form
				layout="vertical"
				size="large"
				style={{ width: '100%' }}
				autoComplete="off"
				onFinish={onFinish}
			>
				{alert}
				<Form.Item
					name="title"
					label={tt('Field.Title.Label')}
					rules={[{ required: true, message: tt('Field.Title.Missing') }]}
				>
					<Input maxLength={255} />
				</Form.Item>
				<Form.Item
					name="description"
					label={tt('Field.Description.Label')}
					rules={[{ required: true, message: tt('Field.Description.Missing') }]}
				>
					<Input.TextArea maxLength={255} />
				</Form.Item>
				<Form.Item
					name="severity"
					label={tt('Field.Severity.Label')}
					rules={[{ required: true, message: tt('Field.Severity.Missing') }]}
				>
					<Radio.Group>
						<Radio value={BugSeverity.Minor}>{t('BugSeverity.Minor')}</Radio>
						<Radio value={BugSeverity.Major}>{t('BugSeverity.Major')}</Radio>
						<Radio value={BugSeverity.Critical}>{t('BugSeverity.Critical')}</Radio>
					</Radio.Group>
				</Form.Item>
				<Row justify="end">
					<Col>
						<Button type="primary" htmlType="submit">
							{t('Submit')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

const FeatureRequestCard = (props: { onFinish: () => void }) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'HelpCenter.FeatureRequest.Form',
	});
	const [alert, setAlert] = useState<JSX.Element | null>(null);
	const { mutateAsync } = useFeatureRequestMutation();

	const onFinish = (values: any) => {
		mutateAsync({
			input: {
				title: values.title,
				description: values.description,
				urgency: values.urgency,
				meta: {
					route: window.location.pathname,
				},
			},
		})
			.then((res) => {
				message.success(tt('Success'));
			})
			.catch(({ error }) => {
				message.error(tt('Error'));
			})
			.finally(() => {
				props.onFinish();
			});
	};

	return (
		<Card>
			<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
				{tt('Title')}
			</Typography.Text>
			<Form
				layout="vertical"
				size="large"
				style={{ width: '100%' }}
				autoComplete="off"
				onFinish={onFinish}
			>
				{alert}
				<Form.Item
					name="title"
					label={tt('Field.Title.Label')}
					rules={[{ required: true, message: tt('Field.Title.Missing') }]}
				>
					<Input maxLength={255} />
				</Form.Item>
				<Form.Item
					name="description"
					label={tt('Field.Description.Label')}
					rules={[{ required: true, message: tt('Field.Description.Missing') }]}
				>
					<Input.TextArea maxLength={5000} />
				</Form.Item>
				<Form.Item
					name="urgency"
					label={tt('Field.Urgency.Label')}
					rules={[{ required: true, message: tt('Field.Urgency.Missing') }]}
				>
					<Radio.Group>
						<Radio value={FeatureRequestUrgency.SomeTime}>
							{t('FeatureRequestUrgency.SomeTime')}
						</Radio>
						<Radio value={FeatureRequestUrgency.Soon}>
							{t('FeatureRequestUrgency.Soon')}
						</Radio>
						<Radio value={FeatureRequestUrgency.Asap}>
							{t('FeatureRequestUrgency.Asap')}
						</Radio>
					</Radio.Group>
				</Form.Item>
				<Row justify="end">
					<Col>
						<Button type="primary" htmlType="submit">
							{t('Submit')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

const FeedbackCard = (props: { onFinish: () => void }) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'HelpCenter.Feedback.Form',
	});
	const [alert, setAlert] = useState<JSX.Element | null>(null);
	const { mutateAsync } = useFeedbackMessageMutation();

	const onFinish = (values: any) => {
		mutateAsync({
			input: {
				message: values.message,
				sentiment: values.sentiment,
				meta: {
					route: window.location.pathname,
				},
			},
		})
			.then((res) => {
				if (res.success) message.success(tt('Success'));
				else message.error(tt('Error'));
			})
			.catch(({ error }) => {
				message.error(tt('Error'));
			})
			.finally(() => {
				props.onFinish();
			});
	};

	return (
		<Card>
			<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
				{tt('Title')}
			</Typography.Text>
			<Form
				layout="vertical"
				size="large"
				style={{ width: '100%' }}
				autoComplete="off"
				onFinish={onFinish}
			>
				{alert}
				<Form.Item
					name="message"
					label={tt('Field.Message.Label')}
					rules={[{ required: true, message: tt('Field.Message.Missing') }]}
				>
					<Input.TextArea maxLength={5000} />
				</Form.Item>
				<Form.Item
					name="sentiment"
					label={tt('Field.Sentiment.Label')}
					rules={[{ required: true, message: tt('Field.Sentiment.Missing') }]}
				>
					<Radio.Group>
						<Radio value={FeedbackSentiment.Positive}>
							{t('FeedbackSentiment.Positive')}
						</Radio>
						<Radio value={FeedbackSentiment.Neutral}>
							{t('FeedbackSentiment.Neutral')}
						</Radio>
						<Radio value={FeedbackSentiment.Negative}>
							{t('FeedbackSentiment.Negative')}
						</Radio>
					</Radio.Group>
				</Form.Item>
				<Row justify="end">
					<Col>
						<Button type="primary" htmlType="submit">
							{t('Submit')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

const ContactCard = (props: { onFinish: () => void }) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'HelpCenter.Contact.Form',
	});
	const [alert, setAlert] = useState<JSX.Element | null>(null);
	const { mutateAsync } = useContactMessageMutation();

	const onFinish = (values: any) => {
		mutateAsync({
			input: {
				message: values.message,
				meta: {
					route: window.location.pathname,
				},
			},
		})
			.then((res) => {
				if (res.success) message.success(tt('Success'));
				else message.error(tt('Error'));
			})
			.catch(({ error }) => {
				message.error(tt('Error'));
			})
			.finally(() => {
				props.onFinish();
			});
	};

	return (
		<Card style={{ width: '100%' }}>
			<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
				{tt('Title')}
			</Typography.Text>
			<Form
				layout="vertical"
				size="large"
				style={{ width: '100%' }}
				autoComplete="off"
				onFinish={onFinish}
			>
				{alert}
				<Form.Item
					name="message"
					label={tt('Field.Message.Label')}
					rules={[{ required: true, message: tt('Field.Message.Missing') }]}
				>
					<Input.TextArea maxLength={1000} />
				</Form.Item>
				<Row justify="end">
					<Col>
						<Button type="primary" htmlType="submit">
							{t('Submit')}
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

const HelpCenterCards = (props: HelpCenterCardProps) => {
	const { t } = useTranslation('translations');
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const session = useAuthStore((state) => state.session);
	const [selectedCard, setSelectedCard] = useState<0 | 1 | 2 | 3 | undefined>();

	if (!isAuthenticated)
		return (
			<Typography.Title level={2} style={{ color: 'white' }}>
				{t('HelpCenter.MessageNotLoggedIn', {
					firstname: (session as UserSession)?.user?.surname,
				})}
			</Typography.Title>
		);

	return (
		<>
			<Row justify="start" style={{ marginBottom: '32px', flexWrap: 'wrap-reverse' }}>
				<Col>
					<AnimatePresence exitBeforeEnter>
						{selectedCard !== undefined && (
							<motion.div
								key={1}
								initial={{ scale: 0 }}
								animate={{ scale: 1 }}
								exit={{ scale: 0 }}
							>
								<Button
									icon={<ArrowLeftOutlined />}
									size="large"
									onClick={() => setSelectedCard(undefined)}
								/>
							</motion.div>
						)}
					</AnimatePresence>
				</Col>
				<Col>
					<motion.div
						variants={{
							show: { opacity: 1, display: 'block' },
							hide: { opacity: 0, display: 'block' },
						}}
						animate={
							selectedCard === undefined &&
							isAuthenticated &&
							(session as UserSession).user?.surname
								? 'show'
								: 'hide'
						}
					>
						<Typography.Title level={2}>
							{t('HelpCenter.GreetingInformal', {
								firstname: (session as UserSession)?.user?.surname,
							})}
						</Typography.Title>
					</motion.div>
				</Col>
			</Row>
			<AnimatePresence exitBeforeEnter>
				{selectedCard === undefined && (
					<motion.div
						key={0}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{
							duration: 0.2,
							staggerChildren: 0.1,
						}}
					>
						<Row justify="center" gutter={[32, 32]}>
							<Col md={12} lg={12} xl={6}>
								<motion.div key={1} initial={{ scale: 0 }} animate={{ scale: 1 }}>
									<Card hoverable onClick={() => setSelectedCard(0)}>
										<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
											{t('HelpCenter.BugReport.ChoiceTitle')}
										</Typography.Text>
									</Card>
								</motion.div>
							</Col>
							<Col key={2} md={12} lg={12} xl={6}>
								<motion.div key={2} initial={{ scale: 0 }} animate={{ scale: 1 }}>
									<Card hoverable onClick={() => setSelectedCard(1)}>
										<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
											{t('HelpCenter.FeatureRequest.ChoiceTitle')}
										</Typography.Text>
									</Card>
								</motion.div>
							</Col>
							<Col key={3} md={12} lg={12} xl={6}>
								<motion.div key={3} initial={{ scale: 0 }} animate={{ scale: 1 }}>
									<Card hoverable onClick={() => setSelectedCard(2)}>
										<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
											{t('HelpCenter.Feedback.ChoiceTitle')}
										</Typography.Text>
									</Card>
								</motion.div>
							</Col>
							<Col md={12} lg={12} xl={6}>
								<motion.div key={4} initial={{ scale: 0 }} animate={{ scale: 1 }}>
									<Card hoverable onClick={() => setSelectedCard(3)}>
										<Typography.Text style={{ fontWeight: 700, fontSize: '24px' }}>
											{t('HelpCenter.Contact.ChoiceTitle')}
										</Typography.Text>
									</Card>
								</motion.div>
							</Col>
						</Row>
					</motion.div>
				)}
				{selectedCard !== undefined && (
					<motion.div
						key={1}
						initial={{ opacity: 0, y: 100 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 100 }}
						transition={{
							duration: 0.2,
						}}
					>
						<Row justify="center">
							{selectedCard === 0 && (
								<Col style={{ width: '100%', maxWidth: '500px' }}>
									<BugReportCard onFinish={() => setSelectedCard(undefined)} />
								</Col>
							)}
							{selectedCard === 1 && (
								<Col style={{ width: '100%', maxWidth: '500px' }}>
									<FeatureRequestCard onFinish={() => setSelectedCard(undefined)} />
								</Col>
							)}
							{selectedCard === 2 && (
								<Col style={{ width: '100%', maxWidth: '500px' }}>
									<FeedbackCard onFinish={() => setSelectedCard(undefined)} />
								</Col>
							)}
							{selectedCard === 3 && (
								<Col style={{ width: '100%', maxWidth: '500px' }}>
									<ContactCard onFinish={() => setSelectedCard(undefined)} />
								</Col>
							)}
						</Row>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export interface HelpCenterProps {}

export const HelpCenter = (props: HelpCenterProps) => {
	const [visible, setVisible] = useState(false);

	const toggleVisible = () => setVisible(!visible);
	const [helpButtonAnimate, setHelpButtonAnimate] = useState<'in' | 'out'>('out');

	const helpButtonVariants = {
		in: {
			x: -24,
			scale: 1.1,
		},
		out: {
			x: 0,
			scale: 1,
		},
	};

	return (
		<AnimatePresence>
			{visible && (
				<motion.div
					key={1}
					initial={{
						// backdropFilter: 'blur(0px)',
						backgroundColor: 'rgba(0, 0, 0, 0)',
						opacity: 0,
					}}
					animate={{
						// backdropFilter: 'blur(15px)',
						backgroundColor: 'rgba(0, 0, 0, 0.5)',
						opacity: 1,
					}}
					exit={{
						// backdropFilter: 'blur(0px)',
						backgroundColor: 'rgba(0, 0, 0, 0)',
						opacity: 0,
					}}
					className="Overlay"
				>
					<HelpCenterCards />
				</motion.div>
			)}
			<motion.div
				variants={helpButtonVariants}
				animate={helpButtonAnimate}
				className="HelpButton"
			>
				<Button
					style={{ width: '100%', height: '60px', textAlign: 'left', fontSize: '30px' }}
					onClick={toggleVisible}
					type="primary"
					size="large"
					onMouseEnter={() => setHelpButtonAnimate('in')}
					onMouseLeave={() => !visible && setHelpButtonAnimate('out')}
				>
					{visible ? <CloseOutlined /> : <QuestionCircleFilled />}
				</Button>
			</motion.div>
		</AnimatePresence>
	);
};
