import { configurePersist } from '@onlyknoppas/zustand-persist';
import { Switch, Tag } from 'antd';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import create, { SetState } from 'zustand';

interface DemoStoreInterface {
	demo: boolean;
	setDemo: (demo: boolean) => void;
}

const { persist } = configurePersist({
	storage: localStorage,
	rootKey: 'demo',
});

export const useDemoStore = createSelectorHooks(
	create(
		persist({ key: 'demo' }, (set: SetState<DemoStoreInterface>) => ({
			demo: false,
			setDemo: (demo: boolean) => set({ demo }),
		})),
	),
);

export const DemoSwitch = () => {
	const { demo, setDemo } = useDemoStore();

	return <Switch checked={demo} onChange={(e) => setDemo(e)} />;
};

export const DemoIndicator = () => {
	const demo = useDemoStore.useDemo();
	if (!demo) return null;
	return (
		<div style={{ position: 'absolute', right: 0, top: 0 }}>
			<Tag color="lime">DEMO</Tag>
		</div>
	);
};
