import create from 'zustand';

interface Identifiable {
	id: number;
}

export interface IdentifiableItemsStore<ItemType extends Identifiable> {
	items: ItemType[];
	add: (a: ItemType) => void;
	get: (id: number) => ItemType | undefined;
	update: (id: number, itemUpdate: Omit<Partial<ItemType>, keyof Identifiable>) => void;
	remove: (id: number) => void;
	clear: () => void;
}

export const createIdentifiableItemsStore = <ItemType extends Identifiable>() =>
	create<IdentifiableItemsStore<ItemType>>((set, get) => ({
		items: [],
		add: (a) => set((state) => ({ items: [...state.items, a] })),
		get: (id) => get().items.find((a) => a.id === id),
		update: (id, itemUpdate) => {
			set((state) => ({
				items: state.items.map((a) => (a.id === id ? { ...a, ...itemUpdate } : a)),
			}));
		},
		remove: (id) =>
			set((state) => ({
				items: state.items.filter((a) => a.id !== id),
			})),
		clear: () => set({ items: [] }),
	}));
