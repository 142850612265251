import React, { CSSProperties, useCallback, useState } from 'react';
import { Badge, Button, CardProps, Col, Divider, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import './CompanyCard.less';
import { UserAvatar } from '../util/Avatar';
import { Card } from '../util/Card';
import { useTranslation } from 'react-i18next';
import { CurrentPlanTag } from './Plans';
import { AccessLevel, SelfCompaniesQuery } from '../../generated/graphql';
import { SettingOutlined } from '@ant-design/icons';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { accessLevelAbove } from '../../lib/util';

export type CompanyCardProps = {
	company: Exclude<SelfCompaniesQuery['self']['companies'], undefined | null>[0];
	onClick?: () => void;
	detailed?: boolean;
	style?: CSSProperties;
} & CardProps;

export const CompanyCard = ({
	company,
	onClick,
	detailed = false,
	...props
}: CompanyCardProps) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const avatarData = {
		surname: company.name.slice(0, 1),
		lastname: company.name.slice(1),
		id: company.logo?.id,
	};

	let actions: JSX.Element[] = [];
	if (accessLevelAbove(AccessLevel.User, company.selfAccessLevel)) {
		actions.push(
			<Button
				onClick={() => navigate('/admin/company/' + company.id)}
				icon={<SettingOutlined />}
			/>,
		);
	}

	return (
		<Card
			id={'' + company.id}
			hoverable={onClick != null}
			onClick={() => onClick && onClick()}
			{...props}
		>
			<Row gutter={8}>
				<Col>
					<Typography.Title ellipsis={true} level={5}>
						{company.name}
					</Typography.Title>
				</Col>
				{company.demo && (
					<Col>
						<Typography.Text type="secondary">DEMO</Typography.Text>
					</Col>
				)}
			</Row>
			<Row align="middle" gutter={[16, 0]} wrap={false}>
				<Col>
					<UserAvatar shape="square" size={48} user={avatarData} />
				</Col>
				<Col flex="auto">
					{detailed && (
						<Row gutter={16}>
							<Col>{`${company.countChannels} ${t('Channels')}`}</Col>
							<Col>{`${company.users.length} ${t('Users')}`}</Col>
						</Row>
					)}
					<Row>
						<Col>
							<Space>
								<Badge
									title={t('IncidentsStats.OpenIncidents')}
									count={company.incidentStats.openIncidents}
									style={{ backgroundColor: '#127aba' }}
								/>
								{company.incidentStats.deadlineIncidents.in7Days > 0 && (
									<Badge
										title={t('IncidentsStats.Deadline.7days')}
										count={company.incidentStats.deadlineIncidents.in7Days}
										style={{ backgroundColor: '#faad14' }}
									/>
								)}
								{company.incidentStats.deadlineIncidents.in3Days > 0 && (
									<Badge
										title={t('IncidentsStats.Deadline.3days')}
										count={company.incidentStats.deadlineIncidents.in3Days}
										style={{ backgroundColor: '#f5222d' }}
									/>
								)}
							</Space>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row justify="end">
				{actions.map((a, i) => (
					<Col key={i}>{a}</Col>
				))}
			</Row>
		</Card>
	);
};

export type JoinOrCreateCompanyCardProps = {
	onJoin: () => void;
	onCreate: () => void;
};

export const JoinOrCreateCompanyCard = (props: JoinOrCreateCompanyCardProps) => {
	const { t } = useTranslation('translations');
	return (
		// <Card >
		<Row className="JoinOrCreateCard" align="middle" justify="center">
			<Col onClick={props.onCreate} flex="auto">
				<Button
					type="ghost"
					size="large"
					style={{ width: '100%', height: '100%' }}
					// icon={<PlusSquareOutlined style={{ fontSize: '32px' }} />}
				>
					{t('Create')}
				</Button>
			</Col>
			<Col span={1}>
				<Divider></Divider>
			</Col>
			<Col onClick={props.onJoin} flex="auto">
				<Button
					type="dashed"
					size="large"
					style={{ width: '100%', height: '100%' }}
					// icon={<ImportOutlined style={{ fontSize: '32px' }} />}
				>
					{t('Join')}
				</Button>
			</Col>
		</Row>
		// </Card>
	);
};
