import { useEffect, useState } from 'react';

const _requestRecorder = async () => {
	try {
		const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
		return new MediaRecorder(stream);
	} catch (error: any) {
		if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
			return null;
		} else {
			throw error;
		}
	}
};

export const useRecorder = (
	dataCallback: (data: Blob) => void,
	requestRecorder: () => Promise<MediaRecorder | null> = _requestRecorder,
) => {
	const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
	const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

	const [isRecording, setIsRecording] = useState(false);
	const [time, setTime] = useState<{ minutes: number; seconds: number }>({
		minutes: 0,
		seconds: 0,
	});

	const [dataavailableListener, setDataAvailableListener] = useState<
		(e: BlobEvent) => void
	>(() => (e: BlobEvent) => dataCallback(e.data));

	// if the dataCallback changes and a recorder already exists
	// then we want ot update the recorder's event listener
	useEffect(() => {
		const newListener = (e: BlobEvent) => dataCallback(e.data);
		if (recorder) {
			recorder.removeEventListener('dataavailable', dataavailableListener);
			recorder.addEventListener('dataavailable', newListener);
		}
		setDataAvailableListener(() => newListener);
	}, [dataCallback]);

	const startRecording = async () => {
		if (isRecording) {
			console.log('already recording');
			return false;
		}
		if (recorder === null) {
			const newRecorder = await requestRecorder();
			if (newRecorder === null) {
				return false;
			}
			newRecorder.addEventListener('dataavailable', dataavailableListener);
			newRecorder.start();
			setRecorder(newRecorder);
		} else {
			recorder.start();
		}
		setTimer(
			setInterval(
				() =>
					setTime((t) => ({
						seconds: t.seconds < 59 ? t.seconds + 1 : 0,
						minutes: t.seconds === 59 ? t.minutes + 1 : t.minutes,
					})),
				1000,
			),
		);
		setIsRecording(true);
		return true;
	};

	const stopRecording = () => {
		if (!isRecording || !recorder) {
			return false;
		}
		if (recorder) {
			recorder.stop();
		}
		setTime({ minutes: 0, seconds: 0 });
		if (timer) clearInterval(timer);
		setIsRecording(false);
		setTimer(undefined);
		return true;
	};

	return { isRecording, startRecording, stopRecording, time };
};
