import { formatDistanceToNowStrict, isPast } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocale } from '../lib/util';

const deadlineMillisLeft = (deadline: Date) => {
	return Math.max(0, deadline.getTime() - Date.now());
};

const formattedTimeLeft = (deadline: Date, locale: string) =>
	formatDistanceToNowStrict(deadline, {
		unit: deadlineMillisLeft(deadline) < 1000 ? 'second' : 'minute',
		locale: getDateFnsLocale(locale),
	});

export const useFormattedCountdown = (deadlineParam?: Date) => {
	const deadline = deadlineParam != null ? new Date(deadlineParam) : new Date(Date.now());
	const { i18n } = useTranslation('translations');
	const [time, setTime] = useState<string>(formattedTimeLeft(deadline, i18n.language));
	const [millis, setMillis] = useState<number>(deadlineMillisLeft(deadline));

	useEffect(() => {
		const interval = setInterval(() => {
			if (isPast(deadline)) {
				setTime('EXPIRED!');
				clearInterval(interval);
			} else setTime(formattedTimeLeft(deadline, i18n.language));
			setMillis(deadlineMillisLeft(deadline));
		}, 250);
		return () => clearInterval(interval);
	}, [deadline]);

	return { formatted: time, millis };
};
