import { useCallback, useEffect, useState } from 'react';
import { gql } from 'graphql-request';
import { Media, MediaType } from '../generated/graphql';
import { useGQLMutationWithFiles } from '../hooks/useGQL';
import { Attachment } from './util/Attachment';
import { mimeToMediaType } from '../lib/util';
import { fileTypeFromBuffer } from 'file-type';
import { message } from 'antd';
import { useRemoveNoteMediaMutation } from '../generated/graphql';

export interface NoteAttachmentProps {
	file?: File;
	type?: MediaType;
	noteId: number;
	mediaId?: number;
	onSuccess: (media: {
		id: number;
		uri: string;
		type: MediaType;
		fileName: string;
		extension: string;
		nanoId: string;
	}) => void;
	onRemove: () => void;
}

export const NoteAttachment = ({
	file,
	type,
	noteId,
	mediaId,
	onSuccess,
	onRemove,
}: NoteAttachmentProps) => {
	const [mediaType, setMediaType] = useState<MediaType | undefined>(undefined);
	const { mutateAsync, progress, isError, isSuccess } = useGQLMutationWithFiles(gql`
		mutation ($noteId: Int!, $input: CreateMediaInput!, $file: Upload!) {
			addNoteMedia(noteId: $noteId, createMediaInput: $input, file: $file) {
				id
				uri
				type
				fileName
				extension
				nanoId
			}
		}
	`);
	const [uploadedMediaId, setUploadedMediaId] = useState<number | undefined>(mediaId);
	const { mutateAsync: mutateAsyncRemove, isLoading: removeLoading } =
		useRemoveNoteMediaMutation();

	const upload = useCallback(
		(file: File) => {
			(async () => {
				const inferredType = await fileTypeFromBuffer(await file.arrayBuffer());
				const extension = file.name.split('.').pop();
				if (!type) {
					type = mimeToMediaType(inferredType?.mime);
				}
				setMediaType(type);
				let encryptedFile: File | null = null;
				let nonce: Buffer | null = null;
				let fileName = file.name;
				const variables = {
					noteId,
					file: null,
					input: {
						type,
						extension,
						fileName,
					},
				};
				const res = await mutateAsync([variables, new Map([['variables.file', file]])]);
				setUploadedMediaId(res.data.addNoteMedia.id);
				onSuccess(res.data.addNoteMedia);
			})();
		},
		[onSuccess, mutateAsync, noteId],
	);

	const reset = () => {
		if (isError && file) upload(file);
	};

	useEffect(() => {
		if (uploadedMediaId === undefined && file !== undefined) upload(file);
	}, [file, upload, uploadedMediaId]);

	const remove = () => {
		if (uploadedMediaId) {
			mutateAsyncRemove({ noteId, mediaId: uploadedMediaId })
				.then(() => {
					setUploadedMediaId(undefined);
					onRemove();
				})
				.catch(() => message.error('Failed to remove attachment.'));
		}
	};

	return (
		<Attachment
			file={file}
			type={mediaType}
			reset={reset}
			progress={progress}
			isError={isError}
			isSuccess={isSuccess}
			onRemove={remove}
			isLoading={removeLoading}
		/>
	);
};
