import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FeatureNames } from '../../generated/graphql';
import { useLogout } from '../../hooks/useAuth';
import { UserAvatar } from '../util/Avatar';
import { Button } from '../util/Button';
import { FeatureToggle } from '../util/FeatureFlags';

export const UserMenu = () => {
	const logout = useLogout();
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	return (
		<Dropdown
			placement="bottomRight"
			trigger={['click']}
			overlay={
				<Menu style={{ overflow: 'hidden' }} className="UserMenu">
					<FeatureToggle features={[FeatureNames.MessageTemplates]}>
						<Menu.Item onClick={() => navigate('/admin/templates')}>
							{t('AdminPortal.UserMenu.Templates')}
						</Menu.Item>
					</FeatureToggle>
					<Menu.Item onClick={() => navigate('/admin/settings')}>
						{t('AdminPortal.UserMenu.Settings')}
					</Menu.Item>
					<Menu.Item disabled={true}>
						<Button onClick={() => logout()}>{t('AdminPortal.UserMenu.Logout')}</Button>
					</Menu.Item>
				</Menu>
			}
		>
			<Button size="large" style={{ height: 'auto' }}>
				<Space>
					<UserAvatar size={32} />
					<DownOutlined size={16} />
				</Space>
			</Button>
		</Dropdown>
	);
};
