import { Col, Row } from 'antd';

function withCentered<T>(WrappedComponent: React.ComponentType<T>) {
	// eslint-disable-next-line react/display-name
	return (props: T) => (
		<Row align="middle" justify="center" style={{ height: '100vh' }}>
			<Col>
				<WrappedComponent {...props} />
			</Col>
		</Row>
	);
}

export default withCentered;
