import { Col, InputNumber, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ensureHexColor, ensureRgba } from '../../lib/util';
import { ColorInput } from './ColorInput';

// parse boxShadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px or #000000 0px 0px 0px 0px
function parseBoxShadow(boxShadow: string) {
	const regex =
		/(?<color>rgba?\([\d,.\s]+\))\s*(?<horizontal>-?\d+)px\s*(?<vertical>-?\d+)px\s*(?<blur>-?\d+)px\s*(?<spread>-?\d+)px/;
	const match = boxShadow.match(regex);
	if (match == null)
		return {
			color: 'rgba(0, 0, 0, 0)',
			horizontal: 0,
			vertical: 0,
			blur: 0,
			spread: 0,
		};
	return {
		color: ensureRgba(match.groups?.color ?? ''),
		horizontal: parseInt(match.groups?.horizontal ?? '0'),
		vertical: parseInt(match.groups?.vertical ?? '0'),
		blur: parseInt(match.groups?.blur ?? '0'),
		spread: parseInt(match.groups?.spread ?? '0'),
	};
}

function stringifyBoxShadow(boxShadow: ReturnType<typeof parseBoxShadow>) {
	return `${boxShadow.color} ${boxShadow.horizontal}px ${boxShadow.vertical}px ${boxShadow.blur}px ${boxShadow.spread}px`;
}

function isBoxShadowValid(boxShadow: ReturnType<typeof parseBoxShadow>) {
	return (
		!isNaN(boxShadow.horizontal) &&
		!isNaN(boxShadow.vertical) &&
		!isNaN(boxShadow.blur) &&
		!isNaN(boxShadow.spread)
	);
}

// an input composed of a ColorInput and 4 NumberInputs as sliders
export const BoxShadowInput = ({
	boxShadow,
	onChange,
}: {
	boxShadow: string;
	onChange: (boxShadow: string) => void;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'BoxShadowInput' });
	const parsedBoxShadow = parseBoxShadow(boxShadow);
	const [boxShadowState, setBoxShadowState] = useState(parsedBoxShadow);

	useEffect(() => {
		if (isBoxShadowValid(boxShadowState)) onChange(stringifyBoxShadow(boxShadowState));
	}, [boxShadowState]);

	return (
		<Row gutter={[8, 8]} style={{ fontSize: '12px' }}>
			<Col span={24}>
				<Space direction="vertical">
					<Typography.Text>{t('Color')}</Typography.Text>
					<ColorInput
						type="rgba"
						color={boxShadowState.color}
						onChange={(color) => setBoxShadowState({ ...boxShadowState, color })}
					/>
				</Space>
			</Col>
			<Col>
				<Space direction="vertical">
					<Typography.Text>{t('HorizontalOffset')}</Typography.Text>
					<InputNumber
						value={boxShadowState.horizontal}
						onChange={(horizontal) =>
							setBoxShadowState({ ...boxShadowState, horizontal })
						}
					/>
				</Space>
			</Col>
			<Col>
				<Space direction="vertical">
					<Typography.Text>{t('VerticalOffset')}</Typography.Text>
					<InputNumber
						value={boxShadowState.vertical}
						onChange={(vertical) => setBoxShadowState({ ...boxShadowState, vertical })}
					/>
				</Space>
			</Col>
			<Col>
				<Space direction="vertical">
					<Typography.Text>{t('BlurRadius')}</Typography.Text>
					<InputNumber
						value={boxShadowState.blur}
						onChange={(blur) => setBoxShadowState({ ...boxShadowState, blur })}
					/>
				</Space>
			</Col>
			<Col>
				<Space direction="vertical">
					<Typography.Text>{t('SpreadRadius')}</Typography.Text>
					<InputNumber
						value={boxShadowState.spread}
						onChange={(spread) => setBoxShadowState({ ...boxShadowState, spread })}
					/>
				</Space>
			</Col>
		</Row>
	);
};
