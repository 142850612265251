import { Form, Button, Row, Space, Alert, Col } from 'antd';
import { LoginCredentials } from '../../models/auth.model';
import { useAuthStore, useLogin } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Input } from '../util/Input';
import { Card } from '../util/Card';

const LoginForm = ({
	toRegister,
	to2fa,
	toHinterLogin,
}: {
	to2fa: () => void;
	toRegister?: () => void;
	toHinterLogin?: () => void;
}) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'LoginForm' });
	const [login, isLoading] = useLogin().login;
	const navigate = useNavigate();
	const [alert, setAlert] = useState<JSX.Element | null>(null);

	const onFinish = async (values: LoginCredentials) => {
		try {
			const { success, message, need2FA } = await login(values.email, values.password);
			if (success) {
				if (need2FA) to2fa();
				else {
					navigate('/admin/dashboard');
				}
			} else {
				setAlert(<Alert type="error" description={message} />);
			}
		} catch (err: any) {
			setAlert(<Alert type="error" description={t('LoginFatalErrorMessage')} />);
		}
	};

	return (
		<Form
			name="basic"
			initialValues={{ remember: true }}
			onFinish={onFinish}
			autoComplete="off"
			layout="vertical"
			size="large"
			style={{ width: '100%' }}
		>
			<Space direction="vertical" size="small">
				<Card hoverable={false}>
					{alert}
					<Form.Item
						label={tt('Email.Label')}
						name="email"
						rules={[{ required: true, message: tt('Email.Missing') }]}
					>
						<Input maxLength={255} />
					</Form.Item>

					<Form.Item
						label={tt('Password.Label')}
						name="password"
						rules={[{ required: true, message: tt('Password.Missing') }]}
					>
						<Input.Password maxLength={255} />
					</Form.Item>
				</Card>

				<Row style={{ marginTop: '16px' }} justify="space-between">
					<Col>
						{toRegister !== undefined ? (
							<Button type="default" size="large" onClick={toRegister}>
								{t('Register')}
							</Button>
						) : null}
					</Col>
					<Col>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								style={{ width: '100%' }}
								loading={isLoading}
							>
								{t('Login')}
							</Button>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col>
						<Button type="text" size="middle" onClick={toHinterLogin}>
							{tt('HinterLogin')}
						</Button>
					</Col>
					<Col>
						<Button type="text" size="middle" onClick={() => navigate('/resetPassword')}>
							{tt('ResetPassword')}
						</Button>
					</Col>
				</Row>
			</Space>
		</Form>
	);
};

export default LoginForm;
