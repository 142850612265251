import { Col, Row, Button } from 'antd';
import { FeatureNames } from '../../../generated/graphql';
import { GqlErrorResult } from '../../util/GqlErrorResult';
import { CompanyCard } from '../CompanyCard';
import { IncidentCard } from '../IncidentCard';
import './Dashboard.less';
import { ChannelCard } from '../ChannelCard';
import { FeatureLock } from '../FeatureLock';
import { useImmer } from 'use-immer';
import { StaggerAnimationChild, StaggerAnimationParent } from '../../util/Animation';
import { useMediaQuery } from 'react-responsive';
import { Channel } from './types';
import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useIncidentFilterStore } from '../IncidentsFilter';
import { useTranslation } from 'react-i18next';

export const Channels = ({
	channels,
	selectedId,
	onCreate,
	onSelect,
}: {
	channels?: Channel[];
	selectedId?: number;
	onCreate?: () => void;
	onSelect?: (index: number) => void;
}) => {
	const { t } = useTranslation('translations');
	const initialStyle = { left: 0, top: 0, width: 0, height: 0 };
	const cardsRef = useRef<Array<HTMLDivElement | null>>([]);
	const [selectorStyle, setSelectorStyle] = useImmer<{
		left: number;
		top: number;
		width: number;
		height: number;
	}>(initialStyle);
	const [selectedIndex, setSelectedIndex] = useState(
		channels != null && selectedId != null
			? channels.findIndex((c) => c.id === selectedId)
			: -1,
	);
	const isHorizontal = useMediaQuery({ maxWidth: 768 });
	const [scroll, setScroll] = useState(0);
	const scrollRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		selectedId === undefined && selectChannel(-1);
	}, [selectedId]);

	const selectChannel = (index: number) => {
		onSelect && onSelect(index);
		setSelectedIndex(index);
		moveSelector(index);
	};

	const moveSelector = (index: number) => {
		const moveDown = selectedIndex >= 0 && index > selectedIndex;
		const moveUp = selectedIndex >= 0 && index < selectedIndex;
		const channelCardWrapper = cardsRef.current[index];
		const vPadding = 16;
		const hPadding = 8;
		if (channelCardWrapper) {
			const channelCard = channelCardWrapper.childNodes[0]
				.childNodes[0] as HTMLDivElement;
			if (isHorizontal)
				setSelectorStyle({
					top: channelCardWrapper?.offsetTop - vPadding,
					left: channelCardWrapper?.offsetLeft - (moveDown ? 16 + 100 : 0) - scroll,
					width: 300 + 4 * hPadding,
					height: channelCard?.offsetHeight + 2 * vPadding,
				});
			else
				setSelectorStyle({
					left: channelCardWrapper?.offsetLeft - hPadding,
					top: channelCardWrapper?.offsetTop - (moveDown ? 32 : 0),
					height: channelCard?.offsetHeight + 2 * vPadding,
					width: channelCardWrapper?.offsetWidth + 2 * hPadding,
				});
		} else setSelectorStyle(initialStyle);
	};

	useEffect(() => {
		if (cardsRef.current && selectedIndex >= 0) {
			setTimeout(() => moveSelector(selectedIndex), 500);
		}
	}, [isHorizontal]);

	useEffect(() => {
		if (cardsRef.current && selectedIndex >= 0) {
			moveSelector(-1);
			setTimeout(() => moveSelector(selectedIndex), 1500);
		}
	}, [cardsRef.current]);

	const rowStyle = isHorizontal
		? {
				marginLeft: '-16px',
				marginRight: '-16px',
				paddingLeft: '24px',
				paddingRight: '24px',
				paddingTop: '16px',
				paddingBottom: '16px',
				scrollX: 'auto',
		  }
		: {
				paddingLeft: '16px',
				paddingRight: '16px',
		  };

	return (
		<>
			<motion.div
				style={{
					position: 'absolute',
					zIndex: 0,
					backgroundColor: '#D0EAFB',
					borderRadius: 16,
					paddingBottom: '-16px',
				}}
				animate={selectorStyle}
			/>
			<Row
				ref={scrollRef}
				style={{ zIndex: 1, ...rowStyle }}
				gutter={[16, 16]}
				className="VerticalScroll"
				onScroll={() => {
					if (scrollRef.current == null) return;
					const diff = scroll - scrollRef.current.scrollLeft;
					setScroll(scrollRef.current.scrollLeft);
					setSelectorStyle((draft) => {
						draft.left += diff;
					});
				}}
				align="middle"
			>
				{channels?.map((channel, i) => (
					<Col ref={(el) => (cardsRef.current[i] = el)} key={i} md={24} xl={24}>
						<StaggerAnimationChild>
							<motion.div
								variants={{
									selected: isHorizontal
										? { marginLeft: 8, marginRight: 8, width: '300px' }
										: { marginTop: 16, marginBottom: 16, width: '100%' },
									unselected: isHorizontal
										? { margin: 0, width: '200px' }
										: { margin: 0, width: '100%' },
								}}
								animate={selectedIndex === i ? 'selected' : 'unselected'}
							>
								<ChannelCard
									style={
										isHorizontal ? { width: '100%', height: '200px' } : { width: '100%' }
									}
									onClick={() => {
										if (selectedIndex === i) selectChannel(-1);
										else selectChannel(i);
									}}
									channel={channel}
								/>
							</motion.div>
						</StaggerAnimationChild>
					</Col>
				))}
				{onCreate && (
					<Col md={24} xl={24} style={isHorizontal ? { height: '100%' } : {}}>
						<StaggerAnimationChild>
							<FeatureLock featureName={FeatureNames.MultipleChannels}>
								<Button
									type="ghost"
									size="large"
									style={{ width: '100%', height: '200px' }}
									onClick={onCreate}
									// icon={<PlusSquareOutlined style={{ fontSize: '32px' }} />}
								>
									{t('Create')}
								</Button>
							</FeatureLock>
						</StaggerAnimationChild>
					</Col>
				)}
			</Row>
		</>
	);
};
