import './Input.less';
import { Input as AntdInput, InputProps } from 'antd';
import { PasswordProps, TextAreaProps, GroupProps } from 'antd/lib/input';
import { DesignConfig } from '../HinterPortal/ChannelEditor';
import { CSSProperties } from 'react';

// I create a custom type so that I am sure that I actually add all the subtypes to the Input Component that I declare on InputType.
// I could also cast my custom Input to AntdInput type but then type safety is lost.
export type InputType = {
	(props: InputProps): JSX.Element;
	Password: typeof AntdInput.Password;
	TextArea: typeof AntdInput.TextArea;
};

const MyInput = ({
	style,
	design,
	autoComplete = 'off',
	...props
}: InputProps & { design?: Partial<DesignConfig>; autoComplete?: string }) => {
	const defaultStyle: React.CSSProperties = {};
	const styleOverwrite: React.CSSProperties = {};
	if (design) {
		if (design.borderRadius != null)
			styleOverwrite.borderRadius =
				design.borderRadius < 1 ? 'none' : design.borderRadius;
		if (design.borderWidth != null) {
			styleOverwrite.outlineStyle = design.borderWidth < 1 ? 'none' : 'solid';
			styleOverwrite.outlineWidth = design.borderWidth;
		}
		if (design.borderColor != null) styleOverwrite.outlineColor = design.borderColor;
		// if (design.fontColor != null) styleOverwrite.color = design.fontColor;
		// if (design.fontFamily != null) styleOverwrite.fontFamily = design.fontFamily;
	}
	return (
		<AntdInput
			className="Input"
			style={{ ...defaultStyle, ...style, ...styleOverwrite }}
			autoComplete={autoComplete}
			{...props}
		/>
	);
};

const MyTextArea = ({
	autoComplete = 'off',
	design,
	...props
}: TextAreaProps & { design?: Partial<DesignConfig>; autoComplete?: string }) => {
	const styleOverwrite: CSSProperties = {};
	if (design) {
		if (design.borderRadius != null)
			styleOverwrite.borderRadius =
				design.borderRadius < 1 ? 'none' : design.borderRadius;
		if (design.borderWidth != null) {
			styleOverwrite.outlineStyle = design.borderWidth < 1 ? 'none' : 'solid';
			styleOverwrite.outlineWidth = design.borderWidth;
		}
		if (design.borderColor != null) styleOverwrite.outlineColor = design.borderColor;
		// if (design.fontColor != null) styleOverwrite.color = design.fontColor;
		// if (design.fontFamily != null) styleOverwrite.fontFamily = design.fontFamily;
	}
	return (
		<AntdInput.TextArea
			className="InputTextArea"
			{...props}
			style={styleOverwrite}
			autoComplete={autoComplete}
		/>
	);
};

const MyPassword = ({ design, ...props }: PasswordProps & { design?: DesignConfig }) => {
	const styleOverwrite: CSSProperties = {};
	if (design) {
		if (design.borderRadius != null)
			styleOverwrite.borderRadius =
				design.borderRadius < 1 ? 'none' : design.borderRadius;
		if (design.borderWidth != null) {
			styleOverwrite.outlineStyle = design.borderWidth < 1 ? 'none' : 'solid';
			styleOverwrite.outlineWidth = design.borderWidth;
		}
		if (design.borderColor != null) styleOverwrite.outlineColor = design.borderColor;
		// if (design.fontColor != null) styleOverwrite.color = design.fontColor;
		// if (design.fontFamily != null) styleOverwrite.fontFamily = design.fontFamily;
	}
	return (
		<AntdInput.Password className="InputPassword" {...props} style={styleOverwrite} />
	);
};

const MyGroup = (props: GroupProps) => {
	return <AntdInput.Group {...props} />;
};

MyInput.TextArea = MyTextArea;
MyInput.Password = MyPassword;
MyInput.Group = MyGroup;

export const Input = MyInput;
