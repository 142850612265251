import {
	CompanyFeature,
	Feature,
	FeatureNames,
	FeaturesQuery,
	useFeaturesQuery,
	usePublicFeaturesQuery,
} from '../generated/graphql';
import create from 'zustand';
import { features } from 'process';
import { Roles, useAuthStore } from './useAuth';
import { useEffect, useState } from 'react';
import { useSelectedCompanyStore } from './useSelectedCompanyStore';

interface FeatureStore {
	features: FeaturesQuery['features'];
	clear: () => void;
	setFeatures: (features: FeaturesQuery['features']) => void;
	once: boolean;
	setOnce: () => void;
}

export const useFeatureStore = create<FeatureStore>((set) => ({
	features: [],
	once: false,
	setFeatures: (features) => set({ features }),
	clear: () => set({ features: [] }),
	setOnce: () => set({ once: true }),
}));

export const useSyncFeatures = () => {
	const companyId = useSelectedCompanyStore((state) => state.companyId);
	const setFeatures = useFeatureStore((state) => state.setFeatures);
	const [isAuthenticated, role] = useAuthStore((state) => [
		state.isAuthenticated,
		state.session?.role,
	]);

	const { data, isSuccess } = useFeaturesQuery(
		{ companyId },
		{
			enabled: isAuthenticated && role === Roles.USER && companyId != null,
		},
	);

	usePublicFeaturesQuery(
		{},
		{
			enabled: !isAuthenticated || role !== Roles.USER,
			onSuccess: (data) => setFeatures(data.publicFeatures),
		},
	);

	useEffect(() => {
		if (isSuccess && data) setFeatures(data.features);
	}, [isSuccess, data]);

	useEffect(() => console.log('updatedCompany'), [companyId]);
};

export const useFeature = (
	flagNames: FeatureNames[],
	condition: 'and' | 'or' = 'and',
) => {
	const allFlags = useFeatureStore((state) => state.features);
	const [active, setActive] = useState(false);

	useEffect(() => {
		if (allFlags.length > 0) {
			const flags: boolean[] = [];
			for (const f of flagNames) {
				const flag = allFlags.find((flag) => flag.name === f);
				if (!flag) {
					console.warn(`Unknown feature flag ${flag}`);
					flags.push(false);
					return;
				}
				flags.push(flag.enabled);
			}
			if (condition === 'and') setActive(flags.reduce((p, c) => p && c));
			else if (condition === 'or') setActive(flags.reduce((p, c) => p && c));
			// default and
			else setActive(flags.reduce((p, c) => p && c));
		}
	}, [allFlags, setActive, condition, features]);

	return active;
};
