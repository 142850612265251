import { Col, Row, Spin } from 'antd';
import { Card } from './Card';
import Meta from 'antd/lib/card/Meta';
import { UserAvatar } from './Avatar';
import './UserCard.less';
import { DesignConfig } from '../HinterPortal/ChannelEditor';

export interface UserCardProps {
	user?: {
		id: number;
		surname?: string | null;
		lastname?: string | null;
		avatar?: {
			id: number;
		} | null;
	};
	design?: DesignConfig;
	size?: 'small' | 'medium';
}

export const UserCard = ({ user, design, size = 'medium' }: UserCardProps) => {
	if (!user) return <Spin />;

	const avatarData = {
		surname: user.surname,
		lastname: user.lastname,
		id: user.avatar?.id,
	};

	let avatarSize: number;
	let className: string;
	switch (size) {
		case 'small':
			avatarSize = 32;
			className = 'UserCardSmall';
			break;
		case 'medium':
			avatarSize = 64;
			className = 'UserCardMedium';
			break;
	}

	return (
		<Card className="UserCard" id={'' + user.id}>
			<Row align="middle" gutter={[16, 0]} wrap={false}>
				<Col>
					<UserAvatar size={avatarSize} user={avatarData} />
				</Col>
				<Col flex="auto">
					<Meta
						style={{ textAlign: 'left' }}
						title={user.surname + ' ' + user.lastname}
					/>
				</Col>
			</Row>
		</Card>
	);
};
