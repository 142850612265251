import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { JoinOrCreateCompany } from './SetupCompany';

export const WelcomeModal = ({
	visible,
	onJoin,
	onSetup,
	onClose,
}: {
	visible: boolean;
	onJoin: () => void;
	onSetup: () => void;
	onClose: () => void;
}) => {
	const { t } = useTranslation('translations');
	return (
		<Modal
			centered
			closable={true}
			destroyOnClose
			afterClose={onClose}
			visible={visible}
			footer={null}
		>
			<JoinOrCreateCompany onJoin={onJoin} onCreate={onSetup} />
		</Modal>
	);
};
