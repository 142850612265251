import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from 'antd';
import { DesignConfig } from '../HinterPortal/ChannelEditor';
import './Switch.less';

export type SwitchProps = { design?: DesignConfig } & AntdSwitchProps;
export const Switch = ({ design, ...props }: SwitchProps) => {
	let styleOverwrite: React.CSSProperties = {};
	if (design) {
		if (design.borderRadius != null)
			styleOverwrite.borderRadius =
				design.borderRadius < 1 ? 'none' : design.borderRadius;
		if (design.borderWidth != null) {
			styleOverwrite.outlineStyle = design.borderWidth < 1 ? 'none' : 'solid';
			styleOverwrite.outlineWidth = design.borderWidth;
		}
		if (design.borderColor != null) styleOverwrite.outlineColor = design.borderColor;
		// if (design.fontColor != null) styleOverwrite.color = design.fontColor;
		// if (design.fontFamily != null) styleOverwrite.fontFamily = design.fontFamily;
		// if (design.boxShadow != null) styleOverwrite.boxShadow = design.boxShadow;
		if (design.primaryColor != null) {
			if (props.checked) styleOverwrite.backgroundColor = design.primaryColor;
			else if (design.backgroundColor) styleOverwrite.backgroundColor = undefined;
		}
	}

	return <AntdSwitch {...props} style={styleOverwrite} />;
};
