import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography, Tag, Tooltip } from 'antd';
import modal from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import {
	MessageTemplateDataFragment,
	useRemoveMessageTemplateMutation,
} from '../../../generated/graphql';
import { Card } from '../../util/Card';
import { TemplateShare } from './MessageTemplateShareSelect';

export const SharingTag = ({ sharing }: { sharing: TemplateShare }) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'MessageTemplateCard.SharingTag',
	});

	if (sharing.companyId == null && sharing.channelId == null) {
		return (
			<Tooltip title={t('Private.Tooltip')}>
				<Tag color="green">{t('Private.Label')}</Tag>
			</Tooltip>
		);
	}
	if (sharing.companyId != null && sharing.channelId == null) {
		return (
			<Tooltip title={t('Company.Tooltip')}>
				<Tag color="blue">{t('Company.Label')}</Tag>
			</Tooltip>
		);
	}
	if (sharing.channelId != null) {
		return (
			<Tooltip title={t('Channel.Tooltip')}>
				<Tag color="cyan">{t('Channel.Label')}</Tag>
			</Tooltip>
		);
	}
	return null;
};

export const MessageTemplateCard = ({
	template,
	onClick,
}: {
	template: MessageTemplateDataFragment;
	onClick?: (t: MessageTemplateDataFragment) => void;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'MessageTemplateCard' });
	return (
		<Card
			onClick={() => onClick && onClick(template)}
			hoverable={onClick != null}
			style={{ maxHeight: '250px' }}
		>
			<Space direction="vertical" size="small" style={{ width: '100%' }}>
				<Typography.Text strong ellipsis>
					{template.name}
				</Typography.Text>
				<Space>
					<SharingTag
						sharing={{ companyId: template.company?.id, channelId: template.channel?.id }}
					/>
					{template.channel && (
						<Typography.Text type="secondary">{template.channel.name}</Typography.Text>
					)}
				</Space>
				<Typography.Paragraph ellipsis={{ rows: 3 }} style={{ whiteSpace: 'pre-wrap' }}>
					{template.text}
				</Typography.Paragraph>
				{template.createdBy && (
					<Typography.Text type="secondary">
						{t('CreatedBy')} {template.createdBy?.name}
					</Typography.Text>
				)}
			</Space>
		</Card>
	);
};
