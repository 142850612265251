import './Card.less';
import React from 'react';
import { Card as AntdCard, CardProps } from 'antd';
import { DesignConfig } from '../HinterPortal/ChannelEditor';

export const Card = ({
	children,
	style,
	design,
	...props
}: CardProps & { design?: DesignConfig }) => {
	let styleOverwrite: React.CSSProperties = {};
	if (design) {
		if (design.borderRadius != null)
			styleOverwrite.borderRadius =
				design.borderRadius < 1 ? 'none' : design.borderRadius;
		if (design.borderWidth != null) {
			styleOverwrite.outlineStyle = design.borderWidth < 1 ? 'none' : 'solid';
			styleOverwrite.outlineWidth = design.borderWidth;
		}
		if (design.borderColor != null) styleOverwrite.outlineColor = design.borderColor;
		// if (design.fontColor != null) styleOverwrite.color = design.fontColor;
		// if (design.fontFamily != null) styleOverwrite.fontFamily = design.fontFamily;
		if (design.boxShadow != null) styleOverwrite.boxShadow = design.boxShadow;
	}
	return (
		<AntdCard
			className={props.hoverable ? 'Card' : 'Card NoHover'}
			style={{ ...style, ...styleOverwrite }}
			{...props}
		>
			{children}
		</AntdCard>
	);
};
