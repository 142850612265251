import { List, Space, Typography } from 'antd';
import { UserAvatar } from '../util/Avatar';
import { Select } from '../util/Select';

export interface UserInfo {
	id: number;
	email: string;
	surname: string;
	lastname: string;
	avatar?: { uri?: string | null; id: number } | null;
}

export interface UserSelectProps {
	users: UserInfo[];
	onSelect: (user?: UserInfo) => void;
	value?: UserInfo;
	style?: any;
}

export const UserSelect = ({ users, onSelect, value, style }: UserSelectProps) => {
	return (
		<Select
			onChange={(v) => {
				onSelect(users.find((u) => u.id === v?.valueOf()));
			}}
			value={value?.id}
			placeholder="Select user"
			style={style}
		>
			{users.map((u: UserInfo) => (
				<Select.Option value={u.id} key={u.id}>
					<Space>
						<UserAvatar
							size={24}
							user={{
								surname: u.surname,
								lastname: u.lastname,
								id: u.avatar?.id,
							}}
						/>
						<Typography.Text>{u.surname + ' ' + u.lastname}</Typography.Text>
					</Space>
				</Select.Option>
			))}
		</Select>
	);
};
