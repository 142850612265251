import { configurePersist } from '@onlyknoppas/zustand-persist';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import createStore from 'zustand';
import { SelfCompaniesQuery, useSelfCompaniesQuery } from '../generated/graphql';

const { persist } = configurePersist({
	storage: sessionStorage,
	rootKey: 'selectedCompany',
});

export type SelectedCompanyType = Exclude<
	SelfCompaniesQuery['self']['companies'],
	null | undefined
>[0];

interface SelectedCompanyStore {
	companyId?: number | null;
	company?: SelectedCompanyType | null;
	setCompany: (company: SelectedCompanyType) => void;
	reset: () => void;
}

export const useSelectedCompanyStore = createSelectorHooks(
	createStore<SelectedCompanyStore>(
		persist({ key: 'selectCompany' }, (set, get) => ({
			setCompany: (company) => set(() => ({ company, companyId: company.id })),
			reset: () => set(() => ({ company: null, companyId: null })),
		})),
	),
);
