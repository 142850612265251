import { Spin } from 'antd';
import { useParams } from 'react-router';
import { Navigate } from 'react-router-dom';
import {
	useChannelUuidByCompanySlugQuery,
	useChannelUuidByNanoIdQuery,
} from '../../generated/graphql';
import HinterPortal from './HinterPortal';

export default function RedirectToUuidPortal() {
	let { companySlug, nanoId, channelUuidOrCompanySlug, channelId } = useParams();
	// check if channel uuid by regex
	if (channelUuidOrCompanySlug?.match(/^[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}$/))
		channelId = channelUuidOrCompanySlug;
	// check if nano id by regex
	else companySlug = channelUuidOrCompanySlug;
	const byNanoId = useChannelUuidByNanoIdQuery(
		{ nanoId: nanoId ?? '' },
		{
			enabled: !!nanoId,
		},
	);
	const bySlug = useChannelUuidByCompanySlugQuery(
		{ slug: companySlug ?? '' },
		{
			enabled: !nanoId && !!companySlug,
		},
	);
	if (channelId) return <HinterPortal channelId={channelId} />;
	if (byNanoId.isLoading || bySlug.isLoading) return <Spin size="large" />;
	if ((!companySlug && !nanoId) || byNanoId.isError || bySlug.isError)
		return <Navigate to="/portal/login" />;
	const uuid =
		byNanoId.data?.channelUuidByNanoId ?? bySlug.data?.channelUuidByCompanySlug;
	if (uuid) return <Navigate to={`/portal/${uuid}`} />;
	return <Navigate to="/portal/login" />;
}
