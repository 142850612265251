import { WarningFilled } from '@ant-design/icons';
import { AvatarProps, Avatar, Spin, Popover, Row, Col } from 'antd';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useSelfQuery, useUserQuery } from '../../generated/graphql';
import { useGet } from '../../hooks/useREST';

export const RectangleAvatar = ({
	src,
	size,
	icon,
	style: styleOverride,
	className,
	onClick,
	...props
}: AvatarProps & React.DOMAttributes<unknown>) => {
	let style: React.CSSProperties = {};
	let radius = '16px';
	if (typeof size === 'number') {
		if (size < 48) radius = '8px';
		style.height = size;
		style.fontSize = size * 0.66 + 'px';
	} else {
		switch (size) {
			case 'small':
				style.height = '32px';
				style.fontSize = '18px';
				radius = '8px';
				break;
			case 'default':
				style.height = '64px';
				style.fontSize = '48px';
				break;
			case 'large':
				style.height = '96px';
				style.fontSize = '64px';
				break;
		}
	}
	style.minWidth = style.height;
	styleOverride = {
		borderRadius: radius,
		maxWidth: '300px',
		objectFit: 'contain',
		...styleOverride,
	};

	return (
		<Row style={style} justify="center" align="middle">
			<Col style={{ fontSize: '0px', height: '100%', width: '100%' }}>
				{src ? (
					typeof src === 'string' ? (
						<img
							src={src}
							className={className}
							style={{ height: '100%', ...styleOverride }}
							onClick={onClick}
							alt="User avatar"
						/>
					) : (
						src
					)
				) : (
					<div
						onClick={onClick}
						className={className}
						style={{
							backgroundColor: '#C6C6C6',
							width: '100%',
							height: '100%',
							color: '#fff',
							textAlign: 'center',
							...styleOverride,
						}}
					>
						<span
							style={{
								lineHeight:
									typeof style.height === 'number' ? style.height + 'px' : style.height,
							}}
						>
							{icon ? icon : props.children}
						</span>
					</div>
				)}
			</Col>
		</Row>
	);
};

export interface AvatarData {
	surname?: string | null;
	lastname?: string | null;
	id?: number | null;
}

export type RenderAvatarProps = {
	data: AvatarData;
	getRefetchHandle?: (handle: () => void) => void;
	type?: 'circle' | 'square' | 'rectangle';
	popover?: string;
} & AvatarProps &
	React.DOMAttributes<unknown>;

const RenderAvatar = ({
	data: avatar,
	getRefetchHandle,
	type,
	popover,
	...avatarProps
}: RenderAvatarProps) => {
	type = type || 'circle';
	const generate = !avatar.id;
	const endpoint = '/media/public/image/b64';
	const variables: { id: string; fit: string; size?: number } = {
		id: '' + avatar.id,
		fit: 'cover',
	};
	if (type !== 'rectangle')
		variables.size = typeof avatarProps.size === 'number' ? avatarProps.size : 128;
	const {
		data,
		isLoading,
		isError,
		refetch: refetchQuery,
	} = useGet<Buffer>(endpoint, variables, {
		enabled: !generate,
	});

	const refetch = () => {
		refetchQuery();
	};

	useEffect(() => {
		if (getRefetchHandle && refetchQuery) {
			getRefetchHandle(refetch);
		}
	}, [getRefetchHandle, refetchQuery]);

	let elem = null;
	if (type === 'rectangle') {
		if (generate) {
			const s =
				(avatar.surname?.slice(0, 1) || '') + (avatar.lastname?.slice(0, 1) || '');
			elem = <RectangleAvatar {...avatarProps}>{s}</RectangleAvatar>;
		} else {
			if (isLoading)
				return <RectangleAvatar icon={<Spin />} {...avatarProps}></RectangleAvatar>;
			if (isError)
				return (
					<RectangleAvatar icon={<WarningFilled />} {...avatarProps}></RectangleAvatar>
				);
			else
				elem = (
					<RectangleAvatar
						{...avatarProps}
						src={'data:image/png;base64, ' + data}
					></RectangleAvatar>
				);
		}
	} else {
		if (generate) {
			const s =
				(avatar.surname?.slice(0, 1) || '') + (avatar.lastname?.slice(0, 1) || '');
			elem = (
				<Avatar shape={type} {...avatarProps}>
					{s}
				</Avatar>
			);
		} else {
			if (isLoading)
				return <Avatar shape={type} icon={<Spin />} {...avatarProps}></Avatar>;
			if (isError)
				return <Avatar shape={type} icon={<WarningFilled />} {...avatarProps}></Avatar>;
			else
				elem = (
					<Avatar
						shape={type}
						{...avatarProps}
						src={'data:image/png;base64, ' + data}
					></Avatar>
				);
		}
	}

	return (
		<Popover content={popover || avatar.surname + ' ' + avatar.lastname}>{elem}</Popover>
	);
};

type UserAvatarProps = {
	user?: AvatarData;
	userId?: number;
	getRefetchHandle?: (handle: () => void) => void;
	type?: 'circle' | 'square' | 'rectangle';
	popover?: string;
	self?: boolean;
} & AvatarProps &
	React.DOMAttributes<unknown>;

export const UserAvatar = ({
	userId,
	user,
	getRefetchHandle,
	self = false,
	...avatarProps
}: UserAvatarProps) => {
	const {
		data: dataSelf,
		isLoading: isLoadingSelf,
		isError: isErrorSelf,
	} = useSelfQuery({}, { enabled: self });
	const { data, isLoading, isError } = useUserQuery(
		{ userId: userId || 0 },
		{ enabled: user == null && userId != null },
	);
	if (user) {
		return (
			<RenderAvatar getRefetchHandle={getRefetchHandle} data={user} {...avatarProps} />
		);
	} else {
		if (isLoadingSelf || isLoading || (!dataSelf && !data)) return <Spin />;
		if (isErrorSelf || isError) return <WarningFilled />;
		else {
			if (dataSelf?.self != null && !user && !userId) {
				return (
					<RenderAvatar
						getRefetchHandle={getRefetchHandle}
						data={{
							surname: dataSelf?.self.surname || '',
							lastname: dataSelf?.self.lastname || '',
							id: dataSelf?.self.avatar?.id || 0,
						}}
						{...avatarProps}
					/>
				);
			} else if (data != null) {
				return (
					<RenderAvatar
						getRefetchHandle={getRefetchHandle}
						data={{
							surname: data?.user.surname || '',
							lastname: data?.user.lastname || '',
							id: data?.user.avatar?.id || 0,
						}}
						{...avatarProps}
					/>
				);
			} else return null;
		}
	}
};
