import { MessageTemplates } from '../AdminPortal/MessageTemplates/MessageTemplates';
import { motion, AnimatePresence } from 'framer-motion';
import * as Sentry from '@sentry/react';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Space, Button, Spin, Collapse, Typography, Row, Col } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIncidentQuery } from '../../generated/graphql';
import { Link } from 'react-router-dom';
import { useMessageTemplates } from '../AdminPortal/MessageTemplates/useMessageTemplates';

export const ChatTemplates = ({
	incidentId,
	style,
	className,
	onUseTemplate,
	disabled,
}: {
	incidentId: number;
	style?: React.CSSProperties;
	className?: string;
	onUseTemplate: (templateId: number) => void;
	disabled?: boolean;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'ChatTemplates' });
	const [visible, setVisible] = useState(false);
	const { data, isSuccess, isError } = useIncidentQuery({ id: incidentId });
	const { privateTemplates, companyTemplates, channelTemplates } = useMessageTemplates({
		companyId: data?.incident.channel.company.id,
		channelId: data?.incident.channel.id,
	});

	if (isError) {
		// capture sentry exception
		Sentry.captureException(
			new Error('Error while fetching incident for ChatMessageTemplates'),
		);
		return null;
	}

	if (!data?.incident.channel.id) return null;

	const TemplateButton = (props: {
		name: string;
		text: string;
		nanoId?: string;
		id: number;
		onUse: (id: number) => void;
	}) => (
		<Button type="text" style={{ width: '100%' }} size="small">
			<Row
				gutter={[8, 8]}
				wrap={false}
				style={{ width: '100%' }}
				onClick={() => props.onUse(props.id)}
			>
				<Col>
					<Typography.Text ellipsis>{props.name}</Typography.Text>
				</Col>
				<Col flex="auto">
					<Typography.Text ellipsis={true} type="secondary">
						{props.text}
					</Typography.Text>
				</Col>
			</Row>
		</Button>
	);

	return (
		<Collapse ghost className={className} style={style} accordion={true}>
			<Collapse.Panel
				key={0}
				header={t('Title')}
				extra={<Link to="/admin/templates">{t('OpenTemplatesDashboard')}</Link>}
			>
				<Space direction="vertical" style={{ width: '100%' }} size="small">
					{privateTemplates?.map((t) => (
						<TemplateButton key={t.nanoId} {...t} onUse={onUseTemplate} />
					))}
					{channelTemplates?.map((t) => (
						<TemplateButton key={t.nanoId} {...t} onUse={onUseTemplate} />
					))}
					{companyTemplates?.map((t) => (
						<TemplateButton key={t.nanoId} {...t} onUse={onUseTemplate} />
					))}
				</Space>
			</Collapse.Panel>
		</Collapse>
	);
};
