import { LoadingOutlined } from '@ant-design/icons';
import { Form, Alert, Col, message, Result, Row, Space, Spin, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import 'braft-editor/dist/index.css';
import { GraphQLError } from 'graphql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import {
	CompanyDesignFragment,
	IncidentCategory,
	PublicChannelQuery,
	useClaimIncidentMutation,
	usePublicChannelQuery,
	useUpdateChannelMutation,
	useUpdateCompanyMutation,
} from '../../generated/graphql';
import { Roles, useAuthStore, useLogin } from '../../hooks/useAuth';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { Breadcrumb } from '../AdminPortal/Breadcrumb';
import { UserMenu } from '../AdminPortal/UserMenu';
import { PageAnimation } from '../util/Animation';
import { UserAvatar } from '../util/Avatar';
import { Button } from '../util/Button';
import { Card } from '../util/Card';
import { LanguageSelect } from '../util/EnumSelect';
import { Footer } from '../util/Footer';
import { Input } from '../util/Input';
import { Logo } from '../util/Logo';
import {
	ConfigUpdate,
	createChannelEditable,
	Editor,
	RichTextEditor,
	useChannelEditStore,
} from './ChannelEditor';
import { SpecificInfo } from './CountrySpecificInfo';
import HinterIncidentOverview from './HinterIncidentOverview';
import './HinterPortal.less';
import ReportForm from './ReportForm';
import { slugify } from '../../lib/util';
import { GQLError } from '../../hooks/useGQL';
import useStore from './ReportForm/useStore';
import SuggestionForm from './SuggestionForm';
import CompanyRemovedInfo from '../CompanyRemovedInfo';

export interface HinterLoginCardProps {
	onLogin?: () => void;
	config?: CompanyDesignFragment;
}

export const HinterLoginCard = ({ onLogin, config }: HinterLoginCardProps) => {
	const [loginHinter, isLoadingLoginHinter] = useLogin().loginHinter;
	const [loginToken, setLoginToken] = useState('');
	const [errorDisplay, setErrorDisplay] = useState<string>();
	const { t } = useTranslation('translations');
	const navigate = useNavigate();

	const login = async () => {
		try {
			const { success, res } = await loginHinter(loginToken);
			setLoginToken('');
			if (success) navigate(`/portal/${res?.loginHinter.hinter?.channelId}/hint`);
			else setErrorDisplay(t('HinterPortal.Error.Login'));
			onLogin && onLogin();
		} catch (error: any) {
			setErrorDisplay(t('HinterPortal.Error.Internal'));
			throw error;
		}
	};
	return (
		<Card
			design={config}
			title={
				<Typography.Text style={{ fontWeight: 'bold', whiteSpace: 'normal' }}>
					{t('HinterPortal.LoginTitle')}
				</Typography.Text>
			}
			extra={
				<Button
					loading={isLoadingLoginHinter}
					design={config}
					style={{ marginLeft: '8px' }}
					onClick={login}
				>
					{t('HinterPortal.LoginHintButton')}
				</Button>
			}
		>
			<Space
				direction="vertical"
				size="large"
				style={{ width: '100%', maxWidth: '400px' }}
			>
				{errorDisplay && <Alert type="error" message={errorDisplay} />}
				<Input
					maxLength={255}
					design={config}
					placeholder={t('HinterPortal.AuthToken')}
					value={loginToken}
					onChange={(e) => setLoginToken(e.target.value)}
					type="password"
				/>
				<Typography.Paragraph type="secondary">
					{t('HinterPortal.LoginDescription')}
				</Typography.Paragraph>
			</Space>
		</Card>
	);
};

export type HinterPortalWelcomeProps = {
	onLogin?: () => void;
	channel: PublicChannelQuery['publicChannel'];
};

export const HinterPortalWelcome = ({ onLogin, channel }: HinterPortalWelcomeProps) => {
	const { t, i18n } = useTranslation('translations');

	const config = useChannelEditStore((state) => state.config);
	const edit = useChannelEditStore((state) => state.edit);
	const editing = useChannelEditStore((state) => state.editing);
	const reportFormStore = useStore();
	const navigate = useNavigate();

	const avatarData = {
		surname: channel.company.name.slice(0, 1),
		lastname: channel.company.name.slice(1),
		id: channel.company?.logo?.id,
		uri: channel.company?.logo?.uri,
	};

	const switchChannelLink = (uuid: string) => {
		return window.location.pathname.replace(channel._uuid, uuid);
	};

	const onStart = async (
		mode: 'default' | 'lksg' | 'improvementSuggestion' = 'default',
	) => {
		switch (mode) {
			case 'improvementSuggestion':
				navigate('suggest');
				break;
			case 'lksg':
				reportFormStore.updateIncidentForm({
					category: IncidentCategory.SupplyChainAct,
				});
				navigate('report');
				break;
			case 'default':
			default:
				navigate('report');
		}
	};

	const onLaguageChange = useCallback(
		(language) => {
			const redirect = channel.languageRedirects?.find((r) => r.language === language);
			if (redirect != null) {
				window.location.replace(redirect.redirectUrl);
				i18n.changeLanguage(language.toLowerCase());
			}
		},
		[channel],
	);

	if (!config) return <Spin />;

	return (
		<>
			<Space direction="vertical" size={'large'}>
				{channel.languageRedirects != null && (
					<Row gutter={[16, 16]} justify="end">
						<Col>
							<LanguageSelect
								value={channel.config.language}
								onChange={onLaguageChange}
								enumValues={channel.languageRedirects.map((r) => ({
									name: r.language,
								}))}
								design={config.company}
							/>
						</Col>
					</Row>
				)}
				<Row gutter={[16, 16]} justify="space-between" align="middle">
					<Col xs={24} sm={12}>
						{config.channel.showKonfidalLogo ? <Logo size="medium" /> : null}
					</Col>
					<Col xs={24} sm={12} style={{ textAlign: 'right' }}>
						<UserAvatar
							type="rectangle"
							size={64}
							user={avatarData}
							style={{ borderRadius: 0 }}
						/>
					</Col>
				</Row>
				<Row>
					<Col flex="auto">
						<Title
							level={3}
							style={{ textAlign: 'left' }}
							editable={
								editing
									? {
											maxLength: 255,
											...createChannelEditable(config.channel, 'title', edit),
									  }
									: false
							}
						>
							{config.channel.title}
						</Title>
					</Col>
				</Row>
				{channel.otherChannelsOfCompany.length > 0 && (
					<Space direction="vertical">
						<Typography.Title level={5}>
							{t('HinterPortal.OtherChannels.Title', {
								companyName: channel.company.name,
							})}
						</Typography.Title>
						{channel.otherChannelsOfCompany
							.filter(
								(c) =>
									c._uuid !== channel._uuid &&
									c.config.language === channel.config.language,
							)
							.map((c) => (
								<Typography.Link key={channel._uuid} href={switchChannelLink(c._uuid)}>
									{c.config.title}
								</Typography.Link>
							))}
					</Space>
				)}
				<Row gutter={[16, 16]} align="top">
					<Col sm={12} xs={24} style={{ display: 'flex' }}>
						<Space direction="vertical" style={{ width: '100%' }} size="middle">
							<Card
								design={config.company}
								className="Card"
								title={
									<Typography.Text
										style={{ fontWeight: 'bold', whiteSpace: 'normal' }}
										editable={
											editing
												? {
														maxLength: 255,
														...createChannelEditable(
															config.channel,
															'giveHintTitle',
															edit,
														),
												  }
												: false
										}
									>
										{config.channel.giveHintTitle ?? t('HinterPortal.GiveHintTitle')}
									</Typography.Text>
								}
								extra={
									<Button
										design={config.company}
										type="primary"
										onClick={() => onStart('default')}
									>
										{t('HinterPortal.GiveHintButton')}
									</Button>
								}
							>
								<Text
									editable={
										editing
											? {
													maxLength: 255,
													...createChannelEditable(
														config.channel,
														'giveHintDescription',
														edit,
													),
											  }
											: false
									}
									type="secondary"
								>
									{config.channel.giveHintDescription ??
										t('HinterPortal.GiveHintDescription')}
								</Text>
							</Card>
							{config.channel.enableImprovementSuggestion && (
								<Card
									design={config.company}
									className="Card"
									title={
										<Typography.Text
											style={{ fontWeight: 'bold', whiteSpace: 'normal' }}
											editable={
												editing
													? {
															maxLength: 255,
															...createChannelEditable(
																config.channel,
																'giveSuggestionTitle',
																edit,
															),
													  }
													: false
											}
										>
											{config.channel.giveSuggestionTitle ??
												t('HinterPortal.GiveImprovementSuggestionTitle')}
										</Typography.Text>
									}
									extra={
										<Button
											design={config.company}
											type="primary"
											onClick={() => onStart('improvementSuggestion')}
										>
											{t('HinterPortal.GiveImprovementSuggestionButton')}
										</Button>
									}
								>
									<Text
										type="secondary"
										editable={
											editing
												? {
														maxLength: 255,
														...createChannelEditable(
															config.channel,
															'giveSuggestionDescription',
															edit,
														),
												  }
												: false
										}
									>
										{config.channel.giveSuggestionDescription ??
											t('HinterPortal.GiveImprovementSuggestionDescription')}
									</Text>
								</Card>
							)}
						</Space>
					</Col>
					<Col sm={12} xs={24}>
						<HinterLoginCard config={config.company} onLogin={onLogin} />
					</Col>
				</Row>
				<Row>
					<Col>
						<RichTextEditor
							content={config.channel.greetingText}
							edit={(richText) =>
								edit({
									channel: { greetingText: richText },
								})
							}
							editable={editing}
						/>
					</Col>
				</Row>
				<SpecificInfo countryCode={config.channel.country} />
			</Space>
		</>
	);
};

export const HinterPortalClaimIncident = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'Claim' });
	const { data, isLoading, isSuccess, isError, error, mutateAsync } =
		useClaimIncidentMutation();

	const ClaimForm = (
		<Form onFinish={mutateAsync}>
			<Form.Item
				label={tt('Email')}
				name="email"
				rules={[
					{ required: true, message: t('FieldIsMissing', { fieldName: t('Email') }) },
				]}
				tooltip={tt('EmailTooltip')}
			>
				<Input maxLength={255} placeholder={tt('EmailPlaceholder')} />
			</Form.Item>
			<Form.Item
				label={tt('Passphrase')}
				name="password"
				normalize={(value) => slugify(value, false)}
				tooltip={tt('PassphraseTooltip')}
				rules={[
					{
						required: true,
						message: t('FieldIsMissing', {
							fieldName: tt('Passphrase'),
						}),
					},
				]}
			>
				<Input maxLength={255} placeholder={tt('Passphrase')} />
			</Form.Item>
			<Button type="primary" htmlType="submit" loading={isLoading}>
				{tt('Button')}
			</Button>
		</Form>
	);

	const Success = (
		<Result
			status="success"
			title={tt('Success.Title')}
			subTitle={
				<>
					<Typography.Paragraph>{tt('Success.Instruction')}</Typography.Paragraph>
					<Typography.Paragraph code copyable>
						{data?.claimIncident.password}
					</Typography.Paragraph>
					<Typography.Paragraph type="warning">
						{tt('Success.DoNotLooseWarning')}
					</Typography.Paragraph>
				</>
			}
			extra={<Button href={data?.claimIncident.channelLink}>{t('Login')}</Button>}
		></Result>
	);

	const Error = useMemo(() => {
		if (!isError || !error) return null;
		console.log('ERROR', typeof error, JSON.stringify(error));
		switch ((error as GQLError).response.errors[0].message) {
			case 'NotFound':
				return <Alert type="error" description={tt('Message.NotFound')} />;
			case 'AlreadyClaimed':
				return (
					<Alert
						type="error"
						description={tt('Message.AlreadyClaimed')}
						action={<Button href={data?.claimIncident.channelLink}>{t('Login')}</Button>}
					/>
				);
			case 'IncorrectEmail':
				return <Alert type="error" description={tt('Message.IncorrectEmail')} />;
			default:
				return <Alert type="error" description={tt('Message.Error')} />;
		}
	}, [isError, error]);

	return (
		<Row className="Page" justify="center" style={{ backgroundColor: 'white' }}>
			<Col>
				<Space direction="vertical" className="Wide" size="large">
					<Row>
						<Col>
							<Logo onClick={() => navigate('/portal/login')} />
						</Col>
					</Row>
					<PageAnimation type="slide">
						<Row gutter={[24, 24]}>
							<Col sm={24} md={24} lg={12}>
								<Space direction="vertical" size="middle">
									<Typography.Title level={3}>{tt('Title')}</Typography.Title>
									<Typography.Text type="secondary">{tt('Description')}</Typography.Text>
								</Space>
							</Col>
							<Col sm={24} md={24} lg={12}>
								<Card>
									<Space direction="vertical" size="middle">
										<Typography.Text strong>{tt('Instruction')}</Typography.Text>
										{isError && Error}
										{isSuccess ? Success : ClaimForm}
									</Space>
								</Card>
							</Col>
						</Row>
					</PageAnimation>
				</Space>
			</Col>
		</Row>
	);
};

export const HinterPortalLogin = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	return (
		<Row className="Page" justify="center" style={{ backgroundColor: 'white' }}>
			<Col>
				<Space direction="vertical" className="Thin" size="large">
					<Row justify="center">
						<Col>
							<Logo onClick={() => navigate('/portal/login')} />
						</Col>
					</Row>
					<PageAnimation type="slide">
						<Space direction="vertical" size="large">
							<HinterLoginCard />
							<Button type="text" size="middle" onClick={() => navigate('/login')}>
								{t('HinterPortal.AdminLogin')}
							</Button>
							<Button
								type="text"
								size="middle"
								onClick={() => navigate('/portal/claim')}
								wrap="left"
							>
								{t('HinterPortal.ToClaimIncident')}
							</Button>
						</Space>
					</PageAnimation>
				</Space>
			</Col>
		</Row>
	);
};

const HinterPortal = ({ channelId }: { channelId: string }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const config = useChannelEditStore((state) => state.config);

	const role = useAuthStore((s) => s.session?.role);
	const { data, isLoading, isError, isSuccess, error } = usePublicChannelQuery({
		uuid: channelId,
	});
	const { t, i18n } = useTranslation('translations');
	const { mutateAsync: mutateChannel } = useUpdateChannelMutation();
	const { mutateAsync: mutateCompany } = useUpdateCompanyMutation();

	const editorStore = useChannelEditStore((state) => ({
		channelId: state.channelId,
		companyId: state.companyId,
		finishSave: state.finishSave,
		initialized: state.initialized,
		init: state.init,
		config: state.config,
	}));

	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const session = useAuthStore((state) => state.session);

	useEffect(() => {
		if (role === Roles.USER) return; // users dont want that their language is changed
		const channelLanguage = data?.publicChannel.config.language.toLocaleLowerCase();
		if (data && channelLanguage !== i18n.language) {
			i18n.changeLanguage(channelLanguage);
		}
	}, [i18n, data]);

	const save = (
		channelId: number,
		companyId: number,
		configUpdate: ConfigUpdate,
		callback: (success: boolean) => void,
	) => {
		Promise.all([
			mutateChannel({
				id: channelId,
				update: { config: configUpdate.channel },
			}),
			mutateCompany({
				companyId: companyId,
				update: { design: configUpdate.company },
			}),
		])
			.then(([resChannel, resCompany]) => callback(true))
			.catch((err) => {
				callback(false);
				message.error(t('ChannelEditor.Save.Error')); // untranslated
			});
	};

	useEffect(() => {
		if (data?.publicChannel.config != null && !editorStore.initialized) {
			editorStore.init(
				data.publicChannel.id,
				data.publicChannel.company.id,
				{
					channel: data.publicChannel.config,
					company: data.publicChannel.company.design,
				},
				save,
				() => message.success(t('ChannelEditor.Save.Success')),
				() => message.error(t('ChannelEditor.Save.Error')),
			);
		} else if (
			editorStore.initialized &&
			data?.publicChannel.id &&
			data?.publicChannel.company.id &&
			(data?.publicChannel.id !== editorStore.channelId ||
				data?.publicChannel.company.id !== editorStore.companyId)
		) {
			editorStore.init(
				data?.publicChannel.id ?? 0,
				data?.publicChannel.company.id ?? 0,
				{
					channel: data?.publicChannel.config ?? undefined,
					company: data?.publicChannel.company.design ?? undefined,
				},
				save,
				() => message.success(t('ChannelEditor.Save.Success')),
				() => message.error(t('ChannelEditor.Save.Error')),
			);
		}
	}, [data, editorStore]);

	const backgroundStyle: React.CSSProperties = {};
	if (editorStore.config?.company.backgroundColor)
		backgroundStyle.backgroundColor = editorStore.config?.company.backgroundColor;

	let content;
	if (isError) {
		console.log(error);
		let errors = (error as { response: { errors: GraphQLError[] } }).response.errors;
		if (errors && errors.length > 0) {
			const gqlError = errors[0];
			const code = gqlError?.extensions.code as string;
			const message = gqlError.message as string;
			switch (code) {
				case '404':
					return <Result status="404" title={message} />;
				case '403':
					return <Result status="403" title={message} />;
				case '500':
					return <Result status="500" title={message} />;
				default:
					return <Result status="error" title={message} />;
			}
		}
	} else if (isLoading || !data?.publicChannel) {
		content = <Spin />;
	} else if (data?.publicChannel?.company?.deletion != null) {
		content = (
			<CompanyRemovedInfo
				name={data.publicChannel.company.name}
				message={data.publicChannel.company.deletion.message}
				backRoute="/portal/login"
			/>
		);
	} else {
		if (isAuthenticated && session?.role === Roles.HINTER) {
			content = <Navigate to={(channelId ? '' : data.publicChannel.id + '/') + 'hint'} />;
		} else {
			content = <HinterPortalWelcome channel={data?.publicChannel} />;
		}
	}

	return (
		<Row gutter={[16, 16]} align="top" justify="center" style={{ ...backgroundStyle }}>
			<Col>
				<Helmet>
					<title>{t('Meta.Portal.Title')}</title>
				</Helmet>
				<Row className="Container" gutter={[16, 32]}>
					{isAuthenticated && session?.role === Roles.USER ? (
						<Col span={24}>
							<Row justify="space-between">
								{data && (
									<Col>
										<Breadcrumb
											items={[
												{
													type: 'company',
													display: data.publicChannel.company.name,
													routeParam: `${data.publicChannel.company.id}`,
												},
												{
													type: 'channel',
													display: data.publicChannel.name,
													routeParam: `${data.publicChannel.id}`,
												},
											]}
										/>
									</Col>
								)}
								<Col>
									<UserMenu />
									<Editor
										showParentCompanyCheckbox={data?.publicChannel.company.parent != null}
									/>
								</Col>
							</Row>
						</Col>
					) : null}
					<Col span={24}>
						<Routes>
							<Route index element={content} />
							<Route path="report" element={<ReportForm channelId={channelId} />} />
							<Route path="suggest" element={<SuggestionForm channelId={channelId} />} />
							<Route
								path="hint"
								element={<HinterIncidentOverview channelId={channelId || ''} />}
							/>
						</Routes>
					</Col>
					{config?.channel.showParentCompany &&
						data?.publicChannel.company.parent != null && (
							<Col span={24}>
								<Typography.Text strong>
									{t('HinterPortal.AdministratedBy', {
										companyName:
											data?.publicChannel.company.parent.address.recipientName ??
											data?.publicChannel.company.parent.name,
									})}
								</Typography.Text>
							</Col>
						)}
				</Row>
			</Col>
			<Col span={24}>
				<Footer />
			</Col>
		</Row>
	);
};

export default HinterPortal;
