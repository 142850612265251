import { Col, Divider, Modal, Result, Row, Typography } from 'antd';
import { t } from 'i18next';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReward } from 'react-rewards';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { Button } from '../util/Button';
import { Descriptions } from '../util/Descriptions';
import { usePlansModalStore } from './Plans';

export interface CompliantModalProps {
	visible: boolean;
	onVisibleChange: (v: boolean) => void;
}

export const CompliantModal = ({ visible, onVisibleChange }: CompliantModalProps) => {
	const selectedCompany = useSelectedCompanyStore((state) => state.company);
	const { reward } = useReward('rewardId', 'confetti', {
		spread: 100,
		startVelocity: 25,
		elementCount: 80,
		lifetime: 150,
	});
	const { t } = useTranslation('translations');
	const confettiHolder = useRef<HTMLSpanElement | null>(null);
	const openPlansModal = usePlansModalStore((state) => state.open);
	useEffect(() => {
		if (visible && confettiHolder.current != null) {
			reward();
		}
	}, [visible, confettiHolder.current]);

	const upsell = selectedCompany?.tier === 0;

	const UpsellFooter = () => (
		<Row justify="space-between" align="middle" style={{ paddingBottom: '6px' }}>
			<Col>
				<Button type="text" onClick={() => onVisibleChange(false)}>
					{t('CompliantModal.Upsell.CancelButton')}
				</Button>
			</Col>
			<Col>
				<Button
					type="primary"
					onClick={() => {
						openPlansModal({});
						onVisibleChange(false);
					}}
				>
					{t('CompliantModal.Upsell.OkButton')}
				</Button>
			</Col>
		</Row>
	);

	return (
		<Modal
			visible={visible}
			cancelButtonProps={{ style: { display: 'none' } }}
			okText={t('CompliantModal.OkText')}
			destroyOnClose
			footer={upsell ? <UpsellFooter /> : undefined}
			onCancel={() => onVisibleChange(false)}
			onOk={() => onVisibleChange(false)}
		>
			<Row justify="center">
				<Col>
					<span ref={confettiHolder} id="rewardId" />
				</Col>
			</Row>
			<Result
				status="success"
				title={t('CompliantModal.Title')}
				style={
					upsell
						? { borderBottom: '1px solid rgba(0, 0, 0, 0.1)', marginBottom: '32px' }
						: {}
				}
			/>
			{upsell && (
				<>
					<Typography.Title style={{ textAlign: 'center' }} level={4}>
						{t('CompliantModal.Upsell.Title')}
					</Typography.Title>
					<Typography.Paragraph type="secondary" style={{ textAlign: 'center' }}>
						{t('CompliantModal.Upsell.Sidenote')}
					</Typography.Paragraph>
				</>
			)}
		</Modal>
	);
};
