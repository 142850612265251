import { Col, Dropdown, Form, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { gql } from 'graphql-request';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	MediaType,
	NoteDataFragment,
	NoteState,
	NoteType,
	UpdateNoteInput,
	useArchiveNoteMutation,
	useCreateNoteMutation,
	useNoteQuery,
	useNotesOfIncidentQuery,
	useRemoveNoteMediaMutation,
	useRemoveNoteMutation,
	useSubmitNoteMutation,
	useUpdateNoteMutation,
} from '../../generated/graphql';
import { useGQLMutationWithFiles } from '../../hooks/useGQL';
import { Button } from '../util/Button';
import { Card } from '../util/Card';
import { NoteTypeSelect } from '../util/EnumSelect';
import { debounce, property } from 'lodash';
import useModal from 'antd/lib/modal/useModal';
import { NoteAttachment } from '../NoteAttachment';
import { MediaListItem } from '../util/MediaThumbnail';
import {
	CloseOutlined,
	DeleteOutlined,
	EditOutlined,
	PaperClipOutlined,
	PlusOutlined,
	SaveOutlined,
} from '@ant-design/icons';
import { useQuery, useQueryClient } from 'react-query';
import { t } from 'i18next';
import { Tag, TagTime } from '../util/Tag';
import { useImmer } from 'use-immer';
import hash_sum from 'hash-sum';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import create from 'zustand';
import Markdown from 'react-markdown';
import { Input } from '../util/Input';

export interface NoteFormProps {
	incidentId: number;
	enabled: boolean;
	initialValue?: UpdateNoteInput;
	noteId?: number;
	onSubmit?: ((note: NoteDataFragment) => void) | (() => void);
	onChange?: (note: NoteDataFragment) => void;
	onCancel?: () => void;
	onArchive?: ((id: number) => void) | (() => void);
}

export const NoteForm = ({
	incidentId,
	initialValue,
	noteId,
	enabled,
	onSubmit: onSubmitParent,
	onChange: onChangeParent,
	onCancel: onCancelParent,
	onArchive: onArchiveParent,
}: NoteFormProps) => {};

export interface AddNoteModalProps {
	incidentId: number;
	close: () => void;
	onNewNote: (note: NoteDataFragment) => void;
}

export const AddNoteModal = ({ incidentId, close, onNewNote }: AddNoteModalProps) => {
	const qC = useQueryClient();
	const { t } = useTranslation('translations', { keyPrefix: 'Note.Form' });
	const [form] = useForm<UpdateNoteInput>();

	const [noteId, setNoteId] = useState<number>();
	const [confirmLoading, setConfirmLoading] = useState(false);

	const { mutateAsync: create } = useCreateNoteMutation();
	const { mutateAsync: remove } = useRemoveNoteMutation();
	const { mutateAsync: submit } = useSubmitNoteMutation();

	const { visible, initialType } = useNotesHandleStore();

	const onCancel = () => {
		close();
		if (noteId === undefined) return;
		remove({ id: noteId })
			.then((res) => {
				// initialValue && form.setFieldsValue(initialValue);
			})
			.finally(() => {
				setNoteId(undefined);
			});
		// .catch((err) => {}); // TODO: handle error
	};

	useEffect(() => {
		if (visible && noteId === undefined) {
			create({
				input: {
					incidentId,
					type: initialType ?? NoteType.Information,
				},
			})
				.then((res) => {
					setNoteId(res.createNote.id);
					form.setFieldsValue(res.createNote);
				})
				.catch((err) => {});
		} else if (visible && initialType) {
			form.setFieldsValue({ type: initialType });
		}
	}, [noteId, visible, initialType]);

	const onSubmit = (values: UpdateNoteInput) => {
		if (noteId !== undefined) {
			setConfirmLoading(true);
			submit({ id: noteId, input: values })
				.then((res) => {
					setNoteId(undefined);
					onNewNote(res.submitNote);
					close();
				})
				.catch(() => onCancel()) // TODO: handle error
				.finally(() => setConfirmLoading(false));
		}
	};

	return (
		<Modal
			visible={visible}
			footer={false}
			confirmLoading={confirmLoading}
			closable
			destroyOnClose
			onCancel={onCancel}
		>
			{noteId === undefined ? (
				<Spin />
			) : (
				<Form form={form} preserve={false} layout="vertical" onFinish={onSubmit}>
					<Form.Item name="type" label={t('Field.Type.Label')}>
						<NoteTypeSelect defaultValue={NoteType.ProgressUpdate} />
					</Form.Item>
					<Form.Item name="text">
						<TextArea />
					</Form.Item>
					<Row justify="space-between">
						<Col>
							<Button type="text" onClick={onCancel}>
								{t('Button.Cancel')}
							</Button>
							<Button type="primary" htmlType="submit">
								{t('Button.Create')}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</Modal>
	);
};

export interface NoteCardProps {
	id: number;
	onChange: (note: NoteDataFragment) => void;
	locked: boolean;
	onRemove: (note: NoteDataFragment) => void;
	noteData?: NoteDataFragment;
	style?: React.CSSProperties;
}

export const NoteCard = ({
	id,
	onChange,
	locked,
	onRemove,
	noteData,
	style,
}: NoteCardProps) => {
	const { t } = useTranslation('translations');
	const { t: typeT, i18n } = useTranslation('translations', { keyPrefix: 'NoteType' });
	const { data, isLoading, isError, refetch } = useNoteQuery(
		{ id },
		{ enabled: noteData == null },
	);
	const fileInputElem = useRef<HTMLInputElement | null>(null);
	const { mutateAsync: update } = useUpdateNoteMutation();
	const { mutateAsync: archive } = useArchiveNoteMutation();
	const [attachments, setAttachments] = useImmer<JSX.Element[]>([]);
	const { mutateAsync: removeMedia, isLoading: removeLoading } =
		useRemoveNoteMediaMutation();
	const [editing, setEditing] = useState(false);
	const [editedText, setEditedText] = useState<string>();

	const note = noteData ?? data?.note;

	const createPropertyChange = (property: keyof UpdateNoteInput) => {
		return async (x: any) => {
			if (!data || x === data?.note[property]) return;
			data.note[property] = x;
			await update({ id: data.note.id, input: { [property]: x } })
				.then(() => {
					message.success(t('DefaultMessage.Save.Success'));
				})
				.catch(() => {
					message.error(t('DefaultMessage.Save.Error'));
				});
			refetch();
		};
	};

	const onStartEdit = () => {
		setEditedText(note?.text ?? '');
		setEditing(true);
	};

	const onCancelEdit = () => {
		setEditing(false);
	};

	const onSaveEdit = () => {
		if (note != null && editedText !== undefined) {
			note.text = editedText;
			update({ id: note.id, input: { text: editedText } })
				.then(() => {
					message.success(t('DefaultMessage.Save.Success'));
				})
				.catch(() => {
					message.error(t('DefaultMessage.Save.Error'));
				})
				.finally(() => {
					refetch();
					setEditing(false);
				});
		}
	};

	const onArchive = () => {
		if (data) {
			Modal.confirm({
				content: t('ConfirmDeleteModal.Title'),
				okText: t('Ok'),
				cancelText: t('Cancel'),
				onOk: () =>
					archive({ id: data.note.id }).then((res) => onRemove(res.archiveNote)), // TODO: handle error
			});
		}
	};

	const removeAttachment = (key: string) =>
		setAttachments((draft) => draft.filter((x) => x.key !== 'new'));

	const addAttachment = (f: File, type?: MediaType) => {
		if (!data) return;
		setAttachments((draft) => {
			draft.push(
				<NoteAttachment
					key="new"
					file={f}
					type={type}
					noteId={data?.note.id}
					onRemove={() => removeAttachment('new')}
					onSuccess={(m) => {
						data.note.medias.push(m);
						refetch();
						removeAttachment('new');
					}}
				/>,
			);
		});
	};

	const onFileSelect = (e: any) => {
		const files = e.target.files as FileList;
		for (let i = 0; i < files.length; i++) {
			const f = files[i];
			addAttachment(f);
		}
	};

	const onAddMedia = () => {
		if (fileInputElem.current) {
			fileInputElem.current.click();
		}
	};

	return (
		<Card style={{ maxWidth: '500px', width: '100%', ...style }}>
			<input
				type="file"
				id="file"
				onChange={onFileSelect}
				ref={fileInputElem}
				style={{ display: 'none' }}
			/>
			{note == null ? (
				<Spin />
			) : (
				<Space direction="vertical" style={{ width: '100%' }}>
					<Row justify="space-between">
						<Col>
							<Space>
								{/* <NoteTypeSelect
								value={data.note.type}
								onChange={createPropertyChange('type')}
							/> */}
								<Tag color="blue">{typeT(note.type)}</Tag>
								<TagTime time={note.created} />
							</Space>
						</Col>
						<Col>
							<Space>
								{editing ? (
									<>
										<Button type="ghost" icon={<SaveOutlined />} onClick={onSaveEdit} />
										<Button
											type="ghost"
											icon={<CloseOutlined />}
											onClick={onCancelEdit}
										/>
									</>
								) : (
									<Button
										type="ghost"
										disabled={locked}
										icon={<EditOutlined />}
										onClick={onStartEdit}
									/>
								)}
								<Button
									disabled={locked}
									type="ghost"
									icon={<PaperClipOutlined />}
									onClick={onAddMedia}
								/>
								<Button
									disabled={locked}
									type="ghost"
									icon={<DeleteOutlined />}
									onClick={onArchive}
								/>
							</Space>
						</Col>
					</Row>
					{editing ? (
						<Input.TextArea
							style={{ width: '100%', hyphens: 'auto', whiteSpace: 'pre-wrap' }}
							autoSize={{ minRows: 3 }}
							onChange={(e) => setEditedText(e.target.value)}
							value={editedText}
							maxLength={30000}
						/>
					) : (
						<div style={{ hyphens: 'auto', whiteSpace: 'pre-wrap' }}>
							<Markdown>{note.text ?? ''}</Markdown>
						</div>
					)}
					<div style={{ display: 'flex', gap: '16px', flexWrap: 'wrap' }}>
						{note.medias.map((m) => (
							<div key={m.id} style={{ width: '226px' }}>
								<MediaListItem
									id={m.id}
									nanoId={m.nanoId}
									type={m.type}
									fileName={m.fileName}
									onRemove={() => {
										removeMedia({ noteId: id, mediaId: m.id }).then(() => refetch());
									}}
								/>
							</div>
						))}
						{attachments}
					</div>
				</Space>
			)}
		</Card>
	);
};

export interface NotesProps {
	incidentId: number;
	locked: boolean;
	onRejectReason?: (exists: boolean) => void;
	onActionReport?: (exists: boolean) => void;
}

export interface NotesHandlesStore {
	addActionReport: () => void;
	addRejectReason: () => void;
	setVisible: (v: boolean) => void;
	setInitialType: (t?: NoteType) => void;
	visible: boolean;
	initialType?: NoteType;
}

export const useNotesHandleStore = createSelectorHooks(
	create<NotesHandlesStore>((set) => ({
		addActionReport: () => set({ visible: true, initialType: NoteType.ActionReport }),
		addRejectReason: () => set({ visible: true, initialType: NoteType.RejectReason }),
		setVisible: (v) => set({ visible: v }),
		setInitialType: (t) => set({ initialType: t }),
		visible: false,
	})),
);

export const Notes = ({
	incidentId,
	locked,
	onRejectReason,
	onActionReport,
}: NotesProps) => {
	const { t } = useTranslation('translations');
	const notesQuery = useNotesOfIncidentQuery({ incidentId });
	const { setVisible, setInitialType } = useNotesHandleStore();

	const addNote = useCallback(
		(type?: NoteType) => {
			type && setInitialType(type);
			setVisible(true);
		},
		[setInitialType, setVisible],
	);

	const onNewNote = useCallback(
		(note: NoteDataFragment) => {
			notesQuery.data?.notesOfIncident.push(note);
			notesQuery.refetch();
			if (note.type === NoteType.ActionReport && onActionReport) onActionReport(true);
			if (note.type === NoteType.RejectReason && onRejectReason) onRejectReason(true);
			setInitialType(undefined);
		},
		[setInitialType, notesQuery, onActionReport, onRejectReason],
	);

	const onRemovedNote = useCallback(
		(note: NoteDataFragment) => {
			notesQuery.refetch();
			if (note.type === NoteType.ActionReport && onActionReport) onActionReport(false);
			if (note.type === NoteType.RejectReason && onRejectReason) onRejectReason(false);
		},
		[notesQuery, onActionReport, onRejectReason],
	);

	return (
		<>
			<AddNoteModal
				close={() => setVisible(false)}
				incidentId={incidentId}
				onNewNote={onNewNote}
			/>
			<Space direction="vertical" style={{ width: '100%' }}>
				<Row justify="space-between">
					<Col>
						<Typography.Title level={2}>{t('Notes')}</Typography.Title>
					</Col>
					<Col>
						<Button
							disabled={locked}
							type="ghost"
							size="large"
							onClick={() => addNote(NoteType.Information)}
							icon={<PlusOutlined />}
						/>
					</Col>
				</Row>
				<Row gutter={[16, 16]} wrap>
					{notesQuery.data?.notesOfIncident.map((n) => (
						<Col md={12} sm={24} key={n.id}>
							<NoteCard
								id={n.id}
								onChange={() => notesQuery.refetch()}
								locked={locked}
								onRemove={onRemovedNote}
							/>
						</Col>
					))}
				</Row>
			</Space>
		</>
	);
};
