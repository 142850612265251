import { useTranslation } from 'react-i18next';
import {
	EuCountryCodes,
	IncidentCategory,
	IncidentStatus,
	Languages,
} from '../generated/graphql';

export const useCountryTranslation = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'Countries' });
	return (key: EuCountryCodes | string) => {
		switch (key) {
			// these enum unrolls are needed so that automatic translation key parsers find the keys
			case 'AT':
				return t('AT');
			case 'BE':
				return t('BE');
			case 'BG':
				return t('BG');
			case 'CY':
				return t('CY');
			case 'CZ':
				return t('CZ');
			case 'DE':
				return t('DE');
			case 'DK':
				return t('DK');
			case 'EE':
				return t('EE');
			case 'ES':
				return t('ES');
			case 'FI':
				return t('FI');
			case 'FR':
				return t('FR');
			case 'GR':
				return t('GR');
			case 'HR':
				return t('HR');
			case 'HU':
				return t('HU');
			case 'IE':
				return t('IE');
			case 'IT':
				return t('IT');
			case 'LT':
				return t('LT');
			case 'LU':
				return t('LU');
			case 'LV':
				return t('LV');
			case 'MT':
				return t('MT');
			case 'NL':
				return t('NL');
			case 'PL':
				return t('PL');
			case 'PT':
				return t('PT');
			case 'RO':
				return t('RO');
			case 'SE':
				return t('SE');
			case 'SI':
				return t('SI');
			case 'SK':
				return t('SK');
			default:
				return key;
		}
	};
};

export const useLanguageTranslation = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'LanguageNames' });
	return (key: Languages | string) => {
		switch (key) {
			case 'BG':
				return t('BG');
			case 'CS':
				return t('CS');
			case 'DA':
				return t('DA');
			case 'DE':
				return t('DE');
			case 'EN':
				return t('EN');
			case 'EL':
				return t('EL');
			case 'ET':
				return t('ET');
			case 'FI':
				return t('FI');
			case 'FR':
				return t('FR');
			case 'ES':
				return t('ES');
			case 'HR':
				return t('HR');
			case 'HU':
				return t('HU');
			case 'IT':
				return t('IT');
			case 'IS':
				return t('IS');
			case 'LT':
				return t('LT');
			case 'LV':
				return t('LV');
			case 'NL':
				return t('NL');
			case 'PL':
				return t('PL');
			case 'PT':
				return t('PT');
			case 'RO':
				return t('RO');
			case 'SK':
				return t('SK');
			case 'SL':
				return t('SL');
			case 'SV':
				return t('SV');
			case 'TR':
				return t('TR');
			case 'UK':
				return t('UK');
			default:
				return key;
		}
	};
};

export const useIncidentStatusTranslation = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentStatus' });
	return (key: IncidentStatus | string) => {
		switch (key) {
			case 'CLOSED':
				return t('CLOSED');
			case 'CONFIRMED':
				return t('CONFIRMED');
			case 'CREATED':
				return t('CREATED');
			case 'DRAFT':
				return t('DRAFT');
			case 'PROGRESS':
				return t('PROGRESS');
			case 'REJECTED':
				return t('REJECTED');
			default:
				return key;
		}
	};
};

export const useIncidentCategoryTranslation = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentCategories' });
	return (key: IncidentCategory | string) => {
		switch (key) {
			case 'UNCATEGORIZED':
				return t('UNCATEGORIZED');
			case 'BRIBERY_CORRUPTION':
				return t('BRIBERY_CORRUPTION');
			case 'COMPETITION_LAW':
				return t('COMPETITION_LAW');
			case 'INTEREST_CONFLICT':
				return t('INTEREST_CONFLICT');
			case 'FINANCIAL':
				return t('FINANCIAL');
			case 'FRAUD':
				return t('FRAUD');
			case 'HARASSMENT':
				return t('HARASSMENT');
			case 'DISCRIMINATION':
				return t('DISCRIMINATION');
			case 'INTERNATIONAL_TRADE':
				return t('INTERNATIONAL_TRADE');
			case 'CODE_OF_CONDUCT':
				return t('CODE_OF_CONDUCT');
			case 'PRODUCT':
				return t('PRODUCT');
			case 'PERSONAL_DATA':
				return t('PERSONAL_DATA');
			case 'PERSONAL_RIGHTS':
				return t('PERSONAL_RIGHTS');
			case 'ENVIRONMENTAL':
				return t('ENVIRONMENTAL');
			default:
				return key;
		}
	};
};
