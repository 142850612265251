import { Col, Row, Space, Typography } from 'antd';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { PageAnimation } from '../util/Animation';
import { Button } from '../util/Button';
import { FullScreenGlass } from '../util/Glass';
import { Logo } from '../util/Logo';
import { CreateCompanyForm } from './CreateCompanyForm';
import { JoinCompanyForm } from './JoinCompanyForm';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { AcceptCompanyInviteModal } from './CompanyModal';
import { useRedirectContextStore } from '../Redirect/useRedirectStore';
import { Card } from '../util/Card';
import { t } from 'i18next';

export interface JoinOrCreateCompanyProps {
	onJoin: () => void;
	onCreate: () => void;
}

export const JoinOrCreateCompany = ({ onJoin, onCreate }: JoinOrCreateCompanyProps) => {
	const { t } = useTranslation('translations');
	return (
		<>
			<Row>
				<Col>
					<Typography.Title level={2}>{t('Dashboard.Welcome.Title')}</Typography.Title>
				</Col>
			</Row>
			<Row>
				<Col>
					<Typography.Paragraph>{t('Dashboard.Welcome.Text')}</Typography.Paragraph>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col style={{ height: '100px' }} xs={24} sm={12} md={12}>
					<Button
						style={{
							width: '100%',
							height: '100%',
							padding: '16px',
							whiteSpace: 'normal',
							fontWeight: 'bold',
						}}
						onClick={onCreate}
					>
						{t('Dashboard.Welcome.Setup')}
					</Button>
				</Col>
				<Col style={{ height: '100px' }} xs={24} sm={12} md={12}>
					<Button
						style={{
							width: '100%',
							height: '100%',
							padding: '16px',
							whiteSpace: 'normal',
							fontWeight: 'bold',
						}}
						onClick={onJoin}
					>
						{t('Dashboard.Welcome.Join')}
					</Button>
				</Col>
			</Row>
		</>
	);
};

export interface RegisterCompanyProps {
	onComplete: () => void;
	parentPath: string;
}

export const SetupCompany = ({ onComplete, parentPath }: RegisterCompanyProps) => {
	const setCompany = useSelectedCompanyStore((state) => state.setCompany);
	const company = useSelectedCompanyStore((state) => state.company);
	const navigate = useNavigate();

	useEffect(() => {
		if (company) {
			console.log('company already configured', company);
			navigate('/admin/dashboard');
		}
	}, [company]);

	const location = useLocation();
	const locationParts = location.pathname.split('/');
	const baseIndex = locationParts.findIndex((x) => x === parentPath);
	const key = locationParts.slice(baseIndex + 1, baseIndex + 2).join('/');

	const redirectContext = useRedirectContextStore((state) => state.context);
	const [acceptCompanyInviteModalVisible, setAcceptCompanyInviteModalVisible] =
		useState(false);
	useEffect(() => {
		if (redirectContext !== undefined && redirectContext.joinCompany !== undefined) {
			setAcceptCompanyInviteModalVisible(true);
		}
	}, [redirectContext]);

	return (
		<>
			<AcceptCompanyInviteModal
				onClose={() => setAcceptCompanyInviteModalVisible(false)}
				visible={acceptCompanyInviteModalVisible}
				onSuccess={(company) => setCompany(company)}
			/>
			<AnimatePresence exitBeforeEnter>
				<Routes location={location} key={key}>
					<Route
						path=""
						element={
							<PageAnimation type="slide">
								<JoinOrCreateCompany
									onJoin={() => navigate('join')}
									onCreate={() => navigate('create')}
								/>
							</PageAnimation>
						}
					/>
					<Route
						path="create"
						element={
							<PageAnimation type="slide">
								<Space size="middle" direction="vertical" style={{ maxWidth: '400px' }}>
									<Typography.Title level={3}>
										{t('Dashboard.Welcome.Setup')}
									</Typography.Title>
									<Card style={{ maxWidth: '400px', width: '100%' }} hoverable={false}>
										<CreateCompanyForm
											onSuccess={(company) => {
												setCompany(company);
												onComplete();
											}}
											onCancel={() => navigate('')}
										/>
									</Card>
								</Space>
							</PageAnimation>
						}
					/>
					<Route
						path="join"
						element={
							<PageAnimation type="slide">
								<Space size="middle" direction="vertical" style={{ maxWidth: '400px' }}>
									<Typography.Title level={3}>
										{t('Dashboard.Welcome.Join')}
									</Typography.Title>
									<Card style={{ maxWidth: '400px', width: '100%' }} hoverable={false}>
										<JoinCompanyForm
											onSuccess={(company) => {
												setCompany(company);
												onComplete();
											}}
											onCancel={() => navigate('')}
										/>
									</Card>
								</Space>
							</PageAnimation>
						}
					/>
				</Routes>
			</AnimatePresence>
		</>
	);
};
