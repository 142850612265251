import { Col, Form, message, Modal, Row, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	IncidentCategory,
	IncidentType,
	useCreateIncidentManualMutation,
} from '../../../generated/graphql';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { formItemLayout, IncidentReportFormFields } from '../../Form/IncidentReportForm';
import { Button } from '../../util/Button';
import { Card } from '../../util/Card';
import { DatePicker } from '../../util/DatePicker';
import { Select } from '../../util/Select';
import { FormDataType } from './CreateIncidentModal';
import { motion } from 'framer-motion';
import { IncidentTypeSelect } from '../../util/EnumSelect';

export interface CallcenterFormProps {
	channelId?: number;
}

export const CallcenterForm = ({ channelId }: CallcenterFormProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'CallcenterForm' });
	const { t: tt } = useTranslation('translations', { keyPrefix: 'CreateIncidentModal' });
	const [form] = useForm<FormDataType>();
	const { mutateAsync, isLoading } = useCreateIncidentManualMutation();
	const submit = (values: FormDataType) => {
		if (channelId == null) {
			message.error(t('SelectChannel'));
			return;
		}
		const { created, ...input } = form.getFieldsValue();
		input.encryptionVersion = 0;
		input.recipients = [];
		input.channelId = channelId;
		mutateAsync({ created, input })
			.then((res) => {
				message.success(tt('Success.Title'));
				onReset();
				Modal.success({
					title: t('Modal.Success.Title'),
					content: t('Modal.Success.Description', {
						id: res.createIncidentManual.incident.fileNumber,
					}),
					okText: t('Modal.Success.Close'),
					cancelButtonProps: { style: { display: 'None' } },
				});
			})
			.catch((err) => {
				message.error(tt('Error.Title'));
			});
	};

	const onReset = () => {
		form.resetFields();
	};

	if (channelId == null)
		return (
			<Row justify="center" align="middle" style={{ width: '100%', minHeight: '400px' }}>
				<Col xs={24} sm={18} md={12}>
					<Typography.Text type="secondary" strong style={{ fontSize: '16px' }}>
						{t('SelectChannel')}
					</Typography.Text>
				</Col>
			</Row>
		);

	return (
		<motion.div
			initial={{ opacity: 0, scale: 0 }}
			animate={{ opacity: 1, scale: 1 }}
			exit={{ opacity: 0, scale: 0 }}
		>
			<Card hoverable={false}>
				<Form
					initialValues={{
						category: IncidentCategory.Uncategorized,
						hinter: { anonymous: false },
					}}
					form={form}
					onFinish={(values) => submit(values)}
				>
					<Form.Item
						{...formItemLayout}
						name="created"
						label={tt('Field.Received.Label')}
					>
						<DatePicker showTime defaultValue={moment()} />
					</Form.Item>
					{/* <Form.Item
						{...formItemLayout}
						name="channelId"
						label={tt('Field.Channel.Label')}
						rules={[{ required: true, message: tt('Field.Channel.Missing') }]}
					>
						<Select defaultValue={channelId}>
							{selectedCompany?.channels.map((c) => (
								<Select.Option key={c.id} value={c.id}>
									{c.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item> */}
					<IncidentReportFormFields form={form} />
					<Form.Item
						{...formItemLayout}
						name="type"
						label={tt('Field.Type.Label')}
						rules={[{ required: true, message: tt('Field.Type.Missing') }]}
					>
						<IncidentTypeSelect defaultValue={IncidentType.Voice} />
					</Form.Item>
					<Row justify="space-between">
						<Col>
							<Button type="text" onClick={onReset}>
								{t('Reset')}
							</Button>
						</Col>
						<Col>
							<Button onClick={() => form.submit()} type="primary">
								{t('Submit')}
							</Button>
						</Col>
					</Row>
				</Form>
			</Card>
		</motion.div>
	);
};
