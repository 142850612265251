import React, { useEffect, useRef, useState } from 'react';
import 'antd/dist/antd.css';
import './App.less';
import { Routes, Route } from 'react-router-dom';
import AdminPortal from './components/AdminPortal/AdminPortal';
import {
	HinterPortalClaimIncident,
	HinterPortalLogin,
} from './components/HinterPortal/HinterPortal';
import { useAuthStore, useLogout, useRefresh } from './hooks/useAuth';
import { usePGPIdentitySetup } from './hooks/useCrypt';
import { useSyncFeatures } from './hooks/useFeatures';
import { useTranslation } from 'react-i18next';
import { Redirect } from './components/Redirect/Redirect';
import { Helmet } from 'react-helmet';
import RedirectToUuidPortal from './components/HinterPortal/RedirectToUuidPortal';
import { Result } from 'antd';

const Env = () => {
	const [apiEnv, setApiEnv] = useState<string>();
	fetch('/dev/api/version').then((res) => {
		res.text().then((text) => {
			setApiEnv(text);
		});
	});

	return (
		<div style={{ padding: '20px' }}>
			<h1>Env</h1>
			<p>SERVER_ENV: {process.env.SERVER_ENV}</p>
			<p>NODE_ENV: {process.env.NODE_ENV}</p>
			<p>REACT_APP_VERSION: {process.env.REACT_APP_VERSION}</p>
			<p>REACT_APP_API_PATH: {process.env.REACT_APP_API_PATH}</p>
			<p>REACT_APP_WEBSITE_NAME: {process.env.REACT_APP_WEBSITE_NAME}</p>
			<p>REACT_APP_BASENAME: {process.env.REACT_APP_BASENAME}</p>
			<p>PUBLIC_URL: {process.env.PUBLIC_URL}</p>
			<p>REACT_APP_ENVIRONMENT: {process.env.REACT_APP_ENVIRONMENT}</p>
			<p>REACT_APP_PRERELEASE: {process.env.REACT_APP_PRERELEASE}</p>
			<p>
				REACT_APP_CHECKOUT_INVOICE_ONLY: {process.env.REACT_APP_CHECKOUT_INVOICE_ONLY}
			</p>
			<br />
			<p>{apiEnv}</p>
		</div>
	);
};

function App(): JSX.Element {
	useSyncFeatures();
	useRefresh();
	usePGPIdentitySetup();
	const signalLogout = useAuthStore.useSignalLogout();
	const consumeSignalLogout = useAuthStore.useConsumeSignalLogout();
	const logout = useLogout();

	const { i18n, t } = useTranslation('translations');

	useEffect(() => {
		if (i18n.language) {
			if (i18n.language.startsWith('de-')) i18n.changeLanguage('de');
			if (i18n.language.startsWith('en-')) i18n.changeLanguage('en');
			if (i18n.language.startsWith('pt-')) i18n.changeLanguage('pt');
		}
	}, [i18n.language]);

	useEffect(() => {
		if (signalLogout) {
			logout();
			consumeSignalLogout();
		}
	}, [signalLogout]);

	return (
		<>
			<Helmet>
				<title>{t('Meta.Default.Title')}</title>

				<meta name="url" property="og:url" content="https://app.konfidal.eu/" />
				<meta name="type" property="og:type" content="website" />
				<meta name="title" property="og:title" content={t('Meta.Default.Title')} />
				<meta
					name="description"
					property="og:description"
					content={t('Meta.Default.Description')}
				/>

				<meta name="twitter:card" content="summary_large_image" />
				<meta property="twitter:domain" content="app.konfidal.eu" />
				<meta property="twitter:url" content="https://app.konfidal.eu/" />
				<meta name="twitter:title" content="t('Meta.Default.Title')" />
				<meta name="twitter:description" content={t('Meta.Default.Description')} />

				<meta property="image" content="https://app.konfidal.eu/meta_image.png" />
				<meta property="og:image" content="https://app.konfidal.eu/meta_image.png" />
				<meta name="twitter:image" content="https://app.konfidal.eu/meta_image.png" />
				<meta name="linkedin:image" content="https://app.konfidal.eu/meta_image.png" />
			</Helmet>
			<Routes>
				<Route path="redirect/*" element={<Redirect />} />
				<Route path="portal/login" element={<HinterPortalLogin />} />
				<Route path="portal/claim" element={<HinterPortalClaimIncident />} />
				<Route path="portal/s/:nanoId/*" element={<RedirectToUuidPortal />} />
				<Route
					path="portal/:channelUuidOrCompanySlug/*"
					element={<RedirectToUuidPortal />}
				/>
				{(process.env.NODE_ENV === 'development' ||
					process.env.REACT_APP_ENVIRONMENT === 'local') && (
					<Route path="/env" element={<Env />} />
				)}
				<Route path="/*" element={<AdminPortal />} />
			</Routes>
		</>
	);
}

export default App;
