import { Row, Col, Result } from 'antd';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FeatureNames } from '../../../generated/graphql';
import { useFeature } from '../../../hooks/useFeatures';
import { Breadcrumb } from '../Breadcrumb';
import { MessageTemplates } from './MessageTemplates';
import { useEffect, useState } from 'react';

export const MessageTemplatesPage = () => {
	const { t } = useTranslation('translations');
	const messageTemplatesFeatureEnabled = useFeature([FeatureNames.MessageTemplates]);
	// TODO: remove timeout hack
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		setTimeout(() => setLoading(false), 2000);
	}, []);
	return (
		<>
			<Helmet>
				<title>{t('Meta.MessageTemplates.Title')}</title>
				<meta name="description" content={t('Meta.MessageTemplates.Description')} />
			</Helmet>
			<Row
				align="middle"
				justify="space-between"
				style={{ textAlign: 'left' }}
				gutter={[16, 16]}
			>
				<Col span={24}>
					<Breadcrumb
						items={[{ type: 'templates', display: t('MessageTemplatesPage.Title') }]}
					/>
				</Col>
				{!messageTemplatesFeatureEnabled && !loading ? (
					<Result status="403" title={t('MessageTemplatesPage.FeatureDisabled')} />
				) : (
					<Col span={24}>
						<MessageTemplates />
					</Col>
				)}
			</Row>
		</>
	);
};
