import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { useState } from 'react';
import {
	MutationFunction,
	QueryFunction,
	QueryKey,
	useMutation,
	useQuery,
} from 'react-query';
import { useAuthStore } from './useAuth';

const API_URL = (
	(process.env.REACT_APP_BASENAME ?? '') + process.env.REACT_APP_API_PATH
).replace('//', '/');

const handleRESTError = (error: any & Error): any => {
	console.log(error);
	return error;
};

export const usePost = <VarsType, ResponseType>(endpoint: string, options = {}) => {
	const { session, isAuthenticated } = useAuthStore();

	const headers: AxiosRequestHeaders = {};
	if (isAuthenticated && session) {
		if (session.token) headers.Authorization = 'Bearer ' + session.token;
		if (session.csrfToken) headers['x-csrf-token'] = session.csrfToken;
	}

	const mutation: MutationFunction<ResponseType, VarsType> = async (vars: VarsType) => {
		try {
			return (
				await axios.post<VarsType, AxiosResponse<ResponseType>>(
					API_URL + endpoint,
					vars,
					{
						headers,
						withCredentials: true,
					},
				)
			).data;
		} catch (error) {
			handleRESTError(error);
			throw error;
		}
	};

	return useMutation(mutation, options);
};

export const useGet = <ResponseType>(
	endpoint: string,
	params: any,
	options: any = { refetchOnWindowFocus: true },
) => {
	const { session, isAuthenticated } = useAuthStore();

	const headers: AxiosRequestHeaders = {};
	if (isAuthenticated && session) {
		if (session.token) headers.Authorization = 'Bearer ' + session.token;
		if (session.csrfToken) headers['x-csrf-token'] = session.csrfToken;
	}

	const query: QueryFunction<ResponseType, QueryKey> = async ({ queryKey }) => {
		const params = queryKey[1];

		try {
			return (
				await axios.get<ResponseType>(API_URL + endpoint, {
					params,
					headers,
					withCredentials: true,
				})
			).data;
		} catch (error) {
			handleRESTError(error);
			throw error;
		}
	};

	return useQuery([endpoint, params], query, options);
};

export enum MediaType {
	IMAGE = 'IMAGE',
	AUDIO = 'AUDIO',
	FILE = 'FILE',
}

export interface Media {
	id: number;
	uri?: string;
	type: MediaType;
}

export const useMediaUpload = (type: MediaType = MediaType.IMAGE, options = {}) => {
	const [progress, setProgress] = useState(0);
	const mutation = useMutation<AxiosResponse<Media>, AxiosError, File>(
		(file: File) => {
			const formData = new FormData();
			formData.append('file', file);
			return axios.post<File, AxiosResponse<Media>>(
				API_URL + '/media/' + type.toLocaleLowerCase(),
				formData,
				{
					onUploadProgress: (e) => setProgress(Math.round((e.loaded * 100) / e.total)),
					headers: {
						'Content-Type': 'multipart/form-data',
					},
					...options,
				},
			);
		},
		{
			onError: (e: any) => {
				handleRESTError(e);
			},
			onSuccess: () => {
				console.log('file uploaded successfully');
			},
		},
	);

	return { ...mutation, progress };
};

export const useDistortVoice = (options = {}) => {
	const [progress, setProgress] = useState(0);
	const { session, isAuthenticated } = useAuthStore();

	const headers: AxiosRequestHeaders = {};
	if (isAuthenticated && session) {
		if (session.token) headers.Authorization = 'Bearer ' + session.token;
		if (session.csrfToken) headers['x-csrf-token'] = session.csrfToken;
	}

	const mutation = useMutation<AxiosResponse<ArrayBuffer>, AxiosError, File | Blob>(
		(file: File | Blob) => {
			const formData = new FormData();
			formData.append('file', file);
			return axios.post<File, AxiosResponse<ArrayBuffer>>(
				API_URL + '/anonymize/audio',
				formData,
				{
					onUploadProgress: (e) => setProgress(Math.round((e.loaded * 100) / e.total)),
					headers: {
						...headers,
						'Content-Type': 'multipart/form-data',
					},
					responseType: 'arraybuffer',
					withCredentials: true,
					...options,
				},
			);
		},
		{
			onError: (e: any) => {
				handleRESTError(e);
			},
			onSuccess: () => {
				console.log('file uploaded successfully');
			},
		},
	);

	return { ...mutation, progress };
};

export const usePutMedia = (options = {}) => {
	const { session, isAuthenticated } = useAuthStore();

	const headers: AxiosRequestHeaders = {};
	if (isAuthenticated && session) {
		if (session.token) headers.Authorization = 'Bearer ' + session.token;
		if (session.csrfToken) headers['x-csrf-token'] = session.csrfToken;
	}

	const [progress, setProgress] = useState(0);
	const mutation = useMutation<
		AxiosResponse<Media>,
		AxiosError,
		{ id: number; file: File }
	>(
		({ id, file }) => {
			const formData = new FormData();
			formData.append('file', file);
			return axios.put<File, AxiosResponse<Media>>(API_URL + '/media', formData, {
				onUploadProgress: (e) => setProgress(Math.round((e.loaded * 100) / e.total)),
				params: { id: '' + id },
				headers: {
					'Content-Type': 'multipart/form-data',
					...headers,
				},
				withCredentials: true,
				...options,
			});
		},
		{
			onError: (e: any) => {
				handleRESTError(e);
			},
			onSuccess: () => {
				console.log('file uploaded successfully');
			},
		},
	);

	return { ...mutation, progress };
};
