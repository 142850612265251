import React, { useEffect, useState } from 'react';
import create, { State, StateCreator } from 'zustand';
import produce, { Draft } from 'immer';
import {
	EnumFilterOps,
	IncidentCategory,
	IncidentFilterInput,
	IncidentStatus,
	PageInfo,
	PageQuery,
	SearchIncident,
	SortDirection,
	SortIncident,
	SortIncidentField,
	useIncidentsQuery,
} from '../../generated/graphql';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { Col, Empty, Row, Space, Typography } from 'antd';
import { Button } from '../util/Button';
import { useTranslation } from 'react-i18next';
import { Select } from '../util/Select';
import { AnimatePresence, filterProps, motion } from 'framer-motion';
import { WritableDraft } from 'immer/dist/internal';
import { devtools } from 'zustand/middleware';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { Card } from '../util/Card';
import { DeleteOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import { t } from 'i18next';
import { Alert } from '../util/Alert';
import { IncidentCategorySelect, IncidentStatusSelect } from '../util/EnumSelect';

enum UIFilterNames {
	Status = 'Status',
	Category = 'Category',
	Assignee = 'Assignee',
	DeadlineDays = 'DeadlineDays',
}

export interface FilterStoreInterface {
	channelId?: number;
	filters: Map<number, IncidentFilterInput>;
	sort: SortIncident;
	search?: SearchIncident;
	setChannelId: (channelId?: number) => void;
	addFilter: (filter: IncidentFilterInput) => number;
	updateFilter: (id: number, filterUpdate: Partial<IncidentFilterInput>) => void;
	removeFilter: (id: number) => void;
	setSort: (sort: SortIncident) => void;
	setSearch: (search?: string) => void;
}

const immer =
	<T extends State>(
		config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>,
	): StateCreator<T> =>
	(set, get, api) =>
		config((fn) => set(produce<T>(fn)), get, api);

type StoreSet = (fn: (draft: WritableDraft<FilterStoreInterface>) => void) => void;

export const CHANNEL_FILTER_MAP_ID = 189;

export const useIncidentFilterStore = createSelectorHooks(
	create<FilterStoreInterface>(
		devtools(
			immer((set, get) => ({
				channelId: undefined,
				filters: new Map(),
				sort: {
					direction: SortDirection.Asc,
					field: SortIncidentField.DeadlineDays,
				},
				setChannelId: (channelId?: number) => {
					set((state) => {
						if (channelId) {
							state.channelId = channelId;
							state.filters.set(CHANNEL_FILTER_MAP_ID, { channel: { value: channelId } });
						} else if (state.filters.has(CHANNEL_FILTER_MAP_ID)) {
							state.channelId = undefined;
							state.filters.delete(CHANNEL_FILTER_MAP_ID);
						}
					});
				},
				addFilter: (filter: IncidentFilterInput) => {
					const id = Math.round(Math.random() * 999999);
					set((state) => {
						state.filters.set(id, filter);
					});
					return id;
				},
				updateFilter: (id: number, update: Partial<IncidentFilterInput>) => {
					set((state) => {
						state.filters.set(id, update);
					});
				},
				removeFilter: (id: number) => {
					set((state) => {
						state.filters.delete(id);
					});
				},
				setSort: (sort: SortIncident) => {
					set((state) => {
						state.sort = sort;
					});
				},
				setSearch: (search?: string) => {
					set((state) => {
						state.search =
							search == null
								? undefined
								: {
										text: search,
								  };
					});
				},
			})),
		),
	),
);

export interface IncidentPageStore {
	pageQuery: PageQuery;
	pageInfo?: PageInfo;
	setPageInfo: (pageInfo: PageInfo) => void;
	setPage: (currentPage: number) => void;
	setPageSize: (pageSize: number) => void;
	nextPage: () => void;
	previousPage: () => void;
}

export const createPageStore = () =>
	create<IncidentPageStore>((set, get) => ({
		pageQuery: { page: 0, limit: 5 },
		setPageInfo: (pageInfo: PageInfo) =>
			set(
				produce((state) => {
					state.pageInfo = pageInfo;
				}),
			),
		setPage: (currentPage: number) =>
			set(
				produce((state) => {
					state.pageQuery.page = currentPage;
				}),
			),
		setPageSize: (pageSize: number) =>
			set(
				produce((state) => {
					state.pageQuery.limit = pageSize;
				}),
			),
		nextPage: () =>
			set(
				produce((state) => {
					state.pageQuery.page++;
				}),
			),
		previousPage: () =>
			set(
				produce((state) => {
					state.pageQuery.page--;
				}),
			),
	}));

export interface IncidentsFilterProps {
	style?: React.CSSProperties;
	visible: boolean;
}

export const useIncidentPageStore = createSelectorHooks(createPageStore());

export const useFilteredIncidents = () => {
	const page = useIncidentPageStore((state) => state.pageQuery);
	const setPageInfo = useIncidentPageStore.useSetPageInfo();
	const setPage = useIncidentPageStore.useSetPage();
	const [filters, sort, search] = useIncidentFilterStore((state) => [
		state.filters,
		state.sort,
		state.search,
	]);
	const companyId = useSelectedCompanyStore((state) => state.companyId);
	const query = useIncidentsQuery(
		{
			page,
			filter: { filters: Array.from(filters.values()) },
			sort,
			search,
			companyId: companyId || 0,
		},
		{ enabled: companyId != null },
	);

	useEffect(() => {
		if (query.data) setPageInfo(query.data.incidents.page);
	}, [query.data]);

	useEffect(() => {
		setPage(0);
	}, [filters, sort, search]);

	return query;
};

interface IncidentFilterCardProps {
	id?: number;
	cardKey: number;
	onCreate: (id: number) => void;
	mode?: 'card' | 'form' | 'button';
	type?: UIFilterNames;
}

export interface StatusFilterFormProps extends IncidentFilterCardProps {}

export const StatusFilterForm = ({
	id,
	cardKey: key,
	onCreate,
}: StatusFilterFormProps) => {
	const setId = useFilterCardsStore.useSetFilterId();
	const addFilter = useIncidentFilterStore.useAddFilter();
	const updateFilter = useIncidentFilterStore.useUpdateFilter();
	const filters = useIncidentFilterStore.useFilters();

	return (
		<Row style={{ width: '100%' }}>
			<Col style={{ width: '100%' }}>
				<IncidentStatusSelect
					style={{ minWidth: '150px', width: '100%' }}
					value={filters.get(id || -1)?.status?.value || undefined}
					onChange={(v) => {
						if (id)
							updateFilter(id, {
								status: { op: EnumFilterOps.Equal, value: v as IncidentStatus },
							});
						else {
							const filter = {
								status: { op: EnumFilterOps.Equal, value: v as IncidentStatus },
							};
							const id = addFilter(filter);
							setId(key, id);
							onCreate(id);
						}
					}}
				/>
			</Col>
		</Row>
	);
};

export interface CategoryFilterFormProps extends IncidentFilterCardProps {}

export const CategoryFilterForm = ({
	id,
	cardKey: key,
	onCreate,
}: CategoryFilterFormProps) => {
	const setId = useFilterCardsStore.useSetFilterId();
	const addFilter = useIncidentFilterStore.useAddFilter();
	const updateFilter = useIncidentFilterStore.useUpdateFilter();
	const filters = useIncidentFilterStore.useFilters();

	return (
		<Row style={{ width: '100%' }}>
			<Col style={{ width: '100%' }}>
				<IncidentCategorySelect
					style={{ minWidth: '150px', width: '100%' }}
					value={filters.get(id || -1)?.category?.value || undefined}
					onChange={(v) => {
						if (id)
							updateFilter(id, {
								category: {
									op: EnumFilterOps.Equal,
									value: v as IncidentCategory,
								},
							});
						else {
							const filter = {
								category: { op: EnumFilterOps.Equal, value: v as IncidentCategory },
							};
							const id = addFilter(filter);
							setId(key, id);
							onCreate(id);
						}
					}}
				/>
			</Col>
		</Row>
	);
};

export interface AssigneeFilterFormProps extends IncidentFilterCardProps {}
export const AssigneeFilterForm = ({ id }: AssigneeFilterFormProps) => {
	return <div></div>;
};

export const IncidentsFilterCard = ({
	id,
	cardKey: key,
	onCreate,
	onRemove,
	mode = 'button',
	type,
}: IncidentFilterCardProps & { onRemove: () => void }) => {
	const { t } = useTranslation('translations');
	const { t: tFilterName } = useTranslation('translations', {
		keyPrefix: 'UIFilterNames',
	});
	const setMode = useFilterCardsStore.useSetMode();
	const removeFilter = useIncidentFilterStore.useRemoveFilter();
	const setFilterType = useFilterCardsStore.useSetFilterType();
	const [visible, setVisible] = useState(true);
	const cards = useFilterCardsStore.useCards();
	const addCard = useFilterCardsStore.useAddCard();

	const onDelete = () => {
		setVisible(false);
		if (id) removeFilter(id);
		setTimeout(onRemove, 250);
	};

	const onAdd = () => {
		setMode(key, 'form');
	};

	const onFieldSelect = (v: SelectValue) => {
		if (v == null) return;
		setFilterType(key, v as UIFilterNames);
		setMode(key, 'card');
	};

	let component;

	switch (mode) {
		case 'button':
			return (
				<motion.div
					style={{ width: '100%', height: '100%' }}
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
				>
					<Button style={{ width: '100%', height: '100%' }} onClick={onAdd} type="ghost">
						{t('Add')}
					</Button>
				</motion.div>
			);
		case 'card':
			const props: IncidentFilterCardProps = {
				cardKey: key,
				id,
				onCreate,
			};
			switch (type) {
				case UIFilterNames.Status:
					component = <StatusFilterForm {...props} />;
					break;
				case UIFilterNames.Category:
					component = <CategoryFilterForm {...props} />;
					break;
				// case UIFilterNames.Assignee:
				// 	component = <AssigneeFilterForm {...props} />;
				// 	break;
				default:
					component = <Alert type="info" message={t('IncidentFilter.NotImplemented')} />;
			}
			break;
	}

	return (
		<AnimatePresence>
			{visible ? (
				<motion.div
					style={{ height: '100%' }}
					initial={{ scale: 0 }}
					animate={{ scale: 1 }}
					exit={{ scale: 0 }}
				>
					<Card
						style={{ height: '100%', overflowY: 'auto' }}
						title={
							mode === 'form' ? (
								<Select
									placeholder={t('IncidentFilter.Card.FilterSelect.Placeholder')}
									onChange={onFieldSelect}
								>
									{Object.keys(UIFilterNames)
										.filter((name) => {
											if (cards.find((c) => c.type === name)) return false;
											else return true;
										})
										.map((k: string) => k as UIFilterNames)
										.map((k) => (
											<Select.Option key={k} value={k}>
												{tFilterName(k)}
											</Select.Option>
										))}
								</Select>
							) : (
								type && tFilterName(type)
							)
						}
						extra={<DeleteOutlined onClick={onDelete} />}
						hoverable={false}
					>
						<div style={{ marginTop: '-16px', width: '100%' }}>{component}</div>
					</Card>
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};

interface FilterCardsStore {
	cards: (IncidentFilterCardProps & { onRemove: () => void })[];
	addCard: (card: IncidentFilterCardProps & { onRemove: () => void }) => void;
	removeCard: (key: number) => void;
	setMode: (key: number, mode: 'card' | 'form' | 'button') => void;
	setFilterId: (key: number, id: number) => void;
	setFilterType: (key: number, type: UIFilterNames) => void;
}
const useFilterCardsStore = createSelectorHooks(
	create<FilterCardsStore>((set) => ({
		cards: [],
		addCard: (card) =>
			set(
				produce((state) => {
					console.log('addCard ', card);
					state.cards.push(card);
				}),
			),
		removeCard: (key) =>
			set(
				produce((state) => {
					const idx = state.cards.findIndex((e: any) => e.cardKey === key);
					state.cards.splice(idx, 1);
				}),
			),
		setMode: (key, mode) =>
			set(
				produce((state) => {
					console.log('setmode ', key);
					const idx = state.cards.findIndex((e: any) => e.cardKey === key);
					state.cards[idx].mode = mode;
				}),
			),
		setFilterId: (key, id) =>
			set(
				produce((state) => {
					const idx = state.cards.findIndex((e: any) => e.cardKey === key);
					state.cards[idx].id = id;
				}),
			),
		setFilterType: (key, type) =>
			set(
				produce((state) => {
					const idx = state.cards.findIndex((e: any) => e.cardKey === key);
					state.cards[idx].type = type;
				}),
			),
	})),
);

export const IncidentsFilter = ({ style, visible }: IncidentsFilterProps) => {
	const filterCards = useFilterCardsStore.useCards();
	const addCard = useFilterCardsStore.useAddCard();
	const removeCard = useFilterCardsStore.useRemoveCard();

	const onFilterRemove = (key: number) => {
		removeCard(key);
	};

	const onFilterAdd = () => {};

	useEffect(() => {
		if (
			filterCards.find((c) => c.mode !== 'card') === undefined &&
			Object.keys(UIFilterNames).filter(
				(name) => filterCards.find((c) => c.type === name) === undefined,
			).length > 0
		) {
			const key = Math.round(Math.random() * 99999);
			const newCard = {
				onCreate: onFilterAdd,
				onRemove: () => onFilterRemove(key),
				cardKey: key,
			};
			addCard(newCard);
		}
	}, [filterCards]);

	const motionProps = {
		variants: {
			hidden: {
				scaleY: 0,
				opacity: 0,
				height: 0,
				marginBottom: 0,
			},
			visible: {
				scaleY: 1,
				opacity: 1,
				height: 'auto',
				marginBottom: 16,
			},
		},
	};

	if (!visible) return null;

	return (
		<Col span={24}>
			<AnimatePresence exitBeforeEnter>
				<motion.div {...motionProps} animate={visible ? 'visible' : 'hidden'}>
					<Row gutter={[16, 16]}>
						{filterCards.map((card) => (
							<Col
								style={{ minWidth: '200px', width: '50%', minHeight: '100px' }}
								key={card.cardKey}
							>
								<IncidentsFilterCard {...card} />
							</Col>
						))}
					</Row>
				</motion.div>
			</AnimatePresence>
		</Col>
	);
};
