import { motion, useIsPresent } from 'framer-motion';

const pageMotionSlide = {
	initial: {
		opacity: 0,
		x: 200,
	},
	animate: {
		opacity: 1,
		x: 0,
		transition: { duration: 0.5 },
	},
	exit: {
		opacity: 0,
		x: -200,
		transition: { duration: 0.5 },
	},
};

const pageMotionStack = {
	initial: {
		opacity: 0,
		scale: 1.5,
	},
	animate: {
		opacity: 1,
		scale: 1,
		transition: { duration: 0.3 },
	},
	exit: {
		opacity: 0,
		scale: 0.75,
		transition: { duration: 0.3 },
	},
};

export const PageAnimation = ({
	children,
	style,
	type,
}: {
	type: 'slide' | 'stack';
	children: React.ReactNode;
	style?: React.CSSProperties;
}) => {
	const variants = type === 'stack' ? pageMotionStack : pageMotionSlide;
	return (
		<motion.div
			initial="initial"
			animate="animate"
			exit="exit"
			variants={variants}
			style={{ width: '100%', ...style }}
		>
			{children}
		</motion.div>
	);
};

const staggeredAppear = {
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			when: 'beforeChildren',
			staggerChildren: 0.1,
		},
	},
	hidden: {
		opacity: 0,
		scale: 0,
		transition: {
			when: 'afterChildren',
		},
	},
};

export const StaggerAnimationParent = ({ children }: { children: React.ReactNode }) => {
	return (
		<motion.div
			initial="hidden"
			animate="visible"
			exit="hidden"
			variants={staggeredAppear}
		>
			{children}
		</motion.div>
	);
};

export const StaggerAnimationChild = ({ children }: { children: React.ReactNode }) => (
	<motion.div variants={staggeredAppear}>{children}</motion.div>
);
