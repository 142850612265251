import { AudioOutlined, EditOutlined } from '@ant-design/icons';
import { Col, Form, Row, Typography, Spin, Space } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import useForm from 'antd/lib/form/hooks/useForm';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import Paragraph from 'antd/lib/typography/Paragraph';
import { update } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	CreateIncidentInput,
	IncidentCategory,
	IncidentType,
} from '../../../generated/graphql';
import { Button } from '../../util/Button';
import { IncidentCategorySelect, IncidentTypeSelect } from '../../util/EnumSelect';
import { Input } from '../../util/Input';
import { Switch } from '../../util/Switch';
import { useChannelEditStore, DesignConfig } from '../ChannelEditor';
import useStore from './useStore';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
		md: { span: 6 },
	},
	wrapperCol: {
		flex: 'auto',
	},
};

const ReportTypeChoice = ({
	design,
	onVocally,
	onTextually,
	voiceReportsEnabled = true,
	anonymousReportsEnabled = true,
}: {
	design?: DesignConfig;
	onVocally: () => void;
	onTextually: () => void;
	voiceReportsEnabled?: boolean;
	anonymousReportsEnabled?: boolean;
}) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'ReportForm.Form.ReportTypeChoice',
	});
	const { incidentForm, updateHinterForm, formInstance } = useStore();

	const vocallyButton = (
		<Button
			style={{ width: '100%' }}
			size="large"
			onClick={onVocally}
			icon={<AudioOutlined />}
			design={design}
			type={incidentForm.type === IncidentType.Voice ? 'primary' : 'default'}
		>
			{t('Vocally.Button')}
		</Button>
	);
	const textuallyButton = (
		<Button
			style={{ width: '100%' }}
			size="large"
			onClick={onTextually}
			icon={<EditOutlined />}
			design={design}
			type={incidentForm.type === IncidentType.Text ? 'primary' : 'default'}
		>
			{t('Textually.Button')}
		</Button>
	);

	const anonymouseSwitch = (
		<>
			<Form layout="horizontal" style={{ width: '100%' }}>
				<Form.Item
					// wrapperCol={{ flex: 'inherit' }}
					label={t('Anonymous.Button')}
					{...formItemLayout}
				>
					<Switch
						checked={incidentForm.hinter.anonymous}
						onChange={(e) => {
							updateHinterForm({ anonymous: e });
							formInstance?.setFieldsValue({
								hinter: {
									anonymous: e,
								},
							});
						}}
						design={design}
					/>
				</Form.Item>
			</Form>
			{/* <Typography.Text strong>{t('Anonymous.Button')}</Typography.Text> */}
			{incidentForm.hinter.anonymous && (
				<Typography.Text>{t('Anonymous.Disclaimer')}</Typography.Text>
			)}
		</>
	);

	return (
		<Row justify="center" wrap gutter={[24, 24]}>
			{voiceReportsEnabled && (
				<>
					<Col xs={24} sm={12} md={12} xl={12}>
						{textuallyButton}
					</Col>
					<Col xs={24} sm={12} md={12} xl={12}>
						{vocallyButton}
					</Col>
				</>
			)}
			{anonymousReportsEnabled && (
				<Col xs={24} sm={24} md={24} xl={24}>
					{anonymouseSwitch}
				</Col>
			)}
		</Row>
	);
};

const TextualReportForm = () => {
	const { t } = useTranslation('translations');
	const { incidentForm, updateIncidentForm, setFormInstance, updateHinterForm } =
		useStore();
	const [form] = useForm<CreateIncidentInput>();
	const design = useChannelEditStore((state) => state.config?.company);

	useEffect(() => {
		form.setFieldsValue(incidentForm);
		setFormInstance(form);
	}, []);

	return (
		<Form
			style={{ width: '100%' }}
			layout="horizontal"
			form={form}
			initialValues={{
				hinter: {
					anonymous: incidentForm.hinter.anonymous ?? false,
				},
				category: IncidentCategory.Uncategorized,
			}}
			onFieldsChange={(_, __) => {
				const values = form.getFieldsValue();
				updateIncidentForm(values);
				updateHinterForm(values.hinter);
			}}
		>
			<Form.Item
				name="subject"
				label={t('ReportForm.Form.Subject')}
				rules={[
					{
						required: true,
						message: t('ReportForm.Form.Missing.Subject'),
					},
				]}
				{...formItemLayout}
			>
				<Input
					design={design}
					placeholder={t('ReportForm.Form.Placeholder.Subject')}
					maxLength={450}
				/>
			</Form.Item>
			<Form.Item
				name="description"
				label={t('ReportForm.Form.Description')}
				rules={[
					{
						required: true,
						message: t('ReportForm.Form.Missing.Description'),
					},
				]}
				{...formItemLayout}
			>
				<Input.TextArea
					design={design}
					placeholder={t('ReportForm.Form.Placeholder.Description')}
					rows={5}
					minLength={100}
					maxLength={5500}
				/>
			</Form.Item>
			<Form.Item
				name="category"
				label={t('ReportForm.Form.Category')}
				rules={[{ required: true }]}
				{...formItemLayout}
			>
				<IncidentCategorySelect
					exclude={[IncidentCategory.Suggestion]}
					design={design}
					defaultValue={IncidentCategory.Uncategorized}
				/>
			</Form.Item>
			{/* <Form.Item
				label={t('ReportForm.Form.Anonymous')}
				name={['hinter', 'anonymous']}
				{...formItemLayout}
				wrapperCol={{ flex: 'inherit' }}
				valuePropName="checked"
			>
				<Switch
					design={design}
					checked={incidentForm?.hinter?.anonymous}
					// onChange={switchAnonymous}
				/>
			</Form.Item> */}
			<Form.Item label={t('ReportForm.Form.Contact')} name="hinter" noStyle>
				<Form.Item
					key={0}
					label={t('ReportForm.Form.Email')}
					name={['hinter', 'email']}
					{...formItemLayout}
					rules={[
						{
							required:
								incidentForm.type === IncidentType.Text &&
								!incidentForm.hinter?.anonymous,
							message: t('ReportForm.Form.Missing.Email'),
						},
					]}
					extra={
						incidentForm.hinter?.anonymous && (
							<Typography.Text
								style={{ marginTop: '16px', display: 'block', maxWidth: '300px' }}
								type="secondary"
							>
								{t('ReportForm.Form.InfoAnonymous.Email')}
							</Typography.Text>
						)
					}
				>
					<Input design={design} maxLength={100} />
				</Form.Item>
				{!incidentForm.hinter?.anonymous && (
					<Form.Item
						label={t('ReportForm.Form.Firstname')}
						name={['hinter', 'firstname']}
						rules={[
							{
								required:
									incidentForm.type === IncidentType.Text &&
									!incidentForm.hinter?.anonymous,
								message: t('ReportForm.Form.Missing.Firstname'),
							},
						]}
						{...formItemLayout}
					>
						<Input design={design} maxLength={100} />
					</Form.Item>
				)}
				{!incidentForm.hinter?.anonymous && (
					<Form.Item
						label={t('ReportForm.Form.Lastname')}
						name={['hinter', 'lastname']}
						rules={[
							{
								required:
									incidentForm.type === IncidentType.Text &&
									!incidentForm.hinter?.anonymous,
								message: t('ReportForm.Form.Missing.Lastname'),
							},
						]}
						{...formItemLayout}
					>
						<Input design={design} maxLength={100} />
					</Form.Item>
				)}
				{!incidentForm.hinter?.anonymous && (
					<Form.Item
						label={t('ReportForm.Form.Phone')}
						name={['hinter', 'phone']}
						{...formItemLayout}
					>
						<Input design={design} maxLength={100} />
					</Form.Item>
				)}
			</Form.Item>
		</Form>
	);
};

const StepReport = ({
	onSubmit,
	voiceReportsEnabled,
	anonymousReportsEnabled,
}: {
	onSubmit: () => void;
	voiceReportsEnabled: boolean;
	anonymousReportsEnabled: boolean;
}) => {
	const { t } = useTranslation('translations');
	const { incidentForm, updateIncidentForm } = useStore();
	const design = useChannelEditStore((s) => s.config?.company);

	const onVocally = () => {
		updateIncidentForm({
			subject: t('ReportForm.DefaultsForVocally.Subject'),
			description: t('ReportForm.DefaultsForVocally.Description'),
			category: IncidentCategory.Uncategorized,
			type: IncidentType.Voice,
		});
	};

	const onTextually = () => {
		updateIncidentForm({ type: IncidentType.Text });
	};

	return (
		<Space direction="vertical" size="large" style={{ width: '100%' }}>
			<ReportTypeChoice
				{...{
					design,
					onVocally,
					onTextually,
					voiceReportsEnabled,
					anonymousReportsEnabled,
				}}
			/>
			{incidentForm.type === IncidentType.Text && <TextualReportForm />}
		</Space>
	);
};

export default StepReport;
