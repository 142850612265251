import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ErrorFallback = ({ error }: { error: any }) => {
	const { t } = useTranslation('translations');
	return (
		<>
			<Result
				status="500"
				title={t('ErrorFallback.Result.Title')}
				subTitle={error.message || t('ErrorFallback.Result.SubTitleNoMessage')}
			/>
		</>
	);
};

export const ErrorFallbackWithReset = ({
	error,
	resetErrorBoundary,
}: {
	error: any;
	resetErrorBoundary?: () => void;
}) => {
	const { t } = useTranslation('translations');
	return (
		<>
			<Result
				status="500"
				title={t('ErrorFallback.Result.Title')}
				subTitle={error.message || t('ErrorFallback.Result.SubTitleNoMessage')}
				extra={
					resetErrorBoundary ? (
						<Button onClick={resetErrorBoundary}>{t('Retry')}</Button>
					) : null
				}
			/>
		</>
	);
};

export const ErrorFallbackWithBack = ({
	error,
	resetErrorBoundary,
}: {
	error: any;
	resetErrorBoundary: () => void;
}) => {
	const { t } = useTranslation('translations');
	const nav = useNavigate();
	return (
		<>
			<Result
				status="500"
				title={t('ErrorFallback.Result.Title')}
				subTitle={error.message || t('ErrorFallback.Result.SubTitleNoMessage')}
				extra={<Button onClick={() => nav(-1)}>{t('Back')}</Button>}
			/>
		</>
	);
};
