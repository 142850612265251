import { AccessLevel, Languages, MediaType } from '../generated/graphql';
import ANTD_LOCALES, { ANTD_DATE_PICKER_LOCALES } from './antdLocales';
import namedColors from './namedColors';
import DATE_FNS_LOCALES from './datefnsLocales';
import slugifyLib from 'slugify';

export const getCookieValue = (name: string) =>
	document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export function formatTime(date: string, locale = 'en') {
	const now = new Date();
	const then = new Date(date);
	let options: Intl.DateTimeFormatOptions;
	if (now.getDate() === then.getDate() && now.getMonth() === then.getMonth())
		options = {
			hour: '2-digit',
			minute: '2-digit',
		};
	else if (now.getMonth() === then.getMonth() && now.getDate() - then.getDate() < 7)
		options = {
			weekday: 'short',
			hour: '2-digit',
			minute: '2-digit',
		};
	else if (now.getFullYear() === then.getFullYear())
		options = {
			weekday: 'short',
			month: 'short',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		};
	else
		options = {
			year: 'numeric',
			weekday: 'short',
			month: 'short',
			day: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		};
	return then.toLocaleString(locale, options);
}

export async function toBuffer(arrayBuffer: ArrayBuffer): Promise<Buffer> {
	const buf = Buffer.alloc(arrayBuffer.byteLength);
	const view = new Uint8Array(arrayBuffer);
	for (let i = 0; i < buf.length; ++i) {
		buf[i] = view[i];
	}
	return buf;
}

export async function toArrayBuffer(buffer: Buffer): Promise<ArrayBuffer> {
	const arrayBuffer = new ArrayBuffer(buffer.length);
	const view = new Uint8Array(arrayBuffer);
	for (let i = 0; i < buffer.length; ++i) {
		view[i] = buffer[i];
	}
	return arrayBuffer;
}

export function mimeToMediaType(mimeType?: string): MediaType {
	if (!mimeType) return MediaType.Document;
	if (mimeType.startsWith('image')) return MediaType.Image;
	else if (mimeType.startsWith('audio')) return MediaType.Audio;
	else if (mimeType.startsWith('video')) return MediaType.Video;
	else return MediaType.Document;
}

export function getDateFnsLocale(code: string) {
	return DATE_FNS_LOCALES[code as keyof typeof DATE_FNS_LOCALES];
}

export function getAntdLocale(code: string) {
	return ANTD_LOCALES[code as keyof typeof ANTD_LOCALES];
}

export function getDatePickerLocale(code: string) {
	const k = code as keyof typeof ANTD_DATE_PICKER_LOCALES;
	return ANTD_DATE_PICKER_LOCALES[k];
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
	ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export function accessLevelToInt(accessLevel?: AccessLevel | null) {
	switch (accessLevel) {
		case AccessLevel.Admin:
			return 1;
		case AccessLevel.User:
			return 3;
		case AccessLevel.Callcenter:
			return 4;
		case AccessLevel.Guest:
			return 5;
		default:
			return 10;
	}
}

export function accessLevelAbove(
	required: AccessLevel,
	accessLevel?: AccessLevel | null,
) {
	return accessLevelToInt(accessLevel) <= accessLevelToInt(required);
}

// validate hex input
export function validateHex(hex: string) {
	const regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
	return regex.test(hex);
}

export function validateRGB(rgb: string) {
	const regex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*(\d+(\.\d+)?))?\)$/;
	return regex.test(rgb);
}

// rgb color string to hex (rgb(255, 255, 255) -> #ffffff)
export function rgbToHex(rgb: string) {
	const rgbArr = rgb.split(',');
	const r = parseInt(rgbArr[0].split('(')[1], 10);
	const g = parseInt(rgbArr[1], 10);
	const b = parseInt(rgbArr[2].split(')')[0], 10);
	const a = rgbArr.length > 3 ? parseFloat(rgbArr[3]) : 1;

	const hex = ((r << 16) | (g << 8) | b).toString(16);
	return `#${hex.padStart(6, '0')}`;
}

export function hexToRgb(hex: string) {
	const hexArr = hex.split('');
	const r = parseInt(`${hexArr[1]}${hexArr[2]}`, 16);
	const g = parseInt(`${hexArr[3]}${hexArr[4]}`, 16);
	const b = parseInt(`${hexArr[5]}${hexArr[6]}`, 16);
	const a = hexArr.length > 7 ? parseInt(`${hexArr[7]}${hexArr[8]}`, 16) / 255 : 1;

	return `rgba(${r}, ${g}, ${b}, ${a})`;
}

export function ensureRgba(color: string) {
	if (validateRGB(color)) return color;
	else if (validateHex(color)) return hexToRgb(color);
	else if (isColorName(color)) return namedColors[color as keyof typeof namedColors];
	else return color;
}

export function isColor(color: string) {
	return validateHex(color) || validateRGB(color) || isColorName(color);
}

export function isColorName(color: string) {
	return color in namedColors;
}

export function ensureHexColor(color: string) {
	if (validateHex(color)) return color;
	else if (validateRGB(color)) return rgbToHex(color);
	else if (isColorName(color)) return namedColors[color as keyof typeof namedColors];
	else return color;
}

export function slugify(str: string, trim = true) {
	return slugifyLib(str, {
		locale: 'de',
		strict: true,
		lower: true,
		trim,
	});
}
