var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useEffect, useState } from 'react';
import { isFunction } from 'lodash';
export var useDomObserver = function (getter, onGet, deps) {
    var _a = useState(null), element = _a[0], setElement = _a[1];
    // 用于渲染
    var _b = useState(0), renderTick = _b[0], setRenderTick = _b[1];
    useEffect(function () {
        if (!isFunction(getter)) {
            return;
        }
        var el = getter();
        if (!el) {
            setRenderTick(renderTick + 1);
        }
        else {
            setElement(el);
            onGet(element);
        }
    }, __spreadArray([renderTick], deps, true));
    return {
        element: element
    };
};
