import React, { CSSProperties } from 'react';
import { Avatar, Badge, Button, CardProps, Col, Popover, Row, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import './ChannelCard.less';
import { Typography } from 'antd';
import { useQueryClient } from 'react-query';
import { UserAvatar } from '../util/Avatar';
import { useTranslation } from 'react-i18next';
import {
	AccessLevel,
	CompanyChannelsQuery,
	SelfCompaniesQuery,
	useUpdateChannelMutation,
} from '../../generated/graphql';
import { Card } from '../util/Card';
import { SettingOutlined } from '@ant-design/icons';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { accessLevelAbove } from '../../lib/util';

const { Text } = Typography;

export type ChannelCardProps = {
	channel: Exclude<CompanyChannelsQuery['company']['channels'], null | undefined>[0];
	onClick?: () => void;
	style?: CSSProperties;
} & CardProps;

export const ChannelCard = ({
	channel,
	onClick,
	style,
	...cardProps
}: ChannelCardProps) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'ChannelCard' });
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { mutateAsync } = useUpdateChannelMutation();
	const selfAccessLevel = useSelectedCompanyStore(
		(state) => state.company?.selfAccessLevel,
	);

	let actions: JSX.Element[] = [];
	if (accessLevelAbove(AccessLevel.User, selfAccessLevel))
		actions.push(
			<Button
				type="ghost"
				key={0}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					navigate('/admin/channel/' + channel.id);
				}}
				icon={<SettingOutlined />}
				className="channelCardSettings"
			/>,
		);

	const onCardClick = () => {
		if (onClick) onClick();
		else navigate('/admin/channel/' + channel.id);
	};

	return (
		<Card id={'' + channel.id} onClick={onCardClick} hoverable style={style}>
			<Row>
				<Col flex="auto">
					<Typography.Title ellipsis={true} level={5}>
						{channel.name}
					</Typography.Title>
				</Col>
				<Col>
					<Popover content={t('Language')}>
						<Text type="secondary">{channel.config.language}</Text>
					</Popover>
				</Col>
			</Row>
			<Space style={{ width: '100%' }} direction="vertical">
				<Row style={{ textAlign: 'left' }} wrap={false}>
					<Col flex="auto">
						<Text ellipsis>
							{t('Country')}: {channel.config.country}
						</Text>
					</Col>
					<Col>
						<Space>
							<Badge
								title={t('IncidentsStats.OpenIncidents')}
								count={channel.incidentStats.openIncidents}
								style={{ backgroundColor: '#127aba' }}
							/>
							{channel.incidentStats.deadlineIncidents.in7Days > 0 && (
								<Badge
									title={t('IncidentsStats.Deadline.7days')}
									count={channel.incidentStats.deadlineIncidents.in7Days}
									style={{ backgroundColor: '#faad14' }}
								/>
							)}
							{channel.incidentStats.deadlineIncidents.in3Days > 0 && (
								<Badge
									title={t('IncidentsStats.Deadline.3days')}
									count={channel.incidentStats.deadlineIncidents.in3Days}
									style={{ backgroundColor: '#f5222d' }}
								/>
							)}
						</Space>
					</Col>
				</Row>
				<Row justify="space-between" align="middle">
					<Avatar.Group>
						{channel.users.map((u) => (
							<UserAvatar
								key={0}
								user={{
									surname: u.surname,
									lastname: u.lastname,
									id: u.avatar?.id,
								}}
								size={32}
							/>
						))}
					</Avatar.Group>
					{actions.map((a: JSX.Element, i) => (
						<Col key={i}>{a}</Col>
					))}
				</Row>
			</Space>
		</Card>
	);
};
