import './style.less';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Col, Row, Spin, Result, message, Space } from 'antd';
import { Button } from '../../util/Button';
import { useAuthStore } from '../../../hooks/useAuth';

import {
	useCreateIncidentMutation,
	usePublicChannelQuery,
	useSubmitIncidentMutation,
	CreateIncidentInput,
	FeatureNames,
	IncidentType,
	IncidentCategory,
	MediaType,
	IncidentParticipantLevel,
} from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { serializeEncrypted } from '../../../lib/crypt';
import {
	useCryptSensitiveStore,
	useCryptStore,
	useGenerateIdentity,
	useManageMasterKey,
	usePasswordGen,
	usePGPEncrypt,
} from '../../../hooks/useCrypt';
import { useFeature } from '../../../hooks/useFeatures';
import { useChannelEditStore } from '../ChannelEditor';
import { Steps } from '../../util/Steps';
import useStore from './useStore';
import { createIncidentInputFromPartial } from './util';
import StepInform from './StepInform';
import StepReport from './StepReport';
import StepMedia, { useAttchmentsStore } from './StepMedia';
import StepCredentials from './StepCredentials';
import { useNavigate, useParams } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { captureException } from '@sentry/react';

const ReportForm = ({ channelId }: { channelId?: string }) => {
	const { mutateAsync: mutateCreate } = useCreateIncidentMutation();
	const { mutateAsync: mutateSubmit } = useSubmitIncidentMutation();

	const setSessionFromToken = useAuthStore((state) => state.setSessionFromToken);
	const navigate = useNavigate();
	const { t } = useTranslation('translations');
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);

	const design = useChannelEditStore((state) => state.config?.company);

	if (channelId === undefined) {
		throw new Error('Incident Overview requires an id url query param');
	}
	const {
		isLoading,
		isError,
		isSuccess,
		data: channelData,
	} = usePublicChannelQuery({ uuid: channelId });

	const {
		step,
		incident,
		incidentForm,
		formInstance,
		password,
		passwordConfirm,
		setEncryptionKey,
		stepForward,
		stepBackward,
		setPassword,
		setIncident,
		updateIncidentForm,
		clear: clearForm,
		switchedToVoice,
		setSwitchedToVoice,
	} = useStore();
	const [stepLoading, setStepLoading] = useState(false);
	const { items: attachments, clear: clearAttachmentsStore } = useAttchmentsStore();

	const encryptedPrivateKey = useCryptStore((state) => state.encryptedPrivateKey);
	const publicKey = useCryptStore((state) => state.publicKey);
	const masterKeySalt = useCryptStore((state) => state.masterKeySalt);
	const setNeedMasterKeySaltUpload = useCryptStore(
		(state) => state.setNeedMasterKeySaltUpload,
	);

	const generateIdentity = useGenerateIdentity();
	const manageMasterKey = useManageMasterKey();
	const generatePassword = usePasswordGen(16);

	// set the channelId into the form as soon as it is available
	useEffect(() => {
		if (updateIncidentForm) {
			updateIncidentForm({ channelId: channelData?.publicChannel.id });
			if (!channelData?.publicChannel.config?.enableVoiceReports) {
				updateIncidentForm({ type: IncidentType.Text });
			}
		}
	}, [channelData, updateIncidentForm]);

	const [recipients, setRecipients] = useState<{ id?: number; publicKey: string }[]>([]);
	useEffect(() => {
		if (e2eEnabled && isSuccess && channelData && publicKey) {
			const newRecipients: { id?: number; publicKey: string }[] =
				channelData.publicChannel.users.filter((u) => u.publicKey != null) as {
					id: number;
					publicKey: string;
				}[];
			newRecipients.push({ id: undefined, publicKey });
			setRecipients(newRecipients);
		}
	}, [e2eEnabled && isSuccess, channelData, publicKey]);

	const { encrypt, key, encryptedKeys, ready } = usePGPEncrypt(recipients);
	// put the encryptionKey in store so that it can be used in every step
	useEffect(() => {
		if (key != null) setEncryptionKey(key);
	}, [key]);

	// CAUTION: weird hack
	// this effect is triggered by the updateIncidentForm in onVocally in StepReport
	useEffect(() => {
		if (incidentForm.type === IncidentType.Voice && step === 1) {
			createIncidentAction(
				() => stepForward(nSteps),
				() => setStepLoading(false),
			);
		}
	}, [incidentForm.type]);

	const createIncidentAction = useCallback(
		async (onSuccess: () => void, onError: (msg: string) => void) => {
			if (incidentForm.type === IncidentType.Text && !formInstance) return;
			setStepLoading(true);
			let input: CreateIncidentInput | undefined;
			if (
				e2eEnabled &&
				ready &&
				encryptedKeys &&
				publicKey &&
				encryptedPrivateKey &&
				masterKeySalt &&
				recipients?.length > 0 &&
				channelData
			) {
				try {
					if (incidentForm.type === IncidentType.Text) {
						await formInstance?.validateFields();
					}
					input = await createIncidentInputFromPartial(
						async (plaintext: string) => serializeEncrypted(await encrypt(plaintext)),
						1,
						{
							...incidentForm,
							recipients: Array.from(encryptedKeys.entries()).map(([userId, keys]) => ({
								userId,
								encryptedMessageKey: keys[1],
								encryptionVersion: 1,
								level: userId
									? IncidentParticipantLevel.Caseworker
									: IncidentParticipantLevel.Hinter,
							})),
							encryptionVersion: 1,
							hinter: {
								...incidentForm.hinter,
								publicKey,
								encryptedPrivateKey,
								password,
								masterKeySalt,
							},
						},
					);
				} catch (err) {
					setStepLoading(false);
				}
			} else if (e2eEnabled) {
				console.error('End2End encryption is not ready yet.');
				captureException(new Error('End2End encryption is not ready yet.'));
				onError('End2End encryption is not ready yet.');
				return;
			} else if (channelData) {
				try {
					if (incidentForm.type === IncidentType.Text) {
						await formInstance?.validateFields();
					}
					input = await createIncidentInputFromPartial(
						async (plaintext: string) => serializeEncrypted(await encrypt(plaintext)),
						0, // do not use encryption
						{
							...incidentForm,
							recipients: [
								...channelData.publicChannel.users.map((u) => ({
									userId: u.id,
									encryptionVersion: 0,
									level: IncidentParticipantLevel.Caseworker, // TODO: SEVERE levels should be handled by backend
								})),
								{ encryptionVersion: 0, level: IncidentParticipantLevel.Hinter },
							],
							encryptionVersion: 0,
							hinter: {
								...incidentForm.hinter,
								password,
							},
						},
					);
				} catch (err) {
					setStepLoading(false);
				}
			}
			console.log('input', input);
			if (!input) {
				message.error(t('ReportForm.AlertNotCompleted'));
				onError('ReportForm.AlertNotCompleted');
				setStepLoading(false);
				return;
			}

			try {
				const resp = await mutateCreate({ input });
				const { hinterCredentials, incident } = resp.createIncident;
				setSessionFromToken(hinterCredentials.session.token as string);
				setPassword(hinterCredentials.password);
				setIncident(incident);
				onSuccess();
			} catch (err: any) {
				captureException('Could not create the incident', { extra: err });
				onError(`Could not create the incident ${err}`);
			}
			setStepLoading(false);
		},
		[incidentForm],
	);

	// called after the user has filled out the icident form
	const submitAction = async (onError: (msg: string) => void) => {
		setStepLoading(true);
		if (!incident) {
			onError('Fatal: incident should have been created already! Restart form!');
			setStepLoading(false);
			return;
		}
		try {
			await mutateSubmit({ id: incident.id });
			clearForm();
			clearAttachmentsStore();
			navigate('..');
		} catch (err) {
			onError(`Failed to finalize submission ${err}`);
		}
		setStepLoading(false);
	};

	const stepActions = useMemo(
		() => [
			(onSuccess: () => void, onError: () => void) => {
				setStepLoading(true);
				if (e2eEnabled)
					Promise.all([
						generateIdentity(),
						(async () => {
							const password = generatePassword();
							setPassword(password);
							await manageMasterKey(password);
							setNeedMasterKeySaltUpload(false); // we will upload it with incident submission
						})(),
					])
						.then(onSuccess)
						.catch(onError)
						.finally(() => setStepLoading(false));
				else {
					try {
						setPassword(generatePassword());
						onSuccess();
					} catch (err) {
						onError();
					}
					setStepLoading(false);
				}
			},
			createIncidentAction,
			(onSuccess: () => void, onError: () => void) => {
				onSuccess();
			},
			submitAction,
		],
		[e2eEnabled, createIncidentAction, submitAction],
	);

	if (isLoading) return <Spin />;
	else if (isError || !channelData?.publicChannel) return <Result status="404" />;

	const steps = [
		<StepInform channel={channelData?.publicChannel} key={0} />,
		<StepReport
			key={1}
			onSubmit={() =>
				stepActions[1](
					() => stepForward(nSteps),
					() => {},
				)
			}
			voiceReportsEnabled={channelData?.publicChannel.config?.enableVoiceReports ?? true}
			anonymousReportsEnabled={
				channelData?.publicChannel.config?.enableAnonymousReports ?? true
			}
		/>,
		<StepMedia
			key={2}
			voiceReportsEnabled={channelData?.publicChannel.config?.enableVoiceReports}
			anonymousReportsEnabled={channelData?.publicChannel.config?.enableAnonymousReports}
		/>,
		<StepCredentials
			key={3}
			onSubmit={() =>
				stepActions[3](
					() => stepForward(nSteps),
					() => {},
				)
			}
		/>,
	];
	const nSteps = Object.keys(steps).length;

	const stepNames = [
		t('ReportForm.Steps.Information'),
		t('ReportForm.Steps.Meldedaten'),
		t('ReportForm.Steps.Media'),
		t('ReportForm.Steps.Credentials'),
	];

	return (
		<>
			<Helmet>
				<title>{t('Meta.Report.Title')}</title>
			</Helmet>
			<Row>
				<Steps design={design} size="default" current={step}>
					{stepNames.map((stepName, i) => (
						<Steps.Step key={i} title={stepName}></Steps.Step>
					))}
				</Steps>
			</Row>
			{/* <Row>
				<Descriptions>
					<Descriptions.Item label="ready">{ready + ''}</Descriptions.Item>
					<Descriptions.Item label="salt">{masterKeySalt}</Descriptions.Item>
					<Descriptions.Item label="publicKey">{publicKey}</Descriptions.Item>
					<Descriptions.Item label="privateKey">
						{privateKey ? privateKeyToBase64(privateKey) : 'null'}
					</Descriptions.Item>
					<Descriptions.Item label="encPrivateKey">
						{encryptedPrivateKey ? encryptedPrivateKey : 'null'}
					</Descriptions.Item>
					{recipients.map((r) => (
						<>
							<Descriptions.Item label={r.id || 'hinter'}>
								{encryptedKeys ? encryptedKeys.get(r.id) : 'null'}
							</Descriptions.Item>
						</>
					))}
					<Descriptions.Item label="password">{password}</Descriptions.Item>
				</Descriptions>
			</Row> */}
			<Row style={{ marginTop: '2em' }}>
				<Col>
					<Button
						design={design}
						onClick={() => (step > 0 ? stepBackward() : navigate('..'))}
					>
						{t('Back')}
					</Button>
				</Col>
				<Col flex="auto"></Col>
				<Col>
					<Button
						design={design}
						type="primary"
						onClick={() => {
							stepActions[step](
								() => stepForward(nSteps),
								() => {},
							);
						}}
						disabled={
							stepLoading ||
							(step === nSteps - 1 && password !== passwordConfirm) ||
							(step === 1 && incidentForm.type === undefined) ||
							(step === 2 &&
								incidentForm.type === IncidentType.Voice &&
								!attachments.find((a) => a.type === MediaType.Audio))
						}
					>
						<Space>
							{stepLoading && <LoadingOutlined />}
							{step === nSteps - 1 ? t('Submit') : t('Next')}
						</Space>
					</Button>
				</Col>
			</Row>
			<div style={{ width: '100%', marginTop: '2em' }}>{steps[step]}</div>
		</>
	);
};

export default ReportForm;
