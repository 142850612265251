import { Result, Spin, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useConfirmEmailMutation } from '../../generated/graphql';

export const ConfirmEmail = () => {
	const { t } = useTranslation('translations');
	const { token } = useParams();
	const { mutateAsync } = useConfirmEmailMutation();
	const [state, setState] = useState(<Spin />);

	useEffect(() => {
		if (token)
			mutateAsync({ token }).then((res) => {
				if (res.confirmEmail) {
					setState(<Result status="success" />);
				} else {
					setState(<Result status="error" />);
				}
			});
	}, [setState, token, mutateAsync]);

	return (
		<>
			<Helmet>
				<title>{t('Meta.ConfirmEmail.Title')}</title>
			</Helmet>
			<Typography.Title>{t('ConfirmEmail.Title')}</Typography.Title>
			{state}
		</>
	);
};
