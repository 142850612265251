import { DeleteOutlined } from '@ant-design/icons';
import { Col, Input, message, Modal, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
	MessageTemplateDataFragment,
	UpdateMessageTemplateInput,
	useCreateMessageTemplateMutation,
	useRemoveMessageTemplateMutation,
	useUpdateMessageTemplateMutation,
} from '../../../generated/graphql';
import { Button } from '../../util/Button';
import { MessageTemplateShareSelect, TemplateShare } from './MessageTemplateShareSelect';

export interface MessageTemplateFormValue {
	text?: string;
	name?: string;
	companyId?: number;
	channelId?: number;
}

export interface TemplateModalProps {
	initFormValue?: MessageTemplateFormValue;
	templateId?: number; // edit mode when set
	close: () => void;
	onSaveTemplate: (template: MessageTemplateDataFragment) => void;
	onRemoveTemplate: (templateId: number) => void;
	visible: boolean;
	isOwner?: boolean;
}

export const TemplateModal = ({
	initFormValue,
	close,
	onSaveTemplate: onNewTemplate,
	onRemoveTemplate,
	templateId: templateIdProp,
	visible,
	isOwner = false,
}: TemplateModalProps) => {
	const qC = useQueryClient();
	const { t } = useTranslation('translations', { keyPrefix: 'MessageTemplateModal' });
	const [text, setText] = useState(initFormValue?.text);
	const [name, setName] = useState(initFormValue?.name);
	const [sharing, setSharing] = useState<TemplateShare>({
		companyId: initFormValue?.companyId == null ? undefined : initFormValue?.companyId,
		channelId: initFormValue?.channelId == null ? undefined : initFormValue?.channelId,
	});
	const [templateId, setTemplateId] = useState(templateIdProp);

	useEffect(() => {
		setTemplateId(templateIdProp);
		setText(initFormValue?.text);
		setName(initFormValue?.name);
		setSharing({
			companyId: initFormValue?.companyId,
			channelId: initFormValue?.channelId,
		});
	}, [templateIdProp, initFormValue]);

	const { mutateAsync: create, isLoading: createIsLoading } =
		useCreateMessageTemplateMutation();
	const { mutateAsync: update, isLoading: updateIsLoading } =
		useUpdateMessageTemplateMutation();
	// const { mutateAsync: remove, isLoading: removeIsLoading } = useRemoveTemplateMutation();
	const removeMutation = useRemoveMessageTemplateMutation();

	const onRemove = () => {
		Modal.confirm({
			title: t('Remove.Title'),
			okText: t('Remove.Confirm.Button.Ok'),
			cancelText: t('Remove.Confirm.Button.Cancel'),
			onOk: async () => {
				if (templateId !== undefined) {
					try {
						await removeMutation.mutateAsync({ id: templateId });
						message.success(t('Remove.Message.Success'));
						onRemoveTemplate(templateId);
						close();
					} catch (err) {
						message.error(t('Remove.Message.Error'));
					}
				}
			},
		});
	};

	const refetch = () => {
		// TODO: outdated
		qC.invalidateQueries('messageTemplates');
		qC.invalidateQueries('messageTemplatesForUser');
		qC.invalidateQueries('messageTemplatesForCompany');
		qC.invalidateQueries('messageTemplatesForChannel');
	};
	const onOk = async () => {
		if (!text || text.length < 20) {
			message.error(t('Form.Text.TooShort'));
			return;
		}
		if (!name || name.length < 3) {
			message.error(t('Form.Text.Missing'));
			return;
		}
		if (templateId !== undefined) {
			// update
			try {
				const res = await update({ id: templateId, input: { text, name, ...sharing } });
				message.success(t('Update.Message.Success'));
				onNewTemplate(res.updateMessageTemplate);
			} catch (err) {
				message.error(t('Update.Message.Error'));
				// TODO: maybe need some cleanup
			} // TODO: handle error
		} else {
			// create
			try {
				const res = await create({ input: { name, text, ...sharing } });
				setTemplateId(res.createMessageTemplate.id);
				message.success(t('Create.Message.Success'));
				onNewTemplate(res.createMessageTemplate);
			} catch (err) {
				message.error(t('Create.Message.Error'));
				// TODO: maybe need some cleanup
			} // TODO: handle error
		}
	};

	return (
		<Modal
			visible={visible}
			confirmLoading={createIsLoading || updateIsLoading}
			closable
			destroyOnClose
			onCancel={close}
			onOk={onOk}
			okText={templateId ? t('Save') : t('Create.Button')}
			title={templateId ? t('Update.Title') : t('Create.Title')}
		>
			<Space direction="vertical" size="middle" style={{ width: '100%' }}>
				<Input
					placeholder={t('Form.Name.Placeholder')}
					maxLength={400}
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>
				<Input.TextArea
					placeholder={t('Form.Text.Placeholder')}
					maxLength={2000}
					value={text}
					autoSize={{ minRows: 5, maxRows: 10 }}
					onChange={(e) => setText(e.target.value)}
				/>
				<Row>
					<Col flex="auto">
						<MessageTemplateShareSelect
							style={{ width: '100%' }}
							value={sharing}
							onSelect={(v) => setSharing(v)}
							allCompanies={false}
							isOwner={isOwner}
						/>
					</Col>
					<Col>
						<Button icon={<DeleteOutlined />} type="text" danger onClick={onRemove}>
							{t('Remove.Label')}
						</Button>
					</Col>
				</Row>
			</Space>
		</Modal>
	);
};
