var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Popover } from 'antd';
import { isNil, noop } from 'lodash';
import { Mask } from '../mask/mask';
import { KEYBOARD_NAMES } from '../const';
import Content from './content';
import { OnBoardingStatus } from '../types';
import enUS from '../locale/en-US';
import { useDomObserver } from '../hooks/use-dom-observer';
export var OnBoarding = forwardRef(function (props, ref) {
    var step = props.step, steps = props.steps, initialStep = props.initialStep, _a = props.useDefaultOperations, useDefaultOperations = _a === void 0 ? true : _a, _b = props.isShowMask, isShowMask = _b === void 0 ? false : _b, _c = props.onStepsEnd, onStepsEnd = _c === void 0 ? noop : _c, styleChecker = props.styleChecker, _d = props.locale, locale = _d === void 0 ? enUS : _d, _e = props.supportKeyboard, supportKeyboard = _e === void 0 ? true : _e, getContainer = props.getContainer;
    // 当前状态
    var _f = useState(OnBoardingStatus.NOT_READY), currentStatus = _f[0], setCurrentStatus = _f[1];
    // 当前步骤(step index)
    var _g = useState(initialStep || 0), currentStep = _g[0], setCurrentStep = _g[1];
    // mask 是否在移动
    var _h = useState(false), isMaskMoving = _h[0], setIsMaskMoving = _h[1];
    // 当前目标 DOM 的监听
    var currentSelectedElement = useDomObserver(steps.length ? function () {
        var _a;
        return (_a = steps[currentStep]) === null || _a === void 0 ? void 0 : _a.selector();
    } : null, function () {
        setCurrentStatus(OnBoardingStatus.READY);
    }, [steps, currentStep]);
    var currentContainerElement = useDomObserver(getContainer, noop, [getContainer]);
    // 获取当前步骤
    var getCurrentStep = function () {
        return steps[currentStep];
    };
    var back = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, beforeBack;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    // 如果是第一步，我们不应该往前走
                    if (currentStep === 0) {
                        return [2 /*return*/];
                    }
                    _a = getCurrentStep().beforeBack, beforeBack = _a === void 0 ? noop : _a;
                    return [4 /*yield*/, beforeBack(currentStep)];
                case 1:
                    _b.sent();
                    setCurrentStep(currentStep - 1);
                    return [2 /*return*/];
            }
        });
    }); };
    var forward = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, beforeForward;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(currentStep === steps.length - 1)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onStepsEnd()];
                case 1:
                    _b.sent();
                    setCurrentStatus(OnBoardingStatus.END);
                    return [2 /*return*/];
                case 2:
                    _a = getCurrentStep().beforeForward, beforeForward = _a === void 0 ? noop : _a;
                    return [4 /*yield*/, beforeForward(currentStep)];
                case 3:
                    _b.sent();
                    setCurrentStep(currentStep + 1);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (!isNil(step)) {
            // 不触发相关生命周期
            setCurrentStep(step);
        }
    }, [step]);
    useEffect(function () {
        var cb = function (event) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(currentStatus === OnBoardingStatus.READY)) return [3 /*break*/, 4];
                        // 阻止键盘方向键导致界面滚动
                        event.preventDefault();
                        if (!(event.key === KEYBOARD_NAMES.ARROW_RIGHT)) return [3 /*break*/, 2];
                        return [4 /*yield*/, forward()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(event.key === KEYBOARD_NAMES.ARROW_LEFT)) return [3 /*break*/, 4];
                        return [4 /*yield*/, back()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        if (supportKeyboard) {
            window.addEventListener('keydown', cb);
        }
        return function () {
            if (supportKeyboard) {
                window.removeEventListener('keydown', cb);
            }
        };
    }, [currentStatus, currentStep]);
    var renderPopover = function (wrapper) {
        var _a;
        var c = getCurrentStep();
        if (!c) {
            return wrapper;
        }
        var renderContent = c.renderContent;
        var content = renderContent ? renderContent(currentStep) : null;
        var defaultOperation = (React.createElement("div", { className: 'onboarding-default-operation' },
            currentStep !== 0 && React.createElement(Button, { className: 'back', onClick: function () { return back(); } }, locale.previous),
            React.createElement(Button, { className: 'forward', type: 'primary', onClick: function () { return forward(); } }, currentStep === steps.length - 1 ? locale.gotIt : locale.next)));
        var options = {
            operationArea: useDefaultOperations ? defaultOperation : undefined,
            content: content
        };
        return !isMaskMoving ? (React.createElement(Popover, { content: React.createElement(Content, __assign({}, options)), visible: true, placement: (_a = getCurrentStep()) === null || _a === void 0 ? void 0 : _a.placement }, wrapper)) : wrapper;
    };
    useImperativeHandle(ref, function () {
        return {
            forward: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, forward()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); },
            back: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, back()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }
        };
    }, [step, currentStep]);
    return ReactDOM.createPortal(currentStatus === OnBoardingStatus.READY ? (React.createElement(Mask, { onAnimationStart: function () {
            setIsMaskMoving(true);
        }, onAnimationEnd: function () {
            setIsMaskMoving(false);
        }, container: currentContainerElement.element, styleChecker: styleChecker, visible: isShowMask, element: currentSelectedElement.element || currentContainerElement.element || document.body, renderMaskContent: function (wrapper) { return renderPopover(wrapper); } })) : React.createElement(Fragment, null), currentContainerElement.element || document.body);
});
