import { InboxOutlined, AudioOutlined } from '@ant-design/icons';
import { Space, Row, Col, Typography, Empty, Modal, Tooltip, message, Spin } from 'antd';
import { useState, useRef, DragEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureNames, MediaType } from '../../../generated/graphql';
import { createIdentifiableItemsStore } from '../../../hooks/useIdentifiableItemsStore';
import { useRecorder } from '../../../hooks/useRecorder';
import { useDistortVoice } from '../../../hooks/useREST';
import { Media } from '../../../generated/graphql';
import { Button } from '../../util/Button';
import { Card } from '../../util/Card';
import { Square } from '../../util/Icons';
import { useChannelEditStore } from '../ChannelEditor';
import { IncidentAttachment, IncidentAttachmentProps } from '../IncidentAttachment';
import useStore from './useStore';
import { useFeature } from '../../../hooks/useFeatures';
import { Switch } from '../../util/Switch';
import { captureException } from '@sentry/react';

export const useAttchmentsStore = createIdentifiableItemsStore<IncidentAttachmentProps>();

const StepMedia = ({
	voiceReportsEnabled = true,
	anonymousReportsEnabled = true,
}: {
	voiceReportsEnabled?: boolean;
	anonymousReportsEnabled?: boolean;
}) => {
	const { t } = useTranslation('translations');
	const { incident, stepBackward } = useStore();
	const [attachments, setAttachments] = useState<Map<number, JSX.Element>>(new Map());
	const store = useAttchmentsStore();
	const encryptionKey = useStore((state) => state.encryptionKey);
	const fileInputElem = useRef<HTMLInputElement | null>(null);
	const onStartRecording = () => startRecording();
	const onStopRecording = () => stopRecording();
	const design = useChannelEditStore((state) => state.config?.company);
	const { mutateAsync: distortVoice } = useDistortVoice();
	const [anonymizingVoice, setAnonymizingVoice] = useState(false);
	const voiceAnonymizationFeatureEnabled = useFeature([FeatureNames.VoiceAnonymization]);
	const [voiceStorageConsent, setVoiceStorageConsent] = useState(true);

	const onRecordedAudio = useCallback(
		(data: Blob) => {
			console.log(
				'dataCallback',
				voiceAnonymizationFeatureEnabled,
				incident?.hinter.anonymous,
				distortVoice,
			);
			if (
				voiceAnonymizationFeatureEnabled &&
				anonymousReportsEnabled &&
				incident?.hinter.anonymous
			) {
				// distort voice by posting it to the voice distortion api
				setAnonymizingVoice(true);
				distortVoice(data)
					.then((res) => {
						if (res) {
							addAttachment(
								new File([new Blob([res.data])], 'recording.webm', {
									type: 'audio/webm',
								}),
								MediaType.Audio,
							);
							message.success(t('ReportForm.Media.Anonymization.Voice.Message.Success'));
						}
					})
					.catch((err) => {
						message.error(t('ReportForm.Media.Anonymization.Voice.Message.Error'));
						captureException(err);
					})
					.finally(() => {
						setAnonymizingVoice(false);
					});
			} else {
				addAttachment(
					new File([data], 'recording.webm', { type: 'audio/webm' }),
					MediaType.Audio,
				);
			}
		},
		[voiceAnonymizationFeatureEnabled, incident?.hinter.anonymous, voiceStorageConsent],
	);

	const { isRecording, startRecording, stopRecording, time } =
		useRecorder(onRecordedAudio);

	const addAttachment = (f: File, type?: MediaType) => {
		if (!incident) {
			return;
		}
		const rndKey = Math.floor(Math.random() * 1000000);
		store.add({
			id: rndKey,
			file: f,
			type: type,
			encryptionKey: encryptionKey,
			onSuccess: ((id: number) => (m: Media) => {
				console.log(`uploaded attachment ${id}: ${m}`);
				store.update(rndKey, { uploadedMedia: m });
			})(rndKey),
			onRemove: () => removeAttachment(rndKey),
			incidentId: incident.id,
			design: design,
			storageConsent: voiceStorageConsent,
		});
	};

	const removeAttachment = (k: number) => {
		store.remove(k);
	};

	const onFileSelect = (files: FileList) => {
		for (let i = 0; i < files.length; i++) {
			const f = files[i];
			addAttachment(f);
		}
		if (fileInputElem.current) {
			fileInputElem.current.files = null;
			fileInputElem.current.value = '';
		}
	};

	const previewVisible = false;
	const previewTitle = '';
	const previewImage = '';

	const onCancelModal = () => {
		console.log('cancel');
	};

	const onDrop: DragEventHandler<HTMLDivElement> = (e: React.DragEvent) => {
		const files = e.dataTransfer?.files;
		if (files) {
			onFileSelect(files);
			e.preventDefault();
		}
	};

	if (!incident) {
		console.log('Incident should have already been created! Restart form.');
		stepBackward();
	}

	return (
		<Space direction="vertical" size="large">
			<Row gutter={[32, 32]} justify="center" align="middle">
				{voiceReportsEnabled && (
					<Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: 'center' }}>
						<Space direction="vertical" size="large" style={{ width: '100%' }}>
							<Typography.Title level={3}>
								{t('ReportForm.Media.VoiceButtonTitle')}
							</Typography.Title>
							<Tooltip
								title={
									voiceAnonymizationFeatureEnabled && incident?.hinter.anonymous === true
										? t('ReportForm.Media.VoiceButtonTooltip.Anonymized')
										: t('ReportForm.Media.VoiceButtonTooltip.Enabled')
								}
							>
								<div>
									<Button
										onClick={isRecording ? onStopRecording : onStartRecording}
										size="large"
										design={design}
										style={{ width: 'auto', height: 'auto' }}
										// disabled={incident?.hinter.anonymous}
									>
										<span style={{ lineHeight: 1, margin: '16px', fontSize: '64px' }}>
											{isRecording ? (
												<>
													<Square
														width="1.5em"
														height="1.5em"
														style={{ verticalAlign: 'middle' }}
													/>
													{`${time.minutes}:${time.seconds}`}
												</>
											) : anonymizingVoice ? (
												<Space direction="vertical">
													<Spin />
													<Typography.Text style={{ fontSize: '12px' }}>
														{t('ReportForm.Media.Anonymization.Voice.Message.Progress')}
													</Typography.Text>
												</Space>
											) : (
												<AudioOutlined />
											)}
										</span>
									</Button>
								</div>
							</Tooltip>
							<Space size="middle" wrap={false}>
								<Tooltip title={t('ReportForm.Media.VoiceStorageConsentCheck.Tooltip')}>
									<Switch
										design={design}
										onChange={setVoiceStorageConsent}
										checked={voiceStorageConsent}
									/>
								</Tooltip>
								<Typography.Text type="secondary">
									{t('ReportForm.Media.VoiceStorageConsentCheck.Label')}
								</Typography.Text>
							</Space>
						</Space>
					</Col>
				)}
				<Col xs={24} sm={24} md={12} lg={12}>
					<Card
						design={design}
						hoverable
						onClick={() => {
							fileInputElem.current && fileInputElem.current.click();
						}}
					>
						<div
							className="DropZone"
							onDrop={onDrop}
							onDragOver={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}
							style={{
								borderRadius: design?.borderRadius,
								borderColor: design?.borderColor,
							}}
						>
							<input
								type="file"
								id="file"
								multiple={true}
								onChange={(e) => onFileSelect(e.target.files as FileList)}
								ref={fileInputElem}
								style={{ display: 'none' }}
							/>
							<Space align="center" direction="vertical">
								<Typography.Text type="secondary" style={{ fontSize: '64px' }}>
									<InboxOutlined />
								</Typography.Text>
								<Typography.Text type="secondary">
									{t('ReportForm.Media.UploadBox.Text')}
								</Typography.Text>
								<Typography.Text type="secondary">
									{t('ReportForm.Media.UploadBox.Hint')}
								</Typography.Text>
							</Space>
						</div>
					</Card>
				</Col>
			</Row>
			<Typography.Title level={3}>{t('ReportForm.Media.Title')}</Typography.Title>
			<Row gutter={[16, 16]}>
				{store.items.length === 0 && (
					<Col span={24} style={{ textAlign: 'center' }}>
						<Empty />
					</Col>
				)}
				{store.items.map((a, i) => (
					<Col key={i}>
						<Card hoverable={false} className="Card" design={design}>
							<IncidentAttachment key={a.id} {...a} />
						</Card>
					</Col>
				))}
			</Row>
			<Row>
				<Modal
					visible={previewVisible}
					title={previewTitle}
					footer={null}
					onCancel={onCancelModal}
				>
					<img alt="example" style={{ width: '100%' }} src={previewImage} />
				</Modal>
			</Row>
		</Space>
	);
};

export default StepMedia;
