import { CreateIncidentInput, CreateIncidentMutation } from '../../../generated/graphql';

export type Incident = CreateIncidentMutation['createIncident']['incident'];

export type RequiredPick<T, K extends keyof T> = Omit<T, K> & Required<Pick<T, K>>;

export const createIncidentInputFromPartial: (
	encrypt: (plaintext: string) => Promise<string>,
	encryptionVersion: number,
	input?: RequiredPick<Partial<CreateIncidentInput>, 'hinter'>,
) => Promise<CreateIncidentInput | undefined> = async (
	encrypt,
	encryptionVersion,
	input?,
) => {
	if (
		input !== undefined &&
		input.subject !== undefined &&
		input.category !== undefined &&
		input.description !== undefined &&
		input.channelId !== undefined &&
		input.recipients !== undefined
	) {
		return {
			...input,
			channelId: input.channelId,
			recipients: input.recipients,
			encryptionVersion,
			subject: encryptionVersion === 0 ? input.subject : await encrypt(input.subject),
			description:
				encryptionVersion === 0 ? input.description : await encrypt(input.description),
		};
	}
	return undefined;
};
