import { Checkbox, Col, Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CompanyQuery, UpdateCompanySettingsInput } from '../generated/graphql';
import { Card } from './util/Card';
import { FeatureToggle } from './util/FeatureFlags';

interface CompanySettingsProps {
	company: CompanyQuery['company'];
	update: (settingsUpdate: UpdateCompanySettingsInput) => void;
	enabled: boolean;
}

export const CompanySettings = ({ company, update, enabled }: CompanySettingsProps) => {
	const { t: tt } = useTranslation('translations', { keyPrefix: 'CompanySettings' });
	return (
		<Card hoverable={false}>
			<Row gutter={[8, 8]}>
				<Col span={24}>
					<Typography.Title level={3}>{tt('Title')}</Typography.Title>
				</Col>
				<Col span={24}>
					<Tooltip title={tt('AddAdminsToChannels.Tooltip')}>
						<Checkbox
							disabled={!enabled}
							checked={company.settings.adminsOnAllChannels}
							onChange={(e) => {
								update({ adminsOnAllChannels: e.target.checked });
							}}
						>
							{tt('AddAdminsToChannels.Label')}
						</Checkbox>
					</Tooltip>
					{company.tier >= 2 && (
						<Tooltip title={tt('AllowChannelSwitching.Tooltip')}>
							<Checkbox
								disabled={!enabled}
								checked={company.settings.allowChannelSwitching}
								onChange={(e) => {
									update({ allowChannelSwitching: e.target.checked });
								}}
							>
								{tt('AllowChannelSwitching.Label')}
							</Checkbox>
						</Tooltip>
					)}
				</Col>
			</Row>
		</Card>
	);
};
