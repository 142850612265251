import { useTranslation } from 'react-i18next';
import { Col, message, Modal, ModalFuncProps, Row, Space, Spin, Typography } from 'antd';
import { useMemo, useState } from 'react';
import { Alert } from '../util/Alert';
import { Input } from '../util/Input';
import { UserInfo, UserSelect } from './UserSelect';
import { IncidentParticipantLevelSelect } from '../util/EnumSelect';
import { IncidentParticipantLevel } from '../../generated/graphql';

export interface InviteModalProps {
	visible: boolean;
	onClose: () => void;
	loading: boolean;
	confirmLoading?: boolean;
	title?: string;
	description?: string;
	onInvite?: (
		userId: number,
		level?: IncidentParticipantLevel,
		message?: string,
	) => Promise<boolean>;
	onInviteByEmail?: (
		email: string,
		level?: IncidentParticipantLevel,
		firstname?: string,
		lastname?: string,
		message?: string,
	) => Promise<boolean>;
	showFirstname?: boolean;
	showLastname?: boolean;
	token?: string;
	users?: UserInfo[];
	error?: string;
	modalProps?: ModalFuncProps;
	entity: 'company' | 'incident';
}

export const InviteModal = ({
	visible,
	onClose: parentOnClose,
	title,
	description,
	onInvite,
	onInviteByEmail,
	showFirstname,
	showLastname,
	token,
	error,
	users,
	loading,
	modalProps,
	entity,
}: InviteModalProps) => {
	const { t } = useTranslation(undefined, { keyPrefix: 'InviteModal' });
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [firstname, setFirstname] = useState<string>();
	const [lastname, setLastname] = useState<string>();
	const [user, setUser] = useState<UserInfo>();
	const [internalError, setInternalError] = useState<string>();
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [level, setLevel] = useState<IncidentParticipantLevel>();
	const [message, setMesssage] = useState<string>();

	const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const isValidEmail = useMemo(() => email && emailRegex.test(email), [email]);

	const onOk = () => {
		if (entity === 'incident' && !level) {
			setInternalError(t('ErrorLevelRequired'));
			return;
		}
		if (user && onInvite) {
			setConfirmLoading(true);
			onInvite(user.id, level, message)
				.then((success) => success && onClose())
				.finally(() => setConfirmLoading(false));
		} else if (email && onInviteByEmail) {
			if (showFirstname && !firstname) {
				setInternalError(t('ErrorFirstnameRequired'));
				return;
			}
			if (showLastname && !lastname) {
				setInternalError(t('ErrorLastnameRequired'));
				return;
			}
			setConfirmLoading(true);
			onInviteByEmail(email, level, firstname, lastname, message)
				.then((success) => success && onClose())
				.finally(() => setConfirmLoading(false));
		}
	};

	const clearForm = () => {
		setEmail(undefined);
		setFirstname(undefined);
		setLastname(undefined);
		setUser(undefined);
		setMesssage(undefined);
	};

	const onUserSelect = (user?: UserInfo) => {
		setUser(user);
		if (user) {
			setEmail(user?.email);
			setFirstname(user?.surname);
			setLastname(user?.lastname);
		}
	};

	const onChangeEmail = (email: string) => {
		setEmail(email.replace('mailto:', ''));
		setUser(users?.find((u) => u.email === email));
	};

	const onChangeMessage = (message: string) => {
		setMesssage(message);
	};

	const onClose = () => {
		clearForm();
		parentOnClose();
	};

	return (
		<Modal
			title={title || t('Title')}
			cancelButtonProps={{ style: { display: 'none' } }}
			okText={t('Invite')}
			visible={visible}
			destroyOnClose
			closable
			onOk={onOk}
			confirmLoading={confirmLoading}
			okButtonProps={{ disabled: !isValidEmail }}
			onCancel={onClose}
			{...modalProps}
		>
			{loading ? (
				<Spin />
			) : (
				<Space style={{ width: '100%' }} direction="vertical" size="middle">
					{error && <Alert closable type="error" message={`${t('Error')}. ${error}`} />}
					{internalError ? (
						<Alert closable type="warning" message={internalError} />
					) : null}
					{description && <Typography.Paragraph>{description}</Typography.Paragraph>}
					{entity === 'incident' && ( // TODO: handle company too
						<Row justify="center" align="middle">
							<Col>
								<IncidentParticipantLevelSelect
									style={{ width: '100%', minWidth: '250px' }}
									value={level}
									onSelect={(v) => setLevel(v as IncidentParticipantLevel)}
								/>
							</Col>
						</Row>
					)}
					{/* {token && (
						<>
							<Row>
								<Col>
									<Typography.Paragraph>{t('TextShare')}</Typography.Paragraph>
								</Col>
							</Row>
							<Row justify="center" align="middle">
								<Col>
									<Typography.Text style={{ fontSize: '16px' }} code copyable>
										{token}
									</Typography.Text>
								</Col>
							</Row>
							<Row>
								<Col>
									<Typography.Text strong>{t('Or')}</Typography.Text>
								</Col>
							</Row>
						</>
					)} */}
					{users && onInvite && (
						<>
							<Row>
								<Col>
									<Typography.Paragraph>
										{t('TextInviteExistingUser')}
									</Typography.Paragraph>
								</Col>
							</Row>
							<Row justify="center" align="middle">
								<Col>
									<UserSelect
										style={{ width: '100%', minWidth: '250px' }}
										users={users}
										onSelect={onUserSelect}
										value={user}
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<Typography.Text strong>{t('Or')}</Typography.Text>
								</Col>
							</Row>
						</>
					)}
					{onInviteByEmail && (
						<>
							<Row>
								<Col>
									<Typography.Paragraph>{t('TextByEmail')}</Typography.Paragraph>
								</Col>
							</Row>
							<Space
								direction="vertical"
								size="middle"
								align="center"
								style={{ width: '100%' }}
							>
								{!user && <Alert type="info" message={t('EmailInviteConsentWarning')} />}
								<Space direction="vertical">
									<Typography.Text type="secondary">{t('EmailLabel')}</Typography.Text>
									<Input
										style={{ width: '100%', maxWidth: '300px' }}
										placeholder={t('EmailLabel')}
										onChange={(e) => onChangeEmail(e.target.value)}
										value={email}
									/>
									{email && !isValidEmail && (
										<Alert message={t('EmailInvalidWarning')} type="warning" />
									)}
								</Space>
								{showFirstname && (
									<Space direction="vertical">
										<Typography.Text type="secondary">
											{t('FirstnameLabel')}
										</Typography.Text>
										<Input
											placeholder={t('FirstnameLabel')}
											style={{ width: '100%', maxWidth: '300px' }}
											onChange={(e) => setFirstname(e.target.value)}
											value={firstname}
										/>
									</Space>
								)}
								{showLastname && (
									<Space direction="vertical">
										<Typography.Text type="secondary">
											{t('LastnameLabel')}
										</Typography.Text>
										<Input
											placeholder={t('LastnameLabel')}
											style={{ width: '100%', maxWidth: '300px' }}
											onChange={(e) => setLastname(e.target.value)}
											value={lastname}
										/>
									</Space>
								)}
							</Space>
						</>
					)}
					{entity === 'incident' && level === IncidentParticipantLevel.Collaborator && (
						<Input.TextArea
							value={message}
							onChange={(e) => onChangeMessage(e.target.value)}
							placeholder={t('Message.Placeholder')}
						/>
					)}
				</Space>
			)}
		</Modal>
	);
};
