import { Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { useTranslation } from 'react-i18next';
import { Button } from '../util/Button';
import { Card } from '../util/Card';
import './ChannelConfigUpsell.less';

export default function ChannelConfigUpsell() {
	const { t } = useTranslation('translations', { keyPrefix: 'ChannelConfigUpsell' });
	return (
		<Card className="UpsellCard">
			<Space
				direction="vertical"
				align="center"
				size="middle"
				style={{ textAlign: 'center' }}
			>
				<Title level={2}>{t('Title')}!</Title>
				<Text>{t('Description')}</Text>
				<Title level={4}>{t('Note')}</Title>
				<Button
					type="primary"
					size="large"
					href="https://my.meetergo.com/martinmeng/upgrade"
					target="_blank"
				>
					{t('Button')}
				</Button>
			</Space>
		</Card>
	);
}
