import './Alert.less';
import { Alert as AntdAlert, AlertProps } from 'antd';

export const Alert = ({ style: styleOverwrite, ...props }: AlertProps) => {
	const style = {
		borderStyle: 'none',
		boxShadow: '0 3px 12px -0px rgb(0, 0, 0, 0.08)',
		...styleOverwrite,
	};
	return <AntdAlert style={style} {...props} />;
};
