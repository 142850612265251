import { Ref, RefObject, useEffect, useRef } from 'react';

/**
 * Hook that detects clicks outside of the passed ref
 */
export const useOnOutsideClick = (ref: RefObject<HTMLElement>, onClick: () => void) => {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: Event) {
			if (event.target && ref.current && !ref.current.contains(event.target as Node)) {
				onClick();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};
