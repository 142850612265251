import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Spin, Row, Col, Button, Divider, Space, Typography, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { HinterSession, Roles, useAuthStore, useLogout } from '../../hooks/useAuth';
import { Chat, ChatProps } from '../Chat/Chat';
import { LockOutlined } from '@ant-design/icons';
import {
	FeatureNames,
	IncidentParticipantLevel,
	IncidentStatus,
	MediaType,
	useIncidentByHinterQuery,
	useNotesForHinterQuery,
} from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import {
	PGPRecipient,
	useCryptSensitiveStore,
	useCryptStore,
	usePGPDecrypt,
} from '../../hooks/useCrypt';
import { MasterKeyPasswordModal } from '../util/MasterKeyPasswordModal';
import { FeatureToggle } from '../util/FeatureFlags';
import { Descriptions } from '../util/Descriptions';
import Title from 'antd/lib/typography/Title';
import { MediaListItem } from '../util/MediaThumbnail';
import {
	useIncidentCategoryTranslation,
	useIncidentStatusTranslation,
} from '../../hooks/useEnumTranslation';
import { NoteCard } from '../AdminPortal/Note';
import { IdlePrompt } from '../util/IdlePrompt';
import { PendingInviteUserRequests } from '../util/PendingIncidentInviteUser';
import { Time } from '../util/Time';
import { UserCard } from '../util/UserCard';
import { Transcription } from '../Chat/Transcription';

type HinterIncidentOverviewProps = {
	channelId: string;
}; // use to redirect back to correct channel upon logout. Or to render in correct channel theming

const HinterIncidentOverview = ({ channelId }: HinterIncidentOverviewProps) => {
	const { t } = useTranslation('translations');
	const translateCategory = useIncidentCategoryTranslation();
	const translateStatus = useIncidentStatusTranslation();
	const session = useAuthStore((state) => state.session);
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const publicKey = useCryptStore((state) => state.publicKey);
	const privateKey = useCryptSensitiveStore((state) => state.privateKey);
	const masterKey = useCryptSensitiveStore((state) => state.masterKey);
	const logout = useLogout();
	const [decryptError, setDecryptError] = useState(false);

	let hinter = (session as HinterSession)?.hinter;
	const { data, isLoading, isError, error, refetch } = useIncidentByHinterQuery(
		{
			hinterId: hinter ? hinter.id : 0,
		},
		{ enabled: isAuthenticated },
	);
	const { data: notesData } = useNotesForHinterQuery(
		{ hinterId: hinter?.id ?? 0 },
		{ enabled: isAuthenticated && hinter != null },
	);

	const navigate = useNavigate();

	const chatParticipants = useMemo<ChatProps['participants']>(
		() =>
			data?.incidentByHinter.recipients.map((r) => {
				if (r.user && r.level === IncidentParticipantLevel.Caseworker)
					return {
						id: r.id,
						level: r.level,
						user: {
							id: r.user.id,
							firstname: r.user.surname ?? '',
							lastname: r.user.lastname ?? '',
						},
					};
				else
					return {
						id: r.id,
						level: r.level,
						hinter: data.incidentByHinter.hinter?.anonymous
							? {
									lastname: t('AnonymousPerson'),
									firstname: '',
							  }
							: {
									firstname: data.incidentByHinter.hinter.firstname ?? '',
									lastname: data.incidentByHinter.hinter.lastname ?? '',
							  },
					};
			}) ?? [],
		[data],
	);

	React.useEffect(() => {
		if (session?.role === Roles[Roles.USER]) logout(t('LogoutReason.Role'));
		if (data?.incidentByHinter.status === IncidentStatus.Draft) {
			logout(t('LogoutReason.Role'));
			navigate('/portal/' + channelId);
		}
	}, [session, logout]);

	React.useEffect(() => {
		// if (!isAuthenticated && (role === undefined || role === Roles.USER)) {
		if (!isAuthenticated) {
			console.log(`isAuthenticated: ${isAuthenticated}. Redirecting to login...`);
			navigate('/portal/' + channelId);
		}
	}, [isAuthenticated, navigate, channelId]);

	const [decrypted, setDecrypted] = useState<{
		subject: string;
		description: string;
	} | null>(null);
	const [recipients, setRecipients] = useState<PGPRecipient[]>([]);
	const { ready: decryptReady, decrypt } = usePGPDecrypt(
		recipients,
		publicKey,
		undefined,
	);

	useEffect(() => {
		if (data) {
			const incident = data.incidentByHinter;
			setRecipients(
				incident.encryptionVersion > 0 &&
					incident.recipients.map((r) => r.encryptionVersion > 0).reduce((p, n) => p && n)
					? (incident.recipients as PGPRecipient[])
					: [],
			);
		}
	}, [data]);

	useEffect(() => {
		if ((data?.incidentByHinter.encryptionVersion !== 0 && !decryptReady) || !data)
			return;
		if (data?.incidentByHinter.encryptionVersion === 0)
			setDecrypted({
				subject: data.incidentByHinter.subject,
				description: data.incidentByHinter.description,
			});
		else
			(async () => {
				try {
					setDecrypted({
						subject: await decrypt('utf-8', data.incidentByHinter.subject),
						description: await decrypt('utf-8', data.incidentByHinter.description),
					});
				} catch (err) {
					setDecryptError(true);
				}
			})();
	}, [data, decrypt, decryptReady]);

	if (isError) {
		return (
			<Result
				status={
					(error as any)?.response?.errors?.at(0)?.extensions?.response?.statusCode ??
					'error'
				}
				title={t('HinterIncidentOverview.Alert.Message')}
				extra={<Button onClick={() => logout()}>{t('Logout')}</Button>}
			/>
		);
	} else if (isLoading || !data) {
		return <Spin />;
	}
	return (
		<Space size="large" direction="vertical">
			<IdlePrompt timeoutMinutes={15} promptTimeoutMinutes={5} />
			<FeatureToggle features={[FeatureNames.E2EEncryption]}>
				<MasterKeyPasswordModal />
			</FeatureToggle>
			{data.incidentByHinter.status === IncidentStatus.Closed && (
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Typography.Title level={3}>
							{t('HinterIncidentOverview.ClosedMessage')}
						</Typography.Title>
					</Col>
					{notesData?.notesForHinter.map((n) => (
						<Col key={n.id}>
							<NoteCard
								locked={true}
								id={n.id}
								noteData={n}
								onChange={() => {}}
								onRemove={() => {}}
							/>
						</Col>
					))}
				</Row>
			)}
			{data.incidentByHinter.status === IncidentStatus.Rejected && (
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Typography.Title level={3}>
							{t('HinterIncidentOverview.RejectedMessage')}
						</Typography.Title>
					</Col>
					{notesData?.notesForHinter.map((n) => (
						<Col key={n.id}>
							<NoteCard
								locked={true}
								id={n.id}
								noteData={n}
								onChange={() => {}}
								onRemove={() => {}}
								style={{ maxWidth: '900px' }}
							/>
						</Col>
					))}
				</Row>
			)}
			<Row justify="space-between" align="middle">
				<Col>
					<Title level={3}>
						{decrypted
							? decrypted.subject
							: decryptError
							? 'Decryption error'
							: 'Decrypting incident...'}
					</Title>
				</Col>
				<Col>
					<Button onClick={() => logout()}>
						<LockOutlined />
						{t('Logout')}
					</Button>
				</Col>
			</Row>
			<Row>
				<Col>
					<PendingInviteUserRequests
						requests={data.incidentByHinter.joinRequests}
						forHinter
						onUpdate={() => refetch()}
					/>
				</Col>
			</Row>
			<Row>
				<Descriptions
					size="small"
					layout="horizontal"
					column={1}
					style={{ textAlign: 'left', width: '100%' }}
				>
					<Descriptions.Item label={t('Category')}>
						{translateCategory(data.incidentByHinter.category)}
					</Descriptions.Item>
					<Descriptions.Item label={t('Submitted')}>
						<Time date={data.incidentByHinter.created} />
					</Descriptions.Item>
					<Descriptions.Item label={t('UpdatedAt')}>
						<Time mode="relative" date={data.incidentByHinter.updated} />
					</Descriptions.Item>
					<Descriptions.Item label={t('Status')}>
						{translateStatus(data.incidentByHinter.status)}
					</Descriptions.Item>
					<Descriptions.Item>
						<Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
							{decrypted ? decrypted.description : 'Decrypting...'}
						</Typography.Text>
					</Descriptions.Item>
				</Descriptions>
			</Row>
			{data.incidentByHinter.channel.config.showRecipients && (
				<>
					<Title level={4}>{t('HinterIncidentOverview.Participants')}</Title>
					<Row style={{ flex: 'auto' }}>
						<Space>
							{data.incidentByHinter.recipients.map(
								(r, i) =>
									r.user && <UserCard size="small" key={r.user.id} user={r.user} />,
							)}
						</Space>
					</Row>
				</>
			)}
			{data.incidentByHinter.medias?.length ? (
				<>
					<Title level={4}>{t('HinterIncidentOverview.AttachmentsTitle')}</Title>
					<Row gutter={[16, 16]}>
						{data.incidentByHinter.medias?.map((m, i) => (
							<Col key={i}>
								{m.type === MediaType.Audio ? (
									<Transcription
										side="hinter"
										incidentId={data.incidentByHinter.id}
										media={m}
									>
										<MediaListItem key={i} {...m} size={48} />
									</Transcription>
								) : (
									<MediaListItem key={i} {...m} size={48} />
								)}
							</Col>
						))}
					</Row>
				</>
			) : null}
			<Row>
				<Divider />
				<Title level={4}>{t('HinterIncidentOverview.Chat.Title')}</Title>
			</Row>
			<Row justify="center" align="middle" style={{ width: '100%', minHeight: '240px' }}>
				<Col style={{ width: '100%' }}>
					<Chat
						side="hinter"
						incidentId={data.incidentByHinter.id as number}
						style={{ maxHeight: '40vh' }}
						participants={chatParticipants}
					/>
				</Col>
			</Row>
		</Space>
	);
};

export default HinterIncidentOverview;
