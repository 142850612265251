import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { RedirectContext } from './RedirectContext';
import create from 'zustand';
import { configurePersist } from '@onlyknoppas/zustand-persist';
import { produce } from 'immer';
import { isRedirectContext } from './RedirectContext.guard';

const { persist } = configurePersist({
	storage: localStorage,
	rootKey: 'context',
});

export interface RedirectStore {
	context?: RedirectContext;
	target?: string;
	setContextFromJson: (context: any) => boolean;
	clearCheckoutContext: () => void;
	clearJoinCompanyContext: () => void;
	clearUserContext: () => void;
	clearJoinIncidentRequestContext: () => void;
	setTarget: (target: string) => void;
}

export const useRedirectContextStore = createSelectorHooks(
	create<RedirectStore>(
		persist(
			{
				key: 'redirect',
				denylist: [],
			},
			(set) => ({
				setContextFromJson: (context: any) => {
					if (!isRedirectContext(context)) {
						console.log('redirect context object had invalid schema');
						return false;
					}
					set({ context });
					return true;
				},
				setTarget: (target) => set({ target }),
				clearCheckoutContext: () =>
					set(
						produce((state: RedirectStore) => {
							if (state.context) state.context.checkout = undefined;
						}),
					),
				clearJoinCompanyContext: () =>
					set(
						produce((state: RedirectStore) => {
							if (state.context) state.context.joinCompany = undefined;
						}),
					),
				clearUserContext: () =>
					set(
						produce((state: RedirectStore) => {
							if (state.context) state.context.user = undefined;
						}),
					),
				clearJoinIncidentRequestContext: () =>
					set(
						produce((state: RedirectStore) => {
							if (state.context) state.context.joinIncidentRequest = undefined;
						}),
					),
			}),
		),
	),
);
