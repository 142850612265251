import {
	CryptographyKey,
	SodiumPlus,
	X25519PublicKey,
	X25519SecretKey,
} from 'sodium-plus';

export type Encrypted = {
	nonce: Buffer;
	ciphertext: Buffer;
};

export type EncryptedBase64 = {
	nonce: string;
	ciphertext: string;
};

export type PublicKey = X25519PublicKey;
export type PrivateKey = X25519SecretKey;

export function encryptedToBase64(encrypted: Encrypted) {
	return {
		nonce: encrypted.nonce.toString('base64'),
		ciphertext: encrypted.ciphertext.toString('base64'),
	};
}

export function encryptedFromBase64(encrypted: EncryptedBase64) {
	return {
		nonce: Buffer.from(encrypted.nonce, 'base64'),
		ciphertext: Buffer.from(encrypted.ciphertext, 'base64'),
	};
}

export function serializeEncrypted(encrypted: Encrypted) {
	return JSON.stringify(encryptedToBase64(encrypted));
}

export function deserializeEncrypted(encrypted: string) {
	return encryptedFromBase64(JSON.parse(encrypted));
}

export function publicKeyToBase64(key: X25519PublicKey) {
	return key.getBuffer().toString('base64');
}

export function privateKeyToBase64(key: X25519SecretKey) {
	return key.getBuffer().toString('base64');
}

export function publicKeyFromBase64(key: string) {
	return new X25519PublicKey(Buffer.from(key, 'base64'));
}

export function privateKeyFromBase64(key: string) {
	return new X25519SecretKey(Buffer.from(key, 'base64'));
}

export async function generateKeyPair(sodium: SodiumPlus) {
	const keypair = await sodium.crypto_box_keypair();
	return {
		secretKey: await sodium.crypto_box_secretkey(keypair),
		publicKey: await sodium.crypto_box_publickey(keypair),
	};
}

export async function generateSymmetricKey(sodium: SodiumPlus) {
	return await sodium.crypto_secretbox_keygen();
}

export async function encryptSym(
	sodium: SodiumPlus,
	key: CryptographyKey,
	message: string | Buffer,
): Promise<Encrypted> {
	const nonce = await sodium.randombytes_buf(24);
	const ciphertext = await sodium.crypto_secretbox(message, nonce, key);
	return { nonce, ciphertext };
}

export async function decryptSym(
	sodium: SodiumPlus,
	key: CryptographyKey,
	encrypted: Encrypted,
) {
	return await sodium.crypto_secretbox_open(encrypted.ciphertext, encrypted.nonce, key);
}

export async function encryptAsym(
	sodium: SodiumPlus,
	senderPrivateKey: PrivateKey,
	receiverPublicKey: PublicKey,
	message: string | Buffer,
): Promise<Encrypted> {
	const nonce = await sodium.randombytes_buf(24);
	const ciphertext = await sodium.crypto_box(
		message,
		nonce,
		senderPrivateKey,
		receiverPublicKey,
	);
	return { nonce, ciphertext };
}

export async function decryptAsym(
	sodium: SodiumPlus,
	receiverPrivateKey: X25519SecretKey,
	senderPublicKey: X25519PublicKey,
	encrypted: Encrypted,
) {
	return await sodium.crypto_box_open(
		encrypted.ciphertext,
		encrypted.nonce,
		receiverPrivateKey,
		senderPublicKey,
	);
}

export async function encryptAsymMultiple(
	sodium: SodiumPlus,
	senderPrivateKey: PrivateKey,
	receiverPublicKeys: PublicKey[],
	message: string | Buffer,
): Promise<[string, Encrypted][]> {
	return await Promise.all(
		Object.entries(receiverPublicKeys).map<Promise<[string, Encrypted]>>(
			async ([id, k]) => [
				k.getBuffer().toString('base64'),
				await encryptAsym(sodium, senderPrivateKey, k, message),
			],
		),
	);
}

export async function pgpEncrypt(
	sodium: SodiumPlus,
	senderPrivateKey: PrivateKey,
	receiverPublicKeys: PublicKey[],
	messages: string[] | Buffer[],
) {
	const key = await generateSymmetricKey(sodium);
	const encryptedKeys = await encryptAsymMultiple(
		sodium,
		senderPrivateKey,
		receiverPublicKeys,
		key.getBuffer(),
	);
	const encryptedMessages = await Promise.all(
		messages.map((m) => encryptSym(sodium, key, m)),
	);
	return { encryptedMessages, encryptedKeys };
}

export async function pgpDecrypt(
	sodium: SodiumPlus,
	encrypted: Encrypted[],
	encryptedKey: Encrypted,
	receiverSecretKey: PrivateKey,
	senderPublicKey: PublicKey,
): Promise<Buffer[]> {
	const key = new CryptographyKey(
		await decryptAsym(sodium, receiverSecretKey, senderPublicKey, encryptedKey),
	);
	const decrypted = await Promise.all(encrypted.map((e) => decryptSym(sodium, key, e)));
	return decrypted;
}

export async function createMasterKey(
	sodium: SodiumPlus,
	password: string,
): Promise<[CryptographyKey, Buffer]> {
	const salt = await sodium.randombytes_buf(16);
	const key = await deriveMasterKey(sodium, password, salt);
	return [key, salt];
}

export async function deriveMasterKey(
	sodium: SodiumPlus,
	password: string,
	salt: Buffer,
): Promise<CryptographyKey> {
	const key = await sodium.crypto_pwhash(
		32,
		password,
		salt,
		sodium.CRYPTO_PWHASH_OPSLIMIT_INTERACTIVE,
		sodium.CRYPTO_PWHASH_MEMLIMIT_INTERACTIVE,
	);
	return key;
}

export async function derivePrivateKeyEncryptionKey(
	sodium: SodiumPlus,
	masterKey: CryptographyKey,
) {
	const key = await sodium.crypto_kdf_derive_from_key(32, 1, 'privatek', masterKey);
	return key;
}

export async function encryptPrivateKeyWithPassword(
	sodium: SodiumPlus,
	privateKey: CryptographyKey,
	password: string,
	salt: Buffer,
): Promise<Encrypted> {
	const masterKey = await deriveMasterKey(sodium, password, salt);
	// const key = await derivePrivateKeyEncryptionKey(sodium, masterKey);
	const nonce = await sodium.randombytes_buf(24);
	const encryptedPrivateKey = {
		nonce,
		ciphertext: await sodium.crypto_secretbox(privateKey.getBuffer(), nonce, masterKey),
	};
	return encryptedPrivateKey;
}

export async function decryptPrivateKeyWithPassword(
	sodium: SodiumPlus,
	encryptedPrivateKey: { nonce: string; ciphertext: string },
	password: string,
) {
	const key = await derivePrivateKeyEncryptionKey(
		sodium,
		new CryptographyKey(Buffer.from(password, 'utf-8')),
	);
	const { nonce, ciphertext } = encryptedPrivateKey;
	const privateKey = await sodium.crypto_secretbox_open(
		Buffer.from(ciphertext, 'base64'),
		Buffer.from(nonce, 'base64'),
		key,
	);
	return privateKey;
}

export async function generatePassword(sodium: SodiumPlus): Promise<string> {
	return (await sodium.randombytes_buf(8)).toString('base64');
}
