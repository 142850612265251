import { Col, Row, Space, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAuthStore, useLogin, UserSession } from '../../hooks/useAuth';
import { PageAnimation } from '../util/Animation';
import { Logo } from '../util/Logo';
import CodeInput from 'react-code-input';
import { Alert } from '../util/Alert';
import { useEffect, useState } from 'react';
import { Card } from '../util/Card';
import { Button } from '../util/Button';
import { isPast, add } from 'date-fns';
import { useConfirm2FaMutation } from '../../generated/graphql';
import { useFormattedCountdown } from '../../hooks/useCountdown';

export const TwoFactorAuthForm = (props: {
	toLogin: () => void;
	onSuccess: () => void;
}) => {
	const { t } = useTranslation('translations');
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
	const [code, setCode] = useState('');
	const session = useAuthStore((state) => state.session);
	const { formatted: timeLeft, millis } = useFormattedCountdown(
		session?.session2FA?.expires,
	);
	const [confirm2FACode, isConfirm2FACodeLoading] = useLogin().confirm2FaCode;

	useEffect(() => {
		if (session?.session2FA == null || isPast(new Date(session.session2FA.expires)))
			props.toLogin();
	}, [session?.session2FA?.expires]);

	const onCodeComplete = async (code: string) => {
		if (!session?.session2FA) {
			setErrorMessage(t('2FA.Error.Internal'));
			return;
		} else {
			const res = await confirm2FACode(session?.session2FA?._uuid, code);
			if (res.success) props.onSuccess();
			else setErrorMessage(res.message);
		}
	};

	return (
		<Space direction="vertical" size="middle">
			<Typography.Text>
				{t('2FA.Description', { email: (session as UserSession).user?.email })}
			</Typography.Text>
			<Card hoverable={false}>
				<Space
					direction="vertical"
					size="middle"
					style={{ width: '100%' }}
					align="center"
				>
					{errorMessage && <Alert type="error" description={errorMessage} />}
					<Typography.Text>
						{t('2FA.ExpiresIn', { count: millis, timeLeft })}
					</Typography.Text>
					<CodeInput
						value={code}
						onChange={(v) => {
							setCode(v);
							v.length === 6 && onCodeComplete(v);
						}}
						type="text"
						fields={6}
						inputMode="numeric"
						name="CodeInput"
						disabled={isConfirm2FACodeLoading}
					/>
					<Spin spinning={isConfirm2FACodeLoading} />
				</Space>
			</Card>

			<Row style={{ marginTop: '16px' }}>
				<Col>
					<Button type="text" size="large" onClick={props.toLogin}>
						{t('Back')}
					</Button>
				</Col>
			</Row>
		</Space>
	);
};
