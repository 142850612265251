import React from 'react';
import { Form } from 'antd';
import { Select } from '../util/Select';
import { Input } from '../util/Input';
import { useTranslation } from 'react-i18next';
import { useCountriesQuery } from '../../generated/graphql';
import { CountrySelect } from '../util/EnumSelect';

export const AddressFormItem = ({ name }: { name: string }) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'AddressFormItem' });
	const { data } = useCountriesQuery();

	return (
		<Form.Item rules={[{ required: true }]} label={tt('Label')}>
			<Form.Item
				name={name ? [name, 'recipientName'] : 'recipientName'}
				label={tt('Name')}
				rules={[
					{
						required: true,
						message: t('FieldIsMissing', { fieldName: tt('Name') }),
					},
				]}
			>
				<Input maxLength={255} placeholder={tt('Name')} />
			</Form.Item>
			<Form.Item>
				<Input.Group compact>
					<Form.Item
						name={name ? [name, 'city'] : 'city'}
						noStyle
						rules={[
							{
								required: true,
								message: t('FieldIsMissing', { fieldName: tt('City') }),
							},
						]}
					>
						<Input maxLength={255} style={{ width: '60%' }} placeholder={tt('City')} />
					</Form.Item>
					<Form.Item
						name={name ? [name, 'postcode'] : 'postcode'}
						noStyle
						rules={[
							{
								required: true,
								message: t('FieldIsMissing', { fieldName: tt('Postcode') }),
							},
						]}
					>
						<Input maxLength={10} style={{ width: '40%' }} placeholder={tt('Postcode')} />
					</Form.Item>
				</Input.Group>
			</Form.Item>
			<Form.Item>
				<Input.Group compact>
					<Form.Item
						name={name ? [name, 'street'] : 'street'}
						noStyle
						rules={[
							{
								required: true,
								message: t('FieldIsMissing', { fieldName: tt('Street') }),
							},
						]}
					>
						<Input maxLength={255} style={{ width: '75%' }} placeholder={tt('Street')} />
					</Form.Item>
					<Form.Item
						name={name ? [name, 'housenumber'] : 'housenumber'}
						noStyle
						rules={[
							{
								required: true,
								message: t('FieldIsMissing', { fieldName: tt('Housenumber') }),
							},
						]}
					>
						<Input
							maxLength={255}
							style={{ width: '25%' }}
							placeholder={tt('Housenumber')}
						/>
					</Form.Item>
				</Input.Group>
			</Form.Item>
			<Form.Item
				name={name ? [name, 'country'] : 'country'}
				rules={[
					{ required: true, message: t('FieldIsMissing', { fieldName: tt('Country') }) },
				]}
			>
				<CountrySelect placeholder={tt('Country')}></CountrySelect>
			</Form.Item>
		</Form.Item>
	);
};
