import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

function CompanyRemovedInfo({
	name,
	message,
	backRoute,
}: {
	name: string;
	message?: string | null;
	backRoute: string;
}) {
	const { t } = useTranslation(undefined, { keyPrefix: 'CompanyRemovedInfo' });
	const navigate = useNavigate();

	return (
		<Result status="404" subTitle={t('SubTitle', { name })} title={t('Title')}>
			<p style={{ whiteSpace: 'pre' }}>{message}</p>
			<Button onClick={() => navigate(backRoute)}>{t('Back')}</Button>
		</Result>
	);
}

export default CompanyRemovedInfo;
