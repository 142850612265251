import { DatePicker as AntdPicker, DatePickerProps } from 'antd';
import './DatePicker.less';

export const DatePicker = (props: DatePickerProps) => {
	return (
		<div className="Picker">
			<AntdPicker {...props} />
		</div>
	);
};
