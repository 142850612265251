import { getCookieValue } from '../lib/util';
import { useState } from 'react';
import axios, { AxiosRequestHeaders } from 'axios';
import { DocumentNode } from 'graphql';
import { GraphQLClient, request } from 'graphql-request';
import { MutationOptions, useMutation, useQuery } from 'react-query';
import { useAuthStore } from './useAuth';
import FormData from 'form-data';
import sum from 'hash-sum';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { captureException } from '@sentry/react';

const API_URL =
	(process.env.REACT_APP_BASENAME ?? '/') + process.env.REACT_APP_API_PATH + '/graphql';

export function getGqlString(doc: DocumentNode) {
	return doc.loc && doc.loc.source.body;
}

const graphQLClient = new GraphQLClient(API_URL, {
	credentials: 'include',
	mode: 'cors',
});

export const useHttpStatusHandler = () => {
	const { t } = useTranslation('translations');
	const emitSignalLogout = useAuthStore.useEmitSignalLogout();
	const isAuthenticated = useAuthStore.useIsAuthenticated();
	return (status: number | undefined) => {
		console.log('HTTP ERROR ' + status);
		switch (status) {
			case undefined:
				break;
			case 401:
				isAuthenticated && emitSignalLogout(t('LogoutReason.401'));
				break;
		}
	};
};

export const useGQLErrorHandler = () => {
	const emitSignalLogout = useAuthStore.useEmitSignalLogout();
	const isAuthenticated = useAuthStore.useIsAuthenticated();
	const { t } = useTranslation('translations');

	const handleSingleError = (err: any) => {
		if (err.extensions.response) {
			switch (err.extensions.response.statusCode) {
				case undefined:
					break;
				case 401:
					isAuthenticated && emitSignalLogout(t('LogoutReason.401'));
					break;
			}
		}
		switch (err.extensions.code) {
			case 'INTERNAL_SERVER_ERROR':
				// do something with it
				break;
		}
		Sentry.captureException(err);
	};

	return (error: any) => {
		if (error.response) {
			error.response.errors.forEach(handleSingleError);
		} else if (error.length > 0) {
			error.forEach(handleSingleError);
		} else {
			Sentry.captureException('useGQLErrorHandler unexpected input', {
				extra: { error },
			});
		}
	};
};

export const useGQLQuery = (
	key: string,
	query: any,
	variables = {},
	queryOptions: any = {},
) => {
	const { isAuthenticated, session } = useAuthStore();

	const headers: HeadersInit = {};
	if (isAuthenticated && session?.token)
		headers.Authorization = 'Bearer ' + session.token;
	if (isAuthenticated && session?.csrfToken) headers['x-csrf-token'] = session?.csrfToken;

	const handleErrors = useGQLErrorHandler();
	queryOptions = {
		...queryOptions,
		refetchWindowFocus: false,
		staleTime: 1000,
		onError: (error: any) => {
			handleErrors(error);
		},
	};

	const fetch = async () => await graphQLClient.request(query, variables, headers);
	const qKey = [key, sum(variables)];
	return useQuery(qKey, fetch, queryOptions);
};

export type GQLError = {
	response: {
		errors: {
			message: string;
			extensions?: {
				code: string;
				response: { statusCode: number; message: string; error: string };
			};
		}[];
	};
};

export const useFetchGQL = <TData, TVariables>(
	query: string,
	options?: HeadersInit,
): ((variables?: TVariables) => Promise<TData>) => {
	const { isAuthenticated, session } = useAuthStore();

	const headers: HeadersInit = {};
	if (isAuthenticated && session?.token)
		headers.Authorization = 'Bearer ' + session.token;
	if (isAuthenticated && session?.csrfToken) headers['x-csrf-token'] = session?.csrfToken;

	const handleErrors = useGQLErrorHandler();

	return async (variables?: TVariables) => {
		try {
			return await graphQLClient.request<TData, TVariables>(query, variables, headers);
		} catch (error) {
			handleErrors(error);
			throw error;
		}
	};
};

export const useGQLMutation = <VarsType>(
	query: any,
	mutationOptions: MutationOptions<any, unknown, VarsType> = {},
) => {
	const { isAuthenticated, session } = useAuthStore();
	const endpoint = API_URL;

	const headers: HeadersInit = {};
	if (isAuthenticated && session?.token)
		headers.Authorization = 'Bearer ' + session.token;
	if (isAuthenticated && session?.csrfToken) headers['x-csrf-token'] = session?.csrfToken;

	const handleHttpStatus = useHttpStatusHandler();
	mutationOptions = {
		...mutationOptions,
		onError: (err: any, vars: VarsType, context: unknown) => {
			handleHttpStatus(err.response?.satus);
		},
	};

	const mutate = async (variables: VarsType) => {
		const res = await graphQLClient.request(query, variables, headers);
		if (res.data.errors && res.data.errors.length > 0) {
			throw new Error(res.data.errors[0]);
		}
		return res;
	};
	return useMutation(mutate, mutationOptions);
};

export const useGQLMutationWithFiles = <
	VarsType,
	TData extends { data?: any; errors?: any },
>(
	query: any,
	mutationOptions: MutationOptions<TData, unknown, [VarsType, Map<string, File>]> = {},
) => {
	const { session, isAuthenticated } = useAuthStore();
	const endpoint = API_URL;
	const [progress, setProgress] = useState(0);

	const headers: AxiosRequestHeaders = {};
	if (isAuthenticated && session?.token)
		headers.Authorization = 'Bearer ' + session.token;
	if (isAuthenticated && session?.csrfToken) headers['x-csrf-token'] = session?.csrfToken;
	headers['Content-Type'] = 'multipart/form-data';

	const handleHttpStatus = useHttpStatusHandler();
	mutationOptions = {
		...mutationOptions,
		onError: (err: any, vars, context: unknown) => {
			handleHttpStatus(err.response?.satus);
		},
	};

	const mutate = async (variables: [VarsType, Map<string, File>]) => {
		const files = variables[1];
		const form = new FormData();
		form.append('operations', JSON.stringify({ query, variables: variables[0] }));
		const map = new Map();
		const filesMap = new Map();
		let i = 0;
		files.forEach((file, k) => {
			map.set('' + i, [k]);
			filesMap.set('' + i, file);
			i += 1;
		});
		form.append('map', JSON.stringify(Object.fromEntries(map)));
		filesMap.forEach((v, k) => {
			form.append(k, v);
		});
		const res = await axios.post<TData>(endpoint, form, {
			headers,
			withCredentials: true,
			onUploadProgress: (e) => setProgress(Math.round((e.loaded * 100) / e.total)),
		});
		if (res.data.errors && res.data.errors.length > 0) {
			console.error(res.data.errors[0]);
			captureException(res.data.errors[0]);
		}
		return res.data;
	};
	return { progress, ...useMutation(mutate, mutationOptions) };
};
