import { CloseOutlined } from '@ant-design/icons';
import { message, Input } from 'antd';
import { useRef, useState } from 'react';
import { HexColorPicker, RgbaColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import { Button } from '../util/Button';
import './ColorInput.less';
import { validateHex } from '../../lib/util';
import { useOnOutsideClick } from '../../hooks/useOnOutsideClick';
import { DesignConfig } from '../HinterPortal/ChannelEditor';

export type ColorInputProps = {
	color: string;
	onChange: (color: string) => void;
	type?: 'hex' | 'rgba';
};

// parse rgba color string: rgba(0, 0, 0, 0.1) to {r: 0, g: 0, b: 0, a: 0.1}
function parseRgba(rgba: string) {
	const regex = /rgba?\((?<r>\d+),\s*(?<g>\d+),\s*(?<b>\d+),\s*(?<a>\d+\.?\d*)\)/;
	const match = rgba.match(regex);
	if (match == null)
		return {
			r: 0,
			g: 0,
			b: 0,
			a: 1,
		};
	return {
		r: parseInt(match.groups?.r ?? '0'),
		g: parseInt(match.groups?.g ?? '0'),
		b: parseInt(match.groups?.b ?? '0'),
		a: parseFloat(match.groups?.a ?? '1'),
	};
}

function stringifyRbga(rgba: ReturnType<typeof parseRgba>) {
	return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}

// colorpicker component that uses antd input in combination with react-colorful color picker
// the antd input allows to edit the color as a hex value
// the input gets an addonafter component that shows the color as a square
// clicking on the color square opens the color picker
// the input should be validated with a regex to only allow hex values
export const ColorInput = ({ color, onChange, type = 'hex' }: ColorInputProps) => {
	const { t } = useTranslation('translations', { keyPrefix: 'ColorInput' });
	const [showPicker, setShowPicker] = useState(false);

	const ref = useRef<HTMLDivElement>(null);

	useOnOutsideClick(ref, () => setShowPicker(false));

	const handleHex = (color: string) => {
		if (!color.startsWith('#')) color = `#${color}`;
		if (color.length > 7) return;
		// regex to test for characters that are not allowed in a hex value
		const regex = /#[^0-9a-fA-F]/;
		if (regex.test(color)) message.error(t('InvalidHex'));
		else onChange(color);
	};

	const handleColorChange = (color: string) => {
		if (type === 'hex') handleHex(color);
		else onChange(color);
	};

	return (
		<div className="color-input" ref={ref}>
			<Input
				value={type === 'hex' ? color.replace('#', '').toUpperCase() : color}
				onChange={(e) => handleColorChange(e.target.value)}
				addonBefore={type === 'hex' ? '#' : null}
				addonAfter={
					<div
						className="color-input__color"
						style={{ backgroundColor: color }}
						onClick={() => setShowPicker(!showPicker)}
					/>
				}
			/>
			{showPicker && (
				<div className="color-input__picker">
					{type === 'hex' ? (
						<HexColorPicker color={color} onChange={handleHex} />
					) : (
						<RgbaColorPicker
							color={parseRgba(color)}
							onChange={(c) => handleColorChange(stringifyRbga(c))}
						/>
					)}
				</div>
			)}
		</div>
	);
};
