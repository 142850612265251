import enGB from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import pt from 'date-fns/locale/pt';
import el from 'date-fns/locale/el';
import es from 'date-fns/locale/es';
import lt from 'date-fns/locale/lt';
import lv from 'date-fns/locale/lv';
import da from 'date-fns/locale/da';
import nl from 'date-fns/locale/nl';
import sv from 'date-fns/locale/sv';
import is from 'date-fns/locale/is';
import cs from 'date-fns/locale/cs';
import pl from 'date-fns/locale/pl';
import ro from 'date-fns/locale/ro';
import nb from 'date-fns/locale/nb';
import it from 'date-fns/locale/it';
import hr from 'date-fns/locale/hr';
import hu from 'date-fns/locale/hu';
import et from 'date-fns/locale/et';
import uk from 'date-fns/locale/uk';
import tr from 'date-fns/locale/tr';

const DATE_FNS_LOCALES = {
	en: enGB,
	enGB,
	de,
	fr,
	pt,
	el,
	es,
	lt,
	lv,
	da,
	nl,
	sv,
	is,
	cs,
	pl,
	ro,
	no: nb,
	nb,
	it,
	hr,
	hu,
	et,
	uk,
	tr,
};

export default DATE_FNS_LOCALES;
