import { captureException } from '@sentry/react';
import { Result } from 'antd';

export const GqlErrorResult = ({ error }: any) => {
	const errors = error.response.errors;
	if (!errors?.length) {
		captureException('GqlErrorResult unexpected input', {
			extra: { error },
		});
		return (
			<Result
				status="error"
				subTitle="An error occurred. Please email admin@konfidal.eu"
			></Result>
		);
	}
	const code = '' + errors[0]?.extensions?.response?.statusCode;
	const message = error.response.errors[0].message;
	const status: '404' | '403' | '500' | 'error' =
		code === '404' || code === '403' || code === '500' ? code : 'error';
	return <Result status={status} subTitle={message}></Result>;
};
