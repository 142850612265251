import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BaseJoyride, { Props } from 'react-joyride';

export const Joyride = (props: Props) => {
	const { t } = useTranslation('translations');
	const locale = {
		previous: t('Onboarding.Previous'),
		next: t('Onboarding.Next'),
		gotIt: t('Onboarding.GotIt') + '!',
	};
	const btnStyle = {
		borderRadius: '16px',
		backgroundColor: 'white',
		color: 'rgba(0, 0, 0, 0.85)', // '#127ABA',
		borderStyle: 'none',
		borderWidth: '0px',
		paddingTop: 12,
		paddingLeft: 16,
		paddingBottom: 12,
		paddingRight: 16,
		fontSize: '16px',
		fontWeight: 'bold',
		fontFamily: 'Inter',
		boxShadow: '0 3px 12px -0px rgb(0 0 0 / 0.08)',
	};
	return (
		<BaseJoyride
			locale={locale}
			styles={{
				spotlight: { borderRadius: '16px' },
				tooltip: { borderRadius: '16px' },
				buttonBack: { ...btnStyle, fontWeight: 'normal' },
				buttonNext: btnStyle,
				buttonSkip: btnStyle,
			}}
			{...props}
		/>
	);
};
