import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.less';
import 'antd-onboarding/assets/index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import i18n from './i18n';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from '@onlyknoppas/zustand-persist';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallbackWithReset } from './components/util/ErrorFallback';
import { Spin } from 'antd';
import { enableMapSet } from 'immer';
import { LoadingOutlined } from '@ant-design/icons';
import sum from 'hash-sum';
import { isEmpty } from 'lodash';

enableMapSet();

if (process.env.REACT_APP_ENVIRONMENT !== 'local')
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
		environment: process.env.REACT_APP_ENVIRONMENT,
		release: process.env.REACT_APP_VERSION,
	});

declare global {
	var queryClient: QueryClient;
}

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			cacheTime: 1000 * 60 * 10,
			staleTime: 1000 * 30,
			queryKeyHashFn: (queryKey) => {
				let hash;
				// if the query key has format [keyName, variablesObject]
				if (Array.isArray(queryKey)) {
					if (queryKey.length === 1) {
						hash = sum(queryKey[0]);
					} else if (queryKey.length > 1) {
						// and the variables object is empty
						if (
							queryKey[1] === null ||
							queryKey[1] === undefined ||
							isEmpty(queryKey[1])
						) {
							// then use the key name as the query key
							// so that useQuery(), useQuery({}) or useQuery(undefined) will be treated as the same query
							hash = sum(queryKey[0]);
						}
					}
				}
				if (hash != null) return hash;
				else return sum(queryKey);
			},
		},
	},
});
globalThis.queryClient = queryClient;

ReactDOM.render(
	<ErrorBoundary
		FallbackComponent={ErrorFallbackWithReset}
		onReset={() => {
			// eslint-disable-next-line no-restricted-globals
			location.reload();
			console.log('reset error boundary');
		}}
	>
		<QueryClientProvider client={queryClient}>
			<Suspense fallback={<LoadingOutlined />}>
				<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
					<PersistGate loading={<Spin />} onBeforeLift={() => console.log('Gate lift')}>
						<App />
					</PersistGate>
				</BrowserRouter>
			</Suspense>
		</QueryClientProvider>
	</ErrorBoundary>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
