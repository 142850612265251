import { Form, Checkbox, Steps, Row, Typography, Alert, Space, Col, message } from 'antd';
import { Input } from '../util/Input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelfQuery, useUpdateUserMutation } from '../../generated/graphql';
import { Roles, useAuthStore } from '../../hooks/useAuth';
import { useManageMasterKey } from '../../hooks/useCrypt';
import { getLang } from '../util';
import { Button } from '../util/Button';
import { CredentialsForm } from './Registration';
import { useQueryClient } from 'react-query';
import { useRedirectContextStore } from '../Redirect/useRedirectStore';
import { Card } from '../util/Card';

export const PersonalDetailsForm = ({ onFinished }: { onFinished: () => void }) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'RegisterForm' });
	const [form] = Form.useForm();
	const { mutateAsync } = useUpdateUserMutation();
	const queryClient = useQueryClient();

	const redirectContext = useRedirectContextStore((state) => state.context);
	const initialValues: any = {};
	if (redirectContext?.user) {
		initialValues.surname = redirectContext.user.firstname;
		initialValues.lastname = redirectContext.user.lastname;
		initialValues.role = redirectContext.user.role;
	}

	const onFormFinish = ({
		surname,
		lastname,
		role,
		phone,
	}: {
		surname: string;
		lastname: string;
		role: string;
		phone?: string;
	}) => {
		mutateAsync({
			input: {
				surname: surname.trim(),
				lastname: lastname.trim(),
				role: role.trim(),
				phone: phone?.trim(),
			},
		})
			.then((res) => {
				queryClient.invalidateQueries('self');
				onFinished();
				message.success(tt('Message.UpdateUser.Success'));
			})
			.catch((error) => message.error(tt('Message.UpdateUser.Error')));
	};

	return (
		<>
			<Form
				form={form}
				name="basic"
				// labelCol={{ span: 8 }}
				// wrapperCol={{ offset: 8, span: 24 }}
				onFinish={onFormFinish}
				autoComplete="on"
				layout="vertical"
				style={{ width: '100%' }}
				size="large"
				initialValues={initialValues}
			>
				<Card hoverable={false}>
					<Form.Item
						label={t('Firstname')}
						name="surname"
						rules={[{ required: true, message: tt('Firstname.Missing') }]}
					>
						<Input maxLength={255} />
					</Form.Item>

					<Form.Item
						label={t('Lastname')}
						name="lastname"
						rules={[{ required: true, message: tt('Lastname.Missing') }]}
					>
						<Input maxLength={255} />
					</Form.Item>
					<Form.Item
						label={tt('Role.Label')}
						name="role"
						rules={[{ required: true, message: tt('Role.Missing') }]}
					>
						<Input maxLength={255} />
					</Form.Item>
					<Form.Item
						label={tt('Phone.Label')}
						name="phone"
						rules={[{ required: true, message: tt('Phone.Missing') }]}
					>
						<Input maxLength={20} />
					</Form.Item>
				</Card>
				<Row style={{ marginTop: '24px' }} justify="end">
					<Col>
						<Form.Item
						// wrapperCol={{ offset: 8, span: 16 }}
						>
							<Button
								style={{ width: '100%', float: 'right' }}
								type="primary"
								htmlType="submit"
							>
								{t('Submit')}
							</Button>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	);
};

const SetupUser = ({ onComplete }: { onComplete: () => void }) => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	// hacky way to determine if first register step has been done already
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const userHasBeenAuthenticatedBefore = useAuthStore(
		(state) => state.userHasBeenAuthenticatedBefore,
	);
	const { data, isSuccess, isError } = useSelfQuery(
		{},
		{
			enabled: isAuthenticated,
		},
	);

	useEffect(() => {
		if (isSuccess && data?.self.surname != null && data?.self.lastname != null) {
			onComplete();
		}
	}, [isAuthenticated, isSuccess, data]);

	return (
		<Space
			direction="vertical"
			size="middle"
			style={{ width: '100%', maxWidth: '400px' }}
		>
			<Typography.Title level={2}>
				{t('RegisterForm.PersonalDetailsTitle')}
			</Typography.Title>
			{/* <Typography.Text>{t('RegisterForm.PersonalDetailsTitle')}</Typography.Text> */}
			<PersonalDetailsForm key={1} onFinished={onComplete} />
		</Space>
	);
};

export default SetupUser;
