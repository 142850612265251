import { Descriptions as AntdDescriptions, DescriptionsProps } from 'antd';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';
import './Descriptions.less';
import { PrimaryColor } from './constants';

type DescriptionsType = {
	(props: DescriptionsProps): JSX.Element;
	Item: (props: DescriptionsItemProps) => JSX.Element;
};

export const DescriptionsItem = ({
	style,
	children,
	...props
}: DescriptionsItemProps) => {
	return <AntdDescriptions.Item {...props}>{children}</AntdDescriptions.Item>;
};

export const Descriptions = (({
	style,
	children,
	labelStyle,
	contentStyle,
	...props
}: DescriptionsProps) => {
	const defaultLabelStyle = {
		fontWeight: 'bold',
		color: PrimaryColor,
		fontSize: '12px',
		marginBottom: '-8px',
	};
	const defaultContentStyle = {
		fontSize: '14px',
		backgroundColor: 'none',
	};
	const defaultStyle: React.CSSProperties = {};
	if (props.layout === 'vertical') {
		defaultStyle.paddingBottom = '0px';
	}
	return (
		<AntdDescriptions
			contentStyle={{ ...defaultContentStyle, ...contentStyle }}
			labelStyle={{ ...defaultLabelStyle, ...labelStyle }}
			style={{ ...defaultStyle, ...style }}
			className="Descriptions"
			{...{ ...{ colon: false }, ...props }}
		>
			{children}
		</AntdDescriptions>
	);
}) as DescriptionsType;

Descriptions.Item = DescriptionsItem;
