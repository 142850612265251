/*
 * Generated type guards for "RedirectContext.tsx".
 * WARNING: Do not manually change this file.
 */
import {
	CheckoutRedirectContext,
	UserRedirectContext,
	RedirectContext,
} from './RedirectContext';

export function isCheckoutRedirectContext(
	obj: any,
	_argumentName?: string,
): obj is CheckoutRedirectContext {
	return (
		((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
		(obj.plan === 'business' || obj.plan === 'enterprise') &&
		typeof obj.currency === 'string'
	);
}

export function isUserRedirectContext(
	obj: any,
	_argumentName?: string,
): obj is UserRedirectContext {
	return (
		((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
		(typeof obj.firstname === 'undefined' || typeof obj.firstname === 'string') &&
		(typeof obj.lastname === 'undefined' || typeof obj.lastname === 'string') &&
		(typeof obj.email === 'undefined' || typeof obj.email === 'string') &&
		(typeof obj.role === 'undefined' || typeof obj.role === 'string') &&
		(typeof obj.company === 'undefined' || typeof obj.company === 'string')
	);
}

export function isRedirectContext(
	obj: any,
	_argumentName?: string,
): obj is RedirectContext {
	return (
		((obj !== null && typeof obj === 'object') || typeof obj === 'function') &&
		(typeof obj.user === 'undefined' || (isUserRedirectContext(obj.user) as boolean)) &&
		(typeof obj.checkout === 'undefined' ||
			(isCheckoutRedirectContext(obj.checkout) as boolean))
	);
}
