import { DownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Layout, Menu, message, Row, Space, Typography } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import useModal from 'antd/lib/modal/useModal';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
	Currencies,
	FeatureNames,
	useUpdateIncidentJoinRequestUserMutation,
} from '../../generated/graphql';
import { useAuthStore, useLogout } from '../../hooks/useAuth';
import { useRedirectContextStore } from '../Redirect/useRedirectStore';
import { PageAnimation } from '../util/Animation';
import { UserAvatar } from '../util/Avatar';
import { FeatureToggle } from '../util/FeatureFlags';
import { Footer } from '../util/Footer';
import { Logo } from '../util/Logo';
import { MasterKeyPasswordModal } from '../util/MasterKeyPasswordModal';
import { ChannelOverview } from './ChannelOverview';
import { AcceptCompanyInviteModal } from './CompanyModal';
import { CompanyOverview } from './CompanyOverview';
import { Dashboard } from './Dashboard';
import IncidentOverview from './IncidentOverview';
import {
	CurrentPlanTag,
	Plans,
	PlansModal,
	PlansPage,
	usePlansModalStore,
} from './Plans';
import { SettingsPage } from './SettingsPage';
import { SelectedCompanyIndicator } from './SelectedCompanyIndicator';
import { Button } from '../util/Button';
import { UserMenu } from './UserMenu';
import { IdlePrompt } from '../util/IdlePrompt';
import MediaQuery from 'react-responsive';
import { useMediaQuery } from 'react-responsive';
import ScrollToTop from '../util/ScrollToTop';
import { DemoIndicator } from './DemoSwitch';
import { TimelogTimer } from './Timelog';
import { MessageTemplatesPage } from './MessageTemplates/MessageTemplatesPage';

const { Header, Content } = Layout;

const ConsumeJoinCompanyContext = () => {
	const redirectContext = useRedirectContextStore((state) => state.context);
	const [acceptCompanyInviteModalVisible, setAcceptCompanyInviteModalVisible] =
		useState(false);
	useEffect(() => {
		if (redirectContext != null && redirectContext.joinCompany != null) {
			setAcceptCompanyInviteModalVisible(true);
		}
	}, []);
	return (
		<AcceptCompanyInviteModal
			onClose={() => setAcceptCompanyInviteModalVisible(false)}
			visible={acceptCompanyInviteModalVisible}
		/>
	);
};

const ConsumeJoinIncidentRedirectContext = () => {
	const context = useRedirectContextStore((state) => state.context);
	const clearContext = useRedirectContextStore.useClearJoinIncidentRequestContext();
	const { mutateAsync } = useUpdateIncidentJoinRequestUserMutation();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const navigate = useNavigate();
	const isMountedRef = useRef(true);
	const timeoutRef = useRef<any>(null);

	useEffect(() => {
		isMountedRef.current = true;

		if (isAuthenticated) {
			// Set a timeout to delay the execution of the async operation
			timeoutRef.current = setTimeout(() => {
				if (
					context !== undefined &&
					context.joinIncidentRequest != null &&
					context.joinCompany == null
				) {
					mutateAsync({ id: context.joinIncidentRequest.id })
						.then((res) => {
							if (isMountedRef.current) {
								navigate('incident/' + res.updateIncidentJoinRequestUser.incident.id);
							}
						})
						.catch((e) => {
							if (isMountedRef.current) {
								message.error('Automatic incident join failed');
							}
						})
						.finally(() => {
							if (isMountedRef.current) {
								clearContext();
							}
						});
				}
			}, 2000); // Delay by 3 seconds
		}

		return () => {
			isMountedRef.current = false;

			// Clear the timeout if the component unmounts to prevent the async operation from running
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [context, isAuthenticated]);

	return null;
};

const ContextConsumers = () => (
	<>
		<ConsumeCheckoutContext />
		<ConsumeJoinCompanyContext />
		<ConsumeJoinIncidentRedirectContext />
	</>
);

export const ConsumeCheckoutContext = () => {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const navigate = useNavigate();
	const redirectContext = useRedirectContextStore((state) => state.context);
	const clearCheckoutContext = useRedirectContextStore.useClearCheckoutContext();
	const setPlansDefaults = usePlansModalStore.useSetDefaults();

	useEffect(() => {
		if (isAuthenticated && redirectContext?.checkout) {
			setPlansDefaults({
				currency:
					Currencies[redirectContext.checkout.currency as keyof typeof Currencies],
				tier: redirectContext.checkout.plan === 'business' ? 1 : 2,
			});
			navigate('/admin/plans');
			clearCheckoutContext();
		}
	}, [isAuthenticated]);
	return null;
};

export const AdminApp = ({ parentPath }: { parentPath: string }) => {
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const logout = useLogout();
	const location = useLocation();

	const loginButton = (
		<Button onClick={() => navigate('/login')}>{t('AdminPortal.UserMenu.Login')}</Button>
	);

	const locationParts = location.pathname.split('/');
	let baseIndex = locationParts.findIndex((x) => x === parentPath);
	baseIndex = baseIndex < 0 ? 0 : baseIndex;
	const key = locationParts.slice(baseIndex + 1, baseIndex + 2).join('/');

	return (
		<>
			<DemoIndicator />
			<ScrollToTop />
			<IdlePrompt timeoutMinutes={45} promptTimeoutMinutes={15} />
			<ContextConsumers />
			<PlansModal />
			<FeatureToggle features={[FeatureNames.E2EEncryption]}>
				<MasterKeyPasswordModal />
			</FeatureToggle>
			<FeatureToggle features={[FeatureNames.Timelog]}>
				<TimelogTimer />
			</FeatureToggle>
			<Row>
				<Col className="Narrow">
					<Space direction="vertical" size={16} className="Content">
						<Row align="middle" justify="space-between" gutter={[16, 16]}>
							<Col>
								<Space size="large" wrap>
									<Logo onClick={() => navigate('/')} />
									<CurrentPlanTag />
								</Space>
							</Col>
							<Col>
								<SelectedCompanyIndicator />
							</Col>
							<Col>{isAuthenticated ? <UserMenu /> : loginButton}</Col>
						</Row>
						<AnimatePresence exitBeforeEnter>
							<Routes location={location} key={key}>
								<Route
									path="incident/:id/*"
									element={
										<PageAnimation type="stack">
											<IncidentOverview />
										</PageAnimation>
									}
								/>
								<Route
									path="company/:id"
									element={
										<PageAnimation type="stack">
											<CompanyOverview />
										</PageAnimation>
									}
								/>
								<Route
									path="channel/:id"
									element={
										<PageAnimation type="stack">
											<ChannelOverview />
										</PageAnimation>
									}
								/>
								<Route
									path="settings"
									element={
										<PageAnimation type="stack">
											<SettingsPage />
										</PageAnimation>
									}
								/>
								<Route
									path="dashboard"
									element={
										<PageAnimation type="stack">
											<Dashboard />
										</PageAnimation>
									}
								/>
								<Route
									path="plans"
									element={
										<PageAnimation type="stack">
											<PlansPage />
										</PageAnimation>
									}
								/>
								<Route
									path="templates"
									element={
										<PageAnimation type="stack">
											<MessageTemplatesPage />
										</PageAnimation>
									}
								/>
								<Route path="*" element={<Navigate to="dashboard" />} />
							</Routes>
						</AnimatePresence>
					</Space>
				</Col>
			</Row>
			<Footer />
		</>
	);
};
