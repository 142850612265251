import { Row, Col, Space, message, Typography } from 'antd';
import { Alert } from './Alert';
import { useTranslation } from 'react-i18next';
import {
	IncidentQuery,
	JoinIncidentRequestState,
	useAcceptIncidentJoinRequestMutation,
	useRejectIncidentJoinRequestMutation,
} from '../../generated/graphql';
import { Button } from './Button';

export const PendingInviteUserRequests = ({
	requests,
	forHinter = false,
	onUpdate,
}: {
	requests: IncidentQuery['incident']['joinRequests'];
	forHinter?: boolean;
	onUpdate?: () => void;
}) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'IncidentParticipantsOverview.Pending',
	});
	const { t: tState } = useTranslation('translations', {
		keyPrefix: 'IncidentParticipantsOverview.Pending.State',
	});
	const { mutateAsync: accept } = useAcceptIncidentJoinRequestMutation();
	const { mutateAsync: reject } = useRejectIncidentJoinRequestMutation();

	const onReject = (id: number) => {
		reject({ id })
			.then(() => {
				message.success(t('RejectButton.Message.Success'));
				onUpdate?.();
			})
			.catch(() => {
				message.error(t('RejectButton.Message.Error'));
			});
	};

	const onAccept = (id: number) => {
		accept({ id })
			.then(() => {
				message.success(t('AcceptButton.Message.Success'));
				onUpdate?.();
			})
			.catch(() => {
				message.error(t('AcceptButton.Message.Error'));
			});
	};

	const shownRequests =
		requests?.filter(
			(r) =>
				r.state !== JoinIncidentRequestState.Closed &&
				r.state !== JoinIncidentRequestState.Rejected,
		) ?? [];

	return (
		<Space direction="vertical" size="middle">
			{shownRequests.length > 0 && (
				<Typography.Title level={2}>{t('Title')}</Typography.Title>
			)}
			{shownRequests.map((request) => (
				<Alert
					key={request.id}
					closable={false}
					message={`${request.initiator.firstname} ${request.initiator.lastname} ${t(
						'Text',
						{
							firstname: request.firstname || request.invitedUser?.firstname,
							lastname: request.lastname || request.invitedUser?.lastname,
							email: request.email || request.invitedUser?.email,
						},
					)} ${tState(request.state)} ${
						request.invitedUser == null ? t('InvitedUserUnregistered') : ''
					}`}
					type="info"
					action={
						forHinter && request.state === JoinIncidentRequestState.Pending ? (
							<Space>
								<Button type="ghost" onClick={() => onReject(request.id)}>
									{t('RejectButton.Label')}
								</Button>
								<Button onClick={() => onAccept(request.id)}>
									{t('AcceptButton.Label')}
								</Button>
							</Space>
						) : null
					}
				/>
			))}
		</Space>
	);
};
