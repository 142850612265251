import { Col, Row, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from './Logo';

export type FooterProps = {
	linksExtra?: [{ label: string; link: string }];
	children?: React.ReactNode;
};

export const Footer = ({ linksExtra, children }: FooterProps) => {
	const { t, i18n } = useTranslation('translations');

	const [langPath, setLangPath] = useState<string | undefined>();
	useEffect(() => {
		if (i18n.language === undefined) return;
		let language = i18n.language;
		if (language.startsWith('en')) {
			language = '';
		} else {
			language += '/';
		}
		setLangPath(language);
	}, [i18n.language]);

	const links = [
		{
			label: t('Footer.PrivacyPolicy'),
			link: `https://konfidal.eu/${langPath}privacy`,
		},
		{
			label: t('Footer.TermsAndConditions'),
			link: `https://konfidal.eu/${langPath}terms`,
		},
		{
			label: t('Footer.Imprint'),
			link: `https://konfidal.eu/${langPath}imprint`,
		},
	].concat(linksExtra || []);

	const FooterCol = ({
		children,
		style,
	}: {
		style?: React.CSSProperties;
		children: React.ReactNode;
	}) => (
		<Col xs={24} sm={12} lg={6} md={8} style={{ textAlign: 'center', ...style }}>
			{children}
		</Col>
	);

	return (
		<Row
			gutter={[32, 16]}
			justify="start"
			align="bottom"
			style={{ marginTop: '128px', padding: '32px' }}
		>
			<Col span={24}>{children}</Col>
			<FooterCol>
				<Logo size={48} />
			</FooterCol>
			{links.map(({ label, link }, i) => (
				<FooterCol key={i}>
					<Typography.Link href={link} target="_blank" rel="noreferrer">
						{label}
					</Typography.Link>
				</FooterCol>
			))}
			<FooterCol>
				<Typography.Text style={{ textAlign: 'center' }} italic>
					Version: {process.env.REACT_APP_VERSION}
				</Typography.Text>
			</FooterCol>
		</Row>
	);
};
