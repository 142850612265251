import { useCallback, useEffect, useState } from 'react';
import { gql } from 'graphql-request';
import {
	CompanyDesignFragment,
	FeatureNames,
	Media,
	MediaType,
	useRemoveIncidentMediaMutation,
} from '../../generated/graphql';
import { useGQLMutationWithFiles } from '../../hooks/useGQL';
import { Attachment } from '../util/Attachment';
import { CryptographyKey } from 'sodium-plus';
import { useEncrypt, useEncryptFile } from '../../hooks/useCrypt';
import { mimeToMediaType } from '../../lib/util';
import { fileTypeFromBuffer } from 'file-type';
import { serializeEncrypted } from '../../lib/crypt';
import { useFeature } from '../../hooks/useFeatures';
import { DesignConfig } from './ChannelEditor';
import { message } from 'antd';

export interface IncidentAttachmentProps {
	id: number;
	file: File;
	type?: MediaType;
	incidentId: number;
	onSuccess: (media: Media) => void;
	onRemove: () => void;
	encryptionKey?: CryptographyKey;
	design?: DesignConfig;
	uploadedMedia?: Media;
	storageConsent?: boolean;
}

export const IncidentAttachment = ({
	file,
	type,
	incidentId,
	onSuccess,
	onRemove,
	encryptionKey,
	uploadedMedia: uploadedMediaProp,
	design,
	storageConsent,
}: IncidentAttachmentProps) => {
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);
	const encryptFile = useEncryptFile(encryptionKey);
	const encrypt = useEncrypt(encryptionKey);
	const [mediaType, setMediaType] = useState<MediaType | undefined>(undefined);
	const { mutateAsync, progress, isError, isSuccess } = useGQLMutationWithFiles(gql`
		mutation ($id: Int!, $input: CreateMediaInput!, $file: Upload!) {
			addIncidentMedia(id: $id, createMediaInput: $input, file: $file) {
				id
				uri
				type
				fileName
				extension
			}
		}
	`);
	const [uploadedMedia, setUploadedMedia] = useState<Media | undefined>(undefined);
	const { mutateAsync: mutateAsyncRemove, isLoading: removeLoading } =
		useRemoveIncidentMediaMutation();

	const upload = useCallback(
		(file: File) => {
			(async () => {
				const inferredType = await fileTypeFromBuffer(await file.arrayBuffer());
				const extension = file.name.split('.').pop();
				if (!type) {
					type = mimeToMediaType(inferredType?.mime);
				}
				setMediaType(type);
				let encryptedFile: File | null = null;
				let nonce: Buffer | null = null;
				let fileName = file.name;
				if (e2eEnabled) {
					({ encryptedFile, nonce } = await encryptFile(file));
					fileName = serializeEncrypted(await encrypt(fileName));
				}
				const variables = {
					id: incidentId,
					file: null,
					input: {
						type,
						extension,
						nonce: nonce ? nonce.toString('base64') : null,
						fileName,
						storageConsent,
					},
				};
				try {
					const res = await mutateAsync([
						variables,
						new Map([['variables.file', encryptedFile ? encryptedFile : file]]),
					]);
					console.log(res);
					setUploadedMedia(res.data.addIncidentMedia);
					onSuccess(res.data.addIncidentMedia);
				} catch (error) {
					console.log('Upload failed!');
				}
			})();
		},
		[onSuccess, mutateAsync, incidentId],
	);

	const reset = () => {
		if (isError) upload(file);
	};

	useEffect(() => {
		if (uploadedMediaProp) {
			setUploadedMedia(uploadedMediaProp);
		}
		console.log('Attachment created, starting uplaod');
		upload(file);
	}, [file, upload]);

	const remove = () => {
		if (uploadedMedia) {
			console.log('Removing media with id' + uploadedMedia.id);
			mutateAsyncRemove({ incidentId, mediaId: uploadedMedia.id })
				.then(() => {
					setUploadedMedia(undefined);
					onRemove();
				})
				.catch(() =>
					message.error(
						'Failed to remove attachment. If you do not want to share it, DO NOT SUBMIT THE REPORT!',
					),
				);
		}
	};

	return (
		<Attachment
			file={file}
			type={mediaType}
			reset={reset}
			progress={progress}
			isError={isError}
			isSuccess={isSuccess}
			onRemove={remove}
			isLoading={removeLoading}
			design={design}
		/>
	);
};
