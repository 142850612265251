import {
	Col,
	Row,
	Spin,
	Typography,
	Button,
	Space,
	Pagination,
	Empty,
	Checkbox,
	message,
} from 'antd';
import { Select } from '../../util/Select';
import { AccessLevel, FeatureNames } from '../../../generated/graphql';
import './Dashboard.less';
import { useTranslation } from 'react-i18next';
import { FeatureLock } from '../FeatureLock';
import { Company } from './types';
import { StaggerAnimationChild } from '../../util/Animation';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useImmer } from 'use-immer';
import { motion } from 'framer-motion';
import { CompanyCard } from '../CompanyCard';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { t } from 'i18next';
import { useIncidentFilterStore } from '../IncidentsFilter';
import { useDemoStore } from '../DemoSwitch';

const CompanySelect = ({
	value,
	companies,
	onSelect,
	onJoin,
	onCreate,
}: {
	value?: Company | null;
	companies?: Company[];
	onSelect: (company: Company) => void;
	onJoin: () => void;
	onCreate: () => void;
}) => {
	const { t } = useTranslation('translations');
	return (
		<Row gutter={[8, 8]} justify="end">
			<Col>
				<FeatureLock featureName={FeatureNames.MultipleCompanies}>
					<Button type="ghost" onClick={onCreate} size="large">
						{t('Create')}
					</Button>
				</FeatureLock>
			</Col>
			<Col>
				<Button type="dashed" onClick={onJoin} size="large">
					{t('Join')}
				</Button>
			</Col>
			{companies && companies.length > 1 ? (
				<Col>
					<Select
						size="large"
						value={value?.id}
						onSelect={(value: number) => {
							const company = companies?.find((company) => company.id === value);
							if (company) onSelect(company);
						}}
						style={{ width: '100%' }}
					>
						{companies?.map((company, i) => (
							<Select.Option value={company.id} key={i}>
								{company.name}
							</Select.Option>
						))}
					</Select>
				</Col>
			) : null}
		</Row>
	);
};

export interface CompaniesProps {
	onCreate: () => void;
	companies: Company[];
}

// export const Companies = ({
// 	selectedCompany,
// 	onJoin,
// 	onCreate,
// 	companies,
// 	setSelectedCompany,
// }: CompaniesProps) => (
// 	<companieselect
// 		value={selectedCompany}
// 		onJoin={onJoin}
// 		onCreate={onCreate}
// 		companies={companies == null ? [] : companies}
// 		onSelect={setSelectedCompany}
// 	/>
// );

export const Companies = ({ companies: allCompanies, onCreate }: CompaniesProps) => {
	const { t } = useTranslation('translations');
	const initialStyle = { left: 0, top: 0, width: 0, height: 0 };
	const cardsRef = useRef<Array<HTMLDivElement | null>>([]);
	const [selectorStyle, setSelectorStyle] = useImmer<{
		left: number;
		top: number;
		width: number;
		height: number;
	}>(initialStyle);
	const demo = useDemoStore.useDemo();
	const setDemo = useDemoStore.useSetDemo();
	const companies = useMemo(
		() => (demo ? allCompanies.filter((c) => c.demo) : allCompanies),
		[demo, allCompanies],
	);

	const selectedIdStore = useSelectedCompanyStore((state) => state.companyId);
	const setSelectedCompany = useSelectedCompanyStore.useSetCompany();
	const [selectedIndex, setSelectedIndex] = useState(
		companies != null && selectedIdStore != null
			? companies.findIndex((c) => c.id === selectedIdStore)
			: -1,
	);
	const setFilterChannelId = useIncidentFilterStore((s) => s.setChannelId);

	const isHorizontal = true; // useMediaQuery({ maxWidth: 768 });
	const [scroll, setScroll] = useState(0);
	const scrollRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (selectedIdStore == null && companies.length > 0) selectCompany(0);
	}, [selectedIdStore, companies]);

	// when demo is turned automatically select a demo company
	// if no demo company exists, message error and turn demo off
	useEffect(() => {
		if (demo && companies.filter((c) => c.demo).length === 0) {
			message.error(t('ErrorToggleDemoNoCompany'));
			setDemo(false);
		} else if (demo && companies.length > 0) {
			if (!companies[selectedIndex]?.demo) {
				const demoIndex = companies.findIndex((c) => c.demo);
				if (demoIndex === -1) {
					message.error(t('ErrorToggleDemoNoCompany'));
					setDemo(false);
				} else {
					console.log('select company');
					selectCompany(demoIndex);
				}
			}
		}
	}, [demo, companies]);

	const selectCompany = (index: number) => {
		if (index == null || index < 0) return;
		moveSelector(index);
		setSelectedIndex(index);
		setSelectedCompany(companies[index]);
		setFilterChannelId(undefined);
	};

	const moveSelector = (index: number) => {
		const moveDown = selectedIndex >= 0 && index > selectedIndex;
		const moveUp = selectedIndex >= 0 && index < selectedIndex;
		const companyCardWrapper = cardsRef.current[index];
		const vPadding = 16;
		const hPadding = 8;
		if (companyCardWrapper) {
			const companyCard = companyCardWrapper.childNodes[0]
				.childNodes[0] as HTMLDivElement;
			if (isHorizontal)
				setSelectorStyle({
					top: companyCardWrapper?.offsetTop - vPadding,
					left: companyCardWrapper?.offsetLeft - (moveDown ? 16 + 100 : 0) - scroll,
					width: 300 + 4 * hPadding,
					height: companyCardWrapper?.offsetHeight + 2 * vPadding,
				});
			else
				setSelectorStyle({
					left: companyCardWrapper?.offsetLeft - hPadding,
					top: companyCardWrapper?.offsetTop - (moveDown ? 32 : 0),
					height: companyCard?.offsetHeight + 2 * vPadding,
					width: companyCardWrapper?.offsetWidth + 2 * hPadding,
				});
		} else setSelectorStyle(initialStyle);
	};

	useEffect(() => {
		if (cardsRef.current && selectedIndex >= 0) {
			setTimeout(() => moveSelector(selectedIndex), 500);
		}
	}, [isHorizontal]);

	useEffect(() => {
		if (cardsRef.current && selectedIndex >= 0) {
			moveSelector(-1);
			setTimeout(() => moveSelector(selectedIndex), 1500);
		}
	}, [cardsRef.current]);

	const rowStyle = isHorizontal
		? {
				marginLeft: '-16px',
				marginRight: '-16px',
				paddingLeft: '24px',
				paddingRight: '24px',
				paddingTop: '16px',
				paddingBottom: '16px',
				scrollX: 'auto',
		  }
		: {};

	const selected = useMemo(
		() => companies.find((c) => c.id === selectedIdStore ?? 0),
		[companies, selectedIdStore],
	);

	return (
		<>
			<motion.div
				style={{
					position: 'absolute',
					zIndex: 0,
					backgroundColor: '#D0EAFB',
					borderRadius: 16,
					paddingBottom: '-16px',
				}}
				animate={selectorStyle}
			/>
			<Row
				ref={scrollRef}
				style={{ overflowX: 'auto', zIndex: 1, ...rowStyle }}
				gutter={[16, 16]}
				onScroll={() => {
					if (scrollRef.current == null) return;
					const diff = scroll - scrollRef.current.scrollLeft;
					setScroll(scrollRef.current.scrollLeft);
					setSelectorStyle((draft) => {
						draft.left += diff;
					});
				}}
				align="middle"
				wrap={false}
			>
				{companies?.map((company, i) => (
					<Col ref={(el) => (cardsRef.current[i] = el)} key={i}>
						<StaggerAnimationChild>
							<motion.div
								variants={{
									selected: isHorizontal
										? { marginLeft: 8, marginRight: 8, width: '300px', height: '150px' }
										: { marginTop: 16, marginBottom: 16, width: '100%' },
									unselected: isHorizontal
										? { margin: 0, width: '200px', height: '150px' }
										: { margin: 0, width: '100%' },
								}}
								animate={selectedIndex === i ? 'selected' : 'unselected'}
							>
								<CompanyCard
									detailed={selectedIndex === i}
									style={{
										height: '150px',
										width: '100%',
										maxHeight: '150px',
										maxWidth: '100%',
										overflow: 'clip',
									}}
									onClick={() => {
										if (selectedIndex === i) selectCompany(-1);
										else selectCompany(i);
									}}
									company={company}
								/>
							</motion.div>
						</StaggerAnimationChild>
					</Col>
				))}
				<Col style={isHorizontal ? { height: '100%' } : {}}>
					<StaggerAnimationChild>
						{selected != null &&
							selected.parent == null &&
							(selected.selfAccessLevel ?? 5) <= AccessLevel.Admin && (
								<FeatureLock featureName={FeatureNames.MultipleCompanies}>
									<Button
										type="ghost"
										size="large"
										style={{ width: '100%', height: '150px' }}
										onClick={onCreate}
										// icon={<PlusSquareOutlined style={{ fontSize: '32px' }} />}
									>
										{t('Create')}
									</Button>
								</FeatureLock>
							)}
					</StaggerAnimationChild>
				</Col>
			</Row>
		</>
	);
};
