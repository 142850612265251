import {
	Alert,
	Button,
	Card,
	Col,
	Divider,
	message,
	Result,
	Row,
	Space,
	Spin,
	Typography,
} from 'antd';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './Chat.less';
import {
	FeatureNames,
	MediaType,
	MessagesOfIncidentQuery,
	MessageType,
} from '../../generated/graphql';
import { t } from 'i18next';
import { UseQueryResult } from 'react-query';
import { DownloadOutlined, FileFilled, RedoOutlined } from '@ant-design/icons';
import { formatTime, mimeToMediaType } from '../../lib/util';
import { fileTypeFromBuffer } from 'file-type';
import { Encrypted } from '../../lib/crypt';
import { useGet } from '../../hooks/useREST';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useAuthStore, UserSession } from '../../hooks/useAuth';
import { usePGPDecrypt, DecryptOverload, PGPRecipient } from '../../hooks/useCrypt';
import { deserializeEncrypted } from '../../lib/crypt';
import { useFeature } from '../../hooks/useFeatures';
import { FeatureToggle } from '../util/FeatureFlags';
import { useTranslation } from 'react-i18next';
import { $CombinedState } from 'redux';
import { Transcription } from './Transcription';
import { Time } from '../util/Time';
import saveAs from 'file-saver';

export type Message = MessagesOfIncidentQuery['messagesOfIncident'][0];
export type MessageMediaType = Message['medias'][0];
export type QueryResult = UseQueryResult<{ type: string; base64: string }, unknown>;

const MessageImageBase64 = ({ data, ext }: { data: string; ext: string }) => {
	return (
		<>
			<img
				alt={t('MessageImage.Alt')}
				style={{ width: '100%', borderRadius: '16px' }}
				src={`data:image/${ext};base64, ${data}`}
			/>
		</>
	);
};

const MessageAudioBase64 = ({ data, ext }: { data: string; ext: string }) => {
	return (
		<audio
			style={{ maxWidth: '100%', width: '300px' }}
			src={`data:audio/${ext};base64, ${data}`}
			controls
		/>
	);
};

const MessageFile = ({
	media,
	decrypt,
}: {
	media: MessageMediaType;
	decrypt: DecryptOverload;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'Chat.Message.Attachment' });
	const [fileName, setFileName] = useState<string | undefined>();
	const isEncrypted = media.nonce != null;

	useEffect(() => {
		(async () => {
			if (media.fileName)
				try {
					// is encrypted
					const encryptedFileName = deserializeEncrypted(media.fileName);
					setFileName(await decrypt('utf-8', encryptedFileName));
				} catch (e) {
					// is not encrypted
					setFileName(media.fileName);
				}
			else setFileName('unnamed file');
		})();
	}, []);

	const [wantDownload, setWantDownload] = useState(false);
	const { data, isLoading, isError, isIdle, isSuccess } = useGet<{
		type: MediaType;
		base64: string;
	}>('/media/b64', { id: media.id }, { enabled: wantDownload });

	const download = () => {
		if (data && isSuccess) {
			const blob = new Blob([Buffer.from(data.base64, 'base64')]);
			saveAs(blob, `${media.nanoId}-${fileName}`);
			setWantDownload(false);
			return;
		}
		if (!wantDownload) setWantDownload(true);
		if (isError) {
			message.error('Failed to download');
			return;
		}
	};
	useEffect(() => {
		if (wantDownload && isSuccess) {
			download();
		}
	}, [wantDownload, isSuccess]);

	return (
		<div
			style={{
				borderRadius: '16px',
				borderStyle: 'dashed',
				padding: '16px',
				borderWidth: '2px',
				borderColor: 'gray',
			}}
		>
			<Space align="center">
				<FileFilled style={{ fontSize: 32 }} />
				<Space direction="vertical">
					<Typography.Text>
						{fileName
							? fileName
							: isEncrypted
							? t('DecryptingFilename')
							: t('LoadingFilename')}
					</Typography.Text>
					<Row justify="space-between">
						<Col>
							<Typography.Text code type="secondary">
								{media.nanoId}
							</Typography.Text>
						</Col>
						<Col>
							<Button icon={<DownloadOutlined />} type="text" onClick={download} />
						</Col>
					</Row>
				</Space>
			</Space>
		</div>
	);
};

const MessageMediaBase64 = ({
	media,
	decrypt,
	ready,
}: {
	decrypt: DecryptOverload;
	ready: boolean;
	media: MessageMediaType;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'Chat.Message.Attachment' });
	const [decryptedData, setDecryptedData] = useState<string | undefined>();
	const [type, setType] = useState<MediaType>(media.type);

	const decryptFile = useCallback(
		async (data: Encrypted | string) => {
			return await decrypt(undefined, data);
		},
		[decrypt],
	);

	let endpoint = '/media/b64';
	// switch (props.media.type) {
	// 	case MediaType.Audio:
	// 		endpoint += '/audio/b64';
	// 		break;
	// 	case MediaType.Image:
	// 		endpoint += '/image/b64';
	// 		break;
	// 	case MediaType.Document:
	// 		endpoint += '/document/b64';
	// 		break;
	// }
	const { data, isError, isLoading, refetch }: QueryResult = useGet<{
		type: string;
		base64: string;
	}>(
		endpoint,
		{
			id: media.id,
		},
		{
			enabled: !media.deleted && media.type !== MediaType.Document,
			retry: 1,
			staleTime: 6000000,
			refetchOnWindowFocus: false,
		},
	);

	// decrypt media if not document
	useEffect(() => {
		if ((media.nonce && !ready) || !data || isError || isLoading || decryptedData != null)
			return;
		(async () => {
			if (media.type === MediaType.Document) {
			} else {
				if (media.nonce) {
					const decryptedBuffer = await decryptFile({
						nonce: Buffer.from(media.nonce, 'base64'),
						ciphertext: Buffer.from(data.base64, 'base64'),
					});
					setDecryptedData(decryptedBuffer.toString('base64'));
				} else setDecryptedData(data.base64);
			}
		})();
	}, [data, decrypt, ready, isError, isLoading]);

	useEffect(() => {
		(async () => {
			if (decryptedData) {
				const buffer = Buffer.from(decryptedData, 'base64');
				const type = await fileTypeFromBuffer(buffer);
				console.log(`Attachment ${media.id} has type ${type}`);
				setType(mimeToMediaType(type?.mime));
			}
		})();
	}, [decryptedData]);

	if (media.deleted) {
		switch (media.type) {
			case MediaType.Audio:
				return <Typography.Text type="secondary">{t('Deleted.Audio')}</Typography.Text>;
			case MediaType.Image:
				return <Typography.Text type="secondary">{t('Deleted.Image')}</Typography.Text>;
			case MediaType.Document:
				return (
					<Typography.Text type="secondary">{t('Deleted.Document')}</Typography.Text>
				);
			case MediaType.Video:
				return <Typography.Text type="secondary">{t('Deleted.Video')}</Typography.Text>;
		}
	} else if (isError)
		return (
			<Row justify="center" align="middle">
				<Col>
					<Button size="large" icon={<RedoOutlined />} onClick={() => refetch()}></Button>
				</Col>
			</Row>
		);
	else if (isLoading || data == null)
		return (
			<>
				<Row justify="center" align="middle">
					<Col>
						<Spin />
					</Col>
					<Col>{t('Download.InProgress')}...</Col>
				</Row>
			</>
		);
	else if (decryptedData == null)
		return (
			<>
				<Row justify="center" align="middle">
					<Col>
						<Spin />
					</Col>
					<Col>{t('Decrypting')}...</Col>
				</Row>
			</>
		);

	const ext = media?.extension || '';
	if (media.type === MediaType.Audio)
		return <MessageAudioBase64 data={decryptedData} ext={ext} />;
	else if (media.type === MediaType.Image)
		return <MessageImageBase64 data={decryptedData} ext={ext} />;
	else return <p>Unsupported MediaType</p>; // untranslated
};

const MessageMedia = (props: {
	decrypt: DecryptOverload;
	ready: boolean;
	media: MessageMediaType;
	side: 'hinter' | 'user';
	incidentId: number;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'Chat.Message.Attachment' });
	if (props.media === undefined)
		return (
			<Col>
				<Row justify="center" align="middle">
					<Col>
						{/** untranslated */}
						<Typography.Text type="danger">{t('Download.Failed')}</Typography.Text>
					</Col>
				</Row>
			</Col>
		);

	if (props.media.type === MediaType.Document) return <MessageFile {...props} />;
	else if (props.media.type === MediaType.Audio)
		return (
			<Transcription side={props.side} incidentId={props.incidentId} media={props.media}>
				<MessageMediaBase64 {...props} />
			</Transcription>
		);
	else return <MessageMediaBase64 {...props} />;
};

export type ChatMessageProps = {
	side: 'hinter' | 'user';
	message: Message;
};

// eslint-disable-next-line react/display-name
export const ChatMessage = memo(
	({ side, message }: ChatMessageProps) => {
		const { i18n } = useTranslation('translations');
		const session = useAuthStore((state) => state.session as UserSession);
		const isOwn: boolean = useMemo(
			() =>
				((message.fromHinter && side === 'hinter') ||
					(message.sender?.id === session?.user?.id && side === 'user')) ??
				false,
			[session, side, message.recipients],
		);
		const color = isOwn ? '#D0EAFB' : '#E6FFFB';
		const [plainText, setPlainText] = useState(
			message.type === MessageType.Encrypted ? `Decrypting message...` : '',
		);
		const { ready, decrypt } = usePGPDecrypt(
			message.encryptionVersion > 0 &&
				message.recipients.map((r) => r.encryptionVersion > 0).reduce((p, n) => p && n)
				? (message.recipients as PGPRecipient[])
				: [],
			message?.sender?.publicKey
				? message.sender.publicKey
				: message.incident.hinter.publicKey,
			session?.user?.id,
		);
		const [decryptError, setDecryptError] = useState(false);

		useEffect(() => {
			if (message.type === MessageType.Encrypted && ready && decrypt)
				(async () => {
					try {
						setPlainText(await decrypt('utf-8', message.text));
					} catch (err) {
						setDecryptError(true);
					}
				})();
			else setPlainText(message.text);
		}, [ready, message.text]);

		return (
			<Row style={{ textAlign: 'left' }}>
				{isOwn ? <Col flex="auto" style={{ minWidth: '2em' }}></Col> : <></>}
				<Col style={{ maxWidth: '30em' }}>
					<Card
						style={{
							margin: '0.5em',
							backgroundColor: color,
						}}
					>
						<Row gutter={4}>
							<Col>
								<Typography.Text strong style={{ fontSize: '14px', textAlign: 'right' }}>
									{message.sender?.surname || t('Hinter')} {message.sender?.lastname}
								</Typography.Text>
							</Col>
							<Col flex="auto"></Col>
							<Col>
								<Typography.Text
									type="secondary"
									style={{ fontSize: '12px', textAlign: 'right' }}
								>
									<Time date={message.created} mode="adaptive" />
								</Typography.Text>
							</Col>
						</Row>
						<Row>
							<Col>
								<Typography.Text style={{ whiteSpace: 'pre-wrap' }}>
									{message.encryptionVersion > 0 && decryptError
										? '--- Decryption Error --'
										: plainText}
								</Typography.Text>
							</Col>
						</Row>
						{message.medias.length > 0 && (
							<Row style={{ marginTop: '8px' }} gutter={[8, 8]}>
								{message.medias.map((m, i) => (
									<MessageMedia
										side={side}
										key={m.id}
										ready={ready}
										decrypt={decrypt}
										media={m}
										incidentId={message.incident.id}
									/>
								))}
							</Row>
						)}
					</Card>
				</Col>
				{!isOwn ? <Col flex="auto" style={{ minWidth: '2em' }}></Col> : <></>}
			</Row>
		);
	},
	(prev, next) => {
		return (
			prev.message.id === next.message.id &&
			prev.side === next.side &&
			prev.message.updated === next.message.updated &&
			prev.message.medias
				.map(
					(m) => m.updated === next.message.medias.find((mm) => m.id === mm.id)?.updated,
				)
				.every((x) => x)
		);
	},
);
