import {
	Steps as AntdSteps,
	StepsProps as AntdStepsProps,
	StepProps as AntdStepProps,
} from 'antd';
import { useEffect, useRef } from 'react';
import { DesignConfig } from '../HinterPortal/ChannelEditor';

interface StepsType extends React.FC<StepsProps> {
	Step: (props: StepProps) => JSX.Element;
}

export type StepsProps = { design?: DesignConfig } & AntdStepsProps;

/*
A custom component that wraps the antd design Steps component.
*/
const MySteps = ({ design, ...props }: StepsProps) => {
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (ref.current != null && design?.primaryColor != null) {
			let iconProcess = ref.current
				.querySelector('.ant-steps-item-process')
				?.querySelector('.ant-steps-item-icon') as HTMLElement | null;
			let iconFinish = ref.current.querySelectorAll(
				'.ant-steps-item-finish .ant-steps-item-icon',
			) as NodeListOf<HTMLElement> | null;
			let iconWait = ref.current.querySelectorAll(
				'.ant-steps-item-wait .ant-steps-item-icon',
			) as NodeListOf<HTMLElement> | null;
			if (iconProcess) {
				if (design.primaryColor != null)
					iconProcess.style.backgroundColor = design.primaryColor;
				const check = iconProcess.querySelector('span') as HTMLElement | null;
				if (check) check.style.color = '#fff';
			}
			if (iconFinish) {
				Array.from(iconFinish).forEach((icon) => {
					icon.style.backgroundColor = '#fff';
					const check = icon.querySelector('span') as HTMLElement | null;
					if (check && design.primaryColor) check.style.color = design.primaryColor;
				});
			}
			if (iconWait)
				Array.from(iconWait).forEach((icon) => {
					icon.style.backgroundColor = '#fff';
					const check = icon.querySelector('span') as HTMLElement | null;
					if (check && design.primaryColor) check.style.color = 'rgba(0, 0, 0, 0.25)';
				});
		}
	}, [ref.current, design, props.current]);
	return (
		<div ref={ref}>
			<AntdSteps {...props} />
		</div>
	);
};

export type StepProps = { design?: DesignConfig } & AntdStepProps;

const Step = ({ design, ...props }: StepProps) => {
	return <AntdSteps.Step {...props} />;
};

MySteps.Step = Step;

export const Steps = MySteps as StepsType;
