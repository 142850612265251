import { captureException } from '@sentry/react';
import { List, message, Modal, ModalProps } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddChannelUserMutation } from '../../generated/graphql';
import { UserAvatar } from '../util/Avatar';
import { Select } from '../util/Select';
import { UserInfo, UserSelect } from './UserSelect';

export type AddUserModalProps<T> = {
	visible: boolean;
	users: UserInfo[];
	mutateAsync: (userId: number) => Promise<T>;
	onClose: () => void;
	onSuccess: (res: T) => void;
} & ModalProps;

export const AddUserModal = <T,>(props: AddUserModalProps<T>) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'ChannelOverview.AddUserModal',
	});
	const { visible, users, mutateAsync, ...modalProps } = props;
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [selection, setSelection] = useState<UserInfo | undefined>(undefined);
	const [finished, setFinished] = useState(false);

	const onCancel = () => {
		setSelection(undefined);
		setFinished(false);
		setConfirmLoading(false);
		props.onClose();
	};

	const onOk = () => {
		if (finished) {
			props.onClose();
			setSelection(undefined);
			setFinished(false);
			return;
		}
		setConfirmLoading(true);
		if (selection) {
			mutateAsync(selection.id)
				.then((res: T) => {
					props.onSuccess(res);
				})
				.catch((err: any) => {
					captureException(err);
					console.error(err);
					message.error(t('Error'));
				})
				.finally(() => {
					setFinished(true);
					setConfirmLoading(false);
					setSelection(undefined);
					props.onClose();
				});
		}
	};

	return (
		<Modal
			title={tt('Title')}
			okText={finished ? tt('OkTextFinished') : tt('OkText')}
			cancelText={t('Cancel')}
			visible={props.visible}
			closeIcon={<></>}
			closable
			onOk={onOk}
			okButtonProps={{ disabled: selection === undefined }}
			confirmLoading={confirmLoading}
			onCancel={onCancel}
			destroyOnClose={true}
			{...modalProps}
		>
			<UserSelect
				style={{ width: '100%' }}
				users={users}
				onSelect={(user) => setSelection(user)}
				value={selection}
			/>
		</Modal>
	);
};
