import { Col, Row, Spin, Space, Pagination, Empty, Typography } from 'antd';
import { IncidentCard } from '../IncidentCard';
import './Dashboard.less';
import { useTranslation } from 'react-i18next';
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { StaggerAnimationChild, StaggerAnimationParent } from '../../util/Animation';
import {
	createPageStore,
	IncidentsFilter,
	useFilteredIncidents,
	useIncidentFilterStore,
	useIncidentPageStore,
} from '../IncidentsFilter';
import { Button } from '../../util/Button';
import { PlusOutlined } from '@ant-design/icons';
import { CreateIncidentModal, CreateIncidentModalHandles } from './CreateIncidentModal';
import { Input } from '../../util/Input';
import useDebounce from '../../../hooks/useDebounce';
import { accessLevelAbove, getAntdLocale } from '../../../lib/util';
import { AccessLevel } from '../../../generated/graphql';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { useDemoStore } from '../DemoSwitch';
import { create } from 'lodash';

export type IncidentsHandles = {
	openCreateIncidentModal: CreateIncidentModalHandles['open'];
};

export const Incidents = forwardRef<IncidentsHandles>(function Incidents(_props, ref) {
	const { t, i18n } = useTranslation('translations');
	const [showFilter, setShowFilter] = useState(false);
	const createIncidentModalRef = useRef<CreateIncidentModalHandles>(null);
	const channelId = useIncidentFilterStore((state) => state.channelId);
	const { data, isLoading, isError, isSuccess } = useFilteredIncidents();
	const [pageQuery, pageInfo] = useIncidentPageStore((state) => [
		state.pageQuery,
		state.pageInfo,
	]);
	const [setPage, setPageSize] = useIncidentPageStore((state) => [
		state.setPage,
		state.setPageSize,
	]);
	const selfAccessLevel = useSelectedCompanyStore(
		(state) => state.company?.selfAccessLevel,
	);

	const [searchText, setSearchText] = useState<string>();
	const [debouncedSearch, clear] = useDebounce(1000, searchText);
	const setSearch = useIncidentFilterStore.useSetSearch();

	useEffect(() => {
		console.log('update filter: ', debouncedSearch);
		if (debouncedSearch === '') setSearch(undefined);
		if (debouncedSearch != null) setSearch(debouncedSearch);
	}, [debouncedSearch]);

	let content: React.ReactNode | null = null;

	const antdLocale = useMemo(() => getAntdLocale(i18n.language), [i18n]);

	if (isLoading) {
		content = (
			<Row justify="center" align="middle" style={{ width: '100%', height: '400px' }}>
				<Col>
					<Spin />
				</Col>
			</Row>
		);
	} else if (isSuccess && data && data.incidents.page.size === 0) {
		content = (
			<Row justify="center" align="middle" style={{ height: '400px', width: '100%' }}>
				<Col>
					<Empty description={t('NoData')} />
				</Col>
			</Row>
		);
	} else
		content = (
			<StaggerAnimationParent>
				<Space size="middle" direction="vertical" style={{ width: '100%' }}>
					<Row style={{ width: '100%', margin: 0 }} gutter={[16, 16]}>
						{data?.incidents.data.map((item, i) => (
							<Col style={{ padding: 0 }} key={item.id}>
								<StaggerAnimationChild>
									<IncidentCard incident={item} />
								</StaggerAnimationChild>
							</Col>
						))}
					</Row>
				</Space>
			</StaggerAnimationParent>
		);

	useImperativeHandle(
		ref,
		() => ({
			openCreateIncidentModal: (iV) =>
				createIncidentModalRef.current && createIncidentModalRef.current.open(iV),
		}),
		[createIncidentModalRef.current],
	);

	return (
		<>
			<CreateIncidentModal ref={createIncidentModalRef} channelId={channelId} />
			<AnimatePresence exitBeforeEnter>
				<StaggerAnimationChild>
					<Row justify="space-between" gutter={[8, 8]}>
						<Col>
							<Typography.Title level={3} style={{ textAlign: 'left' }}>
								{t('Incidents')}
							</Typography.Title>
						</Col>
						<Col>
							<Space direction="horizontal" style={{ width: '100%' }}>
								<Input
									maxLength={255}
									value={searchText}
									onChange={(e) => setSearchText(e.target.value)}
									placeholder={t('TypeToSearch')}
									// design=
									style={{
										minWidth: 150,
										maxWidth: 300,
										width: '100%',
										boxShadow:
											'0px 28px 28px rgba(9, 61, 93, 0.04), 0px 3px 15px rgba(9, 61, 93, 0.05), 0px 0px 0px rgba(9, 61, 93, 0.05)',
									}}
								/>
								{accessLevelAbove(AccessLevel.User, selfAccessLevel) && (
									<Button
										type="ghost"
										onClick={() => createIncidentModalRef.current?.open()}
										icon={<PlusOutlined />}
									/>
								)}
								<Button type={'ghost'} onClick={() => setShowFilter(!showFilter)}>
									{showFilter ? t('IncidentFilter.Hide') : t('IncidentFilter.Show')}
								</Button>
							</Space>
						</Col>
						{/* </Row>
					<Row style={{ width: '100%' }} justify="end" gutter={[0, 16]}> */}
						<IncidentsFilter visible={showFilter} />
						<Col>
							<Pagination
								defaultCurrent={1}
								defaultPageSize={5}
								current={(pageInfo?.page ?? 0) + 1}
								pageSize={pageQuery?.limit ?? 5}
								total={pageInfo?.total}
								showTotal={(total) => `${total} ${t('Incidents')}`}
								hideOnSinglePage={false}
								showSizeChanger
								size="default"
								pageSizeOptions={['5', '10', '15']}
								onChange={(page, size) => {
									setPage(page - 1);
									setPageSize(size);
								}}
								locale={antdLocale.Pagination}
							/>
						</Col>
						<Col span={24}>
							<AnimatePresence exitBeforeEnter>{content}</AnimatePresence>
						</Col>
					</Row>
				</StaggerAnimationChild>
			</AnimatePresence>
		</>
	);
});
