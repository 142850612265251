import { Result, Form, Button, Alert, Spin, Row, Space, Col } from 'antd';
import { Input } from '../util/Input';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import {
	useRequestPasswordResetMutation,
	useUpdatePasswordMutation,
} from '../../generated/graphql';
import { Roles, useAuthStore } from '../../hooks/useAuth';
import withCentered from '../util/withCentered';
import { Card } from '../util/Card';
import { Helmet } from 'react-helmet';

export const RequestResetPassword = withCentered(() => {
	const { t } = useTranslation('translations');
	const { mutateAsync, error, isError, isLoading, isSuccess } =
		useRequestPasswordResetMutation();
	const [form] = Form.useForm();
	const [result, setResult] = useState<JSX.Element | null>(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (isLoading) setResult(<Spin />);
	}, [setResult, isLoading]);

	const onFinish = useCallback(
		({ email }: { email: string }) => {
			mutateAsync({ email })
				.then((res) => {
					if (res.requestPasswordReset) {
						setResult(
							<Result status="success">
								{t('RequestPasswordReset.Result.Success')}
							</Result>,
						);
						form.resetFields();
					} else {
						setResult(
							<Result status="error">{t('RequestPasswordReset.Result.Error')}</Result>,
						);
					}
				})
				.catch((error) => {
					const code =
						'' + (error as any).response.errors[0]?.extensions?.response?.statusCode;
					const message = (error as any).response.errors[0].message;
					const status: '404' | '403' | '500' | 'error' =
						code === '404' || code === '403' || code === '500' ? code : 'error';
					setResult(<Result status={status} subTitle={message}></Result>);
				});
		},
		[setResult, mutateAsync],
	);

	if (result === null)
		return (
			<Form
				form={form}
				name="basic"
				initialValues={{ remember: true }}
				onFinish={onFinish}
				autoComplete="on"
				layout="vertical"
			>
				<Helmet>
					<title>{t('Meta.PasswordReset.Title')}</title>
				</Helmet>
				<Space direction="vertical" size="large">
					<Card hoverable={false}>
						<Form.Item
							label="E-Mail"
							name="email"
							rules={[
								{
									required: true,
									message: t('RequestPasswordReset.Form.MissingField', 'email address'),
								},
								{ type: 'email', message: t('RequestPasswordReset.Form.Email.Invalid') },
							]}
						>
							<Input maxLength={255} />
						</Form.Item>
					</Card>
					<Row justify="space-between">
						<Col>
							<Button onClick={() => navigate('/login')} type="text" size="large">
								{t('Login')}
							</Button>
						</Col>
						<Col>
							<Button size="large" type="primary" htmlType="submit">
								{t('RequestPasswordReset.Form.Submit')}
							</Button>
						</Col>
					</Row>
				</Space>
			</Form>
		);
	else
		return (
			<Card hoverable={false}>
				<Helmet>
					<title>{t('Meta.PasswordReset.Title')}</title>
				</Helmet>
				<Row justify="center">{result}</Row>
				<Row justify="center">
					<Button onClick={() => setResult(null)}>{t('Back')}</Button>
				</Row>
			</Card>
		);
});

export const SetNewPassword = withCentered(() => {
	const { t } = useTranslation('translations');
	const { token } = useParams();
	const { mutateAsync, isError, isSuccess, isLoading, error } =
		useUpdatePasswordMutation();
	const setSessionFromToken = useAuthStore((state) => state.setSessionFromToken);
	const resetAuthStore = useAuthStore((state) => state.reset);
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const [result, setResult] = useState<JSX.Element | null>(null);

	const { t: tt } = useTranslation('translations', { keyPrefix: 'SetNewPassword' });

	useEffect(() => {
		if (isLoading) setResult(<Spin />);
		if (token) {
			try {
				setSessionFromToken(token);
			} catch (err) {
				setResult(<Result status="error">{tt('TokenInvalid')}</Result>);
			}
		} else {
			setResult(<Result status="error">{tt('MissingTokenParam')}</Result>);
		}
	}, [setSessionFromToken, token, setResult, error, isError, isLoading, isSuccess]);

	const onFinish = useCallback(
		({ password }: { password: string; confirm: string }) => {
			if (!token) return;
			mutateAsync({ password, token })
				.then((data) => {
					if (!data.updatePassword) {
						setResult(<Result status="error">{tt('PasswordUpdateFail')}</Result>);
					} else {
						setResult(
							<Result
								status="success"
								extra={<Button onClick={() => navigate('/login')}>{t('Login')}</Button>}
							>
								{tt('PasswordUpdateSuccess')}
							</Result>,
						);
					}
				})
				.catch((error) => {
					const code =
						'' + (error as any).response.errors[0]?.extensions?.response?.statusCode;
					const message = (error as any).response.errors[0].message;
					const status: '404' | '403' | '500' | 'error' =
						code === '404' || code === '403' || code === '500' ? code : 'error';
					setResult(<Result status={status} subTitle={message}></Result>);
				})
				.finally(() => {
					form.setFieldsValue({ password: '', confirm: '' });
					resetAuthStore();
				});
		},
		[token, setResult, mutateAsync, navigate],
	);

	if (result === null)
		return (
			<Form
				form={form}
				name="basic"
				initialValues={{ remember: true }}
				onFinish={onFinish}
				autoComplete="off"
				layout="vertical"
			>
				<Helmet>
					<title>{t('Meta.PasswordReset.Title')}</title>
				</Helmet>
				<Space direction="vertical" size="large">
					<Card hoverable={false}>
						<Form.Item
							label={t('Password')}
							name="password"
							hasFeedback
							rules={[
								{ required: true, message: tt('Form.Password.Missing') },
								({ getFieldValue }) => ({
									validator(_, value) {
										// const regex = /^(?=.*[0-9])(?=.*[\W])[a-zA-Z0-9\W]{12,36}$/;
										// const match = value.match(regex);
										const match = value.length > 11;
										if (match) return Promise.resolve();
										else return Promise.reject(new Error(tt('Form.Password.Invalid')));
									},
								}),
							]}
						>
							<Input.Password maxLength={255} />
						</Form.Item>
						<Form.Item
							label={t('Confirm')}
							name="confirm"
							dependencies={['password']}
							hasFeedback
							rules={[
								{ required: true, message: tt('Form.Confirm.Missing') },
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve();
										}
										return Promise.reject(new Error(tt('Form.Confirm.Invalid')));
									},
								}),
							]}
						>
							<Input.Password maxLength={255} />
						</Form.Item>
					</Card>
					<Row justify="space-between">
						<Col>
							<Button onClick={() => navigate('/login')} type="text" size="large">
								{t('Login')}
							</Button>
						</Col>
						<Col>
							<Button size="large" type="primary" htmlType="submit">
								{tt('Form.Submit')}
							</Button>
						</Col>
					</Row>
				</Space>
			</Form>
		);
	else
		return (
			<Card hoverable={false}>
				<Row justify="center">{result}</Row>
				<Row justify="center">
					<Button onClick={() => setResult(null)}>{t('Back')}</Button>
				</Row>
			</Card>
		);
});
