import { Col, message, Modal, Row, Space, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EuCountryCodes,
	Languages,
	useCreateChannelMutation,
	useSelfQuery,
} from '../../generated/graphql';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { CountrySelect, LanguageSelect } from '../util/EnumSelect';
import { Input } from '../util/Input';
import * as Sentry from '@sentry/react';

export const CreateChannelModal = (props: {
	visible: boolean;
	companyId: number;
	onClose: () => void;
	onSuccess: (channel: any) => void;
}) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'CompanyOverview.CreateChannelModal',
	});
	const { mutateAsync, isLoading } = useCreateChannelMutation();
	const [name, setName] = useState('');
	const { data: self } = useSelfQuery();
	const selectedCompany = useSelectedCompanyStore((state) => state.company);
	const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>('EN');
	const [selectedCountry, setSelectedCountry] = useState<string | undefined>(
		selectedCompany?.address.country,
	);

	useEffect(() => {
		if (self?.self.lang) setSelectedLanguage(self.self.lang);
		if (selectedCompany) setSelectedCountry(selectedCompany.address.country);
	}, [self, selectedCompany]);

	const onOk = () => {
		if (selectedCompany == null) {
			message.error(t('ErrorNoSelectedCompany'));
			return;
		}
		mutateAsync({
			input: {
				companyId: props.companyId,
				name,
				language: (selectedLanguage ?? self?.self.lang) as Languages,
				country: (selectedCountry ?? selectedCompany.address.country) as EuCountryCodes,
			},
		})
			.then((data) => props.onSuccess(data.createChannel))
			.catch((err) => {
				Sentry.captureException(err);
				message.error(t('DefaultMessage.Create.Error'));
				props.onClose();
			});
	};

	return (
		<Modal
			title={tt('Title')}
			onCancel={(e) => props.onClose()}
			onOk={onOk}
			okText={tt('Ok')}
			cancelText={t('Cancel')}
			visible={props.visible}
			confirmLoading={isLoading}
		>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Input
						maxLength={254}
						value={name}
						placeholder={t('CompanyOverview.CreateChannelModal.Input.Placeholder')}
						onChange={(e) => setName(e.target.value)}
					/>
				</Col>
				<Col span={12}>
					<Space direction="vertical">
						<Typography.Text>{t('Language')}</Typography.Text>
						<LanguageSelect
							style={{ minWidth: '150px' }}
							value={selectedLanguage?.toString()}
							onSelect={setSelectedLanguage}
						/>
					</Space>
				</Col>
				<Col span={12}>
					<Space direction="vertical">
						<Typography.Text>{t('Country')}</Typography.Text>
						<CountrySelect
							style={{ minWidth: '150px' }}
							value={selectedCountry?.toString()}
							onSelect={setSelectedCountry}
						/>
					</Space>
				</Col>
			</Row>
		</Modal>
	);
};
