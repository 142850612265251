import {
	ArrowLeftOutlined,
	BackwardOutlined,
	BankOutlined,
	FileProtectOutlined,
	HomeOutlined,
	InboxOutlined,
	LeftOutlined,
	MailOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Breadcrumb as AntdBreadcrumb, Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

export interface BreadcrumbItemProps {
	type: 'company' | 'user' | 'incident' | 'channel' | 'home' | 'templates';
	display: string | React.ReactNode;
	routeParam?: string;
}

export interface BreadcrumbProps {
	items: BreadcrumbItemProps[];
}

const TYPE_ICON: Record<BreadcrumbItemProps['type'], () => JSX.Element> = {
	home: () => <HomeOutlined />,
	company: () => <BankOutlined />,
	user: () => <UserOutlined />,
	incident: () => <FileProtectOutlined />,
	channel: () => <InboxOutlined />,
	templates: () => <MailOutlined />,
};

const TYPE_ROUTE: Record<BreadcrumbItemProps['type'], string> = {
	home: '/admin/dashboard',
	company: '/admin/company',
	user: '/admin/user',
	incident: '/admin/incident',
	channel: '/admin/channel',
	templates: '/admin/templates',
};

const Item = (props: BreadcrumbItemProps) => {
	const navigate = useNavigate();
	let itemProps = undefined;
	if (props.routeParam !== undefined) {
		itemProps = {
			onClick: () => navigate(`${TYPE_ROUTE[props.type]}/${props.routeParam}`),
		};
	}
	return (
		<AntdBreadcrumb.Item>
			{itemProps ? (
				<Button {...itemProps} type="link" size="small">
					{TYPE_ICON[props.type]()} {props.display}
				</Button>
			) : (
				<Typography.Text>
					{TYPE_ICON[props.type]()} {props.display}
				</Typography.Text>
			)}
		</AntdBreadcrumb.Item>
	);
};

export const Breadcrumb = (props: BreadcrumbProps) => {
	const items = props.items.slice();
	items.unshift({ type: 'home', display: '', routeParam: '' });
	const navigate = useNavigate();

	return (
		<Space>
			{items.length > 1 ? (
				<Button
					type="default"
					onClick={() =>
						navigate(
							// `${TYPE_ROUTE[items[items.length - 2].type]}/${
							// 	items[items.length - 2].routeParam
							// }`,
							-1,
						)
					}
				>
					{<ArrowLeftOutlined />}
				</Button>
			) : null}
			<AntdBreadcrumb style={{ textAlign: 'left' }}>
				{items.map((props: BreadcrumbItemProps, i: number) => (
					<Item key={i} {...props} />
				))}
			</AntdBreadcrumb>
		</Space>
	);
};
