import { Form, FormInstance } from 'antd';
import { useForm, FormProps } from 'antd/lib/form/Form';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { AnimatePresence, motion } from 'framer-motion';
import { t } from 'i18next';
import { useState } from 'react';
import { CreateIncidentInput, IncidentCategory } from '../../generated/graphql';
import { DesignConfig } from '../HinterPortal/ChannelEditor';
import { IncidentCategorySelect } from '../util/EnumSelect';
import { Input } from '../util/Input';
import { Switch } from '../util/Switch';

export const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
		md: { span: 6 },
	},
	wrapperCol: {
		flex: 'auto',
	},
};

export const IncidentReportFormFields = ({
	design,
	form,
}: {
	form: FormInstance;
	design?: DesignConfig;
}) => {
	const [anonymous, setAnonymous] = useState(false);
	const switchAnonymous: SwitchChangeEventHandler = (e) => {
		form.setFieldsValue({ hinter: { anonymous: e } });
		setAnonymous(e);
	};

	return (
		<>
			<Form.Item
				name="subject"
				label={t('ReportForm.Form.Subject') + '*'}
				{...formItemLayout}
				rules={[
					{
						required: true,
						message: t('ReportForm.Form.Missing.Subject'),
					},
				]}
			>
				<Input
					design={design}
					placeholder={t('ReportForm.Form.Placeholder.Subject')}
					maxLength={450}
				/>
			</Form.Item>
			<Form.Item
				name="description"
				label={t('ReportForm.Form.Description') + '*'}
				{...formItemLayout}
				rules={[
					{
						required: true,
						message: t('ReportForm.Form.Missing.Description'),
					},
				]}
			>
				<Input.TextArea
					design={design}
					placeholder={t('ReportForm.Form.Placeholder.Description')}
					rows={5}
					minLength={100}
					maxLength={5500}
				/>
			</Form.Item>
			<Form.Item
				name="category"
				label={t('ReportForm.Form.Category') + '*'}
				{...formItemLayout}
				rules={[
					{
						required: true,
						message: t('ReportForm.Form.Missing.Category'),
					},
				]}
			>
				<IncidentCategorySelect
					design={design}
					// defaultValue={IncidentCategory.Uncategorized}
				/>
			</Form.Item>
			<Form.Item
				label={t('ReportForm.Form.Anonymous')}
				name={['hinter', 'anonymous']}
				{...formItemLayout}
				wrapperCol={{ flex: 'inherit' }}
			>
				<Switch design={design} checked={anonymous} onChange={switchAnonymous} />
			</Form.Item>
			<AnimatePresence>
				{form.getFieldValue(['hinter', 'anonymous']) ? null : (
					<motion.div
						initial={{ opacity: 0, scaleY: 0 }}
						animate={{ opacity: 1, scaleY: 1 }}
						exit={{ opacity: 0, scaleY: 0 }}
					>
						<Form.Item
							label={t('ReportForm.Form.Contact')}
							name="hinter"
							noStyle
							shouldUpdate={(prev, current) => {
								return prev.hinter?.anonymous !== current.hinter?.anonymous;
							}}
						>
							{/** untranslated */}
							<Form.Item
								key={0}
								label={t('ReportForm.Form.Email') + '*'}
								name={['hinter', 'email']}
								{...formItemLayout}
								rules={[
									{
										type: 'email',
										message: t('ReportForm.Form.Invalid.Email'),
									},
									// {
									// 	required: !form.getFieldValue(['hinter', 'anonymous']),
									// 	message: t('ReportForm.Form.Missing.Email'),
									// },
								]}
							>
								<Input design={design} maxLength={100} />
							</Form.Item>
							<Form.Item
								label={t('ReportForm.Form.Firstname')}
								name={['hinter', 'firstname']}
								{...formItemLayout}
							>
								<Input design={design} maxLength={100} />
							</Form.Item>
							<Form.Item
								label={t('ReportForm.Form.Lastname')}
								name={['hinter', 'lastname']}
								{...formItemLayout}
							>
								<Input design={design} maxLength={100} />
							</Form.Item>
							<Form.Item
								label={t('ReportForm.Form.Phone')}
								name={['hinter', 'phone']}
								{...formItemLayout}
							>
								<Input design={design} maxLength={100} />
							</Form.Item>
							{/* <Form.Item
								label={t('ReportForm.Form.DateOfBirth')}
								name={['hinter', 'born']}
								{...formItemLayout}
							>
								<Input design={design} maxLength={100} />
							</Form.Item> */}
						</Form.Item>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

export interface IncidentReportFormProps extends FormProps {
	design?: DesignConfig;
}

export const IncidentReportForm = ({ design, ...formProps }: IncidentReportFormProps) => {
	const [form] = useForm<CreateIncidentInput>();

	if (formProps.form == null) formProps.form = form;

	return (
		<Form style={{ width: '100%', padding: '1em' }} layout="horizontal" {...formProps}>
			<IncidentReportFormFields form={form} />
		</Form>
	);
};
