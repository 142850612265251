import { CopyFilled, CopyOutlined, WarningFilled } from '@ant-design/icons';
import {
	Button,
	Col,
	Typography,
	Row,
	Spin,
	List,
	Space,
	message,
	Divider,
	Modal,
	Tag,
	Checkbox,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import {
	AccessLevel,
	AddChannelUserMutation,
	EuCountryCodes,
	FeatureNames,
	Languages,
	RemoveChannelUserMutation,
	useAddChannelUserMutation,
	useChannelAuditLogQuery,
	useChannelQuery,
	useCompanyChannelsQuery,
	useRemoveChannelMutation,
	useRemoveChannelUserMutation,
	useSelfQuery,
	useUpdateChannelMutation,
	useUpdateOnboardingMutation,
} from '../../generated/graphql';
import { useAuthStore } from '../../hooks/useAuth';
import { UserAvatar } from '../util/Avatar';
import { Breadcrumb } from './Breadcrumb';
import { AddUserModal } from './AddUserModal';
import { UserInfo } from './UserSelect';
import { Joyride } from '../util/Joyride';
import { StoreHelpers } from 'react-joyride';
import { CompliantModal } from './CompliantModal';
import { AuditLogPopup, AuditLogPopupProps } from './AuditLog';
import { FeatureLock } from './FeatureLock';
import { CountrySelect, LanguageSelect } from '../util/EnumSelect';
import { Helmet } from 'react-helmet';
import { Select } from '../util/Select';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { accessLevelAbove } from '../../lib/util';
import Paragraph from 'antd/lib/typography/Paragraph';
import Text from 'antd/lib/typography/Text';
import { FeatureToggle } from '../util/FeatureFlags';
import { Card } from '../util/Card';
import { captureException } from '@sentry/react';
import CompanyRemovedInfo from '../CompanyRemovedInfo';

const { Title } = Typography;

const ChannelAuditLog = (
	props: Omit<AuditLogPopupProps, 'isLoading' | 'isError' | 'log'> & {
		channelId: number;
	},
) => {
	const { data, isLoading, isError } = useChannelAuditLogQuery(
		{ channelId: props.channelId },
		{ enabled: props.visible },
	);
	return (
		<AuditLogPopup
			isLoading={isLoading}
			isError={isError}
			log={data?.channel.auditLog || []}
			{...props}
		/>
	);
};

export interface RemoveChannelModalProps {
	channelId: number;
	visible: boolean;
	hide: () => void;
}

const RemoveChannelModal = ({ channelId, visible, hide }: RemoveChannelModalProps) => {
	const { t } = useTranslation('translations', {
		keyPrefix: 'ChannelOverview.RemoveChannel.Modal',
	});
	const [transferChannelId, setTransferChannelId] = useState<number>();
	const selectedCompany = useSelectedCompanyStore((state) => state.company);
	const { mutateAsync, isLoading } = useRemoveChannelMutation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const onOk = useCallback(() => {
		if (!transferChannelId) return;
		mutateAsync({ id: channelId, transferTo: transferChannelId })
			.then(() => {
				message.success(t('Message.Success'));
				hide();
				navigate(`/admin/dashboard`);
				['self', 'selfCompanies', 'channel', 'company'].forEach((queryKey) =>
					queryClient.invalidateQueries({
						queryKey,
					}),
				);
			})
			.catch(() => {
				message.error(t('Message.Error'));
			});
	}, [mutateAsync, transferChannelId]);

	const {
		data: channelsData,
		isLoading: channelsIsLoading,
		isSuccess: channelsIsSuccess,
		isError: channelsIsError,
		refetch: channelsRefetch,
	} = useCompanyChannelsQuery(
		{ id: selectedCompany?.id ?? 0 },
		{ enabled: selectedCompany != null },
	);
	const channels = channelsData?.company.channels;

	return (
		<Modal
			visible={visible}
			confirmLoading={isLoading}
			title={t('Title')}
			okText={t('Ok')}
			cancelText={t('Cancel')}
			onOk={onOk}
			onCancel={hide}
			okButtonProps={{ danger: true, disabled: transferChannelId === undefined }}
		>
			<Typography.Paragraph>{t('Recommendation')}</Typography.Paragraph>
			<Typography.Paragraph type="warning">{t('Warning')}</Typography.Paragraph>
			<Typography.Paragraph type="secondary">{t('AccessRights')}</Typography.Paragraph>
			<Select
				value={transferChannelId}
				onChange={setTransferChannelId}
				placeholder={channelsIsLoading ? <Spin size="small" /> : t('Placeholder')}
			>
				{' '}
				{channels
					?.filter((c) => c.id !== channelId)
					.map((c) => (
						<Select.Option key={c.id} value={c.id}>
							{' '}
							{c.name}{' '}
						</Select.Option>
					))}{' '}
			</Select>{' '}
		</Modal>
	);
};
export const ChannelOverview = () => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'ChannelOverview' });
	const { id: idString } = useParams();
	if (!idString) throw new Error(tt('MissingUrlParam'));
	const id = parseInt(idString);
	const queryClient = useQueryClient();
	const [{ data: channelData, isLoading, isError, refetch }, channelQueryKey] = [
		useChannelQuery({ id }),
		'channel',
	];
	const { mutateAsync: updateChannel } = useUpdateChannelMutation();
	const [addModalVisible, setAddModalVisible] = useState(false);
	const [rewardModalVisible, setRewardModalVisible] = useState(false);
	const [{ mutateAsync: mutateRemovUser }, removeChannelMutationKey] = [
		useRemoveChannelUserMutation(),
		'removeChannelUser',
	];
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const [
		{ data: selfData, isLoading: selfIsLoading, isError: selfIsError },
		selfQueryKey,
	] = [useSelfQuery({}, { enabled: isAuthenticated }), 'self'];
	const updateOnboarding = useUpdateOnboardingMutation();
	const { mutateAsync } = useAddChannelUserMutation();
	const [joyrideHelpers, setJoyrideHelpers] = useState<StoreHelpers | null>();
	const [onboardingVisible, setOnboardingVisible] = useState(false);
	const [auditLogVisible, setAuditLogVisible] = useState(false);
	const navigate = useNavigate();
	const [removeChannelModalVisible, setRemoveChannelModalVisible] = useState(false);
	const selfAccessLevel = useSelectedCompanyStore(
		(state) => state.company?.selfAccessLevel,
	);

	// useEffect(() => {
	// 	if (selfData?.self.onboarding?.channelCompleted === false) setOnboardingVisible(true);
	// }, [selfData, setOnboardingVisible]);

	if (isLoading || selfIsLoading || !channelData || !selfData) return <Spin />;
	if (isError || selfIsError) return <WarningFilled />;
	if (channelData?.channel.company.deletion) {
		return (
			<CompanyRemovedInfo
				name={channelData.channel.company.name}
				message={channelData.channel.company.deletion.message}
				backRoute="/admin/dashboard"
			/>
		);
	}

	const channel = channelData.channel;
	const self = selfData.self;
	const channelUserIds = channel.users.map((u) => u.id);
	const addableUsers = channel.company.members
		.filter(
			(m) =>
				m.accessLevel !== AccessLevel.Guest &&
				!channelUserIds.includes(m.user.id) &&
				m.user.surname != null &&
				m.user.lastname != null,
		)
		.map((m) => m.user) as UserInfo[];

	const relativeChannelRoute = `/portal/s/${channel.nanoId}`;
	const channelRoute = (process.env.REACT_APP_BASENAME + relativeChannelRoute).replace(
		'//',
		'/',
	);
	const link = `${window.location.origin}${channelRoute}`;

	const removeUser = (
		user: RemoveChannelUserMutation['removeChannelUser']['users'][0],
	) => {
		mutateRemovUser({ channelId: channel.id, userId: user.id })
			.then((res) => {
				const removedId = res.removeChannelUser.users[0].id;
				channel.users = channel.users.filter((u) => !(u.id === removedId));
				queryClient.invalidateQueries(channelQueryKey);
				queryClient.invalidateQueries(removeChannelMutationKey);
				queryClient.invalidateQueries(selfQueryKey);
			})
			.catch(captureException);
	};

	const createEditable = (property: 'name') => {
		return {
			onChange: async (x: any) => {
				if (x === channel[property]) return;
				channelData.channel[property] = x;
				await updateChannel({ id: channel.id, update: { [property]: x } });
				refetch();
			},
		};
	};

	const updateChannelLanguage = async (x: string) => {
		channelData.channel.config.language = x as Languages;
		await updateChannel({
			id: channel.id,
			update: { config: { language: x as Languages } },
		})
			.then(() => {
				refetch();
				message.success(t('DefaultMessage.Save.Success'));
			})
			.catch(() => message.error(t('DefaultMessage.Save.Error')));
	};

	const updateChannelCountry = async (x: string) => {
		channelData.channel.config.country = x as EuCountryCodes;
		await updateChannel({
			id: channel.id,
			update: { config: { country: x as EuCountryCodes } },
		})
			.then(() => {
				refetch();
				message.success(t('DefaultMessage.Save.Success'));
			})
			.catch(() => message.error(t('DefaultMessage.Save.Error')));
	};

	const updateChannelShowRecipients = async (x: boolean) => {
		channelData.channel.config.showRecipients = x;
		await updateChannel({
			id: channel.id,
			update: { config: { showRecipients: x } },
		})
			.then(() => {
				refetch();
				message.success(t('DefaultMessage.Save.Success'));
			})
			.catch(() => message.error(t('DefaultMessage.Save.Error')));
	};

	const updateChannelEnableAnonymousReports = async (x: boolean) => {
		channelData.channel.config.enableAnonymousReports = x;
		await updateChannel({
			id: channel.id,
			update: { config: { enableAnonymousReports: x } },
		})
			.then(() => {
				refetch();
				message.success(t('DefaultMessage.Save.Success'));
			})
			.catch(() => message.error(t('DefaultMessage.Save.Error')));
	};

	const updateChannelEnableVoiceReports = async (x: boolean) => {
		channelData.channel.config.enableVoiceReports = x;
		await updateChannel({
			id: channel.id,
			update: { config: { enableVoiceReports: x } },
		})
			.then(() => {
				refetch();
				message.success(t('DefaultMessage.Save.Success'));
			})
			.catch(() => message.error(t('DefaultMessage.Save.Error')));
	};

	const updateChannelEnableLksg = async (x: boolean) => {
		channelData.channel.config.enableLksg = x;
		await updateChannel({ id: channel.id, update: { config: { enableLksg: x } } })
			.then(() => {
				refetch();
				message.success(t('DefaultMessage.Save.Success'));
			})
			.catch(() => message.error(t('DefaultMessage.Save.Error')));
	};

	const updateChannelEnableImprovementSuggestion = async (x: boolean) => {
		channelData.channel.config.enableImprovementSuggestion = x;
		await updateChannel({
			id: channel.id,
			update: { config: { enableImprovementSuggestion: x } },
		})
			.then(() => {
				refetch();
				message.success(t('DefaultMessage.Save.Success'));
			})
			.catch(() => message.error(t('DefaultMessage.Save.Error')));
	};

	const onCopyLink = () => {
		navigator.clipboard.writeText(link);
		message.success(t('Clipboard.Success'));
		if (onboardingVisible) {
			updateOnboarding.mutateAsync({ input: { channelCompleted: true } });
			joyrideHelpers?.close();
			setOnboardingVisible(false);
			setRewardModalVisible(true);
		}
	};

	return (
		<>
			<Helmet>
				<title>{t('Meta.Channel.Title', { name: channel.name })}</title>
				<meta
					name="description"
					content={t('Meta.Channel.Description', { name: channel.name })}
				/>
			</Helmet>
			<Joyride
				getHelpers={setJoyrideHelpers}
				continuous
				showProgress
				run={onboardingVisible}
				steps={[
					{
						target: '.channeloverview',
						content: (
							<Typography.Paragraph>
								{t('ChannelOverview.Onboarding.Intro')}
							</Typography.Paragraph>
						),
						disableBeacon: true,
					},
					{
						target: '.copylink',
						content: (
							<Typography.Paragraph>
								{t('ChannelOverview.Onboarding.CopyLink')}
							</Typography.Paragraph>
						),
						disableBeacon: true,
						spotlightClicks: true,
						hideFooter: true,
					},
				]}
			/>
			{/* onboarding finished reward modal */}
			<CompliantModal
				visible={rewardModalVisible}
				onVisibleChange={(v: boolean) => setRewardModalVisible(v)}
			/>
			<AddUserModal
				visible={addModalVisible}
				users={addableUsers}
				mutateAsync={async (userId) => {
					return await mutateAsync({ channelId: channel.id, userId });
				}}
				onClose={() => setAddModalVisible(false)}
				onSuccess={(res: AddChannelUserMutation) =>
					(channel.users = res.addChannelUser.users)
				}
			/>
			<ChannelAuditLog
				channelId={channel.id}
				visible={auditLogVisible}
				onClose={() => setAuditLogVisible(false)}
			/>
			<RemoveChannelModal
				visible={removeChannelModalVisible}
				hide={() => setRemoveChannelModalVisible(false)}
				channelId={channel.id}
			/>
			<Space direction="vertical" size="large" style={{ width: '100%' }}>
				<Row gutter={[16, 16]}>
					<Breadcrumb
						items={[
							{
								type: 'company',
								display: channel.company.name,
								routeParam: `${channel.company.id}`,
							},
							{ type: 'channel', display: channel.name },
						]}
					/>
				</Row>
				<Row className="channeloverview" justify="space-between" gutter={[8, 8]}>
					<Col flex="auto">
						<Title editable={createEditable('name')} level={2}>
							{channelData.channel.name}
						</Title>
					</Col>
					<Col>
						<Space>
							<FeatureLock featureName={FeatureNames.AuditLog}>
								<Button onClick={() => setAuditLogVisible(true)}>
									{t('ChannelOverview.OpenAuditLog')}
								</Button>
							</FeatureLock>
							<Button className="copylink" onClick={onCopyLink}>
								{t('CopyLink')}
								<CopyOutlined />
							</Button>
							<Button type="primary" onClick={() => navigate(relativeChannelRoute)}>
								{t('Open')}
							</Button>
						</Space>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col sm={24} md={24} lg={12} xl={10}>
						<Space direction="vertical" size="middle">
							<Space direction="horizontal">
								<Text>Short ID</Text>
								<Text code>{channel.nanoId}</Text>
							</Space>
							<Space direction="horizontal">
								<Text>Long ID</Text>
								<Text code>{channel._uuid}</Text>
							</Space>
							{channel.default && (
								<Tag color="blue">
									{t('Default')} {t('Channel')}
								</Tag>
							)}
						</Space>
					</Col>
					<Col sm={24} md={24} lg={12} xl={14}>
						<Card>
							<Space direction="vertical" size="small">
								<Space direction="horizontal" size="middle">
									<Typography.Text>{t('Language')}</Typography.Text>
									<LanguageSelect
										style={{ minWidth: '120px', width: 'auto' }}
										defaultValue={channelData.channel.config.language}
										onChange={(v, _) => {
											updateChannelLanguage(v);
										}}
									/>
								</Space>
								<Space direction="horizontal" size="middle">
									<Typography.Text>{t('Country')}</Typography.Text>
									<CountrySelect
										style={{ minWidth: '120px', width: 'auto' }}
										defaultValue={channelData.channel.config.country}
										onChange={(v, _) => {
											updateChannelCountry(v);
										}}
									/>
								</Space>
								<FeatureToggle features={[FeatureNames.ChannelConfigShowRecipients]}>
									<Checkbox
										checked={channelData.channel.config.showRecipients}
										onChange={(e) => updateChannelShowRecipients(e.target.checked)}
									>
										{tt('ShowRecipientsCheckbox')}
									</Checkbox>
								</FeatureToggle>
								<FeatureToggle features={[FeatureNames.ChannelConfigEnableVoiceReports]}>
									<Checkbox
										checked={channelData.channel.config.enableVoiceReports}
										onChange={(e) => updateChannelEnableVoiceReports(e.target.checked)}
									>
										{tt('EnableVoiceReportsCheckbox')}
									</Checkbox>
								</FeatureToggle>
								<Checkbox
									checked={channelData.channel.config.enableAnonymousReports}
									onChange={(e) => updateChannelEnableAnonymousReports(e.target.checked)}
								>
									{tt('EnableAnonymousReportsCheckbox')}
								</Checkbox>
								{/* <Checkbox
									checked={channelData.channel.config.enableLksg}
									onChange={(e) => updateChannelEnableLksg(e.target.checked)}
								>
									{tt('EnableLKSG')}
								</Checkbox> */}
								<Checkbox
									checked={channelData.channel.config.enableImprovementSuggestion}
									onChange={(e) =>
										updateChannelEnableImprovementSuggestion(e.target.checked)
									}
								>
									{tt('EnableImprovementSuggestionForm')}
								</Checkbox>
							</Space>
						</Card>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					<Col>
						<Title level={3}>{t('Users')}</Title>
					</Col>
					<Col flex="auto"></Col>
					<Col>
						{addableUsers.length > 0 && (
							<FeatureLock featureName={FeatureNames.MultipleUsers}>
								<Button onClick={() => setAddModalVisible(true)}>{t('Add')}</Button>
							</FeatureLock>
						)}
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<List
							size="small"
							dataSource={channel.users}
							renderItem={(user) => (
								<List.Item
									actions={
										channel.users.length > 1
											? [
													<Button key={0} onClick={() => removeUser(user)}>
														{self.id === user.id ? t('Leave') : t('Remove')}
													</Button>,
											  ]
											: []
									}
								>
									<List.Item.Meta
										style={{ textAlign: 'left' }}
										avatar={
											<UserAvatar
												key={user.id}
												user={{
													surname: user.surname,
													lastname: user.lastname,
													id: user.avatar?.id,
												}}
											/>
										}
										title={user.surname + ' ' + user.lastname}
									/>
								</List.Item>
							)}
						/>
					</Col>
				</Row>
				{accessLevelAbove(AccessLevel.Admin, selfAccessLevel) && (
					<Row gutter={[8, 8]}>
						<Col span={24}>
							<Typography.Title level={3}>
								{t('ChannelOverview.Administration.Title')}
							</Typography.Title>
						</Col>
						<Col>
							<Button
								danger
								type="ghost"
								onClick={() => setRemoveChannelModalVisible(true)}
							>
								{t('ChannelOverview.RemoveChannel.Button')}
							</Button>
						</Col>
					</Row>
				)}
			</Space>
		</>
	);
};
