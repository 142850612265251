import { LeftOutlined, PhoneOutlined, RightOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { UnassignedPhoneCalls } from '../PhoneCall';
import { useSelectedCompanyStore } from '../../../hooks/useSelectedCompanyStore';
import { Button } from '../../util/Button';
import { useTranslation } from 'react-i18next';
import { PhoneCallDataFragment } from '../../../generated/graphql';

const DashboardPhoneCalls = ({
	onNewIncident,
}: {
	onNewIncident: (pC: PhoneCallDataFragment) => void;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'DashboardPhoneCalls' });
	const [opened, setOpened] = useState(false);

	const selectedCompany = useSelectedCompanyStore((s) => s.company);

	if (!selectedCompany) return null;
	return (
		<motion.div
			style={{
				position: 'fixed',
				display: 'flex',
				alignItems: 'start',
				gap: '8px',
				left: 0,
				top: 0,
				height: '100vh',
				maxWidth: '100%',
				zIndex: 3,
			}}
			initial="closed"
			animate={opened ? 'opened' : 'closed'}
			variants={{
				opened: { x: 0 },
				closed: { x: -300 },
			}}
		>
			<Space
				direction="vertical"
				style={{
					padding: '8px',
					width: '300px',
					height: '100%',
					overflowY: 'auto',
					borderTopRightRadius: '16px',
					borderBottomRightRadius: '16px',
				}}
				className="PageBackground"
			>
				<Typography.Title level={4}>{t('Title')}</Typography.Title>
				<Typography.Title level={5}>{selectedCompany.name}</Typography.Title>
				<UnassignedPhoneCalls
					onNewIncident={onNewIncident}
					companyId={selectedCompany.id}
				/>
				{selectedCompany.parent && (
					<>
						<Typography.Title level={5}>{selectedCompany.parent.name}</Typography.Title>
						<UnassignedPhoneCalls
							onNewIncident={onNewIncident}
							companyId={selectedCompany.parent.id}
							movableToCompany={selectedCompany}
						/>
					</>
				)}
			</Space>
			<Button
				style={{ marginTop: '8px' }}
				onClick={() => setOpened(!opened)}
				icon={<PhoneOutlined />}
			>
				{opened ? <LeftOutlined /> : <RightOutlined />}
			</Button>
		</motion.div>
	);
};

export default DashboardPhoneCalls;
