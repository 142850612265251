import { useNavigate } from 'react-router-dom';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { useTranslation } from 'react-i18next';
import {
	Confirm2FaMutation,
	Confirm2FaMutationVariables,
	LoginMutationVariables,
	useConfirm2FaMutation,
	useLogoutMutation,
} from '../generated/graphql';
import createStore, { GetState, SetState, StateCreator } from 'zustand';
import { configurePersist } from '@onlyknoppas/zustand-persist';
import {
	LoginHinterMutationVariables,
	LoginHinterMutation,
	LoginMutation,
	useLoginHinterMutation,
	useLoginMutation,
	useRefreshMutation,
	useSetMasterKeySaltMutation,
} from '../generated/graphql';
import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { createMasterKey, deriveMasterKey } from '../lib/crypt';
import { SodiumPlus } from 'sodium-plus';
import { useCryptSensitiveStore, useCryptStore, useManageMasterKey } from './useCrypt';
import { UseMutateAsyncFunction, useQueryClient } from 'react-query';
import { useSelectedCompanyStore } from './useSelectedCompanyStore';
import { getCookieValue } from '../lib/util';
import * as Sentry from '@sentry/react';

export enum Roles {
	USER = 'USER',
	HINTER = 'HINTER',
}

export type UserSession = LoginMutation['login'];
export type HinterSession = LoginHinterMutation['loginHinter'];
export type Session = UserSession | HinterSession;

export interface AuthStore {
	userHasBeenAuthenticatedBefore: boolean;
	isAuthenticated: boolean;
	isAuthenticating: boolean;
	session: Session | null;
	refreshInterval: NodeJS.Timer | null;
	initialized: boolean;
	signalLogout: boolean;
	signalLogoutReason?: string;
	logoutReason?: string;
	setInitialized: () => {};
	setUnauthenticated: (reason?: string) => void;
	setIsAuthenticating: (b: boolean) => void;
	setSession: (session: Session) => void;
	setSessionFromToken: (token: string) => void;
	setRefreshInterval: (refreshInterval: NodeJS.Timer | null) => void;
	reset: () => void;
	emitSignalLogout: (signalLogoutReason?: string) => void;
	consumeSignalLogout: () => void;
	setLogoutReason: (reason: string) => void;
}

const storeLogic: StateCreator<AuthStore, SetState<AuthStore>, GetState<AuthStore>> = (
	set,
) => {
	return {
		userHasBeenAuthenticatedBefore: false,
		isAuthenticating: false,
		isAuthenticated: false,
		session: null,
		refreshInterval: null,
		initialized: false,
		signalLogout: false,
		signalLogoutReason: '',
		logoutReason: '',
		setInitialized: () => ({ initialized: true }),
		setUnauthenticated: (logoutReason?: string) => {
			set((state) => {
				if (state.refreshInterval) clearInterval(state.refreshInterval);
				return { isAuthenticated: false, logoutReason };
			});
		},
		setIsAuthenticating: (b: boolean) => set((state) => ({ isAuthenticating: b })),
		setSession: (session: Session) =>
			set((state) => ({
				session: { ...state.session, ...session },
				isAuthenticated: session.session2FA == null,
				userHasBeenAuthenticatedBefore: session.role === Roles.USER,
			})),
		setSessionFromToken: (token: string) => {
			const payload = jwtDecode(token) as {
				role: string;
				jti: number;
				exp: number;
				userId: number;
				sid: number;
				csrfToken: string;
			};
			set((state) => ({
				session: {
					...state.session,
					csrfToken: payload.csrfToken,
					role: payload.role,
					user: payload.role === Roles[Roles.USER] ? { id: payload.userId } : null,
					hinter:
						payload.role === Roles[Roles.HINTER]
							? { id: payload.userId, channelId: '' }
							: null,
					expires: payload.exp,
					jwtId: payload.jti,
					token,
					id: payload.sid,
					__typename: 'Session',
					superadmin: false,
				},
				isAuthenticating: false,
				isAuthenticated: true,
				userHasBeenAuthenticatedBefore: payload.role === Roles.USER,
			}));
		},
		setRefreshInterval: (refreshInterval) => set((state) => ({ refreshInterval })),
		reset: () =>
			set(
				{
					isAuthenticating: false,
					isAuthenticated: false,
					session: null,
					refreshInterval: null,
					initialized: false,
				},
				true,
			),
		emitSignalLogout: (reason?: string) =>
			set({ signalLogout: true, signalLogoutReason: reason }),
		consumeSignalLogout: () =>
			set((state) => ({ signalLogout: false, logoutReason: state.signalLogoutReason })),
		setLogoutReason: (logoutReason: string) => set({ logoutReason }),
	};
};

const { persist } = configurePersist({
	storage: localStorage,
	rootKey: 'auth',
});

export const useAuthStore = createSelectorHooks(
	createStore<AuthStore>(
		persist(
			{
				key: 'auth',
				denylist: ['isAuthenticating'],
			},
			storeLogic,
		),
	),
);

export const useRefresh = () => {
	const { mutateAsync: mutateRefresh } = useRefreshMutation();
	const { session, setSession, isAuthenticated } = useAuthStore();
	const { t } = useTranslation('translations');
	const logout = useLogout();

	const refresh = () =>
		mutateRefresh({})
			.then((res) => setSession(res.refreshSession))
			.catch(() => logout(t('LogoutReason.RefreshFail')));

	useEffect(() => {
		if (!isAuthenticated || !session) return;
		const delay = new Date(session.expires).valueOf() - new Date().valueOf();
		const interval = setTimeout(refresh, delay * 0.8);
		console.log('Scheduled refresh in ', delay);
		return () => {
			clearInterval(interval);
		};
	}, [session]);
};

type LoginResult = {
	success: boolean;
	message?: string;
};

type LoginFunctionType = (
	email: string,
	password: string,
) => Promise<LoginResult & { need2FA?: boolean }>;
type LoginHinterFunctionType = (
	token: string,
) => Promise<LoginResult & { res?: LoginHinterMutation }>;
type Confirm2FaCodeFunctionType = (
	session2FAUuid: string,
	code: string,
) => Promise<LoginResult>;

export const useLogin: () => {
	login: [LoginFunctionType, boolean];
	loginHinter: [LoginHinterFunctionType, boolean];
	confirm2FaCode: [Confirm2FaCodeFunctionType, boolean];
} = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'useAuth' });
	const { mutateAsync: mutateLogin, isLoading: isLoadingLogin } = useLoginMutation();
	const { mutateAsync: mutateLoginHinter, isLoading: isLoadingLoginHinter } =
		useLoginHinterMutation();
	const { mutateAsync: mutateCofirm2FA, isLoading: isLoadingConfirm2FA } =
		useConfirm2FaMutation();

	const { setIsAuthenticating, setSession } = useAuthStore();
	const resetCryptStore = useCryptStore((state) => state.reset);
	const manageMasterKey = useManageMasterKey();

	const login = useCallback<LoginFunctionType>(
		(email: string, password: string) => {
			const f = async () => {
				setIsAuthenticating(true);
				try {
					const res = await mutateLogin({ email, password });
					setSession(res.login);
					const salt = res.login.user?.masterKeySalt;
					manageMasterKey(password, salt);
					if (res.login.session2FA == null) {
						setIsAuthenticating(false);
						return { success: true, message: '' };
					} else {
						return { success: true, need2FA: true, message: '' };
					}
				} catch (err: any) {
					setIsAuthenticating(false);
					return {
						success: false,
						message: err.response?.errors[0].message || t('Login.Message.Error'),
					};
				}
			};
			return f();
		},
		[setIsAuthenticating, setSession, mutateLogin],
	);

	const confirm2FaCode = useCallback<Confirm2FaCodeFunctionType>(
		(session2FAUuid: string, code: string) => {
			const f = async () => {
				try {
					const res = await mutateCofirm2FA({ uuid: session2FAUuid, code });
					setSession(res.confirm2FASession);
					setIsAuthenticating(false);
					return { success: true, message: '' };
				} catch (err: any) {
					return { success: false, message: err.response.errors[0].message };
				}
			};
			return f();
		},
		[setSession, mutateCofirm2FA],
	);

	const loginHinter = useCallback<LoginHinterFunctionType>(
		(token: string) => {
			const f = async () => {
				setIsAuthenticating(true);
				try {
					const res = await mutateLoginHinter({ token });
					resetCryptStore();
					setSession(res.loginHinter);
					setIsAuthenticating(false);
					const salt = res.loginHinter.hinter?.masterKeySalt;
					manageMasterKey(token.slice(0, 16), salt);
					return { success: true, message: '', res };
				} catch (err) {
					setIsAuthenticating(false);
					return { success: false, message: JSON.stringify(err) };
				}
			};
			return f();
		},
		[setIsAuthenticating, mutateLoginHinter, setSession],
	);

	return {
		login: [login, isLoadingLogin],
		loginHinter: [loginHinter, isLoadingLoginHinter],
		confirm2FaCode: [confirm2FaCode, isLoadingConfirm2FA],
	};
};

export const useLogout = () => {
	const setUnauthenticated = useAuthStore((state) => state.setUnauthenticated);
	const setLogoutReason = useAuthStore.useSetLogoutReason();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const sensitiveReset = useCryptSensitiveStore((state) => state.reset);
	const cryptReset = useCryptStore((state) => state.reset);
	const authReset = useAuthStore((state) => state.reset);
	const role = useAuthStore((state) => state.session?.role);
	const selectedCompanyReset = useSelectedCompanyStore((state) => state.reset);
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	// TOOD: fix circular dependency
	const { mutateAsync: mutateLogout } = useLogoutMutation();
	const logout = (reason?: string) => {
		if (!isAuthenticated) return;
		setUnauthenticated();
		reason && setLogoutReason(reason);
		mutateLogout({})
			.catch(Sentry.captureException)
			.finally(() => {
				sensitiveReset();
				cryptReset();
				authReset();
				selectedCompanyReset();
				if (role === Roles.USER && !window.location.pathname.endsWith('/login')) {
					navigate('/login');
				}
				queryClient.removeQueries();
			});
	};

	return logout;
};
