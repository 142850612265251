import { Button, Col, Divider, Row, Space, Spin } from 'antd';
import { Select } from '../util/Select';
import { Typography } from 'antd';
import { useRef, useState } from 'react';
import { useGQLMutationWithFiles } from '../../hooks/useGQL';
import { usePutMedia } from '../../hooks/useREST';
import { UserAvatar } from '../util/Avatar';
import { WarningFilled } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import {
	useLanguagesQuery,
	useSelfQuery,
	useUpdateLangMutation,
	Languages,
	useUpdateUserMutation,
	FeatureNames,
} from '../../generated/graphql';
import { gql } from 'graphql-request';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from './Breadcrumb';
import { changeLanguage } from 'i18next';
import { FeatureToggle } from '../util/FeatureFlags';
import { create } from 'domain';
import { Card } from '../util/Card';
import { LanguageSelect } from '../util/EnumSelect';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { DemoSwitch } from './DemoSwitch';

const { Title, Text } = Typography;

export const SettingsPage = () => {
	const { t } = useTranslation('translations');
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [{ data, isLoading, isError, refetch }, selfQueryKey] = [useSelfQuery(), 'self'];
	const { data: languagesData } = useLanguagesQuery();
	const { mutateAsync: updateLang } = useUpdateLangMutation();
	const fileInputElem = useRef<HTMLInputElement | null>(null);
	const { mutateAsync: putAvatar } = usePutMedia({ enabled: !isLoading && !isError });
	const { mutateAsync: updateUser } = useUpdateUserMutation();
	const { mutateAsync: setUserAvatar } = useGQLMutationWithFiles(gql`
		mutation ($id: Int!, $file: Upload!) {
			setUserAvatar(id: $id, file: $file, extension: "png") {
				avatar {
					id
					uri
				}
			}
		}
	`); // TODO: magic number
	const [avatarRefetch, setAvatarRefetch] = useState<() => void | null>();
	const selectedCompany = useSelectedCompanyStore((s) => s.company);

	const onAvatarSelect = (e: any) => {
		if (!data) return;
		const file = e.target.files[0];
		setUserAvatar([{ id: data.self.id, file: null }, new Map([['variables.file', file]])])
			.then((res) => {
				if (avatarRefetch) {
					console.log('call refetch');
					avatarRefetch();
				}
				queryClient.invalidateQueries(selfQueryKey);
			})
			.catch((err) => {
				console.log(`Failed to upload avatar `, err);
			});
	};
	const openFileInput = () => {
		fileInputElem.current && fileInputElem.current.click();
	};

	if (isLoading || !data) return <Spin />;
	if (isError) return <WarningFilled />;

	const avatarData = {
		surname: data.self.surname,
		lastname: data.self.lastname,
		uri: data.self.avatar?.uri,
		id: data.self.avatar?.id,
	};

	const onChangeLanguage = (lang?: string) => {
		console.log('Change language');
		if (lang) {
			changeLanguage(lang.toLowerCase());
			updateLang({ lang: lang as Languages }).then(() => {
				queryClient.invalidateQueries(selfQueryKey);
			});
		}
	};

	const createEditable = (property: 'surname' | 'lastname' | 'role' | 'phone') => {
		return {
			onChange: async (x: any) => {
				if (x === data.self[property]) return;
				data.self[property] = x;
				await updateUser({ input: { [property]: x } });
				refetch();
			},
		};
	};

	return (
		<div className="Narrow">
			<Helmet>
				<title>{t('Meta.Settings.Title')}</title>
				<meta name="description" content={t('Meta.Settings.Description')} />
			</Helmet>
			<input
				type="file"
				id="file"
				onChange={onAvatarSelect}
				ref={fileInputElem}
				style={{ display: 'none' }}
			/>
			<Breadcrumb
				items={[{ type: 'user', display: `${data.self.surname} ${data.self.lastname}` }]}
			/>
			<Row align="middle" gutter={[16, 16]} style={{ textAlign: 'left' }}>
				<Col flex="auto" className="gutter-row">
					<Title style={{ margin: 0 }} level={2}>
						{data.self.surname} {data.self.lastname}
					</Title>
				</Col>
				<Col className="gutter-row">
					<UserAvatar
						className="BoxShadow"
						onClick={() => {
							openFileInput();
						}}
						getRefetchHandle={(refetch) => {
							setAvatarRefetch(refetch);
						}}
						size={96}
						type="circle"
						user={avatarData}
						popover={t('Settings.Avatar.Popover')}
					/>
				</Col>
				{/* <Col span={24} className="gutter-row">
					<Text>ID:</Text> <Text code>{data.self._uuid}</Text>
				</Col> */}
				<Col span={24} className="gutter-row">
					<Text>{t('Settings.Email')}:</Text> <Text strong>{data.self.email}</Text>
				</Col>
				<Col span={24} className="gutter-row">
					<Text>{t('Settings.Firstname')}:</Text>{' '}
					<Text editable={createEditable('surname')} strong>
						{data.self.surname}
					</Text>
				</Col>
				<Col span={24} className="gutter-row">
					<Text>{t('Settings.Lastname')}:</Text>{' '}
					<Text editable={createEditable('lastname')} strong>
						{data.self.lastname}
					</Text>
				</Col>
				<Col span={24} className="gutter-row">
					<Text>{t('Settings.Role')}:</Text>{' '}
					<Text editable={createEditable('role')} strong>
						{data.self.role}
					</Text>
				</Col>
				<Col span={24} className="gutter-row">
					<Text>{t('Phone')}:</Text>{' '}
					<Text editable={createEditable('phone')} strong>
						{data.self.phone}
					</Text>
				</Col>
				<FeatureToggle features={[FeatureNames.MultipleLanguages]}>
					<Col>
						<LanguageSelect
							style={{ minWidth: '150px' }}
							defaultValue={data.self.lang || ''}
							onChange={(e) => onChangeLanguage(e?.toString())}
						/>
					</Col>
				</FeatureToggle>
				<Col flex="auto"></Col>
				<Col>
					<Button disabled onClick={() => {}}>
						{t('Settings.ChangeEmail')}
					</Button>
				</Col>
				<Col>
					<Button onClick={() => navigate('/resetPassword')}>
						{t('Settings.ChangePassword')}
					</Button>
				</Col>
			</Row>
			<Divider />
			{(selectedCompany?.tier ?? 0) >= 3 && (
				<Row>
					<Col>
						<Space>
							<Typography.Text>{t('Settings.DemoSwitchLabel')}</Typography.Text>
							<DemoSwitch />
						</Space>
					</Col>
				</Row>
			)}
		</div>
	);
};
