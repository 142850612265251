import { Button, Col, Empty, Result, Row, Space, Spin } from 'antd';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './Chat.less';
import { WarningFilled } from '@ant-design/icons';
import {
	FeatureNames,
	IncidentParticipantLevel,
	UpdateMessageMutation,
	useMessagesOfIncidentQuery,
} from '../../generated/graphql';
import { ChatInput } from './Input';
import { ChatMessage, Message } from './Message';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Seperator } from './Seperator';
import { Card } from '../util/Card';
import { useAuthStore, UserSession } from '../../hooks/useAuth';
import { useFeatureLock } from '../AdminPortal/FeatureLock';

export interface ChatProps {
	side: 'hinter' | 'caseworker' | 'collaborator';
	incidentId: number;
	style?: any;
	participants: {
		id: number;
		level: IncidentParticipantLevel;
		hinter?: { firstname: string; lastname: string };
		user?: { id: number; firstname: string; lastname: string };
	}[];
}

export const Chat = (props: ChatProps) => {
	const { t, i18n } = useTranslation('translations');
	const { data, isLoading, isError, isSuccess, refetch } = useMessagesOfIncidentQuery(
		{ id: props.incidentId },
		{ refetchInterval: 10000 },
	);
	const [messages, setMessages] = useState<Message[]>([]);
	const lastMessageRef = useRef<HTMLDivElement | null>(null);
	const session = useAuthStore((s) => s.session as UserSession);
	const { locked: chatTabsLocked } = useFeatureLock(FeatureNames.IncidentMultipleChats);

	const [selectedTab, setSelectedTab] = useState<string>('hinter');
	const tabs = useMemo(() => {
		const tabs = new Map<string, string>();
		if (chatTabsLocked || props.side === 'hinter') {
			setSelectedTab('hinter');
			return tabs;
		}
		if (props.side === 'collaborator' && session?.user) {
			const selfParticipant = props.participants.find(
				(p) => p.user?.id === session?.user?.id,
			);
			setSelectedTab(selfParticipant?.user?.id.toString() ?? '');
			return tabs;
		}
		if (props.side === 'caseworker') {
			tabs.set('hinter', t('Chat.Tabs.Hinter.Title'));
			if (
				props.participants.filter((p) => p.level === IncidentParticipantLevel.Caseworker)
					.length > 1
			)
				tabs.set('caseworkers', t('Chat.Tabs.Caseworkers.Title'));
			for (const p of props.participants.filter(
				(p) => p.level === IncidentParticipantLevel.Collaborator,
			)) {
				tabs.set(
					p.user?.id.toString() ?? '',
					`${p.user?.firstname[0]}. ${p.user?.lastname}`,
				);
			}
			return tabs;
		}
		return tabs;
	}, [props.participants, session, props.side, chatTabsLocked]);

	// set of participants for the selected tab
	const participants = useMemo(
		() =>
			// caseworkers can read every chat
			props.participants.filter(
				(p) =>
					p.level === IncidentParticipantLevel.Caseworker ||
					(selectedTab === 'hinter' && p.level === IncidentParticipantLevel.Hinter) ||
					(selectedTab !== 'caseworkers' &&
						p.level === IncidentParticipantLevel.Collaborator &&
						p.user?.id === parseInt(selectedTab)),
			),
		[selectedTab, props.participants],
	);

	useEffect(() => {
		if (isSuccess && data) {
			// const messageIds = messages.map((m) => m.id);
			// const newMessages = data.messagesOfIncident.filter(
			// 	(m) => !messageIds.includes(m.id),
			// );
			// messages that have the selected collaborator as recipient
			switch (selectedTab) {
				case 'hinter':
					setMessages(
						data.messagesOfIncident.filter(
							(m) => m.recipients.find((r) => r.user?.id === undefined) != null,
						),
					);
					break;
				case 'caseworkers':
					setMessages(
						data.messagesOfIncident.filter((m) =>
							m.recipients.every(
								(r) =>
									participants.find((p) => p.user?.id === r.user?.id)?.level ===
									IncidentParticipantLevel.Caseworker,
							),
						),
					);
					break;
				default:
					// filter for tab key recipient id
					setMessages(
						data.messagesOfIncident.filter((m) =>
							m.recipients.find((r) => r.user?.id === parseInt(selectedTab)),
						),
					);
			}
		}
	}, [data, isSuccess, participants]);

	useEffect(() => {
		if (lastMessageRef !== null) {
			lastMessageRef.current?.scrollTo({ top: lastMessageRef.current?.scrollHeight });
		}
	}, [messages, lastMessageRef]);

	const onNewMessage = useCallback(
		(message: UpdateMessageMutation['updateMessage']) => {
			refetch();
		},
		[refetch],
	);

	if (isError) return <WarningFilled />;
	if (isLoading || !data) return <Spin />;
	// disabled chat tabs locking due to a bug that it is incorrectly displayed to users and confuses them
	// if (chatTabsLocked && props.side === 'collaborator')
	// 	return (
	// 		<Result
	// 			status="info"
	// 			title={t('Chat.Disabled.Title')}
	// 			subTitle={t('Chat.Disabled.SubTitle')}
	// 		/>
	// 	);
	return (
		<Space direction="vertical" size="middle" style={{ width: '100%' }}>
			{tabs.size > 1 && (
				<Space style={{ width: '100%' }} wrap>
					{Array.from(tabs.entries()).map(([key, title]) => (
						<Button
							key={key}
							type={selectedTab === key ? 'primary' : 'default'}
							onClick={() => setSelectedTab(key)}
						>
							{title}
						</Button>
					))}
				</Space>
			)}
			<Card hoverable={false}>
				<div className="BoxWrapper">
					<div className="Box" style={{ ...props.style }} ref={lastMessageRef}>
						{messages.length > 0 ? (
							messages
								.sort(
									(a, b) => new Date(a.created).getTime() - new Date(b.created).getTime(),
								)
								.map((message, i) => (
									<div key={message.id}>
										{i === 0 ||
										new Date(messages[i - 1].created).getDate() !==
											new Date(message.created).getDate() ? (
											<Seperator
												text={new Date(message.created).toLocaleDateString(i18n.language)}
											/>
										) : null}
										<ChatMessage
											key={message.id}
											side={props.side === 'hinter' ? 'hinter' : 'user'}
											message={message}
										/>
									</div>
								))
						) : (
							<Row justify="center" align="middle">
								<Col>
									<Empty description={t('Chat.NoMessages')} />
								</Col>
							</Row>
						)}
					</div>
				</div>
				<ChatInput
					incidentId={props.incidentId}
					onNewMessage={onNewMessage}
					participants={participants}
				/>
			</Card>
		</Space>
	);
};
