import React from 'react';

export const Square = (props: React.SVGProps<SVGSVGElement>) => {
	return (
		<svg
			fill="currentColor"
			stroke="currentColor"
			viewBox="0 0 100 150"
			focusable={false}
			preserveAspectRatio="xMidYMid meet"
			{...props}
		>
			<rect
				x="15"
				y="15"
				width="100"
				height="100"
				fill="currentColor"
				stroke="currentColor"
			/>
		</svg>
	);
};
