/**
 * 获取遮罩样式
 *
 * @author yuzhanglong
 * @date 2021-09-14 18:07:12
 * @param element 遮罩的焦点元素，在视觉上体现为这个元素所在的部分可见，其它的部分都会被遮罩遮挡
 * @param container 遮罩的容器，即遮罩的作用范围
 */
export var getMaskStyle = function (element, container) {
    if (!element) {
        return {};
    }
    var containerScrollHeight = container.scrollHeight, containerScrollWidth = container.scrollWidth, containerScrollTop = container.scrollTop, containerScrollLeft = container.scrollLeft;
    var _a = element.getBoundingClientRect(), elementHeight = _a.height, elementWidth = _a.width, elementLeft = _a.left, elementTop = _a.top;
    var elementTopWithScroll = containerScrollTop + elementTop;
    var elementLeftWithScroll = containerScrollLeft + elementLeft;
    return {
        width: containerScrollWidth,
        height: containerScrollHeight,
        borderTopWidth: Math.max(elementTopWithScroll, 0),
        borderLeftWidth: Math.max(elementLeftWithScroll, 0),
        borderBottomWidth: Math.max(containerScrollHeight - elementHeight - elementTopWithScroll, 0),
        borderRightWidth: Math.max(containerScrollWidth - elementWidth - elementLeftWithScroll, 0)
    };
};
