import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import formatAbsolute from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNowStrict';
import formatDuration from 'date-fns/formatDuration';
import formatRelative from 'date-fns/formatRelative';
import { formatTime, getDateFnsLocale } from '../../lib/util';
import { Tooltip } from 'antd';

export const FMT_DATE = 'P';
export const FMT_TIME = 'p';
export const FMT_DATE_TIME = 'Pp';
export const FMT_TIME_NO_SECONDS = 'HH:mm';
export const FMT_DATE_TIME_NO_SECONDS = 'P HH:mm';
export const FMT_DATE_TIME_NO_SECONDS_NO_YEAR = 'MMM d HH:mm';

export interface TimeProps {
	date: string | Date;
	format?: string;
	mode?: 'absolute' | 'distance' | 'relative' | 'adaptive';
	style?: React.CSSProperties;
	addSuffix?: boolean;
	tooltip?: { mode: 'absolute' | 'distance' | 'relative' | 'adaptive' };
}

const formatFunction = (
	date: string | Date,
	locale: string,
	addSuffix: boolean,
	mode: 'absolute' | 'distance' | 'relative' | 'adaptive',
	format?: string,
) => {
	const options = { locale: getDateFnsLocale(locale), addSuffix };
	const parsed = typeof date === 'string' ? new Date(date) : date;
	switch (mode) {
		case 'absolute':
			return formatAbsolute(parsed, format ?? FMT_DATE_TIME, options);
		case 'relative':
			return formatRelative(parsed, new Date(), options);
		case 'distance':
			return formatDistanceToNow(parsed, options);
		case 'adaptive':
			return formatTime(typeof date === 'string' ? date : date.toISOString(), locale);
	}
};

export const Time = ({
	date,
	style,
	format,
	tooltip,
	addSuffix = true,
	mode = 'absolute',
}: TimeProps) => {
	const { i18n } = useTranslation();
	const formatted = useMemo(
		() => formatFunction(date, i18n.language, addSuffix, mode, format),
		[i18n.language, date, mode],
	);
	if (!tooltip && mode !== 'absolute') tooltip = { mode: 'absolute' };

	if (tooltip)
		return (
			<Tooltip title={formatFunction(date, i18n.language, addSuffix, tooltip.mode)}>
				<span style={style}>{formatted}</span>
			</Tooltip>
		);
	return <span style={style}>{formatted}</span>;
};

export interface DurationProps {
	duration: Duration;
	format?: string;
	mode?: 'absolute' | 'distance' | 'relative';
	style?: React.CSSProperties;
	addSuffix?: boolean;
}

export const Duration = ({ duration, style, addSuffix = true }: DurationProps) => {
	const { i18n } = useTranslation();
	const formatted = useMemo(() => {
		const options = { locale: getDateFnsLocale(i18n.language), addSuffix };
		return formatDuration(duration, options);
	}, [i18n.language, duration]);

	return <span style={style}>{formatted}</span>;
};
