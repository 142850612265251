import enGB from 'antd/es/locale/en_GB';
import de from 'antd/es/locale/de_DE';
import fr from 'antd/es/locale/fr_FR';
import pt from 'antd/es/locale/pt_PT';
import el from 'antd/es/locale/el_GR';
import es from 'antd/es/locale/es_ES';
import lt from 'antd/es/locale/lt_LT';
import lv from 'antd/es/locale/lv_LV';
import da from 'antd/es/locale/da_DK';
import nl from 'antd/es/locale/nl_NL';
import sv from 'antd/es/locale/sv_SE';
import is from 'antd/es/locale/is_IS';
import cs from 'antd/es/locale/cs_CZ';
import pl from 'antd/es/locale/pl_PL';
import ro from 'antd/es/locale/ro_RO';
import nb from 'antd/es/locale/nb_NO';
import it from 'antd/es/locale/it_IT';
import hr from 'antd/es/locale/hr_HR';
import hu from 'antd/es/locale/hu_HU';
import et from 'antd/es/locale/et_EE';
import uk from 'antd/es/locale/uk_UA';
import tr from 'antd/es/locale/tr_TR';

import enGBDatePicker from 'antd/es/date-picker/locale/en_GB';
import deDatePicker from 'antd/es/date-picker/locale/de_DE';
import frDatePicker from 'antd/es/date-picker/locale/fr_FR';
import ptDatePicker from 'antd/es/date-picker/locale/pt_PT';
import elDatePicker from 'antd/es/date-picker/locale/el_GR';
import esDatePicker from 'antd/es/date-picker/locale/es_ES';
import ltDatePicker from 'antd/es/date-picker/locale/lt_LT';
import lvDatePicker from 'antd/es/date-picker/locale/lv_LV';
import daDatePicker from 'antd/es/date-picker/locale/da_DK';
import nlDatePicker from 'antd/es/date-picker/locale/nl_NL';
import svDatePicker from 'antd/es/date-picker/locale/sv_SE';
import isDatePicker from 'antd/es/date-picker/locale/is_IS';
import csDatePicker from 'antd/es/date-picker/locale/cs_CZ';
import plDatePicker from 'antd/es/date-picker/locale/pl_PL';
import roDatePicker from 'antd/es/date-picker/locale/ro_RO';
import nbDatePicker from 'antd/es/date-picker/locale/nb_NO';
import itDatePicker from 'antd/es/date-picker/locale/it_IT';
import hrDatePicker from 'antd/es/date-picker/locale/hr_HR';
import huDatePicker from 'antd/es/date-picker/locale/hu_HU';
import etDatePicker from 'antd/es/date-picker/locale/et_EE';
import ukDatePicker from 'antd/es/date-picker/locale/uk_UA';
import trDatePicker from 'antd/es/date-picker/locale/tr_TR';

const ANTD_LOCALES = {
	en: enGB,
	enGB,
	de,
	fr,
	pt,
	el,
	es,
	lt,
	lv,
	da,
	nl,
	sv,
	is,
	cs,
	pl,
	ro,
	no: nb,
	nb,
	it,
	hr,
	hu,
	et,
	uk,
	tr,
};

export const ANTD_DATE_PICKER_LOCALES = {
	en: enGBDatePicker,
	enGB: enGBDatePicker,
	de: deDatePicker,
	fr: frDatePicker,
	pt: ptDatePicker,
	el: elDatePicker,
	es: esDatePicker,
	lt: ltDatePicker,
	lv: lvDatePicker,
	da: daDatePicker,
	nl: nlDatePicker,
	sv: svDatePicker,
	is: isDatePicker,
	cs: csDatePicker,
	pl: plDatePicker,
	ro: roDatePicker,
	no: nbDatePicker,
	nb: nbDatePicker,
	it: itDatePicker,
	hr: hrDatePicker,
	hu: huDatePicker,
	et: etDatePicker,
	uk: ukDatePicker,
	tr: trDatePicker,
};

export default ANTD_LOCALES;
