import { Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router';
import { useAuthStore, useLogout } from '../../hooks/useAuth';
import { useFormattedCountdown } from '../../hooks/useCountdown';
import addMilliseconds from 'date-fns/addMilliseconds';

export interface IdlePrompProps {
	timeoutMinutes: number;
	promptTimeoutMinutes: number;
}

export const IdlePrompt = ({ timeoutMinutes, promptTimeoutMinutes }: IdlePrompProps) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'IdlePrompt' });
	const navigate = useNavigate();
	const isAuthenticated = useAuthStore.useIsAuthenticated();
	const logout = useLogout();

	const { isPrompted, reset, getRemainingTime } = useIdleTimer({
		name: 'idle-timer',
		timeout: 1000 * 60 * timeoutMinutes,
		promptTimeout: 1000 * 60 * promptTimeoutMinutes,
		onIdle: () => logout(t('LogoutReason.Inactivity')),
	});
	const timeLeft = useFormattedCountdown(addMilliseconds(new Date(), getRemainingTime()));

	return isAuthenticated ? (
		<Modal
			visible={isPrompted()}
			title={tt('Title', { minutes: timeoutMinutes })}
			okText={tt('ExtendButton')}
			cancelText={tt('LogoutButton')}
			closable={false}
			onOk={() => reset()}
			onCancel={() => logout(t('LogoutReason.Inactivity'))}
		>
			{tt('Description', { timeLeft: timeLeft.formatted })}
			{timeLeft.formatted}
		</Modal>
	) : null;
};
