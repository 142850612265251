import {
	Descriptions,
	Result,
	Space,
	Spin,
	Empty,
	Typography,
	message,
	Row,
	Col,
	Input,
	Dropdown,
	Modal,
	Menu,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
	PhoneCallDataFragment,
	useAssignPhoneCallToIncidentMutation,
	useMovePhoneCallToCompanyMutation,
	usePhoneCallOfCompanyQuery,
	usePhoneCallsOfIncidentQuery,
	useRemovePhoneCallMutation,
	useUpdatePhoneCallChannelMutation,
	useUpdatePhoneCallProtocolMutation,
} from '../../generated/graphql';
import { getDateFnsLocale } from '../../lib/util';
import { UserAvatar } from '../util/Avatar';
import { Card } from '../util/Card';
import { Time } from '../util/Time';
import formatDuration from 'date-fns/formatDuration';
import { IncidentSelect } from '../util/IncidentSelect';
import { useState } from 'react';
import { Button } from '../util/Button';
import {
	CheckOutlined,
	CloseOutlined,
	DeleteOutlined,
	EditOutlined,
	MoreOutlined,
} from '@ant-design/icons';
import {
	SelectedCompanyType,
	useSelectedCompanyStore,
} from '../../hooks/useSelectedCompanyStore';
import { useQueryClient } from 'react-query';
import { ChannelSelect } from '../util/ChannelSelect';

interface PhoneCallCardProps {
	onNewIncident?: (phoneCall: PhoneCallDataFragment) => void;
	onMoved?: (phoneCall: PhoneCallDataFragment) => void;
	phoneCall: PhoneCallDataFragment;
}

export const PhoneCallCard = ({
	phoneCall,
	onNewIncident,
	onMoved,
}: PhoneCallCardProps) => {
	const { t } = useTranslation('translations');
	const {
		t: tt,
		i18n: { language },
	} = useTranslation('translations', { keyPrefix: 'PhoneCallCard' });
	const qC = useQueryClient();
	const selectedCompany = useSelectedCompanyStore((s) => s.company);
	const [selectedIncidentId, setSelectedIncidentId] = useState<number>();
	const [selectedChannelId, setSelectedChannelId] = useState<number>();
	const [selectedOption, setSelectedOption] =
		useState<{ label: string; value: number }>();
	const { mutateAsync: assign } = useAssignPhoneCallToIncidentMutation();
	const { mutateAsync: updateProtocol } = useUpdatePhoneCallProtocolMutation();
	const { mutateAsync: moveToCompany } = useMovePhoneCallToCompanyMutation();
	const { mutateAsync: updateChannel } = useUpdatePhoneCallChannelMutation();
	const { mutateAsync: removePhoneCall } = useRemovePhoneCallMutation();

	const onAssign = async () => {
		if (!selectedIncidentId) return;
		try {
			const res = await assign({
				phoneCallId: phoneCall.id,
				incidentId: selectedIncidentId,
			});
			message.success(
				tt('Assign.Message.Success', {
					incident: res.assignPhoneCallToIncident.incident?.fileNumber,
				}),
			);
			onMoved && onMoved(res.assignPhoneCallToIncident);
		} catch (e) {
			message.error(tt('Assign.Message.Error'));
		}
	};
	const onMoveToSelectedCompany = async () => {
		if (!selectedCompany) return;
		try {
			const res = await moveToCompany({
				id: phoneCall.id,
				companyId: selectedCompany.id,
			});
			message.success(tt('Move.Message.Success'));
			await qC.invalidateQueries('phonecallsOfCompany');
			onMoved && onMoved(res.movePhoneCallToCompany);
		} catch (e: any) {
			if (e.errors[0]?.extensions?.response?.statusCode === 403)
				message.error(tt('Move.Message.Error.403'));
			else message.error(tt('Move.Message.Error.500'));
		}
	};

	const onUpdateChannel = async (channelId: number) => {
		if (!channelId) return;
		try {
			const res = await updateChannel({
				id: phoneCall.id,
				channelId,
			});
			message.success(tt('UpdateChannel.Message.Success'));
			await qC.invalidateQueries('phonecallsOfCompany');
			onMoved && onMoved(res.updatePhoneCallChannel);
		} catch (e: any) {
			message.error(tt('UpdateChannel.Message.Error'));
		}
	};

	const [protocolEdit, setProtocolEdit] = useState<string>();
	const [editingProtocol, setEditingProtocol] = useState(false);
	const protocolEditable = {
		maxLength: 4000,
		onStart: () => {
			setProtocolEdit(phoneCall.protocol ?? '');
			setEditingProtocol(true);
		},
		onCancel: () => {
			setProtocolEdit(undefined);
			setEditingProtocol(false);
		},
		onChange: (x: string) => setProtocolEdit(x),
		onEnd: async () => {
			if (!protocolEdit || protocolEdit === phoneCall.protocol) return;
			phoneCall.protocol = protocolEdit;
			try {
				await updateProtocol({ id: phoneCall.id, protocol: protocolEdit });
				message.success(t('DefaultMessage.Save.Success'));
			} catch (e) {
				message.error(t('DefaultMessage.Save.Error'));
			}
			setProtocolEdit(undefined);
			setEditingProtocol(false);
		},
	};

	const menuItems = [];

	if (!phoneCall.incident) {
		menuItems.push({
			key: 'delete',
			title: tt('Delete.Button'),
			icon: <DeleteOutlined />,
		});
	}

	const menu =
		menuItems.length > 0 ? (
			<Dropdown
				overlay={
					<Menu
						style={{ overflow: 'hidden' }}
						onClick={({ key }: { key: string }) => {
							switch (key) {
								case 'delete':
									Modal.confirm({
										title: tt('Delete.Confirm.Title'),
										okText: tt('Delete.Confirm.Button'),
										onOk: async () => {
											try {
												await removePhoneCall({ id: phoneCall.id });
												message.success(tt('Delete.Message.Success'));
												onMoved && onMoved(phoneCall);
											} catch (e) {
												message.error(tt('Delete.Message.Error'));
											}
										},
									});
									break;
							}
						}}
					>
						{menuItems.map(({ key, icon, title }) => (
							<Menu.Item key={key} icon={icon}>
								{title}
							</Menu.Item>
						))}
					</Menu>
				}
				trigger={['click']}
			>
				<MoreOutlined />
			</Dropdown>
		) : null;

	return (
		<Card>
			<Space direction="vertical">
				<Row justify="space-between">
					<Col>
						<Typography.Text strong>
							{phoneCall.externalId ?? phoneCall.nanoId}
						</Typography.Text>
					</Col>
					<Col>
						<Space direction="horizontal">
							{phoneCall.user && (
								<UserAvatar
									user={{
										surname: phoneCall.user.surname,
										lastname: phoneCall.user.lastname,
										id: phoneCall.user.avatar?.id,
									}}
									size="small"
								/>
							)}
							{menu}
						</Space>
					</Col>
				</Row>
				<Typography.Text
					type="secondary"
					style={{ wordBreak: 'break-word', fontSize: '12px' }}
				>
					{phoneCall.externalNote}
				</Typography.Text>
				<Descriptions
					labelStyle={{ fontSize: '12px' }}
					contentStyle={{ fontSize: '12px' }}
					column={2}
					size="small"
					layout="horizontal"
				>
					{phoneCall.status && (
						<Descriptions.Item label="Status">{phoneCall.status}</Descriptions.Item>
					)}
					{phoneCall.externalUrl && (
						<Descriptions.Item label="Link">{phoneCall.externalUrl}</Descriptions.Item>
					)}
					{!phoneCall.incident && phoneCall.externalServiceName && (
						<Descriptions.Item label="Service">
							{phoneCall.externalServiceName}
						</Descriptions.Item>
					)}
					{phoneCall.duration && (
						<Descriptions.Item label="Duration">
							{formatDuration(
								{
									seconds: phoneCall.duration / 1000,
								},
								{
									locale: getDateFnsLocale(language),
								},
							)}
						</Descriptions.Item>
					)}
					<Descriptions.Item label="Started">
						<Time mode="relative" date={phoneCall.created} />
					</Descriptions.Item>
					{phoneCall.updated && (
						<Descriptions.Item label="Updated">
							<Time mode="relative" date={phoneCall.updated} />
						</Descriptions.Item>
					)}
					{phoneCall.channel && !phoneCall.incident && (
						<Descriptions.Item label="Channel">
							{phoneCall.incident == null &&
							phoneCall.company.id === selectedCompany?.id ? (
								<ChannelSelect
									size="small"
									value={phoneCall.channel?.id}
									onChange={onUpdateChannel}
									style={{ maxWidth: '190px' }}
								/>
							) : (
								phoneCall.channel.name
							)}
						</Descriptions.Item>
					)}
				</Descriptions>
				{phoneCall.incident === null && (
					<>
						{selectedCompany?.id !== phoneCall.company.id && (
							<Button
								type="dashed"
								size="small"
								onClick={onMoveToSelectedCompany}
								style={{ maxWidth: '100%', height: 'auto', whiteSpace: 'normal' }}
							>
								{tt('Move.Button', { company: selectedCompany?.name })}
							</Button>
						)}
						<Space>
							<IncidentSelect
								size="small"
								value={selectedIncidentId}
								onChange={(v: number, o: { label: string; value: number } | any) => {
									setSelectedIncidentId(v);
									setSelectedOption(o as { label: string; value: number });
								}}
								allowClear={true}
							/>
							{!selectedIncidentId ? (
								onNewIncident && (
									<Button
										size="small"
										type="primary"
										onClick={() => onNewIncident(phoneCall)}
									>
										{tt('NewIncident.Button')}
									</Button>
								)
							) : (
								<Button size="small" type="primary" onClick={onAssign}>
									{tt('Assign.Button', { incident: selectedOption?.label })}
								</Button>
							)}
						</Space>
					</>
				)}
				{phoneCall.incident && (
					<>
						<Typography.Text strong>{tt('Label.Protocol')}</Typography.Text>
						{editingProtocol ? (
							<Space wrap>
								<Input.TextArea
									value={protocolEdit}
									onChange={(e) => setProtocolEdit(e.target.value)}
									maxLength={protocolEditable.maxLength}
									autoSize
								/>
								<Button
									size="small"
									onClick={protocolEditable.onEnd}
									icon={<CheckOutlined />}
								/>
								<Button
									size="small"
									onClick={protocolEditable.onCancel}
									icon={<CloseOutlined />}
								/>
							</Space>
						) : (
							<Space wrap>
								<Typography.Paragraph style={{ marginBottom: 0 }}>
									{phoneCall.protocol ?? tt('ProtocolNeeded')}
								</Typography.Paragraph>
								<Button
									size="small"
									type="text"
									icon={<EditOutlined />}
									onClick={protocolEditable.onStart}
								/>
							</Space>
						)}
					</>
				)}
			</Space>
		</Card>
	);
};

export const UnassignedPhoneCalls = ({
	companyId,
	onNewIncident,
	movableToCompany,
}: {
	onNewIncident: (phoneCall: PhoneCallDataFragment) => void;
	companyId: number;
	movableToCompany?: SelectedCompanyType;
}) => {
	const { t } = useTranslation('translations');
	const { data, isLoading, isSuccess, isError, refetch } = usePhoneCallOfCompanyQuery(
		{
			companyId,
			unassigned: true,
		},
		{
			enabled: companyId !== undefined,
			refetchInterval: 3000,
		},
	);
	if (isLoading)
		return (
			<Card>
				<Spin />
			</Card>
		);
	if (isError)
		return (
			<Card>
				<Result status="error" title={t('ErrorFallback.Result.Title')} />
			</Card>
		);
	if (isSuccess && data?.phonecallsOfCompany.length === 0)
		return (
			<Card>
				<Empty />
			</Card>
		);
	return (
		<Space direction="vertical" style={{ width: '100%' }}>
			{isSuccess &&
				data?.phonecallsOfCompany?.map((p) => (
					<PhoneCallCard
						onNewIncident={onNewIncident}
						key={p.id}
						phoneCall={p}
						onMoved={(pC) => refetch()}
					/>
				))}
		</Space>
	);
};

export const IncidentPhoneCalls = ({ incidentId }: { incidentId: number }) => {
	const { t } = useTranslation('translations');
	const { data, isLoading, isSuccess, isError } = usePhoneCallsOfIncidentQuery({
		incidentId,
	});
	if (isLoading) return <Spin />;
	if (isError) return <Result status="error" title={t('ErrorFallback.Result.Title')} />;
	if (isSuccess && data?.phonecallsOfIncident.length === 0) return null;
	if (isSuccess)
		return (
			<>
				<Row>
					<Col span={24}>
						<Typography.Title level={2}>{t('IncidentPhoneCalls.Title')}</Typography.Title>
					</Col>
				</Row>
				<Row
					gutter={[16, 16]}
					style={{ paddingBottom: '16px', maxHeight: '600px', overflowY: 'auto' }}
				>
					{data?.phonecallsOfIncident?.map((p) => (
						<Col key={p.id} xs={24} sm={12} md={12} lg={8}>
							<PhoneCallCard phoneCall={p} />
						</Col>
					))}
				</Row>
			</>
		);
	return null;
};
