import create, { SetState } from 'zustand';
import { CryptographyKey, SodiumPlus, X25519SecretKey } from 'sodium-plus';
import { generate as generatePassword } from 'generate-password';
import {
	Encrypted,
	PrivateKey,
	publicKeyFromBase64,
	generateKeyPair,
	encryptSym,
	serializeEncrypted,
	deserializeEncrypted,
	decryptSym,
	generateSymmetricKey,
	encryptAsymMultiple,
	decryptAsym,
	createMasterKey,
	deriveMasterKey,
} from '../lib/crypt';
import { useCallback, useEffect, useState } from 'react';
import {
	FeatureNames,
	useHinterQuery,
	useSetIdentityMutation,
	useSetMasterKeySaltMutation,
	useSelfQuery,
} from '../generated/graphql';
import { Roles, useAuthStore } from './useAuth';
import { configurePersist } from '@onlyknoppas/zustand-persist';
import { toBuffer } from '../lib/util';
import { useFeature } from './useFeatures';
import { EWOULDBLOCK } from 'constants';

const { persist, purge } = configurePersist({
	storage: sessionStorage,
	rootKey: 'crypt',
});

export interface CryptStore {
	sodium?: SodiumPlus;
	userId?: number;
	encryptedPrivateKey?: string | null;
	publicKey?: string | null;
	masterKeySalt?: string | null;
	needMasterKeySaltUpload: boolean;
	needIdentityUpload: boolean;
	setUserId: (id: number) => void;
	setNeedMasterKeySaltUpload: (x: boolean) => void;
	setNeedIdentityUpload: (x: boolean) => void;
	setMasterKeySalt: (salt?: string) => void;
	setIdentity: (args: {
		publicKey: string | null;
		encryptedPrivateKey?: string | null;
	}) => void;
	setEncryptedPrivateKey: (key: string) => void;
	setPublicKey: (key: string) => void;
	setSodium: (sodium: SodiumPlus) => void;
	reset: () => void;
}

export const useCryptStore = create<CryptStore>(
	persist(
		{
			key: 'crypt',
			denylist: ['sodium'],
		},
		(set: SetState<CryptStore>) => ({
			needMasterKeySaltUpload: false,
			needIdentityUpload: false,
			setUserId: (userId: number) => set({ userId }),
			setNeedMasterKeySaltUpload: (x) => set((state) => ({ needMasterKeySaltUpload: x })),
			setNeedIdentityUpload: (x) => set((state) => ({ needIdentityUpload: x })),
			setMasterKeySalt: (salt) => {
				set((state) => ({ masterKeySalt: salt }));
			},
			setIdentity: (args) =>
				set((state) => {
					return args;
				}),
			setEncryptedPrivateKey: (encryptedPrivateKey: string) => {
				set((state: CryptStore) => ({
					encryptedPrivateKey,
				}));
			},
			setPublicKey: (publicKey: string) => {
				set((state: CryptStore) => ({
					publicKey,
				}));
			},
			setSodium: (sodium: SodiumPlus) => set((state) => ({ sodium })),
			reset: () => {
				set(
					(state) => ({
						...state,
						needMasterKeySaltUpload: false,
						needIdentityUpload: false,
						encryptedPrivateKey: null,
						publicKey: null,
						masterKeySalt: null,
						needCrypt: false,
					}),
					true,
				);
			},
		}),
	),
);

export interface CryptSensitiveStore {
	needCrypt: boolean;
	setPrivateKey: (key: PrivateKey) => void;
	setMasterKey: (key?: CryptographyKey) => void;
	masterKey?: CryptographyKey | null;
	privateKey?: PrivateKey | null;
	reset: () => void;
	setNeedCrypt: (need: boolean) => void;
}
export const useCryptSensitiveStore = create<CryptSensitiveStore>((set) => ({
	needCrypt: false,
	setMasterKey: (key) =>
		set((state) => {
			return { masterKey: key };
		}),
	setPrivateKey: (privateKey: PrivateKey) => set((state) => ({ privateKey })),
	reset: () => set({ privateKey: null, masterKey: null }),
	setNeedCrypt: (need) => set({ needCrypt: need }),
}));

export const usePasswordGen = (length: number) => {
	return () => generatePassword({ length: 16 });
};

const useGenerateIdentityStore = create((set: any) => ({
	generating: false,
	setGenerating: (generating: boolean) =>
		set((state: any) => ({
			generating,
		})),
}));

export const useGenerateIdentity = () => {
	const setIdentity = useCryptStore((state) => state.setIdentity);
	const { generating, setGenerating } = useGenerateIdentityStore();
	const setNeedIdentityUpload = useCryptStore((state) => state.setNeedIdentityUpload);
	const setPrivateKey = useCryptSensitiveStore((state) => state.setPrivateKey);

	return async () => {
		if (generating) return;
		setGenerating(true);
		try {
			const sodium = await SodiumPlus.auto();
			const { publicKey, secretKey } = await generateKeyPair(sodium);
			const publicKeyBase64 = publicKey.toString('base64');
			setIdentity({ publicKey: publicKeyBase64 });
			setPrivateKey(secretKey);
			setNeedIdentityUpload(true);
			// console.log(
			// 	'New Identity: Public: ',
			// 	publicKey.getBuffer().toString('base64'),
			// 	' Private: ',
			// 	secretKey.getBuffer().toString('base64'),
			// );
			setGenerating(false);
		} catch (e) {
			setGenerating(false);
			throw e;
		}
	};
};

// should be included at root level component
// TODO: make singleton hook to ensure no duplicate instantiation
export const usePGPIdentitySetup = () => {
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);
	const {
		sodium,
		publicKey,
		encryptedPrivateKey,
		needIdentityUpload,
		needMasterKeySaltUpload,
		masterKeySalt,
		setSodium,
		setNeedIdentityUpload,
		setIdentity,
		setEncryptedPrivateKey,
		setMasterKeySalt,
		setNeedMasterKeySaltUpload,
	} = useCryptStore();
	const { privateKey, masterKey, setPrivateKey } = useCryptSensitiveStore();
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const session = useAuthStore((state) => state.session);
	const { data: selfData } = useSelfQuery(
		{},
		{ enabled: isAuthenticated && session?.role === Roles.USER },
	);
	const { data: hinterData, isSuccess: hinterIsSuccess } = useHinterQuery(
		{},
		{ enabled: isAuthenticated && session?.role === Roles.HINTER },
	);
	const { mutateAsync: mutateSetIdentity } = useSetIdentityMutation();
	const { mutateAsync: mutateMasterKeySalt } = useSetMasterKeySaltMutation();
	const generateIdentity = useGenerateIdentity();

	// load sodium
	useEffect(() => {
		if (sodium == null) (async () => setSodium(await SodiumPlus.auto()))();
	}, [setSodium, sodium]);

	// check remote and local identity and generate if not existing
	useEffect(() => {
		const hasLocalIdentity =
			publicKey != null && (encryptedPrivateKey != null || privateKey != null);
		if (e2eEnabled && isAuthenticated) {
			// console.log('ident sync: has local ', hasLocalIdentity);
			let user;
			if (selfData && session?.role === Roles.USER) {
				user = {
					masterKeySalt: selfData.self.masterKeySalt,
					publicKey: selfData.self.publicKey,
					encryptedPrivateKey: selfData.self.encryptedPrivateKey,
				};
			} else if (hinterData && session?.role === Roles.HINTER) {
				user = {
					masterKeySalt: hinterData.hinter.masterKeySalt,
					publicKey: hinterData.hinter.publicKey,
					encryptedPrivateKey: hinterData.hinter.encryptedPrivateKey,
				};
			} else return;

			if (user.masterKeySalt) {
				setMasterKeySalt(user.masterKeySalt);
			}
			if (user.publicKey && user.encryptedPrivateKey) {
				// console.log('ident sync: set local from remote');
				setIdentity({
					publicKey: user.publicKey,
					encryptedPrivateKey: user.encryptedPrivateKey,
				});
			} else if (
				selfData &&
				!selfData?.self.publicKey &&
				!selfData?.self.encryptedPrivateKey
			) {
				if (!hasLocalIdentity) {
					// only generate identities for users not for hinters
					// console.log('ident sync: generate new identity');
					generateIdentity();
				} else {
					// console.log('ident sync: set need upload');
					setNeedIdentityUpload(true);
				}
				// } else {
				// 	console.log('ident sync: do nothing ');
			}
		}
	}, [
		sodium,
		selfData,
		publicKey,
		encryptedPrivateKey,
		privateKey,
		hinterData,
		isAuthenticated,
	]);

	// identity upload
	useEffect(() => {
		if (
			e2eEnabled &&
			isAuthenticated &&
			encryptedPrivateKey &&
			publicKey &&
			needIdentityUpload
		) {
			console.log('mutateIdentity');
			mutateSetIdentity({ private: encryptedPrivateKey, public: publicKey })
				.then((res) => {
					console.log('mutateIdentity Successful');
					setNeedIdentityUpload(false);
				})
				.catch((res) => {
					console.warn('failed to upload identity');
				});
		}
		// TODO: handle identity upload failed
	}, [privateKey, publicKey, needIdentityUpload, encryptedPrivateKey]);

	// de-/encrypt private Key
	useEffect(() => {
		if (!e2eEnabled) return;
		if (sodium && privateKey && masterKey != null && encryptedPrivateKey == null) {
			(async () => {
				// console.log(
				// 	'encrypting privateKey ',
				// 	privateKey.getBuffer().toString('base64'),
				// 	' with masterKey ',
				// 	masterKey.getBuffer().toString('base64'),
				// 	' from salt ',
				// 	masterKeySalt,
				// );
				const encrypted = await encryptSym(sodium, masterKey, privateKey.getBuffer());
				setEncryptedPrivateKey(serializeEncrypted(encrypted));
			})();
		}
		if (
			sodium &&
			isAuthenticated &&
			privateKey == null &&
			masterKey != null &&
			encryptedPrivateKey != null
		) {
			(async () => {
				// console.log(
				// 	'decrypting encryptedPrivateKey ',
				// 	encryptedPrivateKey,
				// 	' with masterKey ',
				// 	masterKey.getBuffer().toString('base64'),
				// 	' from salt ',
				// 	masterKeySalt,
				// );
				const encrypted = deserializeEncrypted(encryptedPrivateKey);
				const decrypted = await decryptSym(sodium, masterKey, encrypted);
				console.log('setPrivateKey');
				const privateKey = new X25519SecretKey(decrypted);
				setPrivateKey(privateKey);
			})();
		}
	}, [sodium, privateKey, masterKey, encryptedPrivateKey]);

	// upload masterKeySalt if needed
	useEffect(() => {
		if (!e2eEnabled || !isAuthenticated || !needMasterKeySaltUpload || !masterKeySalt)
			return;
		(async () => {
			try {
				await mutateMasterKeySalt({ input: masterKeySalt });
				setNeedMasterKeySaltUpload(false);
			} catch (err) {
				console.warn('Failed to set master key salt: ', err);
			}
		})();
	}, [sodium, mutateMasterKeySalt, needMasterKeySaltUpload, masterKeySalt]);
};

export const useManageMasterKey = () => {
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);
	const setMasterKey = useCryptSensitiveStore((state) => state.setMasterKey);
	const setMasterKeySalt = useCryptStore((state) => state.setMasterKeySalt);
	const setNeedMasterKeySaltUpload = useCryptStore(
		(state) => state.setNeedMasterKeySaltUpload,
	);

	const manageMasterKey = async (password: string, salt?: string | null) => {
		if (!e2eEnabled) {
			console.warn('manageMasterKey called when e2eencryption feature was disabled');
			return;
		}
		// derive masterkey from password and save in storage
		const sodium = await SodiumPlus.auto();
		if (!salt) {
			const [key, newSalt] = await createMasterKey(sodium, password);
			setMasterKey(key);
			setMasterKeySalt(newSalt.toString('base64'));
			// console.log('generated masterKeySalt: ', newSalt.toString('base64'));
			setNeedMasterKeySaltUpload(true);
		} else {
			const saltBuffer = Buffer.from(salt, 'base64');
			const key = await deriveMasterKey(sodium, password, saltBuffer);
			setNeedMasterKeySaltUpload(false);
			setMasterKeySalt(salt);
			setMasterKey(key);
		}
	};
	return manageMasterKey;
};

export interface CreatePGPRecipient {
	id?: number;
	publicKey: string;
	encryptedMessageKey?: string;
}

export const useEncrypt = (key?: CryptographyKey | null) => {
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);
	const sodium = useCryptStore((state) => state.sodium);
	const encrypt = async (data: string | Buffer) => {
		if (!e2eEnabled)
			throw new Error('encrypt called when e2eencryption feature was disabled');
		if (key == null || sodium == null)
			throw new Error(
				'Wait for PGP encryption hook ready flag to be true before trying to encrypt',
			);
		return await encryptSym(sodium, key, data);
	};
	return encrypt;
};

export const useEncryptFile = (key?: CryptographyKey | null) => {
	const sodium = useCryptStore((state) => state.sodium);
	const encrypt = useEncrypt(key);

	const encryptFile = async (file: File) => {
		if (key == null || sodium == null)
			throw new Error(
				'Wait for PGP encryption hook ready flag to be true before trying to encrypt',
			);
		const data = await file.arrayBuffer();
		const buffer = await toBuffer(data);
		const { ciphertext, nonce } = await encrypt(buffer);
		const encryptedFile = new File([ciphertext], 'file'); //TODO: decide whether including a filename makes sense
		return { encryptedFile, nonce };
	};
	return encryptFile;
};

export const usePGPEncrypt = (recipients: CreatePGPRecipient[]) => {
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);
	const sodium = useCryptStore((state) => state.sodium);
	const privateKey = useCryptSensitiveStore((state) => state.privateKey);
	const publicKey = useCryptStore((state) => state.publicKey);
	const [key, setKey] = useState<CryptographyKey | null>(null);
	const [encryptedKeys, setEncryptedKeys] = useState<Map<
		number | undefined,
		[string, string]
	> | null>(null);
	const [ready, setReady] = useState(false);
	const encrypt = useEncrypt(key);

	useEffect(() => {
		if (!e2eEnabled || sodium == null) return;
		(async () => {
			const key = await generateSymmetricKey(sodium);
			setKey(key);
		})();
	}, [sodium]);

	useEffect(() => {
		if (!e2eEnabled) return;
		if (
			sodium &&
			key != null &&
			encryptedKeys != null &&
			recipients.length > 0 &&
			privateKey
		) {
			if (!ready) setReady(true);
		} else if (ready) setReady(false);
	}, [sodium, key, encryptedKeys, recipients, privateKey]);

	useEffect(() => {
		if (!e2eEnabled) return;
		if (
			sodium == null ||
			key == null ||
			encryptedKeys != null ||
			recipients.length === 0 ||
			privateKey == null
		)
			return;
		(async () => {
			const encryptedKeys = await encryptAsymMultiple(
				sodium,
				privateKey,
				recipients.map((r) => publicKeyFromBase64(r.publicKey)),
				key.getBuffer(),
			);
			const userToKeyMap = new Map<number | undefined, [string, string]>(
				encryptedKeys.map(([pK, eK]) => [
					recipients.find((r) => r.publicKey === pK)?.id,
					[pK, serializeEncrypted(eK)],
				]),
			);
			setEncryptedKeys(userToKeyMap);
		})();
	}, [sodium, privateKey, recipients, key, encryptedKeys]);

	const setKeyFromEncrypted = useCallback(
		async (encryptedMessageKey: string) => {
			if (!e2eEnabled) {
				throw new Error(
					'setKeyFromEncrpted called when e2eencryption feature was disabled',
				);
			}
			if (!publicKey || !sodium || !privateKey) return;
			setKey(
				new CryptographyKey(
					await decryptAsym(
						sodium,
						privateKey,
						publicKeyFromBase64(publicKey),
						deserializeEncrypted(encryptedMessageKey),
					),
				),
			);
		},
		[sodium, publicKey, privateKey],
	);

	const reset = () => {
		setKey(null);
		setEncryptedKeys(null);
	};

	return {
		key,
		encryptedKeys,
		ready,
		encrypt,
		reset,
		setKeyFromEncrypted,
		setEncryptedKeys,
	};
};

export interface PGPRecipient {
	user?: { id: number; publicKey?: string | null } | null;
	encryptedMessageKey: string;
	encryptionVersion: number;
}

export type DecryptOverload = {
	(encoding: string, ciphertext: Encrypted | string): Promise<string>;
	(encoding: undefined, ciphertext: Encrypted | string): Promise<Buffer>;
};

export const usePGPDecrypt = (
	recipients: PGPRecipient[],
	senderPublicKey: string | null | undefined,
	userId?: number,
) => {
	const sodium = useCryptStore((state) => state.sodium);
	const [ready, setReady] = useState(false);
	const privateKey = useCryptSensitiveStore((state) => state.privateKey);
	const [key, setKey] = useState<CryptographyKey | null>();
	const [userNotInRecipients, setUserNotInRecipients] = useState(false);

	const decrypt: DecryptOverload = useCallback(
		async (encoding: string | undefined, ciphertext: Encrypted | string) => {
			if (key == null || sodium == null)
				throw new Error(
					'Wait for PGP encryption hook ready flag to be true before trying to encrypt',
				);
			if (typeof ciphertext === 'string') {
				ciphertext = deserializeEncrypted(ciphertext);
			}
			const plaintext = await decryptSym(sodium, key, ciphertext);
			if (encoding !== undefined) return plaintext.toString(encoding) as any;
			else return plaintext as any;
		},
		[key, sodium],
	);

	useEffect(() => {
		if (
			sodium != null &&
			key != null &&
			recipients.length > 0 &&
			privateKey != null &&
			!userNotInRecipients &&
			recipients
				.map((r) => r.encryptedMessageKey !== null)
				.reduce((curr, prev) => curr && prev)
		) {
			if (!ready) setReady(true);
		} else if (ready) setReady(false);
	}, [key, recipients, sodium, privateKey, userNotInRecipients]);

	useEffect(() => {
		if (
			sodium == null ||
			privateKey == null ||
			senderPublicKey == null ||
			recipients.length === 0
		) {
			return;
		}
		const selfRecipient = recipients.find((r) => r.user?.id === userId);
		if (!selfRecipient) {
			setUserNotInRecipients(true);
			return;
		}
		(async () => {
			setKey(
				new CryptographyKey(
					await decryptAsym(
						sodium,
						privateKey,
						publicKeyFromBase64(senderPublicKey),
						deserializeEncrypted(selfRecipient.encryptedMessageKey),
					),
				),
			);
		})();
	}, [senderPublicKey, privateKey, userId, sodium, recipients]);

	const reset = () => {
		console.log('decryptReset');
		setReady(false);
		setKey(null);
	};

	return { decrypt, ready, userNotInRecipients, reset };
};
