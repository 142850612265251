import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { noop } from 'lodash';
import { MASK_ANIMATION_TIME } from '../const';
import { getMaskStyle } from '../utils/get-mask-style';
import { resizeObserverChecker } from '../utils/mask-checker/resize-observer';
export var Mask = function (props) {
    var element = props.element, renderMaskContent = props.renderMaskContent, _a = props.visible, visible = _a === void 0 ? true : _a, styleChecker = props.styleChecker, _b = props.onAnimationEnd, onAnimationEnd = _b === void 0 ? noop : _b, _c = props.onAnimationStart, onAnimationStart = _c === void 0 ? noop : _c, container = props.container;
    var _d = useState({}), style = _d[0], setStyle = _d[1];
    var checkStyle = function () {
        element.scrollIntoView({
            block: 'nearest'
        });
        var style = getMaskStyle(element, container || document.documentElement);
        setStyle(style);
    };
    useEffect(function () {
        if (!element) {
            return;
        }
        checkStyle();
    }, [element, container]);
    useEffect(function () {
        if (!element) {
            return;
        }
        var observer = styleChecker || resizeObserverChecker;
        var o = observer(element, checkStyle);
        o.observe();
        return function () {
            o.destroy();
        };
    }, [element, container]);
    useEffect(function () {
        onAnimationStart();
        var t = null;
        t = setTimeout(function () {
            onAnimationEnd();
        }, MASK_ANIMATION_TIME);
        return function () {
            window.clearTimeout(t);
        };
    }, [element]);
    var maskClasses = classNames('mask', 'mask-animation', !visible && 'mask-not-visible');
    var getContent = function () {
        if (!renderMaskContent) {
            return null;
        }
        return renderMaskContent(React.createElement("div", { className: 'mask-content', style: { width: '100%', height: '100%' } }));
    };
    return (React.createElement("div", { style: style, className: maskClasses }, getContent()));
};
