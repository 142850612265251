import { Spin, Space, Typography } from 'antd';
import { useNavigate } from 'react-router';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { UserAvatar } from '../util/Avatar';
import { Button } from '../util/Button';
import { Card } from '../util/Card';
import { CurrentPlanTag, tierToColor, tierToColorBackground } from './Plans';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { accessLevelAbove } from '../../lib/util';
import { AccessLevel } from '../../generated/graphql';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export const SelectedCompanyIndicator = ({
	forceTag = false,
	planTag = false,
}: {
	forceTag?: boolean;
	planTag?: boolean;
}) => {
	const { t } = useTranslation('translations');
	const { t: tAccessLevel } = useTranslation('translations', {
		keyPrefix: 'AccessLevel',
	});
	const selectedCompany = useSelectedCompanyStore((state) => state.company);
	const navigate = useNavigate();
	const [tagVisible, setTagVisible] = useState(false);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);
	const selfAccessLevel = useSelectedCompanyStore(
		(state) => state.company?.selfAccessLevel,
	);

	if (selectedCompany == null) return <Spin />;

	const avatarData = {
		surname: selectedCompany.name.slice(0, 1),
		lastname: selectedCompany.name.slice(1),
		id: selectedCompany.logo?.id,
	};

	return (
		<div
			onMouseEnter={() => {
				setTagVisible(true);
				intervalId && clearInterval(intervalId);
				setIntervalId(setInterval(() => setTagVisible(false), 3000));
			}}
			onMouseLeave={() => {
				intervalId && clearInterval(intervalId);
				setTagVisible(false);
			}}
			style={{ position: 'relative' }}
		>
			{planTag && (
				<motion.div
					variants={{
						hidden: { bottom: 0, opacity: 0 },
						visible: { bottom: -58, opacity: 1 },
					}}
					animate={tagVisible || forceTag ? 'visible' : 'hidden'}
					style={{
						position: 'absolute',
						width: '100%',
						textAlign: 'center',
						padding: '16px',
						bottom: '0',
					}}
				>
					<CurrentPlanTag />
				</motion.div>
			)}
			<Space>
				{selectedCompany?.parent?.logo?.id != null ? (
					<Space>
						<UserAvatar
							type="rectangle"
							size={32}
							user={{ id: selectedCompany.parent.logo.id }}
						/>
						<RightOutlined />
					</Space>
				) : selectedCompany?.parent?.name != null ? (
					<Space>
						<Typography.Text ellipsis style={{ maxWidth: '150px' }}>
							{selectedCompany.parent.name}
						</Typography.Text>
						<RightOutlined />
					</Space>
				) : null}
				<Button
					type={!accessLevelAbove(AccessLevel.User, selfAccessLevel) ? 'text' : 'default'}
					size="large"
					onClick={() => navigate(`/admin/company/${selectedCompany.id}`)}
					style={{
						height: 'auto',
						// outline: '4px solid ' + tierToColorBackground(selectedCompany.tier),
					}}
					disabled={!accessLevelAbove(AccessLevel.User, selfAccessLevel)}
				>
					<Space>
						<UserAvatar type="rectangle" size={32} user={avatarData} />
						{selectedCompany?.logo?.id == null ? (
							<Typography.Text>{selectedCompany?.name}</Typography.Text>
						) : null}
					</Space>
				</Button>
				{selfAccessLevel && (
					<Typography.Text style={{ fontSize: '12px' }} type="secondary">
						{t('AccessRightIndicator', { level: tAccessLevel(selfAccessLevel) })}
					</Typography.Text>
				)}
			</Space>
		</div>
	);
};
