import React, { useEffect, useMemo } from 'react';
import { Alert, Col, Form, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { useState } from 'react';
import {
	CreateCompanyMutation,
	JoinCompanyMutation,
	useCompanyJoinTokenMutation,
	useInviteByEmailMutation,
	useJoinCompanyMutation,
} from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '../../hooks/useAuth';
import { AddressFormItem } from '../Form/AddressFormItem';
import { JoinCompanyForm } from './JoinCompanyForm';
import { CreateCompanyForm, FormType } from './CreateCompanyForm';
import { useRedirectContextStore } from '../Redirect/useRedirectStore';
import { Input } from '../util/Input';
import { InviteModal } from './Invite';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { useNavigate } from 'react-router';
import { useQueryClient } from 'react-query';
import { captureException } from '@sentry/react';

export const JoinCompanyModal = (props: {
	visible: boolean;
	onClose: () => void;
	onSuccess: (company: JoinCompanyMutation['joinCompany']) => void;
}) => {
	const { t: tt } = useTranslation('translations', { keyPrefix: 'JoinCompanyModal' });
	return (
		<Modal
			centered
			title={tt('Title')}
			visible={props.visible}
			destroyOnClose={true}
			onCancel={props.onClose}
			footer={null}
		>
			<JoinCompanyForm onSuccess={(company) => {}} onError={() => {}} />
		</Modal>
	);
};

export const AcceptCompanyInviteModal = (props: {
	visible: boolean;
	onClose: () => void;
	onSuccess?: (company: JoinCompanyMutation['joinCompany']) => void;
}) => {
	const { t } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', {
		keyPrefix: 'AcceptCompanyInviteModal',
	});
	const redirectContext = useRedirectContextStore((state) => state.context);
	const clearJoinCompanyContext = useRedirectContextStore.useClearJoinCompanyContext();
	const { mutateAsync: joinCompany } = useJoinCompanyMutation();
	const navigate = useNavigate();
	const qC = useQueryClient();

	return (
		<Modal
			wrapProps={{ style: { zIndex: 2000 } }}
			centered
			title={tt('Title')}
			visible={props.visible}
			destroyOnClose={true}
			closable={false}
			maskClosable={false}
			onCancel={() => {
				clearJoinCompanyContext();
				props.onClose();
			}}
			cancelText={t('AcceptCompanyInviteModal.CancelText')}
			okText={t('AcceptCompanyInviteModal.OkText')}
			onOk={() => {
				joinCompany({ token: redirectContext?.joinCompany?.token || '' })
					.then((res) => {
						message.success(t('AcceptCompanyInviteModal.Message.Success'));
						props.onSuccess && props.onSuccess(res.joinCompany);
						qC.invalidateQueries();
						navigate('.');
					})
					.catch(() => {
						message.error(t('AcceptCompanyInviteModal.Message.Error'));
					})
					.finally(() => {
						clearJoinCompanyContext();
						props.onClose();
					});
			}}
			// maskStyle={{ backdropFilter: 'blur(10px)' }}
		>
			<Typography.Text strong>
				{t('AcceptCompanyInviteModal.Text', {
					companyName: redirectContext?.joinCompany?.companyName,
				})}
			</Typography.Text>
		</Modal>
	);
};

export const InviteUserModal = (
	props: {
		visible: boolean;
		companyId: number;
		onClose: () => void;
	} & any,
) => {
	const { t } = useTranslation('translations', { keyPrefix: 'InviteUserToCompanyModal' });
	const { visible, companyId, ...modalProps } = props;
	const { mutateAsync: createToken } = useCompanyJoinTokenMutation();
	const [token, setToken] = useState('');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<any>(undefined);
	const { mutateAsync: inviteByMail } = useInviteByEmailMutation();

	useEffect(() => {
		if (props.visible) {
			createToken({ id: props.companyId })
				.then((res: any) => {
					setToken(res.companyJoinToken.token);
					setError(undefined);
				})
				.catch((err: any) => {
					console.error(err);
					captureException(err);
					setError(err);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [props, createToken]);

	const onOk = async (email: string) => {
		try {
			const res = await inviteByMail({
				id: props.companyId,
				email,
				token,
			});
			message.success(t('Success'));
			props.onClose();
		} catch (error: any) {
			if (error?.response?.errors[0]?.extensions?.code === 'BAD_USER_INPUT') {
				message.error(t('Error.AlreadyInvited'));
			}
			return false;
		}
	};

	return (
		<InviteModal
			loading={loading}
			token={token}
			error={error}
			title={t('Title')}
			visible={props.visible}
			onInviteByEmail={onOk}
			entity="company"
			{...modalProps}
		/>
	);
};

export const CreateCompanyModal = ({
	visible,
	onClose,
	onSuccess,
}: {
	visible: boolean;
	onClose: () => void;
	onSuccess: (company: CreateCompanyMutation['createCompany']['company']) => void;
}) => {
	const { t } = useTranslation('translations');
	const [form] = Form.useForm<FormType>();
	const [isLoading, setIsLoading] = useState(false);
	const parent = useSelectedCompanyStore((state) => state.company);

	return (
		<Modal
			centered
			title={
				parent == null
					? t('CreateCompanyModal.Title')
					: t('CreateCompanyModal.TitleChild', { parentName: parent.name })
			}
			visible={visible}
			// okButtonProps={{ style: { display: 'none' } }}
			onOk={() => form.submit()}
			confirmLoading={isLoading}
			onCancel={() => onClose()}
			cancelText={t('CreateCompanyModal.CancelText')}
			destroyOnClose={true}
			footer={null}
		>
			<CreateCompanyForm
				onLoading={setIsLoading}
				onCancel={onClose}
				onSuccess={(company) => {
					onSuccess(company);
					onClose();
				}}
				child={true}
			/>
		</Modal>
	);
};
