import React, { useRef, useState, useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import {
	AudioMutedOutlined,
	AudioOutlined,
	PaperClipOutlined,
	SendOutlined,
	StopOutlined,
} from '@ant-design/icons';
import { useRecorder } from '../../hooks/useRecorder';
import {
	CreateMessageMutation,
	DraftQuery,
	FeatureNames,
	IncidentParticipantLevel,
	MediaType,
	MessageType,
	UpdateMessageMutation,
	useCreateMessageMutation,
	useDraftQuery,
	useIncidentByHinterQuery,
	useIncidentQuery,
	useUpdateMessageAddRecipientMutation,
	useUpdateMessageMutation,
	useUpdateMessageRemoveRecipientMutation,
	useUseMessageTemplateMutation,
} from '../../generated/graphql';
import {
	Col,
	Row,
	Button,
	Descriptions,
	message,
	Space,
	Popover,
	Typography,
} from 'antd';
import { Input } from '../util/Input';
import { MessageAttachment, MessageAttachmentProps } from './MessageAttachment';
import useDebounce from '../../hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { useCryptSensitiveStore, useCryptStore, useEncrypt } from '../../hooks/useCrypt';
import {
	decryptAsym,
	decryptSym,
	deserializeEncrypted,
	encryptAsymMultiple,
	generateSymmetricKey,
	PrivateKey,
	publicKeyFromBase64,
	serializeEncrypted,
} from '../../lib/crypt';
import { HinterSession, Roles, useAuthStore, UserSession } from '../../hooks/useAuth';
import { CryptographyKey } from 'sodium-plus';
import { useFetchGQL } from '../../hooks/useGQL';
import { useFeature } from '../../hooks/useFeatures';
import { useImmer } from 'use-immer';
import { Square } from '../util/Icons';
import { useDistortVoice } from '../../hooks/useREST';
import { Switch } from '../util/Switch';
import { createIdentifiableItemsStore } from '../../hooks/useIdentifiableItemsStore';
import useStateRef from 'react-usestateref';
import { ChatTemplates } from './ChatTemplates';
import * as Sentry from '@sentry/react';
import { use } from 'i18next';
import { captureException } from '@sentry/react';

const useAttchmentsStore = createIdentifiableItemsStore<MessageAttachmentProps>();

export const ChatInput = ({
	incidentId,
	onNewMessage,
	participants,
}: {
	incidentId: number;
	onNewMessage: (m: UpdateMessageMutation['updateMessage']) => void;
	participants: {
		id: number;
		level: IncidentParticipantLevel;
		hinter?: { firstname: string; lastname: string };
		user?: { id: number; firstname: string; lastname: string };
	}[];
}) => {
	const { t } = useTranslation('translations');
	const queryClient = useQueryClient();
	const createMessageMutation = useCreateMessageMutation();
	const updateMessageMutation = useUpdateMessageMutation();
	const updateMessageAddRecipientMutation = useUpdateMessageAddRecipientMutation();
	const updateMessageRemoveRecipientMutation = useUpdateMessageRemoveRecipientMutation();
	const session = useAuthStore.useSession();
	const attachmentsStore = useAttchmentsStore();
	const role = session?.role;
	const userOrHinterId =
		session && session.role === Roles.HINTER
			? (session as HinterSession).hinter?.id
			: (session as UserSession).user?.id;
	const {
		data: draftData,
		isError: draftIsError,
		error: draftError,
		isSuccess: draftIsSuccess,
		refetch: draftRefetch,
	} = useDraftQuery({ incidentId }, { retry: false });
	const incidentQuery = useIncidentQuery(
		{ id: incidentId },
		{ enabled: role === Roles.USER },
	);
	const incidentByHinterQuery = useIncidentByHinterQuery(
		{ hinterId: userOrHinterId || 0 },
		{ enabled: role === Roles.HINTER },
	);
	const incident =
		role === Roles.HINTER
			? incidentByHinterQuery.data?.incidentByHinter
			: incidentQuery.data?.incident;
	const incidentQuerySuccess =
		role === Roles.HINTER ? incidentByHinterQuery.isSuccess : incidentQuery.isSuccess;
	const { mutateAsync: distortVoice } = useDistortVoice();

	const [needCreateDraft, setNeedCreateDraft] = useState(false);
	const [textInptValue, setTextInputValue] = useState('');
	const [messageText, setMessageText] = useState('');
	const [debouncedMessageText, clearDebounce] = useDebounce(1000, messageText);
	const fileInputElem = useRef<HTMLInputElement | null>(null);
	const [anonymizingVoice, setAnonymizingVoice] = useState(false);
	const [anonymizationEnabled, setAnonymizationEnabled] = useState(
		session?.role === Roles.HINTER && incident?.hinter.anonymous,
	);
	const showRecordButton = useMemo(
		() =>
			incidentByHinterQuery?.data?.incidentByHinter?.channel?.config.enableVoiceReports &&
			role === Roles.HINTER,
		[incident, role],
	);
	const [storageConsent, setStorageConsent] = useState(true);
	const voiceAnonymizationFeatureEnabled = useFeature([FeatureNames.VoiceAnonymization]);
	const messageTemplatesFeatureEnabled = useFeature([FeatureNames.MessageTemplates]);
	useEffect(() => {
		incident != null &&
			setAnonymizationEnabled(
				session?.role === Roles.HINTER && incident?.hinter.anonymous,
			);
	}, [session, incident]);
	const { mutateAsync: applyMessageTemplate, isLoading: applyMessageTemplateLoading } =
		useUseMessageTemplateMutation();

	const [draftId, setDraftId, draftIdRef] = useStateRef<number | undefined>(undefined);
	const recipients = useMemo<{ id: number | undefined; publicKey: string }[]>(
		() =>
			participants.map((p) => ({
				id: p.user?.id,
				publicKey: 'dummy',
			})) ?? [],
		[participants],
	);
	const sodium = useCryptStore((state) => state.sodium);
	const selfParticipant = useMemo(
		() =>
			participants.find(
				(p) =>
					(role === Roles.USER && p.user?.id === userOrHinterId) ||
					(role === Roles.HINTER && p.user?.id == null),
			),
		[participants],
	);

	const publicKey = useCryptStore((state) => state.publicKey);
	const privateKey = useCryptSensitiveStore((state) => state.privateKey);
	const [key, setKey] = useState<CryptographyKey | null>(null);
	const [encryptionReady, setEncryptionReady] = useState(false);
	const encrypt = useEncrypt(key);
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);
	const [loading, setLoading] = useState(false);

	const setKeyFromEncrypted = useCallback(
		async (encryptedMessageKey: string, privateKey: PrivateKey) => {
			if (!e2eEnabled)
				throw new Error('setKeyFromEncrypted called when e2e encryption was disabled');
			if (!publicKey || !sodium || !privateKey) return;
			try {
				const newKey = new CryptographyKey(
					await decryptAsym(
						sodium,
						privateKey,
						publicKeyFromBase64(publicKey),
						deserializeEncrypted(encryptedMessageKey),
					),
				);
				setKey(newKey);
				return newKey;
			} catch (err) {
				return undefined;
			}
		},
		[e2eEnabled, sodium, publicKey],
	);

	const updateDraftRecipients = async (draftData: DraftQuery) => {
		// update draft recipients if different from participants
		const removeRecipients = draftData.draft.recipients.filter(
			(r) => !participants.find((p) => p.user?.id === r.user?.id),
		);
		const addRecipients = participants.filter(
			(p) => !draftData.draft.recipients.find((r) => r.user?.id === p.user?.id),
		);
		Promise.all([
			...removeRecipients.map((r) =>
				updateMessageRemoveRecipientMutation
					.mutateAsync({
						messageId: draftData.draft.id,
						recipientId: r.id,
					})
					.catch(captureException),
			),
			...addRecipients.map((r) =>
				updateMessageAddRecipientMutation
					.mutateAsync({
						messageId: draftData.draft.id,
						input: {
							userId: r.user?.id,
							encryptionVersion: 0,
						},
					})
					.catch(captureException),
			),
		]).then(() => {
			draftRefetch();
		});
	};

	useEffect(() => {
		draftData && updateDraftRecipients(draftData);
	}, [draftData, participants]);

	// fetching existing draft was successful
	useEffect(() => {
		if (e2eEnabled && !(sodium && privateKey)) return;
		if (!draftData || !session || !draftIsSuccess || draftId) return;
		(async () => {
			if (e2eEnabled) {
				if (!privateKey || !sodium) return;
				const recipients = draftData.draft.recipients;
				const selfRecipient = recipients.find(
					(r) => r.user?.id === (session as UserSession).user?.id,
				);
				if (selfRecipient == null) {
					console.log(
						'Draft should have the sender as recipient for message decryption!',
					);
					return;
				}
				if (!selfRecipient.encryptedMessageKey) return; // not an encrypted message
				const newKey = await setKeyFromEncrypted(
					selfRecipient.encryptedMessageKey,
					privateKey,
				);
				if (!newKey) {
					console.warn('failed to decrypt and set draft sym key'); // TODO: delete unencryptable draft silently
					return;
				}
				try {
					if (draftData.draft.text && draftData.draft.text.length > 0)
						setTextInputValue(
							(
								await decryptSym(
									sodium,
									newKey,
									deserializeEncrypted(draftData.draft.text),
								)
							).toString('utf-8'),
						);
					else setTextInputValue('');
				} catch (err) {
					console.log('Failed to decrypt the text from the draft.');
					console.log(err);
				}
			} else {
				setMessageText(draftData.draft.text);
				setTextInputValue(draftData.draft.text);
			}
			setDraftId(draftData.draft.id);
			console.log('setDraftId: ', draftData.draft.id);
			draftData.draft.medias.forEach((m) => addUploadedAttachment(m));
			if (e2eEnabled) setEncryptionReady(true);
		})();
	}, [e2eEnabled, sodium, draftData, draftIsSuccess, privateKey, draftId]);

	// fetching existing draft failed
	useEffect(() => {
		if (
			draftIsError &&
			!draftId &&
			(draftError as any).response.errors[0].extensions.response.statusCode === 404
		)
			setNeedCreateDraft(true);
	}, [draftIsError, draftId]);

	useEffect(() => {
		if (e2eEnabled && !(sodium && privateKey && recipients.length > 0 && !key)) return;
		if (!needCreateDraft || !createMessageMutation.isIdle || !incident) return;
		(async () => {
			let res: CreateMessageMutation;
			setNeedCreateDraft(false);
			if (e2eEnabled && sodium && privateKey) {
				setEncryptionReady(false);
				console.log('Generating key');
				const newKey = await generateSymmetricKey(sodium);
				// encrypt new key for recipients
				const encryptedKeys = await encryptAsymMultiple(
					sodium,
					privateKey,
					recipients.map((r) => publicKeyFromBase64(r.publicKey)),
					newKey.getBuffer(),
				);
				console.log('setEncryptedKeys', encryptedKeys);
				const userEncryptedKeysMap: [number | undefined, [string, string]][] =
					encryptedKeys.map(([pK, eK]) => [
						recipients.find((r) => r.publicKey === pK)?.id,
						[pK, serializeEncrypted(eK)],
					]);
				// create new draft
				console.log('creating new draft');
				res = await createMessageMutation.mutateAsync({
					input: {
						recipients: userEncryptedKeysMap.map(([id, keys]) => ({
							userId: id,
							encryptedMessageKey: keys[1],
							encryptionVersion: 1,
						})),
						encryptionVersion: 1,
						type: MessageType.Encrypted,
						incidentId: incidentId,
						text: '',
					},
				});
				setKey(newKey);
				setEncryptionReady(true);
			} else {
				res = await createMessageMutation.mutateAsync({
					input: {
						incidentId: incidentId,
						text: '',
						encryptionVersion: 0,
						type: MessageType.Plain,
						recipients: [
							...participants.map((r) => ({
								encryptionVersion: 0,
								userId: r?.user?.id,
							})),
						],
					},
				});
			}
			setDraftId(res.createMessage.id);
			console.log('setDraftId: ', res.createMessage.id);
		})();
	}, [
		e2eEnabled,
		draftError,
		draftIsError,
		draftId,
		createMessageMutation,
		privateKey,
		key,
		sodium,
		incidentId,
		recipients,
		needCreateDraft,
	]);

	// update draft on text input field change
	useEffect(() => {
		if (e2eEnabled && !(key && encryptionReady)) return;
		console.log(
			draftId,
			!updateMessageMutation.isError,
			!updateMessageMutation.isLoading,
			updateMessageMutation.isIdle,
			debouncedMessageText,
		);
		if (
			draftId &&
			!updateMessageMutation.isError &&
			!updateMessageMutation.isLoading &&
			// updateMessageMutation.isIdle &&
			debouncedMessageText
		)
			(async () => {
				// sync updated draft to server
				const updateText = e2eEnabled
					? serializeEncrypted(await encrypt(debouncedMessageText))
					: debouncedMessageText;
				try {
					await updateMessageMutation.mutateAsync({
						input: {
							id: draftId,
							text: updateText,
						},
					});
				} catch (err) {
					// TODO: handle error
					console.log('update message failed');
				}
				updateMessageMutation.reset();
			})();
	}, [e2eEnabled, debouncedMessageText, draftId]);

	const removeAttachment = (k: number) => {
		const idx = attachmentsStore.items.findIndex((a) => a.id === k);
		console.log(
			'Removing attachment ' +
				k +
				' at index ' +
				idx +
				' from attachments: ' +
				JSON.stringify(Array.from(attachmentsStore.items)),
		);
		attachmentsStore.remove(k);
	};

	const addAttachment = (f: File, type?: MediaType) => {
		if (!draftIdRef.current) return;
		const rndKey = Math.floor(Math.random() * 1000000);
		console.log(
			`add attachment ${rndKey} to draft ${draftIdRef.current} with storageConsent ${storageConsent}`,
		);
		const attachment: MessageAttachmentProps = {
			id: rndKey,
			file: f,
			type,
			messageId: draftIdRef.current,
			encryptionKey: key,
			storageConsent,
			onSuccess: ((id: number) => (m) => {
				console.log(`uploaded attachment ${id}: ${m}`);
				attachmentsStore.update(id, { uploadedMedia: m });
			})(rndKey),
			onRemove: removeAttachment,
		};
		attachmentsStore.add(attachment);
		console.log('adding attachment ' + rndKey);
	};

	const addUploadedAttachment = (
		m: Exclude<MessageAttachmentProps['uploadedMedia'], undefined>,
	) => {
		console.log('add uploaded attachment');
		if (!draftIdRef.current) return;
		const rndKey = Math.floor(Math.random() * 1000000);
		const attachment: MessageAttachmentProps = {
			id: rndKey,
			uploadedMedia: m,
			messageId: draftIdRef.current,
			encryptionKey: key,
			storageConsent,
			onSuccess: (m) => console.log(`uploaded ${m}`),
			onRemove: removeAttachment,
		};
		// only add if item with the same uploaded media not already in store
		attachmentsStore.items.find((a) => a.uploadedMedia?.id === m?.id) ||
			attachmentsStore.add(attachment);
	};

	const clear = () => {
		setDraftId(undefined);
		setEncryptionReady(false);
		setKey(null);
		attachmentsStore.clear();
		setMessageText('');
		setTextInputValue('');
		clearDebounce();
		createMessageMutation.reset();
		setNeedCreateDraft(true);
	};

	const onSend = async () => {
		if (!draftIdRef.current) return;
		const backupDraftId = draftIdRef.current;
		const updateText =
			draftData?.draft.type === MessageType.Encrypted
				? serializeEncrypted(await encrypt(messageText))
				: messageText;
		const input = {
			id: draftIdRef.current,
			text: updateText,
			draft: false,
		};
		try {
			const res = await updateMessageMutation.mutateAsync({ input });
			clear();
			onNewMessage(res.updateMessage);
			queryClient.invalidateQueries('messagesOfIncident');
		} catch (err) {
			setDraftId(backupDraftId);
			console.log('setDraftId to backup: ', backupDraftId);
			alert(t('Chat.Input.Alert'));
		}
	};

	const attach = () => {
		if (fileInputElem.current) {
			console.log('attach');
			fileInputElem.current.click();
		}
	};

	const onFileSelect = (e: any) => {
		console.log('on file select');
		const files = e.target.files as FileList;
		for (let i = 0; i < files.length; i++) {
			const f = files[i];
			addAttachment(f);
		}
	};

	const onRecordedAudio = useCallback(
		(data: Blob) => {
			if (voiceAnonymizationFeatureEnabled && anonymizationEnabled) {
				// distort voice by posting it to the voice distortion api
				setAnonymizingVoice(true);
				console.log('Anonymizing');
				distortVoice(data)
					.then((res) => {
						if (res) {
							addAttachment(
								new File([new Blob([res.data])], 'recording.webm', {
									type: 'audio/webm',
								}),
								MediaType.Audio,
							);
							message.success(t('ReportForm.Media.Anonymization.Voice.Message.Success'));
						}
					})
					.catch((err) => {
						message.error(t('ReportForm.Media.Anonymization.Voice.Message.Error'));
						captureException(err);
					})
					.finally(() => {
						setAnonymizingVoice(false);
					});
			} else {
				addAttachment(
					new File([data], 'recording.webm', { type: 'audio/webm' }),
					MediaType.Audio,
				);
			}
		},
		[anonymizationEnabled, voiceAnonymizationFeatureEnabled, storageConsent],
	);

	const { isRecording, startRecording, stopRecording, time } =
		useRecorder(onRecordedAudio);
	const onStartRecording = () => {
		startRecording();
	};

	const onStopRecording = () => {
		stopRecording();
	};

	const recordButton = showRecordButton ? (
		<Button
			onClick={isRecording ? onStopRecording : onStartRecording}
			size="large"
			icon={
				isRecording ? (
					<Square style={{ verticalAlign: 'middle' }} width="1.5em" height="1.5em" />
				) : (
					<AudioOutlined />
				)
			}
			disabled={(e2eEnabled && !encryptionReady) || !draftId}
		>
			{isRecording ? (
				<>
					{time.minutes}:{time.seconds}
				</>
			) : null}
		</Button>
	) : null;

	const recordButtonPopoverContent =
		role === Roles.HINTER ? (
			<Space direction="vertical">
				{voiceAnonymizationFeatureEnabled && incident?.hinter.anonymous && (
					<>
						<Typography.Text>{t('Chat.AnonymizeVoice')}</Typography.Text>
						<Switch
							checked={anonymizationEnabled}
							onChange={(c) => setAnonymizationEnabled(c)}
						></Switch>
					</>
				)}
				<Typography.Text>{t('Chat.StorageConsent.Title')}</Typography.Text>
				<Switch checked={storageConsent} onChange={(c) => setStorageConsent(c)}></Switch>
			</Space>
		) : null;

	return (
		<Space direction="vertical" size="middle" style={{ width: '100%' }}>
			{/* <p>anonymization: {anonymizationEnabled ? 'enabled' : 'disabled'}</p> */}
			{/* <p>draftId {draftId}</p> */}
			{/*<p>draftIdRef {draftIdRef.current}</p>`` 
		
		
							(e2eEnabled && !encryptionReady) ||
							!draftId ||
							(messageText === '' && attachmentsStore.items.length === 0) ||
							applyMessageTemplateLoading

			*/}
			{/* {applyMessageTemplateLoading && <p>Applying message template...</p>} */}
			{/* <p>{storageConsent + ''}</p> */}
			{/* {draftData?.draft.recipients.map((r) => `${r.id} ${r.user?.id}`)} */}
			{attachmentsStore.items.map((v) => (
				<MessageAttachment key={v.id} {...v} />
			))}
			<Row align="bottom" gutter={[8, 8]}>
				<Col>
					<input
						type="file"
						id="file"
						onChange={onFileSelect}
						ref={fileInputElem}
						style={{ display: 'none' }}
						disabled={(e2eEnabled && !encryptionReady) || !draftId}
					/>
					<Button onClick={attach} icon={<PaperClipOutlined />} size="large"></Button>
				</Col>
				{session?.role === Roles.HINTER && (
					<Col>
						<Popover content={recordButtonPopoverContent}>{recordButton}</Popover>
					</Col>
				)}
				<Col flex="auto">
					<Input.TextArea
						style={{ minHeight: '40px' }}
						maxLength={8000}
						value={
							e2eEnabled && !encryptionReady
								? 'Waiting for E2E encryption setup...'
								: textInptValue
						}
						onChange={(e) => {
							setMessageText(e.target.value);
							setTextInputValue(e.target.value);
						}}
						placeholder={t('Chat.Input.Placeholder')}
						autoSize={{
							minRows: 1,
							maxRows: 6,
						}}
						disabled={
							(e2eEnabled && !encryptionReady) || !draftId || applyMessageTemplateLoading
						}
					/>
				</Col>
				<Col>
					<Button
						type="primary"
						onClick={onSend}
						icon={<SendOutlined />}
						size="large"
						disabled={
							(e2eEnabled && !encryptionReady) ||
							!draftId ||
							(messageText === '' && attachmentsStore.items.length === 0) ||
							applyMessageTemplateLoading
						}
					></Button>
				</Col>
			</Row>
			{role === Roles.USER &&
				selfParticipant?.level === IncidentParticipantLevel.Caseworker &&
				messageTemplatesFeatureEnabled && (
					<ChatTemplates
						incidentId={incidentId}
						style={{
							width: '100%',
						}}
						onUseTemplate={async (templateId) => {
							if (!draftId) {
								return;
							}
							try {
								const res = await applyMessageTemplate({ id: draftId, templateId });
								setTextInputValue(res.useMessageTemplate.text);
								setMessageText(res.useMessageTemplate.text);
								setDraftId(res.useMessageTemplate.id);
								res.useMessageTemplate.medias.forEach((m) => addUploadedAttachment(m));
							} catch (err) {
								Sentry.captureException(err);
								console.error(err);
							}
						}}
						disabled={draftId == null}
					/>
				)}
		</Space>
	);
};
