import { useEffect, useMemo, useState } from 'react';
import {
	useActiveTimelogQuery,
	useUpdateTimelogMutation,
	TimelogDataFragment,
	useIncidentTimelogQuery,
	useCreateTimelogMutation,
	UpdateTimelogDocument,
	useDeleteTimelogMutation,
} from '../../generated/graphql';
import { Card } from '../util/Card';
import intervalToDuration from 'date-fns/intervalToDuration';
import { Col, Empty, message, Row, Space, Spin, Typography, DatePicker } from 'antd';
import { Button } from '../util/Button';
import { Square } from '../util/Icons';
import {
	ArrowRightOutlined,
	CloseOutlined,
	DeleteOutlined,
	DownOutlined,
	EditOutlined,
	LoadingOutlined,
	PlusOutlined,
	SaveOutlined,
	UpOutlined,
} from '@ant-design/icons';
import { Input } from '../util/Input';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../hooks/useDebounce';
import create from 'zustand';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { getDateFnsLocale, getDatePickerLocale } from '../../lib/util';
import moment from 'moment';
import { stringify } from 'querystring';
import isBefore from 'date-fns/isBefore';
import { captureException } from '@sentry/react';

const pad = (num: number, places: number) => String(num).padStart(places, '0');

interface TimelogStore {
	timelog: TimelogDataFragment | undefined;
	intervalId: NodeJS.Timeout | undefined;
	setTimelog: (timelog: TimelogDataFragment | undefined) => void;
	setIntervalId: (interval: NodeJS.Timeout | undefined) => void;
}

const useTimelogStore = createSelectorHooks(
	create<TimelogStore>((set) => ({
		timelog: undefined,
		intervalId: undefined,
		setTimelog: (timelog: TimelogDataFragment | undefined) => set({ timelog }),
		setIntervalId: (interval: NodeJS.Timeout | undefined) =>
			set({ intervalId: interval }),
	})),
);

export const TimelogTimerDropdown = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'Timelog.Timer' });
	const timelog = useTimelogStore.useTimelog();
	const setTimelog = useTimelogStore.useSetTimelog();
	const [descriptionInput, setDescriptionInput] = useState(
		timelog?.description === null ? undefined : timelog?.description,
	);
	const { mutateAsync: update, isLoading } = useUpdateTimelogMutation();

	const onSave = async () => {
		if (timelog == null) return;
		try {
			const updated = await update({
				id: timelog.id,
				input: { description: descriptionInput },
			});
			setTimelog(updated.updateTimelog);
			setDescriptionInput(updated.updateTimelog.description ?? '');
			message.success(t('Description.SaveButton.Message.Success'));
		} catch (err) {
			message.error(t('Description.SaveButton.Message.Error'));
		}
	};

	if (!timelog) return null;

	return (
		<Space direction="vertical">
			<Input.TextArea
				onChange={(e) => setDescriptionInput(e.target.value)}
				value={descriptionInput}
				placeholder={t('Description.Preview')}
				autoSize={{ minRows: 1, maxRows: 4 }}
			/>
			{descriptionInput !== timelog.description && (
				<Button onClick={onSave} size="small" icon={isLoading && <LoadingOutlined />}>
					{t('Description.SaveButton.Label')}
				</Button>
			)}
		</Space>
	);
};

export const TimelogTimer = () => {
	const { t } = useTranslation('translations', { keyPrefix: 'Timelog' });
	let { data, refetch } = useActiveTimelogQuery();
	const [showDropdown, setShowDropdown] = useState(false);
	const [formattedTime, setFormattedTime] = useState('00:00');
	const { mutateAsync: mutateStop } = useUpdateTimelogMutation();
	const { timelog, setTimelog, intervalId, setIntervalId } = useTimelogStore();

	useEffect(() => {
		setTimelog(data?.activeTimelog ?? undefined);
	}, [data?.activeTimelog]);

	useEffect(() => {
		if (timelog != null && timelog.start != null) {
			if (intervalId != null) clearInterval(intervalId);
			setIntervalId(
				setInterval(() => {
					const duration = intervalToDuration({
						start: new Date(timelog.start),
						end: new Date(),
					});
					let t = `${pad(duration.minutes ?? 0, 2)}:${pad(duration.seconds ?? 0, 2)}`;
					if ((duration.hours ?? 0) > 0) t = `${pad(duration.hours ?? 0, 2)}:${t}`;
					setFormattedTime(t);
				}, 1000),
			);
			return () => intervalId && clearInterval(intervalId);
		} else if (intervalId != null) {
			clearInterval(intervalId);
			setIntervalId(undefined);
		}
	}, [timelog]);

	if (timelog == null) return null;

	const onStop = async () => {
		try {
			if (timelog == null) return;
			const res = await mutateStop({
				id: timelog.id ?? 0,
				input: { end: new Date() },
			});
			refetch();
			data = undefined;
			setTimelog(undefined);
			if (intervalId != null) clearInterval(intervalId);
			setFormattedTime('00:00');
		} catch (err) {
			message.error(t('Timer.Stop.Message.Error'));
		}
	};

	return (
		<div style={{ position: 'fixed', bottom: '1em', left: '1em', zIndex: 10 }}>
			<Card>
				<Space direction="vertical">
					<Space>
						<Button
							type="primary"
							onClick={onStop}
							icon={
								<Square
									style={{ verticalAlign: 'middle' }}
									width="1.5em"
									height="1.5em"
								/>
							}
						>
							&nbsp;
							{formattedTime}
						</Button>
						<Typography.Text strong style={{ fontSize: '14px' }}>
							{timelog.incident.fileNumber}
						</Typography.Text>
						<Button
							onClick={() => setShowDropdown((s) => !s)}
							icon={showDropdown ? <UpOutlined /> : <DownOutlined />}
						></Button>
					</Space>
					{/*TODO: animate*/}
					{showDropdown && <TimelogTimerDropdown />}
				</Space>
			</Card>
		</div>
	);
};

export const TimelogEntry = ({
	timelog,
	refetch,
}: {
	timelog: TimelogDataFragment;
	refetch?: () => void;
}) => {
	const { i18n, t } = useTranslation('translations', { keyPrefix: 'Timelog' });
	const duration = useMemo(
		() =>
			intervalToDuration({
				start: new Date(timelog.start),
				end: new Date(timelog.end),
			}),
		[timelog.start, timelog.end],
	);
	const [editStart, setEditStart] = useState<Date>(new Date(timelog.start));
	const [editEnd, setEditEnd] = useState<Date>(new Date(timelog.end));
	const [editDescription, setEditDescription] = useState<string>();

	const [editing, setEditing] = useState(false);
	const { mutateAsync: update } = useUpdateTimelogMutation();
	const { mutateAsync: remove } = useDeleteTimelogMutation();

	if (timelog == null || timelog.start == null || timelog.end == null) return null;

	let formattedDuration = `${pad(duration.minutes ?? 0, 2)}:${pad(
		duration.seconds ?? 0,
		2,
	)}`;
	if ((duration.hours ?? 0) > 0)
		formattedDuration = `${pad(duration.hours ?? 0, 2)}:${formattedDuration}`;

	const onEdit = () => {
		setEditing(true);
		setEditDescription(timelog.description ?? undefined);
		setEditStart(new Date(timelog.start));
		setEditEnd(new Date(timelog.end));
	};

	const onDelete = () => {
		if (timelog == null) return;
		try {
			remove({ id: timelog.id });
			refetch?.();
		} catch (err) {
			message.error(t('Delete.Message.Error'));
		} finally {
			setEditing(false);
		}
	};

	const onSave = async () => {
		try {
			if (
				timelog.description === editDescription &&
				timelog.start === editStart &&
				timelog.end === editEnd
			)
				return;

			if (isBefore(editEnd, editStart))
				return message.error(t('Edit.Message.EndBeforeStart'));

			const input: { description?: string; start?: Date; end?: Date } = {};
			input.description = editDescription;
			input.start = editStart;
			input.end = editEnd;
			const updated = await update({
				id: timelog.id,
				input,
			});
			timelog = updated.updateTimelog;
			message.success(t('Timer.Description.SaveButton.Message.Success'));
		} catch (err) {
			message.error(t('Timer.Description.SaveButton.Message.Error'));
			captureException(err);
		} finally {
			setEditing(false);
		}
		refetch && refetch();
	};

	return (
		<Row gutter={[8, 8]} justify="space-between" align="middle">
			<Col>
				<Typography.Text>
					{timelog.user.surname} {timelog.user.lastname}
				</Typography.Text>
			</Col>
			<Col style={{ textAlign: 'end' }}>
				{editing ? (
					<Space>
						<DatePicker
							style={{ maxWidth: '210px' }}
							showTime={{ format: 'HH:mm' }}
							locale={getDatePickerLocale(i18n.language)}
							value={moment(editStart)}
							onChange={(x) => x && setEditStart(x?.toDate())}
						/>
						<ArrowRightOutlined />
						<DatePicker.TimePicker
							style={{ maxWidth: '120px' }}
							locale={getDatePickerLocale(i18n.language)}
							value={moment(editEnd)}
							onChange={(x) => x && setEditEnd(x?.toDate())}
						/>
					</Space>
				) : (
					<Typography.Text type="secondary">
						{format(new Date(timelog.start), 'p', {
							locale: getDateFnsLocale(i18n.language),
						}) +
							' - ' +
							format(new Date(timelog.end), 'p', {
								locale: getDateFnsLocale(i18n.language),
							})}
					</Typography.Text>
				)}
			</Col>
			<Col style={{ textAlign: 'end' }}>
				<Typography.Text strong>{formattedDuration}</Typography.Text>
			</Col>
			<Col>
				{editing ? (
					<Space>
						<Button size="small" onClick={onDelete} icon={<DeleteOutlined />} />
						<Button size="small" onClick={onSave} icon={<SaveOutlined />} />
					</Space>
				) : (
					<Button size="small" onClick={onEdit} icon={<EditOutlined />} />
				)}
			</Col>
			<Col span={24}>
				{editing ? (
					<Input.TextArea
						value={editDescription}
						onChange={(e) => setEditDescription(e.target.value)}
						placeholder={t('Timer.Description.Preview')}
					/>
				) : (
					<Typography.Paragraph type="secondary">
						{timelog.description}
					</Typography.Paragraph>
				)}
			</Col>
		</Row>
	);
};

export const IncidentTimelog = ({ incidentId }: { incidentId: number }) => {
	const activeLog = useTimelogStore.useTimelog();
	const setTimelog = useTimelogStore.useSetTimelog();
	const { t, i18n } = useTranslation('translations', { keyPrefix: 'Timelog' });
	const { data, isLoading, refetch } = useIncidentTimelogQuery({ id: incidentId });
	const { mutateAsync: mutateCreate } = useCreateTimelogMutation();

	const startLog = async () => {
		try {
			const res = await mutateCreate({
				input: {
					incidentId: incidentId,
					start: new Date(),
				},
			});
			setTimelog(res.createTimelog);
			message.success(t('Create.Message.Success'));
		} catch (err) {
			message.error(t('Create.Message.Error'));
		}
	};

	if (isLoading) return <Spin />;
	return (
		<Row gutter={[16, 16]}>
			<Col span={18}>
				<Typography.Title level={2}>{t('Title')}</Typography.Title>
			</Col>
			{activeLog == null && (
				<Col span={6} style={{ textAlign: 'end' }}>
					<Button
						size="large"
						type="text"
						onClick={startLog}
						icon={<PlusOutlined />}
						style={{ marginLeft: 'auto' }}
					/>
				</Col>
			)}
			{data == null ? (
				<Col span={24}>
					<Empty description={t('Empty')} />
				</Col>
			) : (
				data?.incident.timelogs.map((e, i, a) => {
					const d = new Date(e.start);
					const showDateHeader =
						i === 0 || getDay(d) !== getDay(new Date(a[i - 1].start));
					return (
						<>
							<Col key={e.id} span={24}>
								{showDateHeader && (
									<Typography.Title level={4}>
										{format(d, 'P', { locale: getDateFnsLocale(i18n.language) })}
									</Typography.Title>
								)}
								<TimelogEntry timelog={e} refetch={refetch} />
							</Col>
						</>
					);
				})
			)}
		</Row>
	);
};
