import React, { useState } from 'react';
import { Form, message, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Input } from '../util/Input';
import { Button } from '../util/Button';
import { useChannelEditStore, DesignConfig } from './ChannelEditor';
import { CredentialsForm } from './ReportForm/StepCredentials';
import {
	useCreateSuggestionIncidentMutation,
	CreateIncidentOutput,
} from '../../generated/graphql';
import { useNavigate } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { Card } from '../util/Card';
import { useAuthStore } from '../../hooks/useAuth';

const SuggestionForm = ({ channelId }: { channelId: string | number }) => {
	const navigate = useNavigate();
	const { t } = useTranslation('translations', { keyPrefix: 'SuggestionForm' });
	const { t: tt } = useTranslation('translations');
	const design = useChannelEditStore((state) => state.config?.company);
	const setSessionFromToken = useAuthStore((s) => s.setSessionFromToken);

	const { mutateAsync, isLoading, isSuccess } = useCreateSuggestionIncidentMutation();

	const [password, setPassword] = useState<string>('');

	if (isSuccess && password != null) {
		return (
			<CredentialsForm
				{...{
					design,
					password,
					onSubmit: () => {
						navigate('..');
					},
					buttonLabel: t('CredentialsForm.ButtonLabel'),
					title: t('CredentialsForm.Title'),
				}}
			/>
		);
	} else
		return (
			<>
				<Space direction="vertical" size="middle">
					<Button design={design} onClick={() => navigate('..')}>
						{tt('Back')}
					</Button>
					<Card design={design}>
						<Space style={{ width: '100%' }} direction="vertical" size="large">
							<Title level={3}>{t('Title')}</Title>
							<Text>{t('Description')}</Text>
							<Form
								layout="vertical"
								style={{ width: '100' }}
								onFinish={(values) =>
									mutateAsync({
										input: {
											channelUuid: channelId,
											...values,
											hinter: {
												anonymous: false,
												...values.hinter,
											},
										},
									})
										.then((res) => {
											setPassword(
												res.createSuggestionIncident.hinterCredentials.password,
											);
											message.success(t('Message.Success'));
											const { hinterCredentials } = res.createSuggestionIncident;
											setSessionFromToken(hinterCredentials.session.token as string);
										})
										.catch(() => {
											message.error(t('Message.Error'));
										})
								}
							>
								<Form.Item
									name="subject"
									label={t('Fields.Subject.Label')}
									rules={[
										{
											required: true,
											message: tt('FieldIsMissing', {
												fieldName: t('Fields.Subject.Label'),
											}),
										},
									]}
								>
									<Input design={design} maxLength={512} />
								</Form.Item>
								<Form.Item
									name="description"
									label={t('Fields.Description.Label')}
									rules={[
										{
											required: true,
											message: tt('FieldIsMissing', {
												fieldName: t('Fields.Description.Label'),
											}),
										},
									]}
								>
									<Input.TextArea
										autoSize={{ minRows: 4, maxRows: 12 }}
										design={design}
										maxLength={2048}
									/>
								</Form.Item>
								<Form.Item label={t('ContactFormTitle')} name="hinter" noStyle>
									<Form.Item
										key={0}
										label={t('Fields.Email.Label')}
										name={['hinter', 'email']}
										rules={[
											{
												required: true,
												message: tt('FieldIsMissing', {
													fieldName: t('Fields.Email.Label'),
												}),
											},
										]}
									>
										<Input design={design} maxLength={100} />
									</Form.Item>
									<Form.Item
										label={t('Fields.Firstname.Label')}
										name={['hinter', 'firstname']}
										rules={[
											{
												required: true,
												message: tt('FieldIsMissing', {
													fieldName: t('Fields.Firstname.Label'),
												}),
											},
										]}
									>
										<Input design={design} maxLength={100} />
									</Form.Item>
									<Form.Item
										label={t('Fields.Lastname.Label')}
										name={['hinter', 'lastname']}
										rules={[
											{
												required: true,
												message: tt('FieldIsMissing', {
													fieldName: t('Fields.Lastname.Label'),
												}),
											},
										]}
									>
										<Input design={design} maxLength={100} />
									</Form.Item>
									<Form.Item label={t('Fields.Phone.Label')} name={['hinter', 'phone']}>
										<Input design={design} maxLength={100} />
									</Form.Item>
								</Form.Item>
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Button
										size="large"
										design={design}
										loading={isLoading}
										type="primary"
										htmlType="submit"
									>
										{t('Submit')}
									</Button>
								</div>
							</Form>
						</Space>
					</Card>
				</Space>
			</>
		);
};

export default SuggestionForm;
