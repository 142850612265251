import './IncidentOverview.less';
import React, { useEffect, useMemo, useState } from 'react';
import {
	Avatar,
	Button,
	Col,
	Descriptions,
	message,
	Popover,
	Result,
	Row,
	Space,
	Spin,
	Tooltip,
	Typography,
} from 'antd';
import { Alert } from '../util/Alert';
import { useParams, Routes, Route, useNavigate } from 'react-router-dom';
import { Chat, ChatProps } from '../Chat/Chat';
import { Breadcrumb, BreadcrumbProps } from './Breadcrumb';
import { useTranslation } from 'react-i18next';
import {
	FeatureNames,
	IncidentInviteExternalMutation,
	IncidentInviteInternalMutation,
	IncidentQuery,
	IncidentStatus,
	useCloseIncidentMutation,
	JoinIncidentRequestState,
	useCompanyQuery,
	useConfirmIncidentMutation,
	useIncidentAuditLogQuery,
	useIncidentInviteExternalMutation,
	useIncidentInviteInternalMutation,
	useIncidentQuery,
	useNotesOfIncidentQuery,
	useRejectIncidentMutation,
	useReopenIncidentMutation,
	useSelfQuery,
	useUpdateOnboardingMutation,
	MediaType,
	IncidentType,
	useUpdateIncidentMutation,
	ClaimDataFragment,
	IncidentParticipantLevel,
	AccessLevel,
} from '../../generated/graphql';
import { OnBoarding } from 'antd-onboarding';
import { PGPRecipient, usePGPDecrypt } from '../../hooks/useCrypt';
import { useAuthStore, UserSession } from '../../hooks/useAuth';
import { useFeature } from '../../hooks/useFeatures';
import { Card } from '../util/Card';
import { MediaListItem, MediaThumbnail } from '../util/MediaThumbnail';
import { AuditLogPopup, AuditLogPopupProps } from './AuditLog';
import { Tag, TagDeadline, TagIncidentCategory, TagIncidentStatus } from '../util/Tag';
import { FeatureLock, useFeatureLock } from './FeatureLock';
import { Notes, useNotesHandleStore } from './Note';
import { Helmet } from 'react-helmet';
import { UserAvatar } from '../util/Avatar';
import { Time } from '../util/Time';
import { IncidentTimelog } from './Timelog';
import { FeatureToggle } from '../util/FeatureFlags';
import { ChatTemplates } from '../Chat/ChatTemplates';
import { IncidentPhoneCalls } from './PhoneCall';
import { Transcription } from '../Chat/Transcription';
import { useQueryClient } from 'react-query';
import Paragraph from 'antd/lib/typography/Paragraph';
import { IncidentParticipantsOverview } from './IncidentParticipantsOverview';
import { useSelectedCompanyStore } from '../../hooks/useSelectedCompanyStore';
import { accessLevelAbove } from '../../lib/util';

const IncidentAuditLog = (
	props: Omit<AuditLogPopupProps, 'isLoading' | 'isError' | 'log'> & {
		incidentId: number;
	},
) => {
	const { data, isLoading, isError } = useIncidentAuditLogQuery(
		{ incidentId: props.incidentId },
		{ enabled: props.visible },
	);
	return (
		<AuditLogPopup
			isLoading={isLoading}
			isError={isError}
			log={data?.incident.auditLog || []}
			{...props}
		/>
	);
};

const IncidentClaimInfo = ({ claim }: { claim: ClaimDataFragment }) => {
	const { t } = useTranslation('translations', { keyPrefix: 'IncidentOverview.Claim' });

	const claimRoute = `/portal/claim/`;
	const appUrl = window.location.origin;
	const claimUrl = (appUrl + process.env.REACT_APP_BASENAME + claimRoute).replace(
		'//',
		'/',
	);

	return (
		<Card>
			<Result status="info" title={t('Title')} />
			<Paragraph strong>{t('Code')}</Paragraph>
			<Paragraph code style={{ textAlign: 'center' }}>
				{claim.password}
			</Paragraph>
			<Paragraph type="secondary">
				{t('Description')} <a href={claimUrl}>{claimUrl}</a>
			</Paragraph>
		</Card>
	);
};

const IncidentOverview = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation('translations');
	const { t: tt } = useTranslation('translations', { keyPrefix: 'IncidentOverview' });
	const { id } = useParams();
	const qC = useQueryClient();
	const [onboardingVisible, setOnboardingVisible] = useState(false);
	const e2eEnabled = useFeature([FeatureNames.E2EEncryption]);
	const { locked: phoneCallFeatureLocked } = useFeatureLock(FeatureNames.PhoneCall);
	const { locked: multipleUsersLocked } = useFeatureLock(FeatureNames.MultipleUsers);

	if (id === undefined) {
		throw new Error(tt('MissingIdParam'));
	}
	const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
	const selfQuery = useSelfQuery({}, { enabled: isAuthenticated });
	const updateOnboarding = useUpdateOnboardingMutation();
	const incidentId = parseInt(id);
	const { data, isLoading, isError, isSuccess, error, refetch } = useIncidentQuery(
		{
			id: incidentId,
		},
		{ enabled: isAuthenticated },
	);
	const { mutateAsync: mutateConfirm, isLoading: mutateConfirmLoading } =
		useConfirmIncidentMutation();
	const { mutateAsync: mutateReject, isLoading: mutateRejectLoading } =
		useRejectIncidentMutation();
	const { mutateAsync: mutateReopen, isLoading: mutateReopenLoading } =
		useReopenIncidentMutation();
	const { mutateAsync: mutateClose, isLoading: mutateCloseLoading } =
		useCloseIncidentMutation();
	const { mutateAsync: mutateUpdate, isLoading: mutateUpdateLoading } =
		useUpdateIncidentMutation();
	const [auditLogVisible, setAuditLogVisible] = useState(false);

	const [decrypted, setDecrypted] = useState<{
		subject: string;
		description: string;
	} | null>(null);
	const [recipients, setRecipients] = useState<PGPRecipient[]>([]);
	const session = useAuthStore((state) => state.session as UserSession);
	const { ready: decryptReady, decrypt } = usePGPDecrypt(
		recipients,
		data?.incident.hinter.publicKey,
		session?.user?.id,
	);
	const showClaimInfo =
		isSuccess &&
		data?.incident.manual &&
		data?.incident.claim != null &&
		!data?.incident.claim.claimed;

	const addActionReport = useNotesHandleStore.useAddActionReport();
	const addRejectReason = useNotesHandleStore.useAddRejectReason();

	const chatParticipants = useMemo<ChatProps['participants']>(
		() =>
			data?.incident.recipients.map((r) => {
				if (r.user)
					return {
						id: r.id,
						level: r.level,
						user: {
							id: r.user.id,
							firstname: r.user.firstname ?? '',
							lastname: r.user.lastname ?? '',
						},
					};
				else
					return {
						id: r.id,
						level: r.level,
						hinter: data.incident.hinter?.anonymous
							? {
									lastname: t('AnonymousPerson'),
									firstname: '',
							  }
							: {
									firstname: data.incident.hinter.firstname ?? '',
									lastname: data.incident.hinter.lastname ?? '',
							  },
					};
			}) ?? [],
		[data],
	);

	const selfParticipant = useMemo(() => {
		const self = data?.incident.recipients.find((r) => r.user?.id === session?.user?.id);
		return self;
	}, [data]);

	useEffect(() => {
		if (e2eEnabled && data) {
			const incident = data.incident;
			setRecipients(
				incident.encryptionVersion > 0 &&
					incident.recipients.map((r) => r.encryptionVersion > 0).reduce((p, n) => p && n)
					? (incident.recipients as PGPRecipient[])
					: [],
			);
		}
	}, [data]);

	useEffect(() => {
		if (!data) return;
		if (data.incident.encryptionVersion > 0 && decryptReady)
			(async () => {
				setDecrypted({
					subject: await decrypt('utf-8', data.incident.subject),
					description: await decrypt('utf-8', data.incident.description),
				});
			})();
		else if (data.incident.encryptionVersion === 0)
			(async () => {
				setDecrypted({
					subject: data.incident.subject,
					description: data.incident.description,
				});
			})();
	}, [data, decrypt, decryptReady]);

	const selfAccessLevel = useSelectedCompanyStore(
		(state) => state.company?.selfAccessLevel,
	);

	const breadcrumbs = useMemo(() => {
		if (data == null) return [];
		const b: BreadcrumbProps['items'] = [
			{
				type: 'incident',
				display:
					// <Typography.Text copyable style={{ fontFamily: 'Courier New' }}>
					data.incident.fileNumber ?? data.incident.nanoId,
				// </Typography.Text>,
				routeParam: `${data.incident.id}`,
			},
		];

		if (accessLevelAbove(AccessLevel.User, selfAccessLevel)) {
			b.unshift({
				type: 'channel',
				display: data.incident.channel.name,
				routeParam: `${data.incident.channel.id}`,
			});
			b.unshift({
				type: 'company',
				display: data.incident.channel.company.name,
				routeParam: `${data.incident.channel.company.id}`,
			});
		}
		return b;
	}, [selfAccessLevel, data]);

	if (isLoading || !data) {
		return <Spin />;
	}
	if (isError) {
		return (
			<Result status="error">
				{`${tt('Fetch.Result.Error')}: ${JSON.stringify(error)}`}
			</Result>
		);
	}

	const onConfirm = () => {
		mutateConfirm({ id: data.incident.id })
			.then(() => {
				message.success(tt('Action.Confirm.Message.Success'));
				refetch();
			})
			.catch(() => {
				message.error(tt('Action.Confirm.Message.Error'));
			});
	};

	const saveIncidentEdit =
		(field: 'description' | 'subject') => async (change: string) => {
			if (!data.incident || change === data.incident[field]) return;
			try {
				const res = await mutateUpdate({
					id: data.incident.id,
					input: {
						[field]: change,
					},
				});
				data.incident[field] = res.updateIncident[field];
				qC.invalidateQueries({ queryKey: ['incident'] });
				message.success(t('DefaultMessage.Save.Success'));
			} catch (e) {
				message.error(t('DefaultMessage.Save.Error'));
			}
		};

	const onReject = () => {
		mutateReject({ id: data.incident.id })
			.then(() => {
				message.success(tt('Action.Reject.Message.Success'));
				refetch();
			})
			.catch(() => {
				message.error(tt('Action.Reject.Message.Error'));
			});
	};

	const onReopen = () => {
		mutateReopen({ id: data.incident.id })
			.then(() => {
				message.success(tt('Action.Reopen.Message.Success'));
				refetch();
			})
			.catch(() => {
				message.error(tt('Action.Reopen.Message.Error'));
			});
	};

	const onClose = () => {
		mutateClose({ id: data.incident.id })
			.then(() => {
				message.success(tt('Action.Close.Message.Success'));
				refetch();
			})
			.catch(() => {
				message.error(tt('Action.Close.Message.Error'));
			});
	};

	const deadlineTag = <TagDeadline days={data.incident.deadlineDays} />;

	const Layout = (children: React.ReactNode[]) => (
		<>
			<Helmet>
				<title>
					{t('Meta.Incident.Title', {
						id: data.incident.fileNumber ?? data.incident.nanoId,
					})}
				</title>
				<meta
					name="description"
					content={t('Meta.Incident.Description', {
						id: data.incident.fileNumber ?? data.incident.nanoId,
					})}
				/>
			</Helmet>
			<Row justify="center">
				<Col flex="auto">
					<Space direction="vertical" size="large" style={{ width: '100%' }}>
						<Breadcrumb items={breadcrumbs} />
						{children}
					</Space>
				</Col>
			</Row>
		</>
	);

	const ChatView = (side: 'collaborator' | 'caseworker') => (
		<Space direction="vertical" size="small" style={{ width: '100%' }}>
			<Typography.Title level={2}>{t('IncidentOverview.ChatTitle')}</Typography.Title>
			{showClaimInfo && data?.incident.claim != null ? (
				<IncidentClaimInfo claim={data.incident.claim} />
			) : (
				<Row style={{ marginBottom: '0.5em' }}>
					<Col flex="auto">
						<Chat
							style={{ maxHeight: '70vh' }}
							incidentId={parseInt(id)}
							side={side}
							participants={chatParticipants ?? []}
						/>
					</Col>
				</Row>
			)}
		</Space>
	);

	const CollaboratorView = Layout([ChatView('collaborator')]);

	const CaseworkerView = Layout([
		<IncidentAuditLog
			key={1}
			incidentId={data.incident.id}
			visible={auditLogVisible}
			onClose={() => setAuditLogVisible(false)}
		/>,
		<Row key={2} justify="end" gutter={[16, 16]}>
			<Col xs={24} sm={24} md={24} lg={16}>
				{data.incident.status === 'CREATED' ? (
					<Row id="incidentConfirm">
						<Col>
							<Alert
								message={tt('Action.Confirm.Description')}
								type="info"
								action={
									<Space>
										<Button loading={mutateConfirmLoading} onClick={onConfirm}>
											{tt('Action.Confirm.Button')}
										</Button>
									</Space>
								}
							/>
						</Col>
					</Row>
				) : null}
			</Col>
			<Col flex="auto"></Col>
			<Col>
				<FeatureLock featureName={FeatureNames.AuditLog}>
					<Button onClick={() => setAuditLogVisible(true)}>
						{t('IncidentOverview.OpenAuditLog')}
					</Button>
				</FeatureLock>
			</Col>
			{(data.incident.status === IncidentStatus.Confirmed ||
				data.incident.status === IncidentStatus.Progress) && (
				<Col>
					<Popover
						placement="topRight"
						style={{ maxWidth: '200px' }}
						content={
							<Space direction="vertical" align="end" style={{ maxWidth: '300px' }}>
								<Typography.Text>
									{data.incident.closable === true
										? t('IncidentOverview.Action.Close.Description')
										: t('IncidentOverview.Action.Close.DescriptionDisabled')}
								</Typography.Text>
								{data.incident.closable === false ? (
									<Button type="primary" onClick={addActionReport}>
										{t('IncidentOverview.Action.Close.DescriptionDisabledButton')}
									</Button>
								) : null}
							</Space>
						}
					>
						<Button
							loading={mutateCloseLoading}
							disabled={data.incident.closable !== true}
							onClick={onClose}
						>
							{t('IncidentOverview.Action.Close.Button')}
						</Button>
					</Popover>
				</Col>
			)}
			{(data.incident.status === IncidentStatus.Confirmed ||
				data.incident.status === IncidentStatus.Progress) && (
				<Col>
					<Popover
						placement="topRight"
						style={{ maxWidth: '200px' }}
						content={
							<Space direction="vertical" align="end" style={{ maxWidth: '300px' }}>
								<Typography.Text>
									{data.incident.rejectable === true
										? t('IncidentOverview.Action.Reject.Description')
										: t('IncidentOverview.Action.Reject.DescriptionDisabled')}
								</Typography.Text>
								{data.incident.closable === false ? (
									<Button type="primary" onClick={addRejectReason}>
										{t('IncidentOverview.Action.Reject.DescriptionDisabledButton')}
									</Button>
								) : null}
							</Space>
						}
					>
						<Button
							loading={mutateRejectLoading}
							disabled={data.incident.rejectable !== true}
							onClick={onReject}
						>
							{t('IncidentOverview.Action.Reject.Button')}
						</Button>
					</Popover>
				</Col>
			)}
			{(data.incident.status === IncidentStatus.Rejected ||
				data.incident.status === IncidentStatus.Closed) && (
				<Col>
					<Tooltip
						placement="topLeft"
						style={{ maxWidth: '200px' }}
						title={t('IncidentOverview.Action.Reopen.Description')}
					>
						<Button
							loading={mutateReopenLoading}
							disabled={mutateReopenLoading}
							onClick={onReopen}
						>
							{t('IncidentOverview.Action.Reopen.Button')}
						</Button>
					</Tooltip>
				</Col>
			)}
		</Row>,
		<Row key={3} gutter={[16, 16]}>
			<Col xs={24} sm={24} md={8} lg={8} xl={9} xxl={10} id="incidentInfo">
				<Space size="middle" direction="vertical" style={{ width: '100%' }}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<Typography.Title level={2}>
							{t('IncidentOverview.InfoTitle')}
						</Typography.Title>
						<Card hoverable={false}>
							<Row justify="end" gutter={[8, 8]} style={{ marginBottom: '4px' }}>
								{data.incident.type === IncidentType.Voice && (
									<Col>
										<Tag color="blue">{t('IncidentTypeVocallyTag')}</Tag>
									</Col>
								)}
								<Col>{deadlineTag}</Col>
							</Row>
							<Space direction="vertical" size="small" style={{ width: '100%' }}>
								<Row justify="space-between">
									<Col span={24}>
										<Typography.Paragraph
											editable={
												data.incident.type === IncidentType.Voice && {
													onChange: saveIncidentEdit('subject'),
												}
											}
											strong
										>
											{decrypted != null
												? decrypted.subject
												: `${t('Decrypting')} ${t('incident')}...`}
										</Typography.Paragraph>
										<Typography.Paragraph
											editable={
												data.incident.type === IncidentType.Voice && {
													onChange: saveIncidentEdit('description'),
												}
											}
											style={{ whiteSpace: 'pre-wrap' }}
										>
											{decrypted != null
												? decrypted.description
												: `${t('Decrypting')} ${t('description')}...`}
										</Typography.Paragraph>
									</Col>
									<Col>
										<Space direction="vertical" size={0}>
											<Typography.Text type="secondary">{t('CreatedAt')}</Typography.Text>
											<Typography.Text>
												<Time date={data.incident.created} />
											</Typography.Text>
										</Space>
									</Col>
									<Col>
										<Space direction="vertical" size={0}>
											<Typography.Text type="secondary">{t('UpdatedAt')}</Typography.Text>
											<Typography.Text>
												<Time date={data.incident.updated} mode="relative" />
											</Typography.Text>
										</Space>
										<Space direction="vertical"></Space>
									</Col>
								</Row>
								<Row justify="space-between">
									<Col>
										<Typography.Text type="secondary">{t('Category')}</Typography.Text>
									</Col>
									<Col>
										<TagIncidentCategory
											category={data.incident.category}
											editable={{ incidentId: data.incident.id, minWidth: 200 }}
										/>
									</Col>
								</Row>
								<Row justify="space-between">
									<Col>
										<Typography.Text type="secondary">{t('Status')}</Typography.Text>
									</Col>
									<Col>
										<TagIncidentStatus status={data.incident.status} />
									</Col>
								</Row>
								{data.incident.hinter && !data.incident.hinter?.anonymous ? (
									<Descriptions
										size="small"
										title={t('Hinter')}
										layout="horizontal"
										column={1}
									>
										<Descriptions.Item label={t('Firstname')}>
											<Typography.Text>{data.incident.hinter.firstname}</Typography.Text>
										</Descriptions.Item>
										<Descriptions.Item label={t('Lastname')}>
											<Typography.Text>{data.incident.hinter.lastname}</Typography.Text>
										</Descriptions.Item>
										<Descriptions.Item label={t('Email')}>
											<Typography.Text>{data.incident.hinter.email}</Typography.Text>
										</Descriptions.Item>
										<Descriptions.Item label={t('Phone')}>
											<Typography.Text>{data.incident.hinter.phone}</Typography.Text>
										</Descriptions.Item>
									</Descriptions>
								) : (
									<Row justify="space-between">
										<Col>
											<Typography.Text type="secondary">{t('Anonymous')}</Typography.Text>
										</Col>
									</Row>
								)}
							</Space>
						</Card>
					</Space>
					<Space direction="vertical" size="small" style={{ width: '100%' }}>
						<Typography.Title level={3}>
							{t('IncidentOverview.Participants.Title')}
						</Typography.Title>
						<Row justify="space-between">
							<Col>
								{!multipleUsersLocked && (
									<Button onClick={() => navigate('participants')}>
										{t('IncidentOverview.Participants.Button')}
									</Button>
								)}
							</Col>
							<Col>
								<Avatar.Group>
									{data.incident.recipients
										.filter((r) => r.user != null)
										.map((r, i) => (
											<UserAvatar
												key={r.user?.id || r.user?.avatar?.id || r.user?.lastname}
												user={{
													surname: (r.user as any)?.surname,
													lastname: (r.user as any)?.lastname,
													id: (r.user as any)?.avatar?.id,
												}}
											/>
										))}
								</Avatar.Group>
							</Col>
						</Row>
					</Space>
					{(data.incident.medias?.length || 0) > 0 ? (
						<Space direction="vertical" size="small" style={{ width: '100%' }}>
							<Typography.Title level={2}>
								{t('IncidentOverview.MediaTitle')}
							</Typography.Title>
							<Space wrap>
								{data.incident.medias?.map((m, i) =>
									m.type === MediaType.Audio ? (
										<Transcription side="user" incidentId={data.incident.id} media={m}>
											<MediaListItem key={i} {...m} size={48} />
										</Transcription>
									) : (
										<MediaListItem key={i} {...m} size={48} />
									),
								)}
							</Space>
						</Space>
					) : null}
				</Space>
			</Col>
			<Col id="incidentChat" xs={24} sm={24} md={16} lg={16} xl={15} xxl={14}>
				{ChatView('caseworker')}
			</Col>
		</Row>,
		!phoneCallFeatureLocked && <IncidentPhoneCalls incidentId={parseInt(id)} />,
		<Row key={4} style={{ marginBottom: '0.5em' }}>
			<Col flex="auto">
				<Notes
					onActionReport={() => refetch()}
					onRejectReason={() => refetch()}
					locked={
						data.incident.status === IncidentStatus.Rejected ||
						data.incident.status === IncidentStatus.Closed
					}
					incidentId={parseInt(id)}
				/>
			</Col>
		</Row>,
		<FeatureToggle key={5} features={[FeatureNames.Timelog]}>
			<Row style={{ marginBottom: '0.5em' }}>
				<Col flex="auto">
					<IncidentTimelog incidentId={data.incident.id} />
				</Col>
			</Row>
		</FeatureToggle>,
	]);

	return (
		<Routes>
			<Route
				index
				element={
					selfParticipant?.level === IncidentParticipantLevel.Caseworker
						? CaseworkerView
						: CollaboratorView
				}
			/>
			<Route
				path="participants"
				element={
					<IncidentParticipantsOverview
						incident={data.incident}
						breadcrumbsRoot={breadcrumbs}
					/>
				}
			/>
		</Routes>
	);
};

export default IncidentOverview;
