import { Space, Row, Col, Result, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../util/Button';
import { Input } from '../../util/Input';
import { useChannelEditStore, DesignConfig } from '../ChannelEditor';
import useStore from './useStore';
import { useState } from 'react';

export const CredentialsForm = ({
	password,
	design,
	onSubmit,
	onPasswordConfirm,
	title,
	buttonLabel,
}: {
	password: string;
	design?: DesignConfig;
	onSubmit: () => void;
	onPasswordConfirm?: (confirmedPassword: string) => void;
	title?: string;
	buttonLabel?: string;
}) => {
	const { t } = useTranslation('translations', { keyPrefix: 'ReportForm.Credentials' });
	const [passwordConfirm, setPasswordConfirm] = useState<string>();

	return (
		<Space direction="vertical" size="middle" align="start">
			<Typography.Title level={3}>{title ?? t('Title')}</Typography.Title>
			<Typography.Text style={{ fontSize: '20px' }} code strong copyable>
				{password}
			</Typography.Text>
			<Typography.Text type="danger" strong>
				{t('DontLoosePasswordWarning')}
			</Typography.Text>
			<Typography.Text>{t('CopyPasteInstructions')}</Typography.Text>
			<Input
				maxLength={255}
				design={design}
				onChange={(e) => {
					setPasswordConfirm(e.target.value);
					onPasswordConfirm && onPasswordConfirm(e.target.value);
				}}
				type="password"
				placeholder={t('Placeholder')}
			/>
			<Button
				disabled={password !== passwordConfirm}
				type="primary"
				onClick={onSubmit}
				size="large"
				design={design}
			>
				{buttonLabel ?? t('SubmitButtonText')}
			</Button>
		</Space>
	);
};

const StepCredentials = ({ onSubmit }: { onSubmit: () => void }) => {
	const { t } = useTranslation('translations', { keyPrefix: 'ReportForm.Credentials' });
	const design = useChannelEditStore((state) => state.config?.company);
	const { password, setPasswordConfirm, passwordConfirm } = useStore();

	return (
		<CredentialsForm
			{...{
				password: password ?? '',
				design,
				onSubmit,
				onPasswordConfirm: setPasswordConfirm,
			}}
		/>
	);
};

export default StepCredentials;
